import { IStyle } from '@fluentui/react';
import { CommonStyles, FontWeights, defaultPalette } from '@h2oai/ui-kit';

export interface ILogRowClassNames {
  root: string;
  collapsed: string;
  expanded: string;
  expandedFullText: string;
  header: string;
  logText: string;
  truncate: string;
}

export interface ILogRowStyles {
  root: IStyle;
  collapsed: IStyle;
  expanded: IStyle;
  expandedFullText: IStyle;
  header: IStyle;
  logText: IStyle;
  truncate: IStyle;
}

export const logRowStylesDefault: ILogRowStyles = {
  root: {
    fontFamily: 'inherit',
    padding: '2px 10px',
    color: 'inherit',
  },
  expanded: {
    color: defaultPalette.white,
  },
  logText: {
    fontFamily: 'inherit',
    padding: '2px 10px',
    color: 'inherit',
    paddingTop: '-10px',
  },
  truncate: {
    ...CommonStyles.truncateString,
  },
  expandedFullText: {
    lineHeight: '1.2',
  },
  collapsed: {
    height: 21,
  },
  header: {
    fontWeight: FontWeights.fw700,
  },
};
