import { Checkbox, ICheckboxProps, useThemeStyles } from '@h2oai/ui-kit';

export function ListItemCheckbox(props: ICheckboxProps) {
  const { styles, ..._fProps } = props;
  return (
    <div
      style={{
        borderRadius: 3,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        position: 'absolute',
        display: 'flex',
        left: 0,
        top: 0,
        width: 32,
        height: 32,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Checkbox
        {..._fProps}
        styles={useThemeStyles(
          {
            checkbox: { margin: 0 },
            label: {
              alignItems: 'center',
            },
          },
          styles
        )}
      />
    </div>
  );
}
