import { MessageBar, useClassNames } from '@h2oai/ui-kit';

import { useNotification } from '../../utils/hooks';
import { INotificationBarStyles, notificationBarStylesDefault } from './NotificationBar.styles';

interface INotificationBarClassNames {
  root: string;
  messageBar: string;
}

export function NotificationBar() {
  const { messages, remove } = useNotification();
  const classNames = useClassNames<INotificationBarStyles, INotificationBarClassNames>(
    'NotificationBar',
    notificationBarStylesDefault
  );
  return messages.length ? (
    <div className={classNames.root}>
      {messages.map((m) => {
        const { id, message, onDismiss, ...messageBarProps } = m;
        return (
          <div key={id} className={classNames.messageBar}>
            <MessageBar
              {...messageBarProps}
              onDismiss={
                onDismiss
                  ? () => {
                      remove(m);
                      onDismiss();
                    }
                  : undefined
              }
            >
              {message}
            </MessageBar>
          </div>
        );
      })}
    </div>
  ) : null;
}
