import { Stack, Text } from '@fluentui/react';
import { getDate, useTheme } from '@h2oai/ui-kit';
import { useEffect, useMemo, useRef, useState } from 'react';

import { LogEntry } from '../../../logging-service/gen/ai/h2o/logging/v1/entry.pb';
import { ReadLogEntriesRequest } from '../../../logging-service/gen/ai/h2o/logging/v1/entry_service.pb';
import { useLogs } from '../../../logging-service/hooks';
import { Row } from './Row';

const defaultCollapsedRowHeight = 31;

export const getPageSize = () => {
  return Math.ceil((window.innerHeight - 420) / defaultCollapsedRowHeight);
};
const initialPageSize = 60;

export type LogRow = {
  key: string;
  entry?: LogEntry;
  expanded: boolean;
};

export type InfiniteLogProps = Partial<ReadLogEntriesRequest>;

const defaultNoLogsFoundKey = 'no-logs-available-row';

const getFormattedTimestamp = (timestamp: string): string => {
  const d = getDate(timestamp);
  return d
    ? `${d.getFullYear()}-${`0${d.getMonth() + 1}`.slice(-2)}-${`0${d.getDate()}`.slice(-2)} ${`0${d.getHours()}`.slice(
        -2
      )}:${`0${d.getMinutes()}`.slice(-2)}:${`0${d.getSeconds()}`.slice(-2)}`
    : 'Unknown date';
};

export const getNoLogsAvailable = () => [
  {
    expanded: false,
    entry: {
      payload: 'No logs available for these parameters',
    },
    key: defaultNoLogsFoundKey,
  } as LogRow,
];

const castPayload = (logEntries: LogEntry[], expandedPreviousItems: LogRow[] = []): LogRow[] => {
  return logEntries && logEntries.length
    ? logEntries.map((entry: LogEntry): LogRow => {
        const { timestamp } = entry;
        return {
          key: Date.now() + timestamp,
          entry: {
            ...entry,
            timestamp: getFormattedTimestamp(timestamp),
          },
          expanded: Boolean(
            expandedPreviousItems.length &&
              expandedPreviousItems.findIndex((i) => timestamp === i.entry?.timestamp) >= 0
          ),
        };
      })
    : getNoLogsAvailable();
};

export const InfiniteLog = (props: InfiniteLogProps) => {
  const { search, searchRegex, logNameExclude, startTime, endTime } = props;
  const { palette, defaultFontStyle } = useTheme();

  const [loadingInitialLogs, setLoadingInitialLogs] = useState<boolean>(true),
    [loadingAdditionalLogs, setLoadingAdditionalLogs] = useState<boolean>(false),
    [items, setItems] = useState<LogRow[]>(castPayload([])),
    [topItemsCounter, setTopItemsCounter] = useState<number>(0),
    [topInView, setTopInView] = useState(false),
    [pageToken, setPageToken] = useState<string>(''),
    [soloPage, setSoloPage] = useState<boolean>(false);

  const pageSize = getPageSize();

  const { readLogs } = useLogs();

  const logEndRef = useRef<null | HTMLDivElement>(null);
  const logTopRef = useRef<null | HTMLDivElement>(null);
  const containerRef = useRef<null | HTMLDivElement>(null);

  const highlightRegExp = useMemo(() => {
    if (!search) return undefined;
    // If regex is turned off we need to escape regex chars
    const searchTerm = searchRegex ? search : search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    return new RegExp(`(${searchTerm})`, 'gi');
  }, [search, searchRegex]);

  const readLogEntries = async (
    request: ReadLogEntriesRequest,
    _previousItems?: LogRow[]
  ): Promise<{ rows: LogRow[]; nextPageToken?: string }> => {
    const { logEntries, nextPageToken } = (await readLogs(request)) || {};
    const rows = castPayload(logEntries || []).reverse();
    return { rows, nextPageToken };
  };

  // initial load
  useEffect(() => {
    const load = async () => {
      setLoadingInitialLogs(true);
      const request = { ...props, pageSize: initialPageSize, pageToken: '' },
        { rows, nextPageToken = '' } = await readLogEntries(request);
      setItems(rows);
      setPageToken(nextPageToken);
      setLoadingInitialLogs(false);
      setSoloPage(!nextPageToken || rows.length < initialPageSize);
    };
    load();
  }, [search, logNameExclude, startTime, endTime]);

  // setup intersection observer
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setTopInView(entry.isIntersecting));
    if (logTopRef.current) observer.observe(logTopRef.current);
    return () => {
      observer.disconnect();
    };
  }, [logTopRef]);

  // scroll to bottom
  useEffect(() => {
    /* After initial load is finished, we need one render to occur to fill the
       container with items. Then we want to scroll to the bottom of the container.
       That is why setTimeout is needed. */
    if (!loadingInitialLogs) {
      setTimeout(() => {
        const { current } = logEndRef || {};
        if (current && typeof current.scrollIntoView === 'function') {
          current?.scrollIntoView();
        }
      }, 0);
    }
  }, [loadingInitialLogs]);

  useEffect(() => {
    if (containerRef?.current) {
      containerRef.current.scrollTop = containerRef?.current?.scrollTop + pageSize * defaultCollapsedRowHeight;
    }
  }, [topItemsCounter, containerRef?.current]);

  // scroll up detector
  useEffect(() => {
    /* Even if `items` includes loaded data, we still need to wait for the DOM render data
       rows before `topInView` can be used to know whether user has scrolled to the top 
       of the list. Therefore we need to check child elements of containerRef instead */
    const rowDivs = containerRef?.current?.getElementsByClassName('log-row');
    const rowDataInDom = rowDivs && rowDivs.length > 0;
    if (topInView && !soloPage && rowDataInDom) {
      setLoadingAdditionalLogs(true);
      const load = async () => {
        const request = { ...props, search, logNameExclude, startTime, endTime, pageSize: getPageSize(), pageToken },
          { rows, nextPageToken } = await readLogEntries(request);
        setItems((prevItems) =>
          prevItems.find((i) => i.key === defaultNoLogsFoundKey) ? rows : [...rows.reverse(), ...prevItems]
        );
        setPageToken(String(nextPageToken));
        setLoadingAdditionalLogs(false);
        setTopItemsCounter((prevTop) => prevTop + 1);
      };
      load();
    }
  }, [topInView, search, logNameExclude, startTime, endTime, soloPage, containerRef]);

  return (
    <Stack
      data-test="infinite-log"
      styles={{ root: { height: '100%', fontFamily: 'monospace', color: palette?.gray300 } }}
    >
      <Row
        header
        row={
          {
            entry: { timestamp: 'Timestamp', logName: 'Log Name' },
          } as LogRow
        }
        styles={{ fontFamily: defaultFontStyle?.fontFamily, color: palette?.white, padding: `5px 10px 10px 10px` }}
      />
      <div className="logs-container" ref={containerRef} style={{ height: '100%', overflowY: 'scroll' }}>
        <div ref={logTopRef} style={{ height: 36, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Text
            styles={{
              root: {
                fontFamily: 'inherit',
                color: 'inherit',
                padding: '5px 10px',
                height: 21,
              },
            }}
          >
            {loadingInitialLogs || loadingAdditionalLogs ? 'Loading...' : ''}
          </Text>
        </div>
        {!loadingInitialLogs && items.map((row) => <Row key={row.key} row={row} highlightRegExp={highlightRegExp} />)}
        <div ref={logEndRef} />
      </div>
    </Stack>
  );
};
