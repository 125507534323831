import { AIEngine, EngineConstraintSet, V1EngineType } from '../../../../../aiem';
import { bytesToGibibytes, gibibytesToBytes } from '../../../utils';
import {
  MetadataLabelCell,
  MetadataRow,
  MetadataTable,
  MetadataTableBody,
  MetadataValueCell,
} from '../../MetadataTable/MetadataTable';
import SpinnerWithTooltip from '../../SpinnerWithTooltip/SpinnerWithTooltip';

function generateTooltipMessage(label: string, min?: number | string | null, max?: number | string | null) {
  if (!(min || max)) return undefined;
  if (!max) return `Min: ${min} ${label}. `;
  if (!min) return `Max: ${max} ${label}. `;
  return `Allowed range: ${min}-${max} ${label}. `;
}

interface ConfigureCustomEngineSizeProps {
  engine: AIEngine;
  constraintSet?: EngineConstraintSet;
  memoryValueGb: number;
  storageValueGb: number;
  modifyEngine: any;
}

export default function ConfigureCustomEngineSize({
  engine,
  constraintSet,
  modifyEngine,
  memoryValueGb,
  storageValueGb,
}: ConfigureCustomEngineSizeProps) {
  const engineType = engine?.engineType;

  return (
    <MetadataTable>
      <MetadataTableBody>
        <MetadataRow>
          <MetadataLabelCell colspan={3}>
            {engineType === V1EngineType.H2O ? 'CPUs per Node' : 'CPU Units'}
          </MetadataLabelCell>
          <MetadataValueCell loading={!constraintSet}>
            <SpinnerWithTooltip
              onChange={(_: any, value: number) => {
                modifyEngine({
                  cpu: value,
                });
              }}
              value={engine.cpu || 0}
              min={constraintSet?.cpu?.min}
              max={constraintSet?.cpu?.max}
              tooltip={generateTooltipMessage('CPU unit(s)', constraintSet?.cpu?.min, constraintSet?.cpu?.max)}
            />
          </MetadataValueCell>
        </MetadataRow>
        <MetadataRow>
          <MetadataLabelCell colspan={3}>
            {engineType === V1EngineType.H2O ? 'GPUs per Node' : 'GPU Units'}
          </MetadataLabelCell>
          <MetadataValueCell loading={!constraintSet}>
            <SpinnerWithTooltip
              min={constraintSet?.gpu?.min}
              max={constraintSet?.gpu?.max}
              tooltip={generateTooltipMessage('GPU unit(s)', constraintSet?.gpu?.min, constraintSet?.gpu?.max)}
              onChange={(_: any, value: number) => {
                modifyEngine({
                  gpu: value,
                });
              }}
              value={engine.gpu || 0}
            />
          </MetadataValueCell>
        </MetadataRow>
        {engineType === V1EngineType.H2O && (
          <MetadataRow>
            <MetadataLabelCell colspan={3}>Number of Nodes</MetadataLabelCell>
            <MetadataValueCell loading={!constraintSet}>
              <SpinnerWithTooltip
                onChange={(_: any, value: number) => {
                  modifyEngine({
                    nodeCount: value,
                  });
                }}
                value={engine.nodeCount || 1}
                min={constraintSet?.nodeCount?.min || 1}
                max={constraintSet?.nodeCount?.max}
                tooltip={generateTooltipMessage(
                  'node(s)',
                  constraintSet?.nodeCount?.min,
                  constraintSet?.nodeCount?.max
                )}
              />
            </MetadataValueCell>
          </MetadataRow>
        )}
        <MetadataRow>
          <MetadataLabelCell colspan={3}>
            {engineType === V1EngineType.H2O ? 'Memory per Node' : 'Memory'}
          </MetadataLabelCell>
          <MetadataValueCell loading={!constraintSet}>
            <SpinnerWithTooltip
              min={bytesToGibibytes(constraintSet?.memoryBytes?.min)}
              max={bytesToGibibytes(constraintSet?.memoryBytes?.max)}
              tooltip={generateTooltipMessage(
                'GiB',
                bytesToGibibytes(constraintSet?.memoryBytes?.min),
                bytesToGibibytes(constraintSet?.memoryBytes?.max)
              )}
              onChange={(_: any, value: number) => {
                modifyEngine({
                  memoryBytes: gibibytesToBytes(value),
                });
              }}
              value={memoryValueGb}
              suffix="GiB"
            />
          </MetadataValueCell>
        </MetadataRow>
        {engineType === V1EngineType.DriverlessAi && (
          <MetadataRow>
            <MetadataLabelCell colspan={3}>Storage</MetadataLabelCell>
            <MetadataValueCell loading={!constraintSet}>
              <SpinnerWithTooltip
                min={bytesToGibibytes(constraintSet?.storageBytes?.min)}
                max={bytesToGibibytes(constraintSet?.storageBytes?.max)}
                tooltip={generateTooltipMessage(
                  'GiB',
                  bytesToGibibytes(constraintSet?.storageBytes?.min),
                  bytesToGibibytes(constraintSet?.storageBytes?.max)
                )}
                onChange={(_: any, value: number) => {
                  modifyEngine({
                    storageBytes: gibibytesToBytes(value),
                  });
                }}
                value={storageValueGb}
                suffix="GiB"
              />
            </MetadataValueCell>
          </MetadataRow>
        )}
      </MetadataTableBody>
    </MetadataTable>
  );
}
