/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/dai_engine_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1DAIEngine,
    V1DAIEngineFromJSON,
    V1DAIEngineFromJSONTyped,
    V1DAIEngineToJSON,
} from './V1DAIEngine';

/**
 * Response message for UpgradeVersionRequest.
 * @export
 * @interface V1DAIEngineServiceUpgradeVersionResponse
 */
export interface V1DAIEngineServiceUpgradeVersionResponse {
    /**
     * 
     * @type {V1DAIEngine}
     * @memberof V1DAIEngineServiceUpgradeVersionResponse
     */
    daiEngine?: V1DAIEngine;
}

export function V1DAIEngineServiceUpgradeVersionResponseFromJSON(json: any): V1DAIEngineServiceUpgradeVersionResponse {
    return V1DAIEngineServiceUpgradeVersionResponseFromJSONTyped(json, false);
}

export function V1DAIEngineServiceUpgradeVersionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1DAIEngineServiceUpgradeVersionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'daiEngine': !exists(json, 'daiEngine') ? undefined : V1DAIEngineFromJSON(json['daiEngine']),
    };
}

export function V1DAIEngineServiceUpgradeVersionResponseToJSON(value?: V1DAIEngineServiceUpgradeVersionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'daiEngine': V1DAIEngineToJSON(value.daiEngine),
    };
}

