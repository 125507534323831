/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as fm from "../../../../../fetch.pb"
import * as AiH2oCloudDiscoveryV1Client from "./client.pb"
export type GetClientRequest = {
  name?: string
}

export type GetClientResponse = {
  client?: AiH2oCloudDiscoveryV1Client.Client
}

export type ListClientsRequest = {
  pageSize?: number
  pageToken?: string
}

export type ListClientsResponse = {
  clients?: AiH2oCloudDiscoveryV1Client.Client[]
  nextPageToken?: string
}

export class ClientService {
  static GetClient(req: GetClientRequest, initReq?: fm.InitReq): Promise<GetClientResponse> {
    return fm.fetchReq<GetClientRequest, GetClientResponse>(`/v1/${req["nameclients"]}?${fm.renderURLSearchParams(req, ["nameclients"])}`, {...initReq, method: "GET"})
  }
  static ListClients(req: ListClientsRequest, initReq?: fm.InitReq): Promise<ListClientsResponse> {
    return fm.fetchReq<ListClientsRequest, ListClientsResponse>(`/v1/clients?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
}