import { IButtonStyles, Icon } from '@fluentui/react';
import {
  Button,
  FontWeights,
  IButtonProps,
  IH2OTheme,
  buttonStylesStealth,
  buttonStylesXSmall,
  useTheme,
} from '@h2oai/ui-kit';

import { FederatedApp } from '../../../../ai.h2o.cloud.appstore';

export interface IFederatedAppsStatusIconProps {
  app: FederatedApp;
}

const fontSize = '15px';

const installedIconsStyles = (theme: IH2OTheme) => ({
  fontSize,
  color: theme.palette?.green500,
});

const uninstalledIconStyles = (theme: IH2OTheme) => ({
  fontSize,
  color: theme.palette?.red500,
});

const buttonStylesGet = (theme: IH2OTheme): IButtonStyles => ({
  root: {
    backgroundColor: theme.palette?.gray200,
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  label: {
    textAlign: 'center',
    fontWeight: FontWeights.semiBold,
  },
});

export function FederatedAppsStatusIcon({ app }: IFederatedAppsStatusIconProps) {
  const theme = useTheme();
  const mailTo = (app: FederatedApp) => {
    const subject = `Purchase request for "${app.name}"`;
    const body = `Hello - I would like to purchase "${app.name}" with ID: ${app.id} for my H2O AI Cloud platform.`;
    window.open(`mailto:example@example.com?subject=${subject}&body=${body}`, '_new');
  };
  if (!app) {
    return null;
  } else if (app.preview) {
    const buttonProps: IButtonProps = {
      styles: [buttonStylesStealth, buttonStylesXSmall, buttonStylesGet],
      text: 'Get',
      onClick: () => mailTo(app),
    };
    return <Button {...buttonProps} />;
  } else if (app.runnable) {
    return <Icon iconName="SkypeCircleCheck" styles={{ root: installedIconsStyles(theme) }} />;
  } else {
    return <Icon iconName="StatusErrorFull" styles={{ root: uninstalledIconStyles(theme) }} />;
  }
}
