import { TextWithCopy, TextWithCopyType } from '@h2oai/ui-kit';

import { AccessData, CliAndApiAccessContentType, ContentSection, EponymousLink, LabeledValue } from './Helpers';

const cliSection: ContentSection = {
  id: 'cli-info',
  title: 'Use the H2O CLI',
  sidebarLabel: 'Use the H2O CLI',
  subsections: (data: AccessData) => [
    {
      label: 'What is the CLI',
      type: CliAndApiAccessContentType.TEXT,
      renderContent: () => (
        <p>The H2O CLI allows you to interact with the H2O AI Cloud platform from a command line.</p>
      ),
    },
    {
      label: 'User persona',
      type: CliAndApiAccessContentType.TEXT,
      renderContent: () => (
        <p>
          This is ideal for <b>app developers</b> who would like to programmatically upload and manage their apps and
          for admins who would like to programmatically manage the platform.
        </p>
      ),
    },
    {
      label: 'Learn more',
      type: CliAndApiAccessContentType.TEXT,
      renderContent: () => (
        <p>
          <EponymousLink href="https://docs.h2o.ai/h2o-ai-cloud/developerguide/cli" />
        </p>
      ),
    },
    {
      label: 'Download the latest CLI',
      type: CliAndApiAccessContentType.TEXT,
      renderContent: () => (
        <p>
          <EponymousLink href="https://h2oai-cloud-release.s3.amazonaws.com/releases/ai/h2o/h2o-cloud/latest/index.html" />
        </p>
      ),
    },
    {
      label: 'Configure the CLI',
      type: CliAndApiAccessContentType.TEXT,
      renderContent: () => {
        return (
          <article>
            <p>
              We recommend ensuring the H2O binary is on your <b>PATH</b> for ease of use. If you are not sure how to do
              that, contact your IT administrator for assistance.
            </p>
            <p>
              Using your favorite terminal, run <b>h2o config setup</b> so it knows how to talk to a particular platform
              deployment. You will be prompted to provide values for the configuration.
            </p>
            <LabeledValue label="Endpoint URL">
              <TextWithCopy type={TextWithCopyType.code} text={data.serverAddress} />
            </LabeledValue>
            <LabeledValue label="OIDC URL">
              <TextWithCopy type={TextWithCopyType.code} text={data.oidcProvider} />
            </LabeledValue>
            <LabeledValue label="Client ID">
              <TextWithCopy type={TextWithCopyType.code} text={data.cliClientId} />
            </LabeledValue>
            <LabeledValue label="CLI Token (Visit this link to generate token)">
              <EponymousLink href={data.getTokenUrl} />
            </LabeledValue>
          </article>
        );
      },
    },
  ],
};

export default cliSection;
