import { MessageBarType } from '@fluentui/react';
import { useTheme, useToast } from '@h2oai/ui-kit';
import { ReactNode, useCallback, useMemo } from 'react';

import {
  AIEMOpType,
  AIEngine,
  EngineConstraintSet,
  EngineListRequest,
  EngineListResponse,
  EngineResponse,
  EngineState,
  EngineVersion,
  V1EngineState,
  V1EngineType,
  V1ListEnginesResponse,
  ValidEngineType,
  listDAIProfiles as apiListDAIProfiles,
  constraintSetTypeMap,
  engineTypeService,
  getEndpointCall,
} from '../aiem';
import { customDAIEngineSizeOption } from '../components/AIEnginesPage/constants';
import { TypeVersionSelection, TypeVersionSummary, VersionsSummary } from '../components/AIEnginesPage/filter.utils';
import { useCloudPlatformDiscovery, useStateQueryParams } from '../utils/hooks';
import { handleErrMsg } from '../utils/utils';
import { defaultBasePath, defaultWorkspaceName, defaultLatest as latest } from './defaults';

export enum FilterLogicalOperatorParenthesisType {
  and = ' AND ',
  or = ' OR ',
  closeAnd = ') AND ',
  doubleCloseAnd = ')) AND ',
  tripleCloseAnd = '))) AND ',
  closeOr = ') OR ',
  doubleCloseOr = ')) OR ',
  tripleCloseOr = '))) OR ',
  open = '(',
  doubleOpen = '((',
  tripleOpen = '(((',
  close = ')',
  doubleClose = '))',
  tripleClose = ')))',
  andOpen = ' AND (',
  andDoubleOpen = ' AND ((',
  andTripleOpen = ' AND (((',
  orOpen = ' OR (',
  orDoubleOpen = ' OR ((',
  orTripleOpen = ' OR (((',
}

export type EngineQueryParamsType = {
  searchTerm: string;
  versions: TypeVersionSelection;
  states: string[];
};

enum EngineQueryParamsKeys {
  search = 'search',
  versions = 'versions',
  states = 'states',
}

const invalidCloseTypes = [
    FilterLogicalOperatorParenthesisType.and,
    FilterLogicalOperatorParenthesisType.or,
    FilterLogicalOperatorParenthesisType.andOpen,
    FilterLogicalOperatorParenthesisType.orOpen,
    FilterLogicalOperatorParenthesisType.doubleOpen,
    FilterLogicalOperatorParenthesisType.open,
    FilterLogicalOperatorParenthesisType.tripleOpen,
    FilterLogicalOperatorParenthesisType.or,
  ],
  invalidCloseParenthesisTypes = [
    FilterLogicalOperatorParenthesisType.closeAnd,
    FilterLogicalOperatorParenthesisType.closeOr,
  ],
  invalidDoubleCloseParenthesisTypes = [
    FilterLogicalOperatorParenthesisType.doubleCloseAnd,
    FilterLogicalOperatorParenthesisType.doubleCloseOr,
  ],
  invalidTripleCloseParenthesisTypes = [
    FilterLogicalOperatorParenthesisType.tripleCloseAnd,
    FilterLogicalOperatorParenthesisType.tripleCloseOr,
  ];

export enum FilterValueEnclosingType {
  None = 'none',
  Quote = 'quote',
  Contains = 'contains',
}

export type FilterCondition = {
  name: string;
  value: string;
  enclosingType?: FilterValueEnclosingType;
  openType?: FilterLogicalOperatorParenthesisType;
  closeType?: FilterLogicalOperatorParenthesisType;
};

export const getFilterExpression = (originalConditions: FilterCondition[]) => {
  // this was causing a bug where the originalConditions were being mutated
  // and impacting the UI filter state management
  const conditions = JSON.parse(JSON.stringify(originalConditions)),
    { length } = conditions;
  if (length) {
    const lastCondition = conditions[length - 1],
      { closeType } = lastCondition;
    if (closeType && invalidCloseTypes.includes(closeType)) {
      lastCondition.closeType = undefined;
    }
    if (closeType && invalidCloseParenthesisTypes.includes(closeType)) {
      lastCondition.closeType = FilterLogicalOperatorParenthesisType.close;
    }
    if (closeType && invalidDoubleCloseParenthesisTypes.includes(closeType)) {
      lastCondition.closeType = FilterLogicalOperatorParenthesisType.doubleClose;
    }
    if (closeType && invalidTripleCloseParenthesisTypes.includes(closeType)) {
      lastCondition.closeType = FilterLogicalOperatorParenthesisType.tripleClose;
    }
  }

  const getEnclosing = (type: FilterValueEnclosingType = FilterValueEnclosingType.None, close = false) =>
    type === FilterValueEnclosingType.None
      ? ``
      : `${close && type === FilterValueEnclosingType.Contains ? '*' : ''}"${
          !close && type === FilterValueEnclosingType.Contains ? '*' : ''
        }`;

  const filter = [...conditions]
    ?.map(
      ({ openType, name, enclosingType = FilterValueEnclosingType.None, value, closeType }) =>
        `${openType || ''}${name}=${getEnclosing(enclosingType)}${value}${getEnclosing(enclosingType, true)}${
          closeType || ''
        }`
    )
    .join('');

  return filter;
};

export type BadgeData = {
  id: string;
  title: string;
  backgroundColor?: string;
  borderLeft?: string;
  color?: string;
  renderer?: () => ReactNode | undefined;
};

export function useEngine() {
  const { addToast } = useToast(),
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    basePath = Object(cloudPlatformDiscovery)?.aiEngineManagerApiUrl || defaultBasePath,
    defaultErrorMessage = 'Severe error with AI Engine Manager',
    { palette, semanticColors: Colors } = useTheme(),
    defaultColor = palette?.gray500,
    defaultBackgroundColor = palette?.white,
    EngineStateMap = new Map<EngineState, BadgeData>([
      [
        V1EngineState.Connecting,
        {
          id: V1EngineState.Connecting,
          title: 'Connecting',
          color: defaultColor,
          backgroundColor: defaultBackgroundColor,
        },
      ],
      [
        V1EngineState.Deleting,
        {
          id: V1EngineState.Deleting,
          title: 'Deleting',
          color: defaultColor,
          backgroundColor: defaultBackgroundColor,
        },
      ],
      [
        V1EngineState.Failed,
        {
          id: V1EngineState.Failed,
          title: 'Failed',
          color: palette?.red900,
          backgroundColor: palette?.red100,
        },
      ],
      [
        V1EngineState.Paused,
        {
          id: V1EngineState.Paused,
          title: 'Paused',
          color: palette?.gray900,
          backgroundColor: palette?.gray200,
        },
      ],
      [
        V1EngineState.Pausing,
        {
          id: V1EngineState.Pausing,
          title: 'Pausing',
          color: defaultColor,
          backgroundColor: defaultBackgroundColor,
        },
      ],
      [
        V1EngineState.Running,
        {
          id: V1EngineState.Running,
          title: 'Running',
          color: palette?.green900,
          backgroundColor: palette?.green100,
        },
      ],
      [
        V1EngineState.Starting,
        {
          id: V1EngineState.Starting,
          title: 'Starting',
          color: defaultColor,
          backgroundColor: defaultBackgroundColor,
        },
      ],
      [
        V1EngineState.Unspecified,
        {
          id: V1EngineState.Unspecified,
          title: 'Unspecified',
          color: palette?.white,
          backgroundColor: palette?.black,
        },
      ],
    ]),
    addErrorToast = useCallback(
      (baseError: string, error: Error): void => {
        addToast({
          messageBarType: MessageBarType.error,
          message: `${baseError}: ${handleErrMsg(error.message)}`,
        });
      },
      [addToast]
    ),
    listEngines = useCallback(
      async (params?: EngineListRequest, errorMessage = defaultErrorMessage) => {
        try {
          if (!params || !params?.parent) {
            params = { ...params, parent: defaultWorkspaceName };
          }
          const res = (await getEndpointCall(basePath, AIEMOpType.list)(params as any)) as EngineListResponse;
          return (res as V1ListEnginesResponse).engines;
        } catch (error: unknown) {
          addErrorToast(errorMessage, error as Error);
        }
        return undefined;
      },
      [addErrorToast, basePath]
    ),
    searchEngines = useCallback(async (criteria: FilterCondition[]) => {
      if (!criteria.length) return await listEngines();
      const filter = getFilterExpression(criteria);
      return await listEngines({ filter } as EngineListRequest);
    }, []),
    listVersions = useCallback(
      async (
        engineType: ValidEngineType,
        includeDeprecated = false,
        errorMessage = defaultErrorMessage
      ): Promise<EngineVersion[]> => {
        const versions: EngineVersion[] = [];
        try {
          const list: EngineVersion[] = await engineTypeService[engineType].listVersions(basePath, {
            filter: includeDeprecated ? `(deprecated=FALSE OR deprecated=TRUE)` : '',
          });
          versions.push(
            ...(list?.map(({ version: v, aliases }) => {
              const isDefault = aliases?.includes(latest),
                version = `${v}${isDefault ? ` (${latest})` : ''}`;
              return { version, isDefault, key: v, type: engineType } as EngineVersion;
            }) || [])
          );
        } catch (error: unknown) {
          addErrorToast(errorMessage, error as Error);
        }
        return versions;
      },
      [addErrorToast, basePath]
    ),
    opOnEngine = useCallback(
      async (engine?: AIEngine, op: AIEMOpType = AIEMOpType.get, abort = false, errorMessage = defaultErrorMessage) => {
        try {
          const res = (await getEndpointCall(
            basePath,
            op,
            engine?.engineType || V1EngineType.DriverlessAi
          )(engine as any, abort)) as EngineResponse;
          return op === AIEMOpType.delete ? {} : (res?.engine as AIEngine);
        } catch (error: unknown) {
          if (op !== AIEMOpType.checkId) addErrorToast(errorMessage, error as Error);
        }
        return undefined;
      },
      [addErrorToast, basePath]
    ),
    getConstraintSet = useCallback(
      async (nameRaw: string = defaultWorkspaceName, engineType: V1EngineType = V1EngineType.DriverlessAi) => {
        const constraintSetType = constraintSetTypeMap.get(engineType);
        const name = `${nameRaw}/${constraintSetType}`;
        const constraintSet = (await getEndpointCall(
          basePath,
          AIEMOpType.constraintSet,
          engineType
        )({ name } as any)) as EngineConstraintSet;
        return constraintSet;
      },
      [basePath]
    ),
    getEngineStateData = useCallback(
      (state?: EngineState, type?: V1EngineType): BadgeData | undefined => {
        const defaultColor = palette?.gray500,
          defaultBackgroundColor = palette?.white,
          EngineStateMap = new Map<EngineState, BadgeData>([
            [
              V1EngineState.Connecting,
              {
                id: V1EngineState.Connecting,
                title: 'Connecting',
                color: defaultColor,
                backgroundColor: defaultBackgroundColor,
              },
            ],
            [
              V1EngineState.Deleting,
              {
                id: V1EngineState.Deleting,
                title: 'Deleting',
                color: defaultColor,
                backgroundColor: defaultBackgroundColor,
              },
            ],
            [
              V1EngineState.Failed,
              {
                id: V1EngineState.Failed,
                title: 'Failed',
                color: palette?.red900,
                backgroundColor: palette?.red100,
              },
            ],
            [
              V1EngineState.Paused,
              {
                id: V1EngineState.Paused,
                title: type === V1EngineType.H2O ? 'Shut down' : 'Paused',
                color: palette?.gray900,
                backgroundColor: palette?.gray200,
              },
            ],
            [
              V1EngineState.Pausing,
              {
                id: V1EngineState.Pausing,
                title: 'Pausing',
                color: defaultColor,
                backgroundColor: defaultBackgroundColor,
              },
            ],
            [
              V1EngineState.Running,
              {
                id: V1EngineState.Running,
                title: 'Running',
                color: palette?.green900,
                backgroundColor: palette?.green100,
              },
            ],
            [
              V1EngineState.Starting,
              {
                id: V1EngineState.Starting,
                title: 'Starting',
                color: defaultColor,
                backgroundColor: defaultBackgroundColor,
              },
            ],
            [
              V1EngineState.Unspecified,
              {
                id: V1EngineState.Unspecified,
                title: 'Unspecified',
                color: palette?.white,
                backgroundColor: palette?.black,
              },
            ],
          ]);
        return state ? EngineStateMap.get(state) : undefined;
      },
      [palette]
    ),
    getEngineTypeData = useCallback(
      (type?: V1EngineType): BadgeData | undefined => {
        const EngineTypeMap = new Map<V1EngineType, BadgeData>([
          [
            V1EngineType.DriverlessAi,
            {
              id: V1EngineType.DriverlessAi,
              title: 'DriverlessAI',
              color: palette?.yellow900,
              backgroundColor: palette?.yellow100,
              borderLeft: `4px solid ${palette?.yellow500}`,
            },
          ],
          [
            V1EngineType.H2O,
            {
              id: V1EngineType.H2O,
              title: 'H2O',
              color: palette?.blue900,
              backgroundColor: palette?.blue100,
              borderLeft: `4px solid ${palette?.blue500}`,
            },
          ],
          [
            V1EngineType.Unspecified,
            {
              id: V1EngineType.Unspecified,
              title: 'Unspecified',
              color: palette?.white,
              backgroundColor: palette?.gray500,
              borderLeft: `4px solid ${palette?.black}`,
            },
          ],
        ]);
        return type ? EngineTypeMap.get(type) : undefined;
      },
      [palette]
    ),
    checkEngineId = useCallback(
      async (engineType?: V1EngineType, id?: string) => {
        if (!engineType || !id) {
          return;
        }
        const name = `${defaultWorkspaceName}/${engineType === V1EngineType.DriverlessAi ? 'dai' : 'h2o'}Engines/${id}`;
        const engine = (await opOnEngine({ engineType, name } as AIEngine, AIEMOpType.checkId)) || null;
        return !Boolean(engine);
      },
      [opOnEngine]
    ),
    engineTypeLogo: {
      [P in keyof V1EngineType as string]: {
        src: string;
        backgroundColor: string | undefined;
      };
    } = {
      [V1EngineType.DriverlessAi]: { src: '/driverlessDarkIcon.png', backgroundColor: Colors?.textPrimary },
      [V1EngineType.H2O]: { src: '/h2o3DarkLogo.png', backgroundColor: Colors?.textPrimary },
      [V1EngineType.Unspecified]: { src: '/logo512.png', backgroundColor: undefined },
    };

  const listDAIProfiles = useCallback(
    async (errorMessage = defaultErrorMessage) => {
      try {
        return (await apiListDAIProfiles(basePath)).adjustedDaiProfiles;
      } catch (error: unknown) {
        addErrorToast(errorMessage, error as Error);
      }
      return undefined;
    },
    [addErrorToast, basePath]
  );

  const listDAIEngineSizeOptions = useCallback(async (errorMessage = defaultErrorMessage) => {
    try {
      return (await listDAIProfiles(errorMessage))
        ?.map((p) => ({ ...p, key: p.name, text: p.displayName }))
        .concat([customDAIEngineSizeOption]);
    } catch (error: unknown) {
      addErrorToast(errorMessage, error as Error);
    }
    return undefined;
  }, []);

  return {
    EngineStateMap,
    basePath,
    getConstraintSet,
    checkEngineId,
    engineTypeLogo,
    getEngineStateData,
    getEngineTypeData,
    listEngines,
    listVersions,
    opOnEngine,
    searchEngines,
    listDAIProfiles,
    listDAIEngineSizeOptions,
  };
}

const getVersionsFromParam = (param: string) => {
  const types = param.split(';');
  if (!types?.length) return {};
  const result: TypeVersionSelection = {};
  types.forEach((t) => {
    const parts = t.split('=');
    if (!parts || parts.length < 2) return;
    const [key, value] = parts;
    const values = value.split(',');
    result[key] = values.reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: true,
      }),
      {}
    );
  });
  return result;
};

export const filterSummarizeSelection = (
  selection: TypeVersionSelection
): { filtered: TypeVersionSelection; summary: TypeVersionSummary } => {
  const filtered = {};
  let totalVersions = 0,
    totalSelected = 0;
  const types: { [P in keyof V1EngineType as string]: VersionsSummary } = {};
  Object.keys(selection).forEach((typeName) => {
    const versionsObj = selection[typeName] || {},
      versions: string[] = [];
    let typeTotalVersions = 0,
      typeSelectedVersions = 0,
      anySelected = false;
    Object.keys(versionsObj).forEach((v) => {
      totalVersions++;
      typeTotalVersions++;
      if (versionsObj[v]) {
        totalSelected++;
        typeSelectedVersions++;
        anySelected = true;
        versions.push(v);
        if (!filtered[typeName]) filtered[typeName] = {};
        filtered[typeName][v] = true;
      }
    });
    types[typeName] = { anySelected, totalVersions: typeTotalVersions, totalSelected: typeSelectedVersions, versions };
  });
  return { filtered, summary: { totalVersions, totalSelected, anySelected: Boolean(totalSelected), types } };
};

// manages Engine Page filter states
export function useEngineQueryParams() {
  const [queryParams, setQueryParams] = useStateQueryParams();
  const params = useMemo<EngineQueryParamsType>(
    () => ({
      searchTerm: queryParams[EngineQueryParamsKeys.search] || '',
      versions: getVersionsFromParam(queryParams[EngineQueryParamsKeys.versions] || ''),
      states: queryParams.states ? queryParams.states.split(',') : [],
    }),
    [queryParams]
  );
  const setSearchTermParam = useCallback((search: string) => {
    setQueryParams(({ [EngineQueryParamsKeys.search]: _omitted, ...old }) =>
      !search ? old : { ...old, [EngineQueryParamsKeys.search]: search }
    );
  }, []);
  const setStates = useCallback((states: string[]) => {
    setQueryParams(({ states: _omitted, ...old }) => (!states.length ? old : { ...old, states: states.join(',') }));
  }, []);
  // sanitize and cast states here
  const setTypeVersions = useCallback((value: TypeVersionSelection) => {
    const { filtered } = filterSummarizeSelection(value);
    const versions = Object.keys(filtered)
      .map((t) => `${t}=${Object.keys(filtered[t]).join(',')}`)
      .join(';');
    setQueryParams(({ [EngineQueryParamsKeys.versions]: _omitted, ...old }) =>
      !versions ? old : { ...old, [EngineQueryParamsKeys.versions]: versions }
    );
  }, []);

  return { params, setSearchTermParam, setTypeVersions, setStates };
}
