import { ITextStyles, Stack, Text } from '@fluentui/react';
import { FontSizes, useTheme } from '@h2oai/ui-kit';
import { useCallback } from 'react';

import { IN_A_FEW_MOMENTS, day, hour, minute, month, week, year } from '../../utils/models';
import AdditionalInfoIcon from '../AdditionalInfoIcon/AdditionalInfoIcon';

export const getFutureRelativeDate = (date: string | Date, baseDate = new Date()) => {
  if (typeof date === 'string') date = new Date(date);
  if (date === baseDate) return 'now';

  const delta = +date - +baseDate;
  let result = '';
  if (delta < 10 * minute) {
    result = IN_A_FEW_MOMENTS;
  } else if (delta < hour) {
    result = `in ${Math.floor(delta / minute)} minutes`;
  } else if (Math.floor(delta / hour) === 1) {
    result = 'in one hour';
  } else if (delta < day) {
    result = `in ${Math.floor(delta / hour)} hours`;
  } else if (delta < day * 2) {
    result = 'tomorrow';
  } else if (delta < week) {
    result = `in ${Math.floor(delta / day)} days`;
  } else if (delta < day * 8) {
    result = 'next week';
  } else if (delta < month) {
    result = `in ${Math.floor(delta / week)} weeks`;
  } else if (delta < day * 31) {
    result = 'next month';
  } else if (delta < year) {
    result = `in ${Math.floor(delta / month)} months`;
  } else if (delta < year * 2) {
    result = 'next year';
  } else if (delta > year) {
    result = `in ${Math.floor(delta / year)} years`;
  }
  return result;
};

export type AutoSuspendProps = {
  suspendAfter: string;
  baseDate?: Date;
};

export function AutoPauseLabel({ suspendAfter }: AutoSuspendProps) {
  const theme = useTheme(),
    date = new Date(suspendAfter),
    color = theme.semanticColors?.textQuinary,
    textStyles: ITextStyles = {
      root: {
        color,
        fontSize: FontSizes.xxsmall,
      },
    },
    onRenderContent = useCallback(() => {
      const tooltipTextStyles: ITextStyles = {
        root: { color: theme.semanticColors?.tooltipText, fontSize: FontSizes.xsmall },
      };
      return (
        <Stack tokens={{ childrenGap: 3 }}>
          <Text styles={tooltipTextStyles}>
            App instances are automatically paused after a set amount of time to save resources.
          </Text>
          <Text styles={tooltipTextStyles}>
            To reset the auto-pause timer and postpone the auto-pause, use the Postpone Auto-Pause action in the button
            dropdown.
          </Text>
        </Stack>
      );
    }, [theme]);

  return getFutureRelativeDate(date) ? (
    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
      <Text styles={textStyles}>{`Instance will be automatically paused ${getFutureRelativeDate(date)}`}</Text>
      <AdditionalInfoIcon onRenderContent={onRenderContent} />
    </Stack>
  ) : null;
}
