import { IDetailsListStyles, IStyle } from '@fluentui/react';
import { FontSizes, FontWeights, IH2OTheme, Sizes } from '@h2oai/ui-kit';

const COL_GAP = `1rem`;
const COL_ACTIONS_WIDTH = `10rem`;
// equals to the width of the chevron
const COL_CHEVRON_WIDTH = `30px`;
// equals to the width of the image in the header
const COL_IMAGE_WIDTH = `40px`;
const COL_STATUS_WIDTH = `5rem`;
const COL_VERSION_WIDTH = `5rem`;
const COL_WIDTH_REMAINDER = `(100% - (7 * ${COL_GAP}) - ${COL_ACTIONS_WIDTH} - ${COL_CHEVRON_WIDTH} - ${COL_IMAGE_WIDTH} - ${COL_STATUS_WIDTH} - ${COL_VERSION_WIDTH})`;
const COL_CATEGORY_WIDTH = `calc(0.4 * ${COL_WIDTH_REMAINDER})`;
const COL_NAME_WIDTH = `calc(0.4 * ${COL_WIDTH_REMAINDER})`;
const COL_VISIBILITY_WIDTH = `calc(0.2 * ${COL_WIDTH_REMAINDER})`;
const ROW_GAP = `1rem`;

const rowStyles = {
  display: `flex`,
  flexDirection: `row nowrap`,
  gap: COL_GAP,
  margin: `0 ${ROW_GAP}`,
  '& > *': {
    // prevent automatic sizing of the flex items as it might break the column alignment
    flexGrow: 0,
    flexShrink: 0,
  },
  '.image': {
    width: COL_IMAGE_WIDTH,
  },
  '.image img': {
    borderRadius: '2px',
  },
  '.name': {
    width: COL_NAME_WIDTH,
  },
  '.version': {
    width: COL_VERSION_WIDTH,
    whiteSpace: `nowrap`,
  },
  '.status': {
    width: COL_STATUS_WIDTH,
    textAlign: `center`,
  },
  '.category': {
    width: COL_CATEGORY_WIDTH,
  },
  '.visibility': {
    width: COL_VISIBILITY_WIDTH,
  },
  '.actions': {
    alignSelf: `center`,
    display: `flex`,
    flexFlow: `row nowrap`,
    gap: `0.5rem`,
    justifyContent: `end`,
    width: COL_ACTIONS_WIDTH,
  },
  '.chevron': {
    width: COL_CHEVRON_WIDTH,
  },
};

export interface IFederatedAppsListHeaderStyles {
  groupHeader: IStyle;
  rowsHeader: IStyle;
}

export const federatedAppsListHeaderStylesDefault = (theme: IH2OTheme): IFederatedAppsListHeaderStyles => ({
  groupHeader: [
    rowStyles,
    {
      fontSize: FontSizes.xsmall,
      margin: 0,
      padding: ROW_GAP,
      position: `relative`,
      '.status': {
        flexGrow: `1`,
        textAlign: `start`,
      },
      '.app-name': {
        alignItems: `center`,
        color: theme.palette?.gray900,
        display: `flex`,
        flexFlow: `row nowrap`,
        fontWeight: FontWeights.semiBold,
        columnGap: `0.5ch`,
      },
      '.app-owner': {
        marginTop: '5px',
        color: theme.palette?.gray500,
      },
      '.chevron': {
        alignSelf: `center`,
        color: theme.palette?.gray900,
        fontWeight: FontWeights.semiBold,
        textAlign: 'right',
      },
    },
  ],
  rowsHeader: [
    rowStyles,
    {
      fontSize: FontSizes.xsmall,
      borderTop: `solid 1px ${theme.palette?.gray200}`,
      paddingTop: ROW_GAP,
      color: theme.palette?.gray900,
      fontWeight: FontWeights.bold,
      whiteSpace: `nowrap`,
      '&:not(.open)': {
        display: 'none',
      },
    },
  ],
});

export const detailsListStylesFederatedApps = (theme: IH2OTheme): Partial<IDetailsListStyles> => ({
  contentWrapper: {
    '.ms-GroupedList-group': {
      backgroundColor: theme.palette?.white,
      border: `solid 1px ${theme.palette?.gray300}`,
      borderRadius: Sizes.borderRadius,
      marginBottom: '10px',
      marginTop: '10px',
    },
  },
});

export interface IFederatedAppsListRowClassNames {
  root: string;
}

export interface IFederatedAppsListRowStyles {
  root: IStyle;
}

export const federatedAppsListRowStylesDefault = (theme: IH2OTheme): IFederatedAppsListRowStyles => ({
  root: [
    rowStyles,
    {
      alignItems: 'center',
      color: theme.palette?.gray900,
      fontSize: FontSizes.xsmall,
      marginTop: ROW_GAP,
      '.ms-GroupSpacer': {
        display: 'none !important',
      },
      '&:last-child': {
        marginBottom: ROW_GAP,
      },
    },
  ],
});
