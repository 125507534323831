import { useCallback } from 'react';

import { FilterCondition, useEngine } from '../aiem/hooks';
import AIEnginesPage from '../components/AIEnginesPage/AIEnginesPage';

export default function AdminAIEnginesPage() {
  const { searchEngines } = useEngine();
  const fetchEngines = useCallback(
    async (filter?: FilterCondition[]) => await searchEngines(filter || []),
    [searchEngines]
  );
  return <AIEnginesPage admin title="Admin AI Engines" fetchEngines={fetchEngines} />;
}
