/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as fm from "../../../../../fetch.pb"
import * as AiH2oCloudDiscoveryV1Environment from "./environment.pb"
export type GetEnvironmentRequest = {
}

export type GetEnvironmentResponse = {
  environment?: AiH2oCloudDiscoveryV1Environment.Environment
}

export class EnvironmentService {
  static GetEnvironment(req: GetEnvironmentRequest, initReq?: fm.InitReq): Promise<GetEnvironmentResponse> {
    return fm.fetchReq<GetEnvironmentRequest, GetEnvironmentResponse>(`/v1/environment?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
}