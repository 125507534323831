import { IDropdownOption, Icon, Stack, Text } from '@fluentui/react';
import { ConfirmDialog, Dropdown, IconName, Sizes, useTheme } from '@h2oai/ui-kit';
import { FormEvent, useCallback, useEffect, useState } from 'react';

import { AIEngine, EngineVersion, ValidEngineType } from '../../../../aiem';
import { defaultVersion } from '../../../../aiem/defaults';
import { useEngine } from '../../../../aiem/hooks';

export type AIEMUpgradeDialogProps = {
  engine?: AIEngine | null;
  hidden: boolean;
  onConfirm: (selectedVersion: string) => unknown;
  onDismiss: () => unknown;
};

export const AIEMUpgradeDialog = ({ engine, hidden, onConfirm, onDismiss }: AIEMUpgradeDialogProps) => {
  const { version, engineType } = engine || {},
    theme = useTheme(),
    { listVersions } = useEngine(),
    [availableVersions, setAvailableVersions] = useState<EngineVersion[] | undefined>(),
    [selectedVersion, setSelectedVersion] = useState<string>(defaultVersion),
    getAndSetVersions = useCallback(async () => {
      const versions = await listVersions(engineType as ValidEngineType);
      setAvailableVersions(versions);
    }, [engine, engineType]),
    title = `Upgrade from ${version} `,
    handleChange = useCallback(
      (event: any) => {
        const value = event?.target?.value;
        setSelectedVersion(value);
      },
      [selectedVersion]
    ),
    handleDropDownChange = (_e: FormEvent<HTMLDivElement>, { key: value }: any) => {
      handleChange(_e);
      setSelectedVersion(value);
    },
    handleConfirm = () => {
      onConfirm(selectedVersion);
    },
    findLatestVersion = (versions: EngineVersion[], currentVersion?: string) => {
      if (!currentVersion) return versions;
      const index = versions.findIndex((value) => value.key === currentVersion);
      if (index >= 0) return versions.slice(0, index);
      return versions;
    },
    getOptions = useCallback(
      () =>
        findLatestVersion(availableVersions || [], version).map(
          ({ key, version: text }: EngineVersion) => (({ key, text } as IDropdownOption) || [])
        ),
      [availableVersions, version]
    ),
    content = (
      <Stack>
        <Dropdown
          label="Select the version"
          placeholder="Select a version"
          options={getOptions()}
          selectedKey={selectedVersion}
          onChange={handleDropDownChange}
          disabled={!availableVersions}
        />
        <Text
          style={{
            background: theme.semanticColors?.messageBarBackgroundWarning,
            borderRadius: `${Sizes.borderRadius}px`,
            padding: `${Sizes.padding}px`,
            marginTop: `${Sizes.cardGap}px`,
            font: `14px/20px Inter`,
          }}
        >
          <p style={{ color: theme.palette?.yellow900 }}>
            <Icon iconName={IconName.WarningSolid} /> This change cannot be undone!
          </p>
          <p>We recommend following these steps before upgrading:</p>
          <ul>
            <li>
              <b>Build MLI models:</b> Before upgrading, run MLI jobs on models that you want to continue to interpret
              in future Driverless AI releases. If an MLI job appears in the list of <b>Interpreted Models</b> in your
              current version, then it is retained after upgrading.
            </li>
            <li>
              <b>Build MOJO pipelines:</b> Before upgrading, <b>build MOJO pipelines</b> on all desired models.
            </li>
          </ul>
        </Text>
      </Stack>
    );

  useEffect(() => {
    if (engine?.upgradeAvailable) {
      getAndSetVersions();
    }
  }, [engine]);

  return (
    <ConfirmDialog
      confirmationButtonText={'Upgrade'}
      hidden={hidden}
      content={content}
      title={title}
      onConfirm={handleConfirm}
      onDismiss={onDismiss}
    />
  );
};
