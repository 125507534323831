import { useCallback, useEffect, useState } from 'react';

import { useQuery, useQueryParams } from '../utils/hooks';
import { defaultResourceNamePrefix } from './defaults';

export type IntervalParams = {
  startTime: string;
  endTime?: string;
};

export enum Intervals {
  OneHour = 'onehour',
  ThreeHours = 'threehours',
  SixHours = 'sixhours',
  TwelveHours = 'twelvehours',
  OneDay = 'oneday',
  TwoDays = 'twodays',
  ThreeDays = 'threedays',
  SevenDays = 'sevendays',
  OneMonth = 'onemonth',
}

enum LogQueryParams {
  resource = 'name',
  searchTerm = 'q',
  regexSearch = 'qrx',
  startTime = 'st',
  logNamesExclude = 'ln',
}

type LogQueryParamsType = {
  resource?: string;
  searchTerm?: string;
  regexSearch?: string;
  startTime?: string;
  logNamesExclude?: string[];
};

type IntervalFilterRatio = {
  [P in keyof Intervals as string]: number;
};

const intervalFilterRatio: IntervalFilterRatio = {
  [Intervals.OneHour]: 60,
  [Intervals.ThreeHours]: 3 * 60,
  [Intervals.SixHours]: 6 * 60,
  [Intervals.TwelveHours]: 12 * 60,
  [Intervals.OneDay]: 24 * 60,
  [Intervals.TwoDays]: 2 * 24 * 60,
  [Intervals.ThreeDays]: 3 * 24 * 60,
  [Intervals.SevenDays]: 7 * 24 * 60,
  [Intervals.OneMonth]: 30 * 24 * 60,
};

export const getStartTime = (interval: Intervals = Intervals.OneHour) =>
  new Date(Date.now() - intervalFilterRatio[interval] * 60 * 1000).toISOString();

// manages Logging Page filter state management
export function useLogQueryParams() {
  const query = useQuery(),
    { clearParams: clearQueryParams, setParams: setQueryParams } = useQueryParams();

  const getResource = (name: string, pathPrefix = defaultResourceNamePrefix) =>
    `${name.includes(pathPrefix) ? '' : pathPrefix}${name}`;
  const resourceName = query.get(LogQueryParams.resource) || '',
    [resource, setLocalResource] = useState<string>(getResource(resourceName));

  const [searchTerm, setSearchTerm] = useState<string>(query.get(LogQueryParams.searchTerm) || ''),
    [regexSearch, setRegexSearchParam] = useState<string>(query.get(LogQueryParams.regexSearch) || ''),
    [startTime, setStartTime] = useState<string>(query.get(LogQueryParams.startTime) || getStartTime());

  const [logNamesExcludeParam, setLogNamesExcludeParam] = useState<string | null>(
      query.get(LogQueryParams.logNamesExclude)
    ),
    [logNamesExclude, setLocalLogNamesExclude] = useState<string[] | null>(
      logNamesExcludeParam ? logNamesExcludeParam.split(',') : null
    );

  const getParams = (): LogQueryParamsType => {
    return {
      resource,
      searchTerm,
      regexSearch,
      startTime,
      logNamesExclude,
    } as LogQueryParamsType;
  };

  // sanitize states here
  const setResource = useCallback((value: string) => {
      setLocalResource(getResource(value));
    }, []),
    setRegexSearch = useCallback((value: boolean) => {
      setRegexSearchParam(value ? '1' : '');
    }, []),
    setLogNamesExclude = useCallback((value: string[]) => {
      setLogNamesExcludeParam(value.join(','));
      setLocalLogNamesExclude(value);
    }, []);

  useEffect(() => {
    clearQueryParams();
    setQueryParams([
      { name: LogQueryParams.resource, value: resource },
      { name: LogQueryParams.searchTerm, value: searchTerm },
      { name: LogQueryParams.regexSearch, value: regexSearch },
      { name: LogQueryParams.startTime, value: startTime },
      {
        name: LogQueryParams.logNamesExclude,
        value: logNamesExcludeParam || '',
      },
    ]);
  }, [resource, startTime, searchTerm, regexSearch, logNamesExcludeParam]);

  return { getParams, setResource, setStartTime, setSearchTerm, setRegexSearch, setLogNamesExclude };
}
