import { IContextualMenuItem, MessageBarType } from '@fluentui/react';
import {
  BasicList,
  Button,
  IEmptyMessageProps,
  IconName,
  WidgetItem,
  WidgetPanel,
  buttonStylesPrimary,
  buttonStylesSecondary,
  buttonStylesWidthXSmall,
  loaderStylesSpinnerButtonPrimary,
  loaderStylesSpinnerButtonSecondary,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useState } from 'react';

import { AIEMOpType, AIEngine, V1Engine, V1EngineState, hasOp, isTransitionalState } from '../../../aiem';
import { defaultWorkspaceName } from '../../../aiem/defaults';
import { useEngine } from '../../../aiem/hooks';
import { useUser } from '../../../utils/hooks';
import { engineButtonTestIdSuffixes, getEngineButtonTestId } from '../../../utils/utils';
import { RoutePaths } from '../../Routes';

interface AIEngineItem {
  id: string;
  title: string;
  iconLocation: string;
  iconBackgroundColor?: string;
  status?: string;
  statusColor?: string;
  statusTextColor?: string;
  statusLoading?: boolean;
}

export interface IAppInstanceListWidgetProps {
  title: string;
}

export const AIEnginesListWidget = ({ title }: IAppInstanceListWidgetProps) => {
  const theme = useTheme();
  const { addToast } = useToast();
  const { engineTypeLogo, getEngineStateData, listEngines, opOnEngine } = useEngine();
  const { id: userId } = useUser();
  const [data, setData] = useState<V1Engine[] | undefined>();
  const [loadingEnginesMap, setLoadingEnginesMap] = useState<V1Engine[]>([]);
  const [loadingMessage, setLoadingMessage] = useState('Loading');
  const loadData = useCallback(async () => {
    setData(await listEngines({ parent: defaultWorkspaceName, filter: `creator = "users/${userId}"` }));
    setLoadingMessage('');
  }, [listEngines]);
  const getAIEngineItem = useCallback(
    (engine: V1Engine) => {
      const { displayName, state, uid } = engine;
      const { backgroundColor, src } = engineTypeLogo[engine.type!];
      const engineStateData = getEngineStateData(state);
      return {
        id: uid || '',
        title: displayName || '',
        iconLocation: src,
        iconBackgroundColor: backgroundColor,
        status: engineStateData?.title || 'Unknown',
        statusColor: engineStateData?.backgroundColor,
        statusTextColor: engineStateData?.color,
        statusLoading: isTransitionalState(state as V1EngineState),
      };
    },
    [theme, engineTypeLogo, getEngineStateData]
  );
  const modifyEngine = useCallback(
    (engine, op: AIEMOpType) => {
      return async () => {
        setLoadingEnginesMap((prev) => [...prev, engine]);
        try {
          await opOnEngine(engine, op);
          addToast({
            message: `Successfully ${op === AIEMOpType.pause ? 'paused' : 'resumed'} engine with id: ${engine.uid}`,
            messageBarType: MessageBarType.success,
          });
        } catch (e) {
          addToast({
            message: `Failed to ${op === AIEMOpType.pause ? 'pause' : 'resume'} AI engine with id ${engine.uid}`,
            messageBarType: MessageBarType.error,
          });
        } finally {
          setLoadingEnginesMap((prev) => prev.filter((e) => e.uid !== engine.uid));
        }
      };
    },
    [opOnEngine]
  );
  const modifyEngines = useCallback(
    (op: AIEMOpType) => {
      let completedJobs = 0;
      const getStatusMessage = (finished: number, total: number) =>
        `${finished} of ${total} engines ${op === AIEMOpType.pause ? 'paused' : 'resumed'}`;
      return async () => {
        const enginesAcceptingOp = data?.filter(({ state, type }) => hasOp(op, type, state)) || [];
        setLoadingMessage(getStatusMessage(0, enginesAcceptingOp.length));
        Promise.allSettled(
          enginesAcceptingOp.map(async (engine) => {
            try {
              await opOnEngine(engine as AIEngine, op);
              completedJobs += 1;
              setLoadingMessage(getStatusMessage(completedJobs, enginesAcceptingOp.length));
            } catch (e) {
              completedJobs += 1;
              setLoadingMessage(getStatusMessage(completedJobs, enginesAcceptingOp.length));
              addToast({
                message: `Failed to ${op === AIEMOpType.pause ? 'pause' : 'resume'} AI engine with id ${engine.uid}`,
                messageBarType: MessageBarType.error,
              });
            } finally {
              setLoadingMessage('');
            }
          })
        );
      };
    },
    [data, opOnEngine]
  );
  useEffect(() => {
    loadData();
  }, [loadData]);
  const emptyMessageProps: IEmptyMessageProps | undefined =
    !data || !data.length ? { message: `You don't have any AI engines` } : undefined;
  const contextMenuItems: IContextualMenuItem[] = [
    {
      key: 'resume',
      text: 'Resume all engines',
      onClick: modifyEngines(AIEMOpType.resume) as () => void,
    },
    {
      key: 'pause',
      text: 'Pause all engines',
      onClick: modifyEngines(AIEMOpType.pause) as () => void,
    },
  ];

  return (
    <WidgetPanel
      title={`${title}${data ? ` (${data.length})` : ''}`}
      linkText="View all"
      linkHref={RoutePaths.MY_AI_ENGINES}
      loadingMessage={loadingMessage}
      emptyMessageProps={emptyMessageProps}
      contextMenuItems={contextMenuItems}
    >
      <BasicList
        data={data ? data.slice(0, 5) : []}
        idField={'uid'}
        itemRenderer={(d: V1Engine, _i, last) => {
          const item = getAIEngineItem(d);
          return (
            <WidgetItem<AIEngineItem>
              idField="id"
              titleField="title"
              imagePathField="iconLocation"
              imageBackgroundColorField="iconBackgroundColor"
              statusField="status"
              statusColorField="statusColor"
              statusTextColorField="statusTextColor"
              statusLoadingField="statusLoading"
              last={last}
              data={item}
              imageHref={d.loginUrl}
              titleHref={d.loginUrl}
              actions={
                <div style={{ display: 'flex', gap: 8 }}>
                  <Button
                    styles={buttonStylesPrimary}
                    text="Visit"
                    data-test={getEngineButtonTestId(d, engineButtonTestIdSuffixes.visit)}
                    iconName={IconName.ArrowUpRight}
                    href={d.loginUrl}
                    disabled={d.state !== V1EngineState.Running || !d.loginUrl}
                  />
                  {d.state !== V1EngineState.Paused ? (
                    <Button
                      styles={[buttonStylesSecondary, buttonStylesWidthXSmall]}
                      iconName={IconName.Pause}
                      text="Pause"
                      disabled={!hasOp(AIEMOpType.pause, d.type, d.state)}
                      data-test={getEngineButtonTestId(d, engineButtonTestIdSuffixes.pause)}
                      onClick={modifyEngine(d, AIEMOpType.pause)}
                      loading={loadingEnginesMap.some((e) => e.uid === d.uid)}
                      loaderProps={{
                        label: 'Loading...',
                        styles: loaderStylesSpinnerButtonSecondary,
                      }}
                    />
                  ) : (
                    <Button
                      styles={[buttonStylesPrimary, buttonStylesWidthXSmall]}
                      iconName={IconName.MSNVideos}
                      text="Resume"
                      disabled={!hasOp(AIEMOpType.resume, d.type, d.state)}
                      data-test={getEngineButtonTestId(d, engineButtonTestIdSuffixes.resume)}
                      onClick={modifyEngine(d, AIEMOpType.resume)}
                      loading={loadingEnginesMap.some((e) => e.uid === d.uid)}
                      loaderProps={{
                        label: 'Loading...',
                        styles: loaderStylesSpinnerButtonPrimary,
                      }}
                    />
                  )}
                </div>
              }
            />
          );
        }}
      />
    </WidgetPanel>
  );
};
