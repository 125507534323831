import { ITextStyles, Stack, Text } from '@fluentui/react';
import {
  Dropdown,
  FontSizes,
  TextWithCopy,
  dropdownStylesInlineLeft,
  textWithCopyStylesBorder,
  textWithCopyStylesNoHeaderStyle,
  useTheme,
} from '@h2oai/ui-kit';
import { useCallback, useState } from 'react';

import { App } from '../../../ai.h2o.cloud.appstore';
import AdditionalInfoIcon from '../../../components/AdditionalInfoIcon/AdditionalInfoIcon';

const APP_NAME_RESOURCE = 'v1/latestapp';

type ShareProps = {
  app: App;
};

export function Share({ app }: ShareProps) {
  const theme = useTheme(),
    textStyles: ITextStyles = {
      root: {
        color: theme.semanticColors?.textQuaternary,
        paddingTop: 2,
      },
    },
    onRenderContent = useCallback(() => {
      const tooltipTextStyles: ITextStyles = {
        root: { color: theme.semanticColors?.tooltipText, fontSize: FontSizes.xsmall },
      };
      return (
        <Stack tokens={{ childrenGap: 3 }}>
          <Text styles={tooltipTextStyles}>Copy link to run or share this app with others.</Text>
        </Stack>
      );
    }, [theme]),
    { href, origin } = window.location, // return the path and full URL
    options = [
      {
        key: 'latest',
        text: 'Latest Version',
        selected: true,
        data: { proc: ({ name }: App) => `${origin}/${APP_NAME_RESOURCE}/${name}` },
      },
      {
        key: 'current',
        text: 'Selected Version',
        data: { proc: () => href },
      },
    ],
    onChange = useCallback(
      (_ev, option) => {
        setShare(option.data.proc(app));
      },
      [app]
    ),
    [share, setShare] = useState<string>(options[0].data.proc(app));

  return (
    <Stack>
      <Stack horizontal tokens={{ childrenGap: 5 }}>
        <Text styles={textStyles}>App URL</Text>
        <Stack horizontal>
          <AdditionalInfoIcon onRenderContent={onRenderContent} />
        </Stack>
      </Stack>
      <TextWithCopy
        styles={[textWithCopyStylesBorder, textWithCopyStylesNoHeaderStyle]}
        hasBorder
        text={share}
        header={
          <Dropdown dropdownWidth="auto" styles={dropdownStylesInlineLeft} onChange={onChange} options={options} />
        }
      />
    </Stack>
  );
}
