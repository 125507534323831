/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/h2o_engine_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1H2OEngine,
    V1H2OEngineFromJSON,
    V1H2OEngineFromJSONTyped,
    V1H2OEngineToJSON,
} from './V1H2OEngine';

/**
 * Response message for H2OEngineService.DeleteH2OEngine.
 * @export
 * @interface V1DeleteH2OEngineResponse
 */
export interface V1DeleteH2OEngineResponse {
    /**
     * 
     * @type {V1H2OEngine}
     * @memberof V1DeleteH2OEngineResponse
     */
    h2oEngine?: V1H2OEngine;
}

export function V1DeleteH2OEngineResponseFromJSON(json: any): V1DeleteH2OEngineResponse {
    return V1DeleteH2OEngineResponseFromJSONTyped(json, false);
}

export function V1DeleteH2OEngineResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1DeleteH2OEngineResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'h2oEngine': !exists(json, 'h2oEngine') ? undefined : V1H2OEngineFromJSON(json['h2oEngine']),
    };
}

export function V1DeleteH2OEngineResponseToJSON(value?: V1DeleteH2OEngineResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'h2oEngine': V1H2OEngineToJSON(value.h2oEngine),
    };
}

