import { defaultBasePath } from './constants';
import {
  ComponentService,
  GetQuotaRequest,
  ListComponentsRequest,
  QuotaService,
  /* WARNING: Do not use the generated UsageService, it sends bad requests!
   *  CalculateUsageRequest and CalculateMaxUsageRequest types are also incorrect.
   *  Use the TelemetryUsageService imported below instead. */
} from './gen/ai/h2o/cloud/telemetry_read_api/v1/aiunits.pb';
import { InitReq } from './gen/fetch.pb';
import { CalculateMaxUsageRequest, CalculateUsageRequest, TelemetryUsageService } from './telemetryUsageService';

const basePath = process.env.REACT_APP_USE_MOCK_SERVER ? defaultBasePath : '';

export class TelemetryServiceImpl {
  private request: InitReq = {};

  constructor(accessToken: string) {
    this.request.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    this.request.pathPrefix = basePath;
  }

  setPathPrefix(pathPrefix: string) {
    this.request.pathPrefix = pathPrefix;
  }

  public async calculateMaxUsage(req: CalculateMaxUsageRequest) {
    const result = await TelemetryUsageService.CalculateMaxUsage(req, this.request);
    return result;
  }

  public async calculateUsage(req: CalculateUsageRequest) {
    const result = await TelemetryUsageService.CalculateUsage(req, this.request);
    return result;
  }

  public async getQuota(req: GetQuotaRequest = {}) {
    const result = await QuotaService.GetQuota(req, this.request);
    return result;
  }

  public async listComponents(req: ListComponentsRequest = {}) {
    const result = await ComponentService.ListComponents(req, this.request);
    return result;
  }
}
