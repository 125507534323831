import { Stack, Text, Toggle } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  Button,
  SearchBox,
  buttonStylesPrimary,
  searchBoxStylesSearch,
  toggleStylesInline,
  useTheme,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useState } from 'react';

import { useLogQueryParams } from '../../../logging-service/utils';

type ButtonSearchBoxProps = {
  onSearch: (value: string, regex: boolean) => void;
  value?: string;
  regex?: boolean;
  invalidCharacters?: string[];
};

export const ButtonSearchBox = ({
  onSearch,
  value,
  regex: isRegex = false,
  invalidCharacters = [],
}: ButtonSearchBoxProps) => {
  const theme = useTheme(),
    { getParams, setSearchTerm, setRegexSearch } = useLogQueryParams(),
    { regexSearch: regexSearchParam, searchTerm: searchTermParam } = getParams(),
    [searchKey, setSearchKey] = useState<string>(searchTermParam || value || ''),
    [invalidMessage, setInvalidMessage] = useState<string>(''),
    [regex, { toggle: toggleRegex }] = useBoolean(Boolean(regexSearchParam) || isRegex);

  const onChangeSearchInput = useCallback(
    (_, newValue) => {
      let invalidMessage = '';
      const caughtCharacters = invalidCharacters?.filter((c) => newValue.includes(c));
      if (caughtCharacters?.length > 0) {
        invalidMessage = `The following character(s) are forbidden: ${caughtCharacters.join(', ')}`;
      }
      if (regex) {
        try {
          new RegExp(newValue);
        } catch (error) {
          invalidMessage = `Invalid regular expression`;
        }
      }
      setInvalidMessage(invalidMessage);
      setSearchKey(newValue || '');
    },
    [regex, invalidCharacters, setSearchKey, setInvalidMessage]
  );

  useEffect(() => {
    setSearchTerm(searchKey);
  }, [searchKey]);

  useEffect(() => {
    setRegexSearch(regex);
  }, [regex]);

  return (
    <>
      <Stack
        horizontal
        verticalAlign="start"
        styles={{ root: { minWidth: 100, marginTop: 7 } }}
        tokens={{ childrenGap: 8 }}
      >
        <Toggle checked={regex} onChange={toggleRegex} styles={toggleStylesInline} inlineLabel label="Regex" />
      </Stack>
      <Stack horizontal={false} styles={{ root: { width: 700 } }}>
        <SearchBox
          styles={searchBoxStylesSearch}
          value={searchKey}
          onChange={onChangeSearchInput}
          placeholder="Search"
          onKeyUp={({ key }) => {
            key === 'Enter' && onSearch(searchKey, regex);
          }}
          onClear={() => {
            setSearchKey('');
            onSearch('', regex);
          }}
        />
        {invalidMessage && (
          <Text style={{ color: theme.semanticColors?.inputErrorMessageText, marginTop: 4 }}>{invalidMessage}</Text>
        )}
      </Stack>
      <Button
        styles={buttonStylesPrimary}
        text="Search"
        disabled={Boolean(invalidMessage)}
        onClick={() => {
          onSearch(searchKey, regex);
        }}
      />
    </>
  );
};
