import * as fm from './gen/fetch.pb';

export type DownloadLogRequest = {
  resource?: string;
  startTime?: string;
  endTime?: string;
  search?: string;
  maxLogEntriesCount?: number;
};

export type DownloadLogResponse = Blob;

export class DownloadLogService {
  static DownloadLog(req: DownloadLogRequest, basePath: string) {
    return fetch(`${basePath}/v1/logEntries:download?${fm.renderURLSearchParams(req, [])}`, {
      method: 'GET',
      headers: { contentType: 'application/zip' },
    });
  }
}
