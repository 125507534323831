import { IGroup, Icon, Image, ImageFit, mergeStyles } from '@fluentui/react';
import { Button, IH2OTheme, Sizes, buttonStylesGhost, useClassNames, useTheme } from '@h2oai/ui-kit';
import { useHistory } from 'react-router-dom';

import { FederatedApp } from '../../../../ai.h2o.cloud.appstore';
import { styleBreakout, styleResetButton } from '../../../../themes/buttonStyles';
import { ClassNamesFromIStyles } from '../../../../utils/models';
import { RoutePaths } from '../../../Routes';
import { IFederatedAppsListHeaderStyles, federatedAppsListHeaderStylesDefault } from '../FederatedAppsList.styles';
import { FederatedAppsStatusIcon } from '../FederatedAppsStatusIcon/FederatedAppsStatusIcon';

// there is an expand/collapse _breakout_ button in the same container, we need to increase zIndex
// of its clickable siblings to in the foreground thus reachable for the mouse cursor
const styleInFrontOfBreakoutButton = mergeStyles({
  zIndex: 1,
});

const styleAnchorLike = (theme: IH2OTheme) =>
  mergeStyles({
    color: theme.semanticColors?.buttonLinkUnderlineText,
    textDecoration: 'underline',
  });

const styleChevronButton = mergeStyles(
  styleResetButton,
  styleBreakout({
    borderRadius: Sizes.borderRadius,
    focusIndicatedForKeyboardOnly: true,
  }),
  {
    'i[data-icon-name]': {
      transition: 'transform 500ms',
    },
    '&[aria-expanded="true"] i': {
      transform: 'rotate(90deg)',
    },
  }
);

export interface IFederatedAppsListItemProps {
  group: IGroup | undefined;
  toggleCollapse: () => void;
}

export function FederatedAppsListHeader({ group, toggleCollapse }: IFederatedAppsListItemProps) {
  const theme = useTheme(),
    app: FederatedApp = group?.data,
    classNames = useClassNames<IFederatedAppsListHeaderStyles, ClassNamesFromIStyles<IFederatedAppsListHeaderStyles>>(
      'FederatedAppsListHeader',
      federatedAppsListHeaderStylesDefault
    ),
    isOpen = !group?.isCollapsed,
    versions: string = !group?.count ? `` : group.count === 1 ? group.data?.version : `${group.count} versions`,
    history = useHistory();
  return !app ? null : (
    <>
      <div className={classNames.groupHeader}>
        <div className={'image'}>
          <Image src={`/v1/asset/${app.iconLocation}`} width={40} height={40} imageFit={ImageFit.cover} />
        </div>
        <div className={'name'}>
          <div className={'app-name'}>
            <span>{app.name}</span>
            <span className={styleInFrontOfBreakoutButton}>
              <FederatedAppsStatusIcon app={app} />
            </span>
          </div>
          <div className={'app-owner'}>{app.owner}</div>
        </div>
        <div className={mergeStyles(`version`, styleAnchorLike(theme))}>{versions}</div>
        <div className={'status'}>&nbsp;</div>
        <div className={'actions'}>
          <Button
            className={styleInFrontOfBreakoutButton}
            onClick={() => {
              history.push(`${RoutePaths.ADMIN_MARKETPLACE_APPS}/${app.id}`);
            }}
            text="View"
            styles={buttonStylesGhost}
          />
        </div>
        <div className={'chevron'}>
          <button
            aria-expanded={isOpen}
            aria-label="Expand or collapse the list of all possible versions"
            onClick={toggleCollapse}
            className={styleChevronButton}
            type="button"
          >
            <Icon iconName="ChevronRight" />
          </button>
        </div>
      </div>
      <div className={`${classNames.rowsHeader} ${isOpen ? 'open' : ''}`}>
        <div className={'image'} />
        <div className={'name'}>App Name / ID</div>
        <div className={'version'}>Versions</div>
        <div className={'status'}>Status</div>
        <div className={'category'}>Category</div>
        <div className={'visibility'}>Visibility</div>
        <div className={'actions'} />
        <div className={'chevron'} />
      </div>
    </>
  );
}
