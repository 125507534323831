/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/h2o_engine_constraint_set_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Message representing a constraint on a user-specified duration value.
 * @export
 * @interface V1ConstraintDuration
 */
export interface V1ConstraintDuration {
    /**
     * Minimum (inclusive) allowed value.
     * @type {string}
     * @memberof V1ConstraintDuration
     */
    min?: string;
    /**
     * Maximum (inclusive) allowed value. Unset is treated as unlimited.
     * @type {string}
     * @memberof V1ConstraintDuration
     */
    max?: string | null;
    /**
     * Default value. It is used as an initial value or when the value is unset by the user.
     * @type {string}
     * @memberof V1ConstraintDuration
     */
    _default?: string;
}

export function V1ConstraintDurationFromJSON(json: any): V1ConstraintDuration {
    return V1ConstraintDurationFromJSONTyped(json, false);
}

export function V1ConstraintDurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ConstraintDuration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'min': !exists(json, 'min') ? undefined : json['min'],
        'max': !exists(json, 'max') ? undefined : json['max'],
        '_default': !exists(json, 'default') ? undefined : json['default'],
    };
}

export function V1ConstraintDurationToJSON(value?: V1ConstraintDuration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'min': value.min,
        'max': value.max,
        'default': value._default,
    };
}

