export const HOUR = 3_600;
export const GIBIBYTE = 1_073_741_824;
export const hoursToSeconds = (value: string | number | null | undefined): string | undefined => {
  const number = Number(value);
  if (isNaN(number)) {
    return undefined;
  }
  return `${(number * HOUR).toString()}s`;
};

const hasS = (value: number): string => (value === 1 ? '' : 's');

export const parseSeconds = (value: string | number | null | undefined): number =>
  typeof value === 'string' ? Number(value?.replace(/[a-zA-Z]/g, '')) : Number(value);

export const secondsToFriendlyInterval = (value: string | number | null | undefined): string | undefined => {
  const seconds = parseSeconds(value);
  if (isNaN(seconds)) {
    return undefined;
  }
  let result = '';
  switch (true) {
    case seconds < 60:
      result = `${seconds} second${hasS(seconds)}`;
      break;
    case seconds < 3600:
      const minutes = Math.floor(seconds / 60);
      result = `${minutes} minute${hasS(minutes)}`;
      break;
    case seconds < 86400:
      const hours = Math.floor(seconds / 3600);
      result = `${hours} hour${hasS(hours)}`;
      break;
    default:
      const days = Math.floor(seconds / 86400);
      result = days === 1 ? 'Tomorrow' : `${days} days`;
      break;
  }
  return result;
};

export const secondsToHours = (value: string | number | null | undefined): string | undefined => {
  const number = typeof value === 'string' ? Number(value?.replace(/[a-zA-Z]/g, '')) : Number(value);
  if (isNaN(number)) {
    return undefined;
  }
  return Math.floor(number / HOUR).toString();
};
export const gibibytesToBytes = (value: string | number | null | undefined): string | undefined => {
  const number = Number(value);
  if (isNaN(number)) {
    return undefined;
  }
  return (number * GIBIBYTE).toString();
};
export const bytesToGibibytes = (value: string | number | null | undefined): string | undefined => {
  const number = Number(value);
  if (isNaN(number)) {
    return undefined;
  }
  return Math.floor(number / GIBIBYTE).toString();
};
export const getNumericValue = (val: string | number | null | undefined): number | undefined => {
  const num = Number(val);
  if (isNaN(num)) {
    return undefined;
  }
  return num;
};
