import { MessageBarType, getId } from '@fluentui/react';
import {
  BookTimeDialog,
  Button,
  FluentClickHandler,
  Footer,
  GTagProvider,
  GTagProviderProps,
  Loader,
  Toast,
  ToastProvider,
  buttonStylesStealth,
  getAppStoreMenuInfo,
  loaderStylesSpinnerXLarge,
  setupGtm,
  useClassNames,
} from '@h2oai/ui-kit';
import { useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';

import { GetEnvironmentDataResponse, User } from '../../ai.h2o.cloud.appstore';
import { ILeftPanelProps, LeftPanel } from '../../components/LeftPanel/LeftPanel';
import { Navigation } from '../../components/Navigation/Navigation';
import { NotificationBar } from '../../components/NotificationBar/NotificationBar';
import { AuthService, EnvService } from '../../services/api';
import { EnvProvider, EnvironmentAndMenu, LeftPanelProvider, UserProvider } from '../../utils/contexts';
import { useCloudPlatformDiscovery, useNotification } from '../../utils/hooks';
import { INotification } from '../../utils/models';
import { fetchAppStoreVersion } from '../../utils/utils';
import { RoutePaths, Routes } from '../Routes';
import { IProtectedStyles, protectedStylesDefault } from './Protected.styles';

declare global {
  interface Window {
    dataLayer?: Record<string, unknown>[] | undefined;
  }
}

interface IProtectedClassNames {
  root: string;
  main: string;
  contents: string;
  contentsBody: string;
}

const getMessageBarType = (severity: string): MessageBarType => {
  switch (severity) {
    case 'error':
      return MessageBarType.error;
    case 'warning':
      return MessageBarType.warning;
    case 'blocked':
      return MessageBarType.blocked;
    case 'success':
      return MessageBarType.success;
    case 'severe':
      return MessageBarType.severeWarning;
    default:
      return MessageBarType.info;
  }
};

const addBookTimeNotification = (
  onClickBookTime: FluentClickHandler,
  addNotification: (notification: INotification) => void
) => {
  addNotification({
    id: getId(),
    messageBarType: MessageBarType.warning,
    actions: (
      <div>
        <Button styles={buttonStylesStealth} text="Book a Time" onClick={onClickBookTime} />
      </div>
    ),
    message: `On trial or provisional accounts, H2O advises against uploading and/or using data with personally identifiable
              information (PII) or other sensitive data. Please schedule a call with one of our Activation Specialists to
              learn how to safely use your own data in the H2O AI Cloud.`,
  });
};

const osanoSrc = 'https://cmp.osano.com/6olZmSX1cFRD2MBt/b53c21d4-1b74-4f36-8e09-bf5fb393f98d/osano.js';

export function Protected() {
  const auth = useAuth(),
    platformDiscovery = useCloudPlatformDiscovery(),
    refMain = useRef<HTMLElement>(null),
    location = useLocation(),
    [user, setUser] = useState<User | null>(null),
    [gTag, setGTag] = useState<GTagProviderProps>({ isGtmReady: false, user: {} }),
    [osanoIsLoaded, setOsanoIsLoaded] = useState<boolean>(false),
    [env, setEnv] = useState<EnvironmentAndMenu | undefined>(),
    [leftPanelProps, setLeftPanelProps] = useState<ILeftPanelProps | undefined>(),
    [bookTimeDialogHidden, setBookTimeDialogHidden] = useState<boolean>(true),
    { add: addNotification } = useNotification(),
    onDismissBookTimeDialog = () => setBookTimeDialogHidden(true),
    onClickBookTime = () => setBookTimeDialogHidden(false);

  useEffect(() => {
    const loadEnv = async (user: User) => {
        try {
          const env: GetEnvironmentDataResponse = await EnvService.getEnvironmentData({});
          const { navLinks = [], bookTimeLink, homePage, federationEnabled, tagManagerId, environmentName } = env;
          setEnv({
            ...env,
            menu: getAppStoreMenuInfo(
              !!platformDiscovery?.aiEngineManagerApiUrl,
              user,
              navLinks,
              bookTimeLink,
              homePage.enabled,
              federationEnabled,
              '',
              true,
              undefined,
              environmentName,
              env?.platformUsageEnabled || false
            ),
          });
          const haicVersion = tagManagerId ? (await fetchAppStoreVersion()) || 'Unknown version' : '';
          setGTag(await setupGtm(TagManager, tagManagerId, user, haicVersion));
        } catch (error) {
          console.error('Google tag manager initialization failed');
          console.error(error);
        }
      },
      loadNotices = async () => {
        try {
          const { notices } = await EnvService.listNotices({});
          notices.forEach(({ title, severity, content }) =>
            addNotification({
              id: getId(),
              messageBarType: getMessageBarType(severity),
              message: `${title ? `${title}, ` : ''}${content}`,
            })
          );
        } catch (error) {}
      },
      init = async () => {
        try {
          const { user } = await AuthService.checkAuth({});
          if (!user) {
            await auth.signoutRedirect({ id_token_hint: auth.user?.id_token });
          }
          setUser(user);
          await Promise.all([loadEnv(user), loadNotices()]);
          if (env?.menu?.hasBookTime) {
            addBookTimeNotification(onClickBookTime, addNotification);
          }
        } catch (error) {
          console.error(error);
        }
      };
    init();
  }, []);
  useEffect(() => {
    if (refMain.current && location.pathname === RoutePaths.APPSTORE) {
      refMain.current.scrollTo(0, 0);
    }
  }, [location.pathname, location.search]);
  useEffect(() => {
    if (!env || !env.osanoEnabled) return;
    if (!document.querySelector(`script[src="${osanoSrc}"`)) {
      const script = document.createElement('script');
      script.src = osanoSrc;
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => setOsanoIsLoaded(true);
    } else {
      setOsanoIsLoaded(true);
    }
  }, [env]);
  const classNames = useClassNames<IProtectedStyles, IProtectedClassNames>('Protected', protectedStylesDefault);
  return !user ? (
    <Loader styles={loaderStylesSpinnerXLarge} label="Loading..." />
  ) : (
    <GTagProvider {...gTag}>
      <EnvProvider env={env}>
        <UserProvider user={user}>
          <ToastProvider>
            <LeftPanelProvider setLeftPanelProps={setLeftPanelProps}>
              <div className={classNames.root}>
                <LeftPanel {...leftPanelProps} />
                <Navigation />
                <Toast />
                <main
                  data-test="main-content"
                  ref={refMain}
                  className={leftPanelProps?.content ? `${classNames.main} has-left-panel` : classNames.main}
                >
                  <div className={classNames.contents}>
                    <NotificationBar />
                    <div className={classNames.contentsBody}>
                      <Routes />
                    </div>
                  </div>
                  <Footer
                    onCookiePreferencesClick={
                      osanoIsLoaded ? () => window['Osano'].cm.showDrawer('osano-cm-dom-info-dialog-open') : undefined
                    }
                    onPrivacyPolicyClick={() => window.open('https://h2o.ai/legal/privacy')}
                  />
                  <BookTimeDialog
                    url={env?.menu?.bookTimeLink}
                    onDismiss={onDismissBookTimeDialog}
                    hidden={bookTimeDialogHidden}
                  />
                </main>
              </div>
            </LeftPanelProvider>
          </ToastProvider>
        </UserProvider>
      </EnvProvider>
    </GTagProvider>
  );
}
