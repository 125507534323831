import { MessageBarType } from '@fluentui/react';
import { useToast } from '@h2oai/ui-kit';
import { useCallback, useEffect, useState } from 'react';

import { Alias, AppInstance, AppInstance_Visibility, UpdateAppInstanceRequest } from '../../ai.h2o.cloud.appstore';
import ErrorPage from '../../pages/ErrorPage';
import { AdminAliasService, AdminAppService, AppService } from '../../services/api';
import { useError, useInstance } from '../../utils/hooks';
import { InstanceListType } from '../../utils/models';
import { InstancePauseResumeMap, InstancePauseResumeOpEnum, handleErrMsg } from '../../utils/utils';
import SearchableInstanceList from '../InstanceList/SearchableInstanceList';

interface InstanceListPageProps {
  type: InstanceListType;
}

export default function InstanceListPage(props: InstanceListPageProps) {
  const { type } = props,
    [instances, setInstances] = useState<AppInstance[]>(),
    [aliases, setAliases] = useState<Alias[]>(),
    [loadingMsg, setLoadingMsg] = useState(''),
    [loading, setLoading] = useState<[string, boolean]>(),
    [terminating, setTerminating] = useState<[string, boolean]>(),
    [err, setErr] = useError(),
    { getInstancesList, setInstanceSuspension, terminateInstance } = useInstance(),
    { addToast } = useToast(),
    isAdmin = type === InstanceListType.admin,
    loadInstances = useCallback(
      async (id?: string) => {
        if (!id) setLoadingMsg('Loading App Instances...');

        try {
          if (isAdmin) {
            const [{ instances }, { aliases }] = await Promise.all([
              AdminAppService.listAppInstances({
                appId: '',
                includeAppDetails: true,
                visibility: AppInstance_Visibility.VISIBILITY_UNSPECIFIED,
                allUsers: true,
              }),
              AdminAliasService.listAliases({ instanceId: '' }),
            ]);
            setAliases(aliases);
            setInstances(instances);
          } else if (type === InstanceListType.my) {
            const appInstances = await getInstancesList({
              appId: '',
              includeAppDetails: true,
              visibility: AppInstance_Visibility.VISIBILITY_UNSPECIFIED,
              allUsers: false,
            });
            setInstances(appInstances);
          }
        } catch (error: unknown) {
          if (error instanceof Error) setErr({ ...error });
        } finally {
          setLoadingMsg('');
        }
      },
      [setErr, getInstancesList]
    ),
    setSuspension = useCallback(
      (appInstance: AppInstance, opEnum: InstancePauseResumeOpEnum) => async () => {
        const { id } = appInstance;
        const op = InstancePauseResumeMap.get(opEnum);
        if (!op) return;
        setLoading([id, true]);
        try {
          await setInstanceSuspension(appInstance, op, isAdmin);
          await loadInstances(id);
          addToast({
            message: `${op.completedDescription} app instance with id ${id}`,
            messageBarType: MessageBarType.success,
          });
        } catch ({ message }) {
          addToast({
            message: `Could not ${op.description} app instance: ${handleErrMsg(message as string)}`,
            messageBarType: MessageBarType.error,
          });
        } finally {
          setLoading([id, false]);
        }
      },
      [loadInstances, setInstanceSuspension]
    ),
    terminate = useCallback(
      (instance: AppInstance) => async () => {
        const { id } = instance;
        setTerminating([id, true]);
        try {
          await terminateInstance(instance, isAdmin);
          await loadInstances(id);
          addToast({ message: `Terminated app instance with id ${id}`, messageBarType: MessageBarType.success });
        } catch ({ message }) {
          addToast({
            message: `Could not terminate app instance: ${handleErrMsg(message as string)}`,
            messageBarType: MessageBarType.error,
          });
        } finally {
          setTerminating([id, false]);
        }
      },
      [loadInstances, terminateInstance]
    ),
    updateVisibility = useCallback(
      (updatedInstance: UpdateAppInstanceRequest) => async () => {
        setLoading([updatedInstance.id, true]);
        try {
          if (isAdmin) {
            await AdminAppService.updateAppInstance(updatedInstance);
          } else if (type === InstanceListType.my) {
            await AppService.updateAppInstance(updatedInstance);
          }
          await loadInstances(updatedInstance.id);
          addToast({
            message: `Edited app instance with id ${updatedInstance.id}`,
            messageBarType: MessageBarType.success,
          });
        } catch ({ message }) {
          addToast({
            message: `Could not update app instance visibility: ${handleErrMsg(message as string)}`,
            messageBarType: MessageBarType.error,
          });
        } finally {
          setLoading([updatedInstance.id, false]);
        }
      },
      [loadInstances, addToast]
    );
  useEffect(() => {
    loadInstances();
  }, [loadInstances]);

  return err ? (
    <ErrorPage {...err} />
  ) : (
    <>
      <SearchableInstanceList
        title={isAdmin ? 'Admin Instances' : 'My Instances'}
        subtitle={`You have ${instances?.length || 0} instances`}
        aliases={isAdmin ? aliases : undefined}
        instances={instances}
        type={type}
        loading={loading}
        terminating={terminating}
        loadingMsg={loadingMsg}
        terminateInstance={terminate}
        setInstanceSuspension={setSuspension}
        updateVisibility={updateVisibility}
      />
    </>
  );
}
