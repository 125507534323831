import { IDropdownOption, Stack } from '@fluentui/react';
import { Accordion, Dropdown, TextField } from '@h2oai/ui-kit';
import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  AIEngine,
  EngineConstraintSet,
  EngineSizeOption,
  EngineVersion,
  V1AdjustedDAIProfile,
  V1DAIEngine,
  V1EngineType,
  ValidEngineType,
  extractWorkspace,
} from '../../../../aiem';
import { defaultVersion } from '../../../../aiem/defaults';
import { useEngine } from '../../../../aiem/hooks';
import {
  DefaultDriverlessEngineSize,
  DefaultH2OEngineSize,
  DriverlessEngineSizeOptionKeyType,
  DriverlessEngineSizeOptions,
  H2OEngineSizeOptionKeyType,
  H2OEngineSizeOptions,
} from '../../constants';
import { bytesToGibibytes } from '../../utils';
import { AdvancedConfiguration } from '../AdvancedConfiguration/AdvancedConfiguration';
import { CKind, ConstraintInput } from '../ConstraintInput/ConstraintInput';
import { DisplayAndId } from '../DisplayAndId/DisplayAndId';
import ConfigureCompressedDataEngine from './components/ConfigureCompressedDataEngine';
import ConfigureCustomEngineSize from './components/ConfigureCustomEngineSize';
import ConfigureRawDataEngine from './components/ConfigureRawDataEngine';
import DisplayPresetEngine from './components/DisplayPresetEngineSize';

export function useAIEMFormAttributes() {
  const inputContainerProps = { styles: { root: { width: 450 } } },
    formRowHeight = 137,
    formRowProps = {
      horizontal: true,
      styles: { root: { width: 666, height: formRowHeight } },
      tokens: { childrenGap: 33 },
    },
    sliderFormRowProps = {
      horizontal: true,
      styles: { root: { width: 666, height: 77 } },
      tokens: { childrenGap: 15 },
    },
    inputRowProps = {
      horizontal: true,
      styles: { root: { width: 666, height: 77 } },
      tokens: { childrenGap: 15 },
    },
    sliderContainerProps = { styles: { root: { width: 450 } } };
  return {
    formRowHeight,
    formRowProps,
    inputContainerProps,
    inputRowProps,
    sliderContainerProps,
    sliderFormRowProps,
  };
}

export type EngineConfigurationProps = {
  operationCreate?: boolean;
  engine: AIEngine;
  updateEngine: (updater: (engine: AIEngine) => AIEngine, defaultValuesSet: boolean) => void;
  validateID: (validate: boolean) => void;
};

export function EngineConfiguration({ engine, updateEngine, operationCreate, validateID }: EngineConfigurationProps) {
  const { listDAIEngineSizeOptions } = useEngine(),
    { inputContainerProps, inputRowProps, sliderContainerProps, sliderFormRowProps } = useAIEMFormAttributes(),
    { listVersions, getConstraintSet } = useEngine();

  const engineType = engine?.engineType,
    engineSizeOptionKeys =
      engine?.engineType === V1EngineType.DriverlessAi ? DriverlessEngineSizeOptionKeyType : H2OEngineSizeOptionKeyType,
    [engineSizeOptions, setEngineSizeOptions] = useState<EngineSizeOption[]>(
      engine?.engineType === V1EngineType.DriverlessAi ? DriverlessEngineSizeOptions : H2OEngineSizeOptions
    ),
    [profile, setProfile] = useState<V1AdjustedDAIProfile | undefined>(undefined),
    defaultEngineSize =
      engine?.engineType === V1EngineType.DriverlessAi ? DefaultDriverlessEngineSize : DefaultH2OEngineSize,
    defaultValuesSet = useRef<boolean>(false),
    [selectedVersion, setSelectedVersion] = useState<string>(defaultVersion),
    [versions, setVersions] = useState<IDropdownOption[]>([]),
    [selectedSize, setSelectedSize] = useState<DriverlessEngineSizeOptionKeyType | H2OEngineSizeOptionKeyType>(
      operationCreate ? defaultEngineSize : engineSizeOptionKeys.custom
    ),
    [constraintSet, setConstraintSet] = useState<EngineConstraintSet>(),
    modifyEngine = useCallback(
      (newEngineFields: Partial<AIEngine>) => {
        updateEngine((previousEngine) => ({ ...previousEngine, ...newEngineFields }), defaultValuesSet.current);
      },
      [updateEngine, defaultValuesSet]
    ),
    handleDropDownChange = (_e: FormEvent<HTMLDivElement>, { key: value }: any) => {
      setSelectedVersion(value);
      modifyEngine({ version: value });
    },
    onDisplayNameChange = (value: string) => modifyEngine({ displayName: value }),
    onIdChange = (value: string) => modifyEngine({ id: value }),
    onRenderSizeOption = (option: any) => {
      return (
        <span style={{ width: '100%' }} data-test={option?.dataTest}>
          {option?.text}
        </span>
      );
    },
    loadEngineSizeOptions = useCallback(async () => {
      const profiles = (await listDAIEngineSizeOptions()) as EngineSizeOption[];
      setEngineSizeOptions(profiles);
      setSelectedSize(profiles[0].key || DriverlessEngineSizeOptionKeyType.custom);
    }, [listDAIEngineSizeOptions]),
    loadConstraintSet = useCallback(async () => {
      const loadedSet = await getConstraintSet(
        operationCreate ? undefined : extractWorkspace(engine?.name),
        engineType
      );
      if (loadedSet) {
        setConstraintSet(loadedSet);
        return {
          cpu: Number(loadedSet?.cpu?._default),
          gpu: Number(loadedSet?.gpu?._default),
          memoryBytes: loadedSet?.memoryBytes?._default,
          storageBytes: loadedSet?.storageBytes?._default,
          nodeCount: Number(loadedSet?.nodeCount?._default),
          maxIdleDuration: loadedSet?.maxIdleDuration?._default,
          maxRunningDuration: loadedSet?.maxRunningDuration?._default,
        };
      }
      return {};
    }, [engineType, engine?.name, setConstraintSet]),
    loadVersions = useCallback(async () => {
      let defaultVersion;
      const versions = await listVersions(engineType as ValidEngineType),
        options =
          versions?.map(({ key, version: text, isDefault }: EngineVersion) => {
            if (isDefault && key) {
              setSelectedVersion(key);
              defaultVersion = key;
            }
            return { key, text } as IDropdownOption;
          }) || [];
      setVersions(options);
      return defaultVersion;
    }, [listVersions, setSelectedVersion, setVersions, modifyEngine]),
    { memoryValueGb, storageValueGb } = useMemo(() => {
      const memoryStringGb = bytesToGibibytes(engine.memoryBytes);
      const memoryValueGb = memoryStringGb ? parseInt(memoryStringGb) : 0;

      const storageStringGb = bytesToGibibytes(engine.storageBytes);
      const storageValueGb = storageStringGb ? parseInt(storageStringGb) : 0;
      return { memoryValueGb, storageValueGb };
    }, [engine]),
    EngineSizeComponent = useMemo(() => {
      if (
        selectedSize === DriverlessEngineSizeOptionKeyType.custom ||
        selectedSize === H2OEngineSizeOptionKeyType.custom
      ) {
        return ConfigureCustomEngineSize;
      }
      if (selectedSize === H2OEngineSizeOptionKeyType.compressed) return ConfigureCompressedDataEngine;
      if (selectedSize === H2OEngineSizeOptionKeyType.raw) return ConfigureRawDataEngine;
      return DisplayPresetEngine;
    }, [selectedSize]);

  useEffect(() => {
    const loadData = async () => {
      if (engineType === V1EngineType.DriverlessAi) await loadEngineSizeOptions();
      let defaultVersion;
      if (operationCreate) {
        defaultVersion = await loadVersions();
      }
      const defaultEngineValues = await loadConstraintSet();
      defaultValuesSet.current = true;
      const nextEngineValues = defaultVersion
        ? { ...defaultEngineValues, version: defaultVersion }
        : defaultEngineValues;
      updateEngine(
        (previousEngine) => ({
          ...previousEngine,
          ...nextEngineValues,
        }),
        defaultValuesSet.current
      );
    };
    loadData();
  }, []);

  return (
    <form>
      <Stack>
        <DisplayAndId
          engine={engine}
          onDisplayNameChange={onDisplayNameChange}
          onIdChange={onIdChange}
          editableId={operationCreate}
          validateID={validateID}
        />
        <Stack {...inputRowProps}>
          <Stack {...inputContainerProps}>
            {operationCreate ? (
              <Dropdown
                label="Version"
                placeholder="Select a Version"
                options={versions}
                selectedKey={selectedVersion}
                onChange={handleDropDownChange}
              />
            ) : (
              <TextField
                name="version"
                label="Version"
                value={engine?.version}
                onChange={(e: any) => modifyEngine({ version: e.event.value })}
                readOnly
              />
            )}
          </Stack>
        </Stack>
        <Stack {...inputRowProps}>
          <Stack {...inputContainerProps}>
            <Dropdown
              label="Size"
              selectedKey={selectedSize}
              options={engineSizeOptions}
              loadingMessage={constraintSet ? undefined : 'Loading...'}
              onRenderOption={onRenderSizeOption}
              onChange={async (_e: FormEvent<HTMLDivElement>, option: any) => {
                const { key: value } = option;
                setSelectedSize(value);
                if (value !== engineSizeOptionKeys.custom && engineType === V1EngineType.DriverlessAi) {
                  const selectedSizeOption = engineSizeOptions.find((option) => option.key === value);
                  setProfile(option);
                  modifyEngine({
                    cpu: selectedSizeOption?.cpu || 0,
                    gpu: selectedSizeOption?.gpu || 0,
                    memoryBytes: selectedSizeOption?.memoryBytes || '0',
                    storageBytes: selectedSizeOption?.storageBytes || '0',
                  });
                }
              }}
            />
          </Stack>
        </Stack>
        <EngineSizeComponent
          engine={engine}
          constraintSet={constraintSet}
          profile={profile}
          modifyEngine={modifyEngine}
          memoryValueGb={memoryValueGb}
          storageValueGb={storageValueGb}
        />
        <Accordion title="Timeout Configuration" isClose styles={{ title: { fontWeight: 400 } }}>
          <Stack {...sliderFormRowProps} styles={{ root: { width: 400, height: 77 } }}>
            <Stack {...sliderContainerProps}>
              <ConstraintInput
                constraintKind={CKind.MAXIDLEDURATION}
                constraint={constraintSet?.[CKind.MAXIDLEDURATION]}
                value={engine?.[CKind.MAXIDLEDURATION]}
                onChange={(_event, value) => {
                  const fieldName = CKind.MAXIDLEDURATION as string;
                  modifyEngine({ [fieldName]: value });
                }}
                label="Max Idle Time (Hours)"
                tooltip="Specify the maximum idle time of the Driverless AI instance. Instance will pause if it is idle for longer
                    than max idle time. When the instance pauses, it can be started again."
              />
            </Stack>
          </Stack>
          <Stack {...sliderFormRowProps} styles={{ root: { width: 400, height: 77 } }}>
            <Stack {...sliderContainerProps}>
              <ConstraintInput
                label="Max Up Time (Hours)"
                constraintKind={CKind.MAXRUNNINGDURATION}
                constraint={constraintSet?.[CKind.MAXRUNNINGDURATION]}
                value={engine?.[CKind.MAXRUNNINGDURATION]}
                onChange={(_event, value) => {
                  const fieldName = CKind.MAXRUNNINGDURATION as string;
                  modifyEngine({ [fieldName]: value });
                }}
                tooltip="Set the duration after which the instance automatically pauses. When the instance pauses, it can be started again."
              />
            </Stack>
          </Stack>
        </Accordion>

        {engineType === V1EngineType.DriverlessAi && (
          <Accordion
            title="Advanced Configuration (app.toml)"
            isClose
            styles={{
              title: { fontWeight: 400 },
              root: { marginTop: 12, marginBottom: 40, height: 'unset !important' },
            }}
          >
            <AdvancedConfiguration
              config={(engine as unknown as V1DAIEngine).config || {}}
              onConfigChange={(config) =>
                updateEngine((oldEngine) => {
                  return {
                    ...oldEngine,
                    config,
                  };
                }, true)
              }
            />
          </Accordion>
        )}
      </Stack>
    </form>
  );
}
