/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/h2o_engine_constraint_set_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1ConstraintDuration,
    V1ConstraintDurationFromJSON,
    V1ConstraintDurationFromJSONTyped,
    V1ConstraintDurationToJSON,
} from './V1ConstraintDuration';
import {
    V1ConstraintNumeric,
    V1ConstraintNumericFromJSON,
    V1ConstraintNumericFromJSONTyped,
    V1ConstraintNumericToJSON,
} from './V1ConstraintNumeric';

/**
 * Message representing a set of constraints for H2OEngine.
 * @export
 * @interface V1H2OEngineConstraintSet
 */
export interface V1H2OEngineConstraintSet {
    /**
     * H2OEngineConstraintSet resource name.
     * @type {string}
     * @memberof V1H2OEngineConstraintSet
     */
    name?: string;
    /**
     * 
     * @type {V1ConstraintNumeric}
     * @memberof V1H2OEngineConstraintSet
     */
    cpu?: V1ConstraintNumeric;
    /**
     * 
     * @type {V1ConstraintNumeric}
     * @memberof V1H2OEngineConstraintSet
     */
    gpu?: V1ConstraintNumeric;
    /**
     * 
     * @type {V1ConstraintNumeric}
     * @memberof V1H2OEngineConstraintSet
     */
    memoryBytes?: V1ConstraintNumeric;
    /**
     * 
     * @type {V1ConstraintDuration}
     * @memberof V1H2OEngineConstraintSet
     */
    maxIdleDuration?: V1ConstraintDuration;
    /**
     * 
     * @type {V1ConstraintDuration}
     * @memberof V1H2OEngineConstraintSet
     */
    maxRunningDuration?: V1ConstraintDuration;
    /**
     * 
     * @type {V1ConstraintNumeric}
     * @memberof V1H2OEngineConstraintSet
     */
    nodeCount?: V1ConstraintNumeric;
}

export function V1H2OEngineConstraintSetFromJSON(json: any): V1H2OEngineConstraintSet {
    return V1H2OEngineConstraintSetFromJSONTyped(json, false);
}

export function V1H2OEngineConstraintSetFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1H2OEngineConstraintSet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'cpu': !exists(json, 'cpu') ? undefined : V1ConstraintNumericFromJSON(json['cpu']),
        'gpu': !exists(json, 'gpu') ? undefined : V1ConstraintNumericFromJSON(json['gpu']),
        'memoryBytes': !exists(json, 'memoryBytes') ? undefined : V1ConstraintNumericFromJSON(json['memoryBytes']),
        'maxIdleDuration': !exists(json, 'maxIdleDuration') ? undefined : V1ConstraintDurationFromJSON(json['maxIdleDuration']),
        'maxRunningDuration': !exists(json, 'maxRunningDuration') ? undefined : V1ConstraintDurationFromJSON(json['maxRunningDuration']),
        'nodeCount': !exists(json, 'nodeCount') ? undefined : V1ConstraintNumericFromJSON(json['nodeCount']),
    };
}

export function V1H2OEngineConstraintSetToJSON(value?: V1H2OEngineConstraintSet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'cpu': V1ConstraintNumericToJSON(value.cpu),
        'gpu': V1ConstraintNumericToJSON(value.gpu),
        'memoryBytes': V1ConstraintNumericToJSON(value.memoryBytes),
        'maxIdleDuration': V1ConstraintDurationToJSON(value.maxIdleDuration),
        'maxRunningDuration': V1ConstraintDurationToJSON(value.maxRunningDuration),
        'nodeCount': V1ConstraintNumericToJSON(value.nodeCount),
    };
}

