import {
  Dashboard,
  DashboardRow,
  DashboardRowLayout,
  IDashboardProps,
  compareVersionString,
  useClassNames,
  useHaicPageTitle,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useState } from 'react';

import { App, AppPreconditionStatus, App_Visibility } from '../../ai.h2o.cloud.appstore';
import { TelemetryService } from '../../services/api';
import { Granularity } from '../../telemetry/gen/ai/h2o/cloud/telemetry_read_api/v1/aiunits.pb';
import { EnvironmentAndMenu } from '../../utils/contexts';
import { useApp, useEnv } from '../../utils/hooks';
import { getAppIconImagePath } from '../../utils/utils';
import { UsageDatum, getDaysAgo, getMinutesAgo } from '../PlatformUsage/components/TimeSeries';
import { granularityOptions } from '../PlatformUsage/constants/granularity';
import { RoutePaths } from '../Routes';
import { AIEnginesListWidget } from './AIEnginesListWidget/AIEnginesListWidget';
import { AppCardListWidget } from './AppCardListWidget/AppCardListWidget';
import { AppInstanceListWidget } from './AppInstanceListWidget/AppInstanceListWidget';
import { AppListWidget } from './AppListWidget/AppListWidget';
import { IHomePageStyles, homePageStylesDefault } from './HomePage.styles';
import { MyAppListWidget } from './MyAppListWidget/MyAppListWidget';
import { PlatformUsageWidget } from './PlatformUsageWidget/PlatformUsageWidget';

export enum WidgetLocations {
  TOP_LEFT = 'TOP_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
  MIDDLE_LEFT = 'MIDDLE_LEFT',
  MIDDLE_CENTER = 'MIDDLE_CENTER',
  MIDDLE_RIGHT = 'MIDDLE_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_CENTER = 'BOTTOM_CENTER',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
}

const addAppByLocation = (
  widgetApps: { [key: string]: App[] },
  app: App,
  location: WidgetLocations,
  env: EnvironmentAndMenu | undefined
) => {
  const appIndexMaps = {
    [WidgetLocations.TOP_LEFT]: new Map<string, number>(
      env?.homePage?.widgetTopLeft?.apps.map((appName, position) => [appName, position])
    ),
    [WidgetLocations.TOP_RIGHT]: new Map<string, number>(
      env?.homePage?.widgetTopRight?.apps.map((appName, position) => [appName, position])
    ),
    [WidgetLocations.BOTTOM_LEFT]: new Map<string, number>(
      env?.homePage?.widgetBottomLeft?.apps.map((appName, position) => [appName, position])
    ),
  };
  if (appIndexMaps[location].has(app.name)) {
    const i = appIndexMaps[location].get(app.name);
    let lastApp: App = widgetApps[location][i];
    if (lastApp) {
      if (compareVersionString(lastApp.version, app.version) === -1) {
        lastApp = { ...app };
      }
    } else {
      lastApp = { ...app };
    }
    widgetApps[location][i] = lastApp;
  }
};

const getEmptyWidgetApps = (): { [key: string]: App[] } => {
  return {
    ...{
      [WidgetLocations.TOP_LEFT]: [],
      [WidgetLocations.TOP_RIGHT]: [],
      [WidgetLocations.MIDDLE_RIGHT]: [],
      [WidgetLocations.BOTTOM_LEFT]: [],
      [WidgetLocations.BOTTOM_CENTER]: [],
      [WidgetLocations.BOTTOM_RIGHT]: [],
    },
  };
};

const getWidgetLocationTitleMap = (env: EnvironmentAndMenu | undefined) => {
  return new Map<WidgetLocations, string>([
    [WidgetLocations.TOP_LEFT, env?.homePage?.widgetTopLeft?.title || ''],
    [WidgetLocations.TOP_RIGHT, env?.homePage?.widgetTopRight?.title || ''],
    [WidgetLocations.MIDDLE_LEFT, 'My app instances'],
    [WidgetLocations.MIDDLE_CENTER, env?.menu?.hasAiEngineManager ? 'My AI engines' : 'My imported apps'],
    [WidgetLocations.MIDDLE_RIGHT, 'My pinned apps'],
    [WidgetLocations.BOTTOM_LEFT, env?.homePage?.widgetBottomLeft?.title || ''],
    [WidgetLocations.BOTTOM_CENTER, 'Latest uploaded apps'],
    [WidgetLocations.BOTTOM_RIGHT, 'Peak AI Unit consumption'],
  ]);
};

export const getWidgetApps = (apps: App[], env: EnvironmentAndMenu | undefined) => {
  const widgetApps = getEmptyWidgetApps();
  const specificAppLocations = [WidgetLocations.TOP_LEFT, WidgetLocations.TOP_RIGHT, WidgetLocations.BOTTOM_LEFT];
  apps.forEach((app, i) => {
    app.iconLocation = getAppIconImagePath(app.iconLocation);
    if (i < 5) {
      // Latest apps are in bottom right
      widgetApps[WidgetLocations.BOTTOM_CENTER].push({ ...app });
    }
    if (app.preference?.pinned && widgetApps[WidgetLocations.MIDDLE_RIGHT].length < 5) {
      // pinned apps are in middle center
      widgetApps[WidgetLocations.MIDDLE_RIGHT].push({ ...app });
    }
    specificAppLocations.forEach((location) => addAppByLocation(widgetApps, app, location, env));
  });
  specificAppLocations.forEach((title) => (widgetApps[title] = widgetApps[title].filter((app) => app)));
  return widgetApps;
};

interface IHomePageClassNames {
  root: string;
}

export const HomePage = () => {
  const { getApps } = useApp();
  const env = useEnv();
  const [data, setData] = useState<{ [key: string]: App[] }>(getEmptyWidgetApps());
  const [formattedUsageData, setFormattedUsageData] = useState<UsageDatum[] | undefined>(undefined);
  const [formattedMaxUsageData, setFormattedMaxUsageData] = useState<UsageDatum | undefined>(undefined);
  const [usageDataLoading, setUsageDataLoading] = useState('Calculating usage...');
  const [widgetTitles, setWidgetTitles] = useState<Map<WidgetLocations, string>>(getWidgetLocationTitleMap(env));
  const [loadingMessage, setLoadingMessage] = useState('Loading');
  const loadData = useCallback(async () => {
    const apps = await getApps({
      limit: 1000, // when 4k, the number of cards is 10
      offset: 0,
      visibility: App_Visibility.ALL_USERS,
      allUsers: true,
      name: '',
      latestVersions: true,
      withPreference: true,
      tags: [],
      conditionsStatus: AppPreconditionStatus.STATUS_UNSPECIFIED,
      visibilities: [],
    });
    setData(getWidgetApps(apps, env));
    setWidgetTitles(getWidgetLocationTitleMap(env));
    setLoadingMessage('');
  }, [getApps, env]);
  const loadMaxUsageData = useCallback(async () => {
    const startTime = getMinutesAgo(10);
    const endTime = getMinutesAgo(0);

    try {
      const response = await TelemetryService.calculateMaxUsage({
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        component: 'components/global',
      });

      if (response.maxUsage) {
        const maxUsage = response.maxUsage;
        const formattedDate = new Date(maxUsage?.timestamp);
        setFormattedMaxUsageData({
          value: maxUsage?.milliAiUnits ? parseInt(maxUsage.milliAiUnits) : NaN,
          time:
            formattedDate.toLocaleDateString(undefined, {
              minute: 'numeric',
            }) || '',
        });
      }
    } catch (error) {
      setUsageDataLoading('Failed to load current usage data');
    }
  }, []);
  const loadUsageData = useCallback(async () => {
    const startTime = getDaysAgo(7);
    const endTime = getDaysAgo(0);
    const granularity = Granularity.DAY;

    try {
      const response = await TelemetryService.calculateUsage({
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        granularity,
        component: 'components/global',
      });

      const currentGranularity =
        granularityOptions.find((option) => option.key === granularity)?.[0] || granularityOptions[1];

      if (response.usage) {
        setFormattedUsageData(
          response.usage.map((datum) => ({
            value: datum?.milliAiUnits ? parseInt(datum.milliAiUnits) : NaN,
            time: currentGranularity?.formatDate(new Date(datum?.interval?.startTime)) || '',
          }))
        );
      }
    } catch (error) {
      setUsageDataLoading('Usages data fail');
    }
  }, []);
  useEffect(() => {
    const load = async () => {
      await loadUsageData();
      await loadMaxUsageData();
      setUsageDataLoading('');
    };

    loadData();
    if (env?.platformUsageEnabled) {
      load();
    }
  }, [loadData, loadUsageData, loadMaxUsageData, env]);
  useHaicPageTitle('Home');

  // TODO: replace this with the data from a new API.
  const DashboardRows: DashboardRow[] = [
    {
      layout: DashboardRowLayout.threeOne,
      widgetIds: [WidgetLocations.TOP_LEFT, WidgetLocations.TOP_RIGHT],
    },
    {
      layout: DashboardRowLayout.oneOneOne,
      widgetIds: [WidgetLocations.MIDDLE_LEFT, WidgetLocations.MIDDLE_CENTER, WidgetLocations.MIDDLE_RIGHT],
    },
    {
      layout: DashboardRowLayout.oneOne,
      widgetIds: [
        WidgetLocations.BOTTOM_LEFT,
        WidgetLocations.BOTTOM_CENTER,
        ...(env?.platformUsageEnabled ? [WidgetLocations.BOTTOM_RIGHT] : []),
      ],
    },
  ];

  const classNames = useClassNames<IHomePageStyles, IHomePageClassNames>('HomePage', homePageStylesDefault);
  const props: IDashboardProps = {
    title: 'Welcome to the H2O AI Cloud',
    rows: DashboardRows,
    cellRenderer: (location: string, windowWidth: number) => {
      const widgetTitle = widgetTitles?.get(location as unknown as WidgetLocations) || '';
      switch (location) {
        case WidgetLocations.TOP_LEFT:
          return (
            <AppCardListWidget
              title={widgetTitle}
              windowWidth={windowWidth}
              data={data[location]}
              loadingMessage={loadingMessage}
            />
          );
        case WidgetLocations.MIDDLE_LEFT:
          return <AppInstanceListWidget title={widgetTitle} />;
        case WidgetLocations.MIDDLE_CENTER:
          return env?.menu?.hasAiEngineManager ? (
            <AIEnginesListWidget title={widgetTitle} />
          ) : (
            <MyAppListWidget title={widgetTitle} />
          );
        case WidgetLocations.BOTTOM_RIGHT:
          return env?.platformUsageEnabled ? (
            <PlatformUsageWidget
              title={widgetTitle}
              data={formattedUsageData}
              maxUsageData={formattedMaxUsageData}
              loadingMessage={usageDataLoading}
            />
          ) : null;
        case WidgetLocations.TOP_RIGHT:
        case WidgetLocations.MIDDLE_RIGHT:
        case WidgetLocations.BOTTOM_LEFT:
        case WidgetLocations.BOTTOM_CENTER:
          return (
            <AppListWidget
              title={widgetTitle}
              link={RoutePaths.APPSTORE}
              data={data[location]}
              loadingMessage={loadingMessage}
            />
          );
      }
      return null;
    },
  };
  return (
    <div className={classNames.root}>
      <Dashboard {...props} />
    </div>
  );
};
