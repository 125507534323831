import { DetailsList, IDetailsListProps, SelectionMode, Stack } from '@fluentui/react';

export interface StyledDetailsListProps extends IDetailsListProps {
  dataTest: string;
  noHeader?: boolean;
}

export default function StyledDetailsList(props: StyledDetailsListProps) {
  const { noHeader, dataTest, ...rest } = props;
  return (
    <Stack
      data-test={dataTest}
      styles={{ root: { width: '100%', position: 'relative', paddingTop: noHeader ? 20 : undefined } }}
    >
      <DetailsList
        selectionMode={SelectionMode.none} // Selection is handled outside of DetailsList
        isHeaderVisible={noHeader ? false : undefined}
        {...rest}
      />
    </Stack>
  );
}
