import { IBreadcrumbItem, IPanelStyles, PanelType, Stack } from '@fluentui/react';
import { Breadcrumb, Button, FontSizes, IconButton, Panel, buttonStylesIcon, buttonStylesPrimary } from '@h2oai/ui-kit';
import { useState } from 'react';

import {
  AIEngine,
  V1Engine,
  V1EngineType,
  ValidEngineType,
  engineTypeService,
  extractId,
  getRandomNumber,
} from '../../../../aiem';
import { EmptyDAIEngine, EmptyH2OEngine } from '../../constants';
import { EngineConfiguration } from '../EngineConfiguration/EngineConfiguration';
import { EngineSelection } from '../EngineSelection/EngineSelection';

export const validateId = (id: string | undefined | null): boolean =>
  Boolean(id) && /^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$/.test(id!);

export const getRandomEngineName = (engineType: V1EngineType) =>
  `New ${engineTypeService[engineType].text} Engine ${getRandomNumber()}`;

const isValidNewEngine = (engine: AIEngine) => {
  const { displayName, id, version, memoryBytes } = engine;
  return Boolean(displayName && validateId(id) && version && memoryBytes);
};

const isValidExistingEngine = (engine: V1Engine) => {
  const { displayName, version } = engine;
  return Boolean(displayName && version);
};

const styleResetHeading = {
  fontSize: `100%`,
  fontWeight: `inherit`,
  margin: 0,
};

enum PanelStep {
  Selection = 'selection',
  EngineConfiguration = 'engineConfiguration',
}

const stylesPanel: Partial<IPanelStyles> = {
  content: {
    padding: 0,
    overflowX: 'hidden',
  },
  scrollableContent: {
    '& > * + *': {
      marginTop: `2rem`,
    },
    '& > *': {
      paddingInline: `1.5rem !important`,
    },
  },
  footerInner: {
    display: `flex`,
    flexFlow: `row-reverse`, // the button order is reversed, the "Confirm" has lower tab-index
    gap: `0.5rem`,
    justifyContent: `space-between`,
    paddingBottom: 0,
  },
  main: {
    paddingBlock: `1.5rem`,
  },
  commands: {
    padding: 0,
    '& > * + *': {
      marginTop: `0.5rem`,
    },
    '& h2': [styleResetHeading, { fontSize: FontSizes.large }],
  },
};

type PanelEditHeaderProps = {
  breadcrumbItems: IBreadcrumbItem[];
  onBackClick?: () => void;
  panelHeading: string;
};

export type AIEMPanelEditProps = {
  operationCreate?: boolean;
  engine: AIEngine;
  onSave: () => void;
  onDismiss: () => void;
  onChange: (engine: AIEngine) => void;
};

type EngineView = {
  isValid: boolean;
  defaultValuesSet: boolean;
  engine: AIEngine;
};

export function AIEMPanelEdit({
  operationCreate,
  onChange,
  onDismiss,
  onSave,
  engine: paramEngine,
}: AIEMPanelEditProps) {
  const [{ engine, isValid, defaultValuesSet }, setEngine] = useState<EngineView>({
      engine: paramEngine,
      isValid: false,
      defaultValuesSet: false,
    }),
    [validatedID, setValidatedID] = useState<boolean>(true);

  const [currentStep, setCurrentStep] = useState<PanelStep>(
      operationCreate && !engine?.engineType ? PanelStep.Selection : PanelStep.EngineConfiguration
    ),
    showSelection = () => {
      setCurrentStep(PanelStep.Selection);
    },
    showEngineConfiguration = () => {
      setCurrentStep(PanelStep.EngineConfiguration);
    };

  const rootBreadcrumbItem = { key: `root`, text: `Create AI Engine` },
    engineSelectionBreadcrumbItem = {
      key: `select`,
      text: `Select Engine`,
      onClick: showSelection,
    },
    { breadcrumbItems, onBackClick, panelHeading }: PanelEditHeaderProps = (function () {
      if (currentStep === PanelStep.Selection) {
        return {
          breadcrumbItems: [rootBreadcrumbItem, { ...engineSelectionBreadcrumbItem, onClick: undefined }],
          panelHeading: `Engine Selection`,
        };
      } else {
        return {
          breadcrumbItems: [
            rootBreadcrumbItem,
            ...(operationCreate ? [engineSelectionBreadcrumbItem] : []),
            {
              key: `edit`,
              text: `Engine Configuration`,
              isCurrentItem: true,
            },
          ],
          onBackClick: operationCreate ? showSelection : onDismiss,
          panelHeading: `Engine Configuration`,
        };
      }
    })() as PanelEditHeaderProps;

  const updateEngine = (updater: (currentEngine: AIEngine) => AIEngine, defaultValuesSet?: boolean) => {
    const newEngine = updater(engine);
    const isNewEngineValid = newEngine
      ? operationCreate
        ? isValidNewEngine(newEngine)
        : isValidExistingEngine(newEngine)
      : false;
    setEngine({ engine: newEngine, isValid: isNewEngineValid, defaultValuesSet: !!defaultValuesSet });
    if (isNewEngineValid) onChange(newEngine);
  };

  const onEngineSelection = (engineType: V1EngineType | undefined) => {
    const displayName = engineType ? getRandomEngineName(engineType) : '';
    setEngine({
      engine: {
        ...(engineType === V1EngineType.H2O ? EmptyH2OEngine : EmptyDAIEngine),
        engineType,
        displayName,
        id: extractId(displayName),
      },
      isValid: false,
      defaultValuesSet: false,
    });
    showEngineConfiguration();
  };

  return (
    <Panel
      isLightDismiss
      data-test="aiem-panel"
      closeButtonAriaLabel="Close"
      isFooterAtBottom={true}
      customWidth="500px"
      isOpen={true}
      onDismiss={onDismiss}
      onRenderFooterContent={() => {
        return currentStep !== PanelStep.Selection ? (
          <>
            <Button
              disabled={!validatedID || !defaultValuesSet || !isValid}
              styles={buttonStylesPrimary}
              text={operationCreate ? `Create` : `Save`}
              onClick={onSave}
            />
            <Button text={operationCreate ? 'Back' : 'Close'} onClick={onBackClick} />
          </>
        ) : (
          <></>
        );
      }}
      onRenderNavigation={() => {
        return (
          <>
            <Stack horizontal horizontalAlign="space-between">
              <h2>{panelHeading}</h2>
              <IconButton iconName="ChromeClose" onClick={onDismiss} styles={[buttonStylesIcon]} />
            </Stack>
            <Breadcrumb items={breadcrumbItems} styles={{ item: { paddingInline: 0 } }} />
          </>
        );
      }}
      styles={stylesPanel}
      type={PanelType.custom}
    >
      {currentStep === PanelStep.Selection ? (
        <EngineSelection
          onEngineSelection={onEngineSelection}
          engineType={(engine?.engineType as ValidEngineType) || ''}
        />
      ) : (
        <EngineConfiguration
          operationCreate={operationCreate}
          engine={engine}
          updateEngine={updateEngine}
          validateID={setValidatedID}
        />
      )}
    </Panel>
  );
}
