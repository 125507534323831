import {
  CheckboxVisibility,
  ColumnActionsMode,
  IButtonProps,
  IColumn,
  IDetailsRowProps,
  IStyle,
  Text,
} from '@fluentui/react';
import {
  Checkbox,
  IconName,
  Loader,
  loaderStylesSpinnerDefault,
  loaderStylesSpinnerTag,
  loaderStylesSpinnerXLarge,
} from '@h2oai/ui-kit';
import { useCallback } from 'react';

import { AIEngine, V1Engine, V1EngineState, V1EngineType, getIdFromName, isTransitionalState } from '../../../../aiem';
import { useEngine } from '../../../../aiem/hooks';
import { useUser } from '../../../../utils/hooks';
import EmptyStateMessage from '../../../EmptyStateMessage/EmptyStateMessage';
import { ListItemCheckbox } from '../../../ListItemCheckbox/ListItemCheckbox';
import { ActionCell } from '../../../ListPages/ActionCell';
import { BadgeCell } from '../../../ListPages/BadgeCell';
import { IconCell } from '../../../ListPages/IconCell';
import { MetadataCell, baseMetadataTextStyles, metadataTextStyles } from '../../../ListPages/MetadataCell';
import StyledDetailsList from '../../../ListPages/StyledDetailsList';
import StyledDetailsRow from '../../../ListPages/StyledDetailsRow';
import { TitleCell } from '../../../ListPages/TitleCell';
import { parseSeconds, secondsToFriendlyInterval } from '../../utils';
import { EngineActionButton } from '../EngineActionButton/EngineActionButton';
import { LabelIconTooltip, OutOfVersionSeverity } from '../LabelIconTooltip/LabelIconTooltip';

const iconSize = 64;

const columns: { [key: string]: IColumn } = {
  title: {
    key: 'name-uid',
    name: 'Name and Id',
    fieldName: 'name-id',
    minWidth: 184,
    maxWidth: 320,
  },
  creator: {
    key: 'creator',
    name: 'Creator',
    fieldName: 'creatorDisplayName',
    minWidth: 100,
    maxWidth: 180,
  },
  engine: {
    key: 'type',
    name: 'Type',
    fieldName: 'type',
    minWidth: 120,
    maxWidth: 160,
  },
  details: {
    key: 'details',
    name: 'Details',
    fieldName: 'details',
    minWidth: 200,
    maxWidth: 280,
  },
  status: {
    key: 'status',
    name: 'Status',
    fieldName: 'status',
    minWidth: 80,
    maxWidth: 100,
    columnActionsMode: ColumnActionsMode.disabled,
  },
  idle: {
    key: 'idle',
    name: 'Stop if Idle till',
    fieldName: 'idle',
    minWidth: 177,
    maxWidth: 177,
    columnActionsMode: ColumnActionsMode.disabled,
  },
  actions: {
    key: 'actions',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 280,
    maxWidth: 280,
    columnActionsMode: ColumnActionsMode.disabled,
  },
};

const myEnginesColumns = [
  columns.title,
  columns.engine,
  columns.details,
  columns.status,
  columns.idle,
  columns.actions,
];

const adminEnginesColumns = [
  columns.title,
  columns.creator,
  columns.engine,
  columns.details,
  columns.status,
  columns.idle,
  columns.actions,
];

export type EngineListProps = {
  admin?: boolean;
  deleteEngine: (engine: AIEngine) => void;
  editEngine: (engine: AIEngine) => void;
  engines: V1Engine[] | undefined;
  loadingMsg?: string;
  openLegacyEngineLogs: (engine: AIEngine) => void;
  pauseEngine: (engine: AIEngine) => void;
  readLogs: (engine: AIEngine) => void;
  resumeEngine: (engine: AIEngine) => void;
  selectedEngineIds: string[];
  setSelectedEngineIds: (selectedIds: string[]) => void;
  updateEngine?: (engine: AIEngine) => void;
  upgradeEngine: (engine: AIEngine) => void;
  viewEngine: (engine: AIEngine) => void;
};

export function EngineList({
  admin,
  deleteEngine,
  editEngine,
  engines,
  loadingMsg,
  openLegacyEngineLogs,
  pauseEngine,
  readLogs,
  resumeEngine,
  selectedEngineIds,
  setSelectedEngineIds,
  upgradeEngine,
  viewEngine,
}: EngineListProps) {
  const { id } = useUser(),
    { engineTypeLogo, getEngineStateData, getEngineTypeData } = useEngine(),
    checkboxRender = (engine: V1Engine) => {
      const engineId = engine.uid || '',
        { src, backgroundColor } = engineTypeLogo[engine.type!];
      return (
        <>
          <IconCell size={iconSize} src={src} styles={{ backgroundColor }} />
          <ListItemCheckbox
            key={engineId}
            onChange={(_, checked) => {
              if (checked) {
                setSelectedEngineIds([...selectedEngineIds, engineId]);
              } else {
                setSelectedEngineIds(selectedEngineIds.filter((id) => id !== engineId));
              }
            }}
            checked={selectedEngineIds?.includes(engineId)}
          />
        </>
      );
    },
    checkboxColumns: IColumn = {
      key: 'checkbox',
      name: '',
      className: 'engine-checkbox',
      fieldName: 'checkbox',
      minWidth: iconSize,
      maxWidth: iconSize,
      onRender: checkboxRender,
    },
    onClickSelectAll = (_: any, checked?: boolean) => {
      if (checked) {
        if (engines) {
          setSelectedEngineIds([...engines.map(({ uid }) => uid || '').filter((uid) => !!uid)]);
        }
      } else {
        setSelectedEngineIds([]);
      }
    },
    onRenderDetailsHeader = () => (
      <Checkbox styles={{ root: { margin: '24px 16px 16px 0px' } }} label="Select all" onChange={onClickSelectAll} />
    ),
    onRenderVersion = (version: string, upgradeAvailable: boolean, deprecated: boolean) => {
      const versionText = `Version ${version}`;
      return upgradeAvailable || deprecated ? (
        <LabelIconTooltip
          label={<Text styles={{ root: baseMetadataTextStyles as IStyle }}>{versionText}</Text>}
          iconName={IconName.WarningSolid}
          tooltip={
            deprecated
              ? 'This Version is deprecated. Upgrade to a newer version.'
              : 'A newer version of Driverless AI is available for upgrade.'
          }
          severity={deprecated ? OutOfVersionSeverity.Warning : OutOfVersionSeverity.Info}
        />
      ) : (
        <Text styles={{ root: metadataTextStyles }}>{versionText}</Text>
      );
    },
    onRenderItemColumn = (engine: V1Engine, _index?: number, col?: IColumn) => {
      if (!col) return <span />;
      const {
          name,
          displayName,
          version,
          state,
          upgradeAvailable,
          creator,
          creatorDisplayName,
          deprecatedVersion: deprecated,
          type,
        } = engine,
        driverless = type === V1EngineType.DriverlessAi,
        aiEngine: AIEngine = { ...engine, engineType: type } as AIEngine;
      switch (col.key) {
        case 'name-uid':
          return <TitleCell title={displayName || 'Untitled engine'} subtitle={getIdFromName(name)!} />;
        case 'creator':
          return <MetadataCell title="Creator" metadata={[creatorDisplayName || '']} />;
        case 'type':
          const engineTypeData = getEngineTypeData(type);
          return (
            <MetadataCell
              title="Type"
              metadata={[
                engineTypeData?.title || 'Unspecified',
                onRenderVersion(version || '', !!upgradeAvailable, !!deprecated),
              ]}
            />
          );
        case 'details':
          const createdDate = engine.createTime;
          const resumeDate = engine.resumeTime;
          const detailsMetadata = [];
          if (createdDate) {
            detailsMetadata.push(`Created ${createdDate.toLocaleDateString()} ${createdDate.toLocaleTimeString()}`);
          }
          if (resumeDate) {
            detailsMetadata.push(`Last resumed ${resumeDate.toLocaleDateString()} ${resumeDate.toLocaleTimeString()}`);
          }
          return <MetadataCell title="Details" metadata={detailsMetadata} />;
        case 'status':
          const engineStateData = getEngineStateData(state, type);
          return (
            <BadgeCell
              title="Status"
              badgeLabel={engineStateData?.title || 'Unknown'}
              badgeTextColor={engineStateData?.color}
              badgeBackgroundColor={engineStateData?.backgroundColor}
              badgeLoading={!!(engineStateData?.id && isTransitionalState(engineStateData.id as V1EngineState))}
              badgeLoaderProps={{
                label: engineStateData?.title,
                styles: [loaderStylesSpinnerDefault, loaderStylesSpinnerTag],
              }}
            />
          );
        case 'idle':
          if (state !== V1EngineState.Running || !engine.currentIdleDuration) {
            return null;
          }
          const {
              maxIdleDuration: maxIdleDurationStr,
              currentIdleDuration: currentIdleDurationStr,
              maxRunningDuration: maxRunningDurationStr,
            } = engine,
            maxIdleDuration = parseSeconds(maxIdleDurationStr),
            currentIdleDuration = parseSeconds(currentIdleDurationStr),
            idleTimeRemaining = maxIdleDuration - currentIdleDuration,
            maxRunningDuration = parseSeconds(maxRunningDurationStr);

          const tooltip = `
          This engine will automatically ${
            driverless ? 'pause' : 'shutdown'
          } after being idle for ${secondsToFriendlyInterval(maxIdleDuration)}
          or running for ${secondsToFriendlyInterval(maxRunningDuration)}.
          ${driverless ? '' : 'H2O-3 instances cannot be resumed after shutting down.'}
          `;
          return (
            <MetadataCell
              title={driverless ? 'Time to Auto-Pause' : 'Time to Auto-Shutdown'}
              metadata={[`${secondsToFriendlyInterval(idleTimeRemaining)}`]}
              tooltip={tooltip}
            />
          );
        case 'actions':
          const owner = id === creator?.split('/')[1];
          return (
            <ActionCell
              primaryButton={
                <EngineActionButton
                  engine={aiEngine}
                  viewEngine={() => viewEngine(aiEngine)}
                  editEngine={() => editEngine(aiEngine)}
                  resumeEngine={() => resumeEngine(aiEngine)}
                  pauseEngine={() => pauseEngine(aiEngine)}
                  deleteEngine={() => deleteEngine(aiEngine)}
                  upgradeEngine={() => upgradeEngine(aiEngine)}
                  readLogs={() => readLogs(aiEngine)}
                  openLegacyEngineLogs={() => openLegacyEngineLogs(aiEngine)}
                />
              }
              secondaryButtonProps={
                {
                  disabled: engine.state !== V1EngineState.Running || !aiEngine.loginUrl || !owner,
                  'data-test': `${getIdFromName(name)}--go-to-button`,
                  title: 'Visit',
                  href: aiEngine.loginUrl,
                  target: '_blank',
                  type: 'link',
                  text: 'Visit',
                } as IButtonProps
              }
            />
          );
      }
      return <></>;
    },
    onRenderRow = useCallback((props?: IDetailsRowProps) => {
      return props ? <StyledDetailsRow {...props} /> : null;
    }, []);

  // TODO: Create custom loading/err/empty component wrapper.
  if (loadingMsg) return <Loader styles={loaderStylesSpinnerXLarge} label={loadingMsg} />;
  if (!engines?.length) return <EmptyStateMessage message="No engines found" />;

  return (
    <StyledDetailsList
      dataTest="engines-list"
      items={engines}
      columns={admin ? [checkboxColumns, ...adminEnginesColumns] : [checkboxColumns, ...myEnginesColumns]}
      onRenderItemColumn={onRenderItemColumn}
      onRenderRow={onRenderRow}
      checkboxVisibility={CheckboxVisibility.hidden}
      onShouldVirtualize={process.env.NODE_ENV === 'test' ? () => false : undefined}
      onRenderDetailsHeader={onRenderDetailsHeader}
    />
  );
}
