import { useCallback, useEffect, useRef, useState } from 'react';

import { AIEngine, EngineConstraintSet, V1H2OEngineSize, calculatedEngineSizes } from '../../../../../aiem';
import { useEngine } from '../../../../../aiem/hooks';
import { H2OEngineSizeOptionKeyType, defaultH2ORawEngineSizeRequest } from '../../../constants';
import { bytesToGibibytes, gibibytesToBytes } from '../../../utils';
import {
  MetadataLabelCell,
  MetadataRow,
  MetadataTable,
  MetadataTableBody,
  MetadataValueCell,
} from '../../MetadataTable/MetadataTable';
import SpinnerWithTooltip from '../../SpinnerWithTooltip/SpinnerWithTooltip';
import EngineSizeTableRows from './EngineSizeTableRows';

const fetchEngineSize = async (basePath: string, datasetSize: number) => {
  const method = calculatedEngineSizes[H2OEngineSizeOptionKeyType.raw].calculate;
  const size: V1H2OEngineSize = await method(basePath, { datasetSizeBytes: datasetSize.toString() } as any);
  return size;
};

interface ConfigureCustomEngineSizeProps {
  engine: AIEngine;
  constraintSet?: EngineConstraintSet;
  memoryValueGb: number;
  modifyEngine: any;
}

export default function ConfigureCustomEngineSize({
  engine,
  constraintSet,
  modifyEngine,
  memoryValueGb,
}: ConfigureCustomEngineSizeProps) {
  const { basePath } = useEngine(),
    mostRecentRequestTime = useRef<number>(0),
    [loading, setLoading] = useState(false),
    [datasetSize, setDatasetSize] = useState(bytesToGibibytes(defaultH2ORawEngineSizeRequest.datasetSizeBytes));

  const calculateEngineSize = useCallback(
    async (datasetSize) => {
      setLoading(true);
      const thisRequestTime = Date.now();
      mostRecentRequestTime.current = thisRequestTime;
      const size = await fetchEngineSize(basePath, datasetSize);
      if (size && mostRecentRequestTime.current === thisRequestTime) {
        modifyEngine({
          nodeCount: size.nodeCount,
          memoryBytes: size.memoryBytes,
          cpu: constraintSet?.cpu?._default || 1,
          gpu: constraintSet?.gpu?._default || 1,
        });
      }
      setLoading(false);
    },
    [setLoading, basePath, fetchEngineSize, constraintSet]
  );

  const onChangeDatasetSize = useCallback(
    (_, value: string) => {
      setDatasetSize(value);
      calculateEngineSize(gibibytesToBytes(value));
    },
    [calculateEngineSize, setDatasetSize]
  );

  useEffect(() => {
    /* Do not add datasetSize to this dependency array. This effect is supposed 
       to occur only once after the constraints are loaded. */
    if (constraintSet) {
      calculateEngineSize(gibibytesToBytes(datasetSize));
    }
  }, [constraintSet]);

  return (
    <MetadataTable>
      <MetadataTableBody>
        <MetadataRow>
          <MetadataLabelCell colspan={3}>Dataset Size</MetadataLabelCell>
          <MetadataValueCell loading={!constraintSet}>
            <SpinnerWithTooltip
              onChange={onChangeDatasetSize}
              value={Number(datasetSize) || 0}
              min={1}
              max={100000000}
              tooltip="How large is your dataset?"
              suffix="GiB"
            />
          </MetadataValueCell>
        </MetadataRow>
        <EngineSizeTableRows
          loading={loading || !constraintSet}
          engineType={engine?.engineType}
          cpu={engine.cpu}
          gpu={engine.gpu}
          nodeCount={engine.nodeCount}
          memoryValueGb={memoryValueGb}
        />
      </MetadataTableBody>
    </MetadataTable>
  );
}
