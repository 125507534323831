import { MessageBarType, PanelType } from '@fluentui/react';
import {
  Button,
  IPanelProps,
  Loader,
  Panel,
  buttonStylesGhost,
  buttonStylesPrimary,
  loaderStylesSpinnerXLarge,
  useToast,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useRef, useState } from 'react';

import { App, App_Visibility, Tag, UpdateAppRequest } from '../../ai.h2o.cloud.appstore';
import { AdminAppService, AdminTagService, AppService, TagService } from '../../services/api';
import { handleErrMsg } from '../../utils/utils';
import { AppConfigPanelContents } from './AppConfigPanelContents';

export interface IAppConfigPanelProps extends IPanelProps {
  app?: App;
  isAdmin?: boolean;
  onDismissPanel: () => void;
  onSaved: () => void;
  setEditPendingAppIds: React.Dispatch<React.SetStateAction<string[]>>;
}

interface ConfigValues {
  visibility: App_Visibility;
  selectedCategoryIds: string[];
  selectedBadgeIds: string[];
  selectedTagIds: string[];
}

const getTagIds = (app: App, configValues: ConfigValues) => {
  const { visibility, selectedTagIds, selectedBadgeIds, selectedCategoryIds } = configValues;
  const allAssignedTagIds = [...selectedTagIds, ...selectedCategoryIds, ...selectedBadgeIds];
  const allAssignedTagIdSet = new Set(allAssignedTagIds);
  const unassignedTagIds = app.tags.reduce((ids: string[], tag) => {
    if (!allAssignedTagIdSet.has(tag.id)) {
      ids.push(tag.id);
    }
    return ids;
  }, []);
  // remove tag ids that are already assigned
  const assignedTagIdSet = new Set(app.tags.map((t) => t.id));
  const assignedTagIds = allAssignedTagIds.reduce((ids: string[], id) => {
    if (!assignedTagIdSet.has(id)) {
      ids.push(id);
    }
    return ids;
  }, []);
  return { visibility, assignedTagIds, unassignedTagIds };
};

const isChanged = (app: App, configValues: ConfigValues) => {
  const { visibility, assignedTagIds, unassignedTagIds } = getTagIds(app, configValues);
  return app.visibility !== visibility || assignedTagIds.length > 0 || unassignedTagIds.length > 0;
};

export function AppConfigPanel(props: IAppConfigPanelProps) {
  const { app, isAdmin, onDismissPanel, onSaved, setEditPendingAppIds, ..._hProps } = props;
  const { addToast } = useToast();
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [tags, setTags] = useState<Tag[]>([]);
  const { type = PanelType.custom, customWidth = '450px', headerText = app?.title, isOpen } = _hProps;
  const configValues = useRef<ConfigValues>();
  const tagService = isAdmin ? AdminTagService : TagService;
  const appService = isAdmin ? AdminAppService : AppService;
  const loadTags = useCallback(async () => {
    setLoading(true);
    const { tags = [] } = await tagService.listTags({});
    setTags(tags);
    setLoading(false);
  }, [tagService]);
  const assignTags = useCallback(
    async (appId: string, tagIds: string[]) => {
      const results = await Promise.all(
        tagIds.map((id) => tagService.assignTag({ appId, id })).map((p) => p.catch((e) => e))
      );
      const messages: string[] = [];
      results.forEach((result, i) => {
        if (result instanceof Error) {
          messages.push(tagIds[i]);
        }
      });
      if (messages.length > 0) {
        addToast({
          messageBarType: MessageBarType.error,
          message: `An error occurred while assigning tag ids(${messages.join(', ')}) to app id(${appId})`,
        });
        return false;
      }
      return true;
    },
    [addToast, tagService]
  );
  const unassignTags = useCallback(
    async (appId: string, tagIds: string[]) => {
      const results = await Promise.all(
        tagIds.map((id) => tagService.unassignTag({ appId, id })).map((p) => p.catch((e) => e))
      );
      const messages: string[] = [];
      results.forEach((result, i) => {
        if (result instanceof Error) {
          messages.push(tagIds[i]);
        }
      });
      if (messages.length > 0) {
        addToast({
          messageBarType: MessageBarType.error,
          message: `An error occurred while unassigning tag ids(${messages.join(', ')}) to app id(${appId})`,
        });
        return false;
      }
      return true;
    },
    [addToast, tagService]
  );
  const updateAppVisibility = useCallback(
    async (params: UpdateAppRequest) => {
      if (app?.visibility === params.visibility) return true;
      try {
        await appService.updateApp(params);
        return true;
      } catch (error: unknown) {
        if (error instanceof Error) {
          addToast({
            messageBarType: MessageBarType.error,
            message: `Could not update app instance visibility: ${handleErrMsg(error.message)}`,
          });
        }
      }
      return false;
    },
    [addToast, app, appService]
  );
  const updateVisibilityAndTags = useCallback(async () => {
    onDismissPanel();
    if (configValues.current && app) {
      setButtonLoading(true);
      const { id } = app;
      setEditPendingAppIds((prevAppIds) => [...prevAppIds, id]);
      const { visibility, assignedTagIds, unassignedTagIds } = getTagIds(app, configValues.current);
      try {
        const operationResults = await Promise.all([
          updateAppVisibility({ id, visibility }),
          assignTags(id, assignedTagIds),
          unassignTags(id, unassignedTagIds),
        ]);
        if (operationResults.every(Boolean)) {
          addToast({
            messageBarType: MessageBarType.success,
            message: `Successfully updated app version with id ${id}`,
          });
        }
      } finally {
        setButtonLoading(false);
        configValues.current = undefined;
        setEditPendingAppIds((prevAppIds) => prevAppIds.filter((appId) => appId !== id));
        onSaved();
      }
    } else {
      configValues.current = undefined;
    }
  }, [
    setButtonLoading,
    setEditPendingAppIds,
    updateAppVisibility,
    assignTags,
    unassignTags,
    onSaved,
    app,
    configValues,
    onDismissPanel,
  ]);
  const onChange = (
    visibility: App_Visibility,
    selectedCategoryIds: string[],
    selectedBadgeIds: string[],
    selectedTagIds: string[]
  ) => {
    configValues.current = { visibility, selectedCategoryIds, selectedBadgeIds, selectedTagIds };
    if (app) setChanged(isChanged(app, configValues.current));
  };
  const onRenderFooterContent = useCallback(
    () => (
      <>
        <div style={{ marginRight: 8, display: 'inline-block' }}>
          <Button
            styles={buttonStylesGhost}
            text="Cancel"
            onClick={() => {
              configValues.current = undefined;
              onDismissPanel();
            }}
          />
        </div>
        <div style={{ display: 'inline-block' }}>
          <Button
            styles={buttonStylesPrimary}
            loading={buttonLoading}
            disabled={!changed}
            text="Save Changes"
            onClick={updateVisibilityAndTags}
          />
        </div>
      </>
    ),
    [onDismissPanel, updateVisibilityAndTags, buttonLoading, changed]
  );
  useEffect(() => {
    if (isOpen) {
      loadTags();
    } else {
      setLoading(true);
      setChanged(false);
      configValues.current = undefined;
    }
  }, [loadTags, isOpen]);
  return (
    <Panel
      {..._hProps}
      isOpen={isOpen}
      headerText={headerText}
      type={type}
      customWidth={customWidth}
      closeButtonAriaLabel="Close"
      isFooterAtBottom={true}
      onDismiss={onDismissPanel}
      onRenderFooterContent={onRenderFooterContent}
    >
      {loading || !app ? (
        <Loader styles={loaderStylesSpinnerXLarge} label="loading" />
      ) : (
        <AppConfigPanelContents app={app} tags={tags} onChange={onChange} />
      )}
    </Panel>
  );
}
