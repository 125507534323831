import { IStyle } from '@fluentui/react';
import { Sizes } from '@h2oai/ui-kit';

export interface INotificationBarStyles {
  root?: IStyle;
  messageBar?: IStyle;
}

export const notificationBarStylesDefault: Partial<INotificationBarStyles> = {
  root: {
    padding: '0 30px',
    marginBottom: `-${Sizes.contentPaddingTop - 16}px`,
    zIndex: 1,
  },
  messageBar: {
    paddingTop: 8,
  },
};
