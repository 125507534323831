/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/h2o_version_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1H2OVersion,
    V1H2OVersionFromJSON,
    V1H2OVersionFromJSONTyped,
    V1H2OVersionToJSON,
} from './V1H2OVersion';

/**
 * Response message for H2OVersionService.ListH2OVersions.
 * @export
 * @interface V1ListH2OVersionsResponse
 */
export interface V1ListH2OVersionsResponse {
    /**
     * Collection of H2OVersions.
     * @type {Array<V1H2OVersion>}
     * @memberof V1ListH2OVersionsResponse
     */
    h2oVersions?: Array<V1H2OVersion>;
    /**
     * Used to retrieve the next page of results.
     * When unset, no further items are available (this response was the last page).
     * @type {string}
     * @memberof V1ListH2OVersionsResponse
     */
    nextPageToken?: string;
    /**
     * Total number of H2OVersions matched by the List request.
     * @type {number}
     * @memberof V1ListH2OVersionsResponse
     */
    totalSize?: number;
}

export function V1ListH2OVersionsResponseFromJSON(json: any): V1ListH2OVersionsResponse {
    return V1ListH2OVersionsResponseFromJSONTyped(json, false);
}

export function V1ListH2OVersionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ListH2OVersionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'h2oVersions': !exists(json, 'h2oVersions') ? undefined : ((json['h2oVersions'] as Array<any>).map(V1H2OVersionFromJSON)),
        'nextPageToken': !exists(json, 'nextPageToken') ? undefined : json['nextPageToken'],
        'totalSize': !exists(json, 'totalSize') ? undefined : json['totalSize'],
    };
}

export function V1ListH2OVersionsResponseToJSON(value?: V1ListH2OVersionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'h2oVersions': value.h2oVersions === undefined ? undefined : ((value.h2oVersions as Array<any>).map(V1H2OVersionToJSON)),
        'nextPageToken': value.nextPageToken,
        'totalSize': value.totalSize,
    };
}

