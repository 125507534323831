import { IStyle } from '@fluentui/react';

export interface ITimeSeriesStyles {
  root: IStyle;
}

export interface ITimeSeriesClassNames {
  root: string;
}

export const timeSeriesStylesDefault: Partial<ITimeSeriesStyles> = {
  root: {
    position: 'relative',
    height: '100%',
  },
};
