import { BaseButton, IGroup, MessageBarType } from '@fluentui/react';
import { ReactNode } from 'react';

import { App } from '../ai.h2o.cloud.appstore';

export const ALL_CATEGORY = 'ALL',
  OTHER_CATEGORY = 'OTHER',
  MOST_POPULAR = 'Most Popular',
  PINNED_APPS = 'Pinned Apps',
  ALL_APPS = 'All Apps';

export const minute = 60000,
  hour = minute * 60,
  day = hour * 24,
  week = day * 7,
  month = day * 30,
  year = day * 365;

export const IN_A_FEW_MOMENTS = 'in a few moments';

export interface INotification {
  id?: string;
  groupId?: string;
  messageBarType?: MessageBarType;
  message: ReactNode;
  actions?: JSX.Element;
  title?: string;
  subTitle?: string;
  onDismiss?: (ev?: React.MouseEvent<HTMLElement | BaseButton>) => any;
}

export interface INotificationAction {
  type: NotificationActionType;
  message: INotification;
}

export enum NotificationActionType {
  Add = 'Add',
  Remove = 'Remove',
}

export enum InstanceListType {
  admin = 'admin',
  my = 'my',
  app = 'app',
}

export type ClassNamesFromIStyles<IStyles extends object> = {
  [P in keyof IStyles]: string;
};

interface AppGroupData {
  latestApp: App;
  apps: App[];
  tags: any[];
}

export interface AppGroup extends IGroup {
  data: AppGroupData;
}

export type AnyFunction = (...args: any) => any;
