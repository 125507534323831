/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/adjusted_dai_profile_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Workspace-scoped DAIProfile referring to a top-level DAIProfile with potentially adjusted values
 * to fit the needs of workspace.
 * @export
 * @interface V1AdjustedDAIProfile
 */
export interface V1AdjustedDAIProfile {
    /**
     * Resource name.
     * @type {string}
     * @memberof V1AdjustedDAIProfile
     */
    readonly name?: string;
    /**
     * Human-readable name of AdjustedDAIProfile.
     * @type {string}
     * @memberof V1AdjustedDAIProfile
     */
    readonly displayName?: string;
    /**
     * The amount of CPU units.
     * @type {number}
     * @memberof V1AdjustedDAIProfile
     */
    readonly cpu?: number;
    /**
     * Message explaining why the value was adjusted. Empty when unchanged.
     * @type {string}
     * @memberof V1AdjustedDAIProfile
     */
    readonly adjustedCpuReason?: string;
    /**
     * The amount of GPU units.
     * @type {number}
     * @memberof V1AdjustedDAIProfile
     */
    readonly gpu?: number;
    /**
     * Message explaining why the value was adjusted. Empty when unchanged.
     * @type {string}
     * @memberof V1AdjustedDAIProfile
     */
    readonly adjustedGpuReason?: string;
    /**
     * The amount of memory in bytes.
     * @type {string}
     * @memberof V1AdjustedDAIProfile
     */
    readonly memoryBytes?: string;
    /**
     * Message explaining why the value was adjusted. Empty when unchanged.
     * @type {string}
     * @memberof V1AdjustedDAIProfile
     */
    readonly adjustedMemoryBytesReason?: string;
    /**
     * The amount of storage in bytes.
     * @type {string}
     * @memberof V1AdjustedDAIProfile
     */
    readonly storageBytes?: string;
    /**
     * Message explaining why the value was adjusted. Empty when unchanged.
     * @type {string}
     * @memberof V1AdjustedDAIProfile
     */
    readonly adjustedStorageBytesReason?: string;
}

export function V1AdjustedDAIProfileFromJSON(json: any): V1AdjustedDAIProfile {
    return V1AdjustedDAIProfileFromJSONTyped(json, false);
}

export function V1AdjustedDAIProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1AdjustedDAIProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'cpu': !exists(json, 'cpu') ? undefined : json['cpu'],
        'adjustedCpuReason': !exists(json, 'adjustedCpuReason') ? undefined : json['adjustedCpuReason'],
        'gpu': !exists(json, 'gpu') ? undefined : json['gpu'],
        'adjustedGpuReason': !exists(json, 'adjustedGpuReason') ? undefined : json['adjustedGpuReason'],
        'memoryBytes': !exists(json, 'memoryBytes') ? undefined : json['memoryBytes'],
        'adjustedMemoryBytesReason': !exists(json, 'adjustedMemoryBytesReason') ? undefined : json['adjustedMemoryBytesReason'],
        'storageBytes': !exists(json, 'storageBytes') ? undefined : json['storageBytes'],
        'adjustedStorageBytesReason': !exists(json, 'adjustedStorageBytesReason') ? undefined : json['adjustedStorageBytesReason'],
    };
}

export function V1AdjustedDAIProfileToJSON(value?: V1AdjustedDAIProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

