import { IRawStyle, Stack } from '@fluentui/react';
import { FontSizes, FontWeights } from '@h2oai/ui-kit';

import { App } from '../../ai.h2o.cloud.appstore';
import Cell from '../../components/ListPages/Cell';
import { AppGroup } from '../../utils/models';

interface CellRendererProps {
  group: AppGroup;
  GroupCell: React.ReactNode;
  onRenderAppCell: (a: App) => React.ReactNode;
  alignRight?: boolean;
  label?: string;
  expanded: boolean;
  rootStyles?: IRawStyle;
}

const ROW_HEIGHT = 40;
export default function CellWrapper(props: CellRendererProps) {
  const { GroupCell, onRenderAppCell, group, alignRight, label, expanded, rootStyles = {} } = props,
    cellStyles = {
      minHeight: ROW_HEIGHT,
      maxHeight: ROW_HEIGHT,
      display: 'flex',
      alignItems: 'center',
      justifyContent: alignRight ? 'flex-end' : 'flex-start',
      fontSize: FontSizes.textPrimary,
    };

  return (
    <Stack tokens={{ childrenGap: 8 }} styles={{ root: rootStyles }}>
      <Cell alignRight={alignRight} style={cellStyles}>
        {GroupCell}
      </Cell>
      {expanded && (
        <>
          <div
            style={{
              marginTop: 24,
              marginBottom: 8,
              fontWeight: FontWeights.bold,
              fontSize: FontSizes.textPrimary,
              lineHeight: 1,
              minHeight: FontSizes.textPrimary,
            }}
          >
            {label}
          </div>
          {group.data.apps.map((app) => (
            <div key={app.id} style={cellStyles}>
              {onRenderAppCell(app)}
            </div>
          ))}
        </>
      )}
    </Stack>
  );
}
