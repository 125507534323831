import { Stack, Text, Toggle } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  Checkbox,
  DetailsList,
  IPanelProps,
  Link,
  MessageBar,
  TextField,
  checkboxStylesCircle,
  debounce,
  getDate,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Alias, AppInstance } from '../../ai.h2o.cloud.appstore';
import { ManagedListSeparator, ManagedListWrapper } from '../ManagedList/ManagedList';
import { ManagedListConfigPanel } from '../ManagedListConfigPanel/ManagedListConfigPanel';

export type IAliasConfigPanelChangeEvent = (
  selectedItem?: AppInstance,
  isPrimary?: boolean,
  newAliasName?: string
) => void;

const addAppName = (items: AppInstance[]) =>
  items.map((instance) => {
    instance['appName'] = instance.appDetails.title;
    return instance;
  }) as AppInstance[];

const compare = (title: string, text: string) => title.toLocaleLowerCase().includes(text.toLocaleLowerCase());

const getAliasUrl = (instance: AppInstance, alias?: Alias, newAliasName?: string) => {
  return `${instance.location.replace(instance.id, alias?.alias || newAliasName || instance.id)}`;
};

export interface IAliasConfigPanelProps extends IPanelProps {
  alias?: Alias;
  instances: AppInstance[];
  onClose?: () => void;
  onAssign: IAliasConfigPanelChangeEvent;
}

export function AliasConfigPanel(props: IAliasConfigPanelProps) {
  const { alias, instances, onClose, onAssign, ..._hProps } = props,
    refSelectedInstance = useRef<AppInstance>(),
    [isPrimary, { toggle: togglePrimary }] = useBoolean(false),
    [selectedItem, setSelectedItem] = useState<AppInstance>(),
    [newAliasName, setNewAliasName] = useState<string>(),
    [instanceItems, setInstanceItems] = useState<AppInstance[]>(instances),
    filterInstance = useCallback(
      (_e, text: string) =>
        setInstanceItems(
          addAppName(
            instances.filter((instance) => compare(instance.id, text) || compare(instance.appDetails.title, text))
          )
        ),
      [instances]
    ),
    onSelectItem = useCallback((instance: AppInstance) => {
      refSelectedInstance.current = instance;
      setSelectedItem(instance);
    }, []),
    onSearchInstanceTextChange = useMemo(() => debounce(300, filterInstance), [filterInstance]);

  useEffect(() => {
    setInstanceItems(addAppName(instanceItems));
  }, []);

  return (
    <ManagedListConfigPanel
      buttonTitleEdit="Assign"
      titleCreate="Create new alias"
      titleEdit="Assign alias"
      isDisabled={alias ? !selectedItem : !newAliasName}
      onDone={() => {
        onAssign(selectedItem, isPrimary, newAliasName);
      }}
      onDismiss={onClose}
      {..._hProps}
    >
      <Stack>
        <Stack>
          {!alias ? (
            <Stack>
              <ManagedListWrapper>
                <MessageBar messageBarType={0}>Save this alias or optionally assign it to an app instance</MessageBar>
              </ManagedListWrapper>
              <TextField
                placeholder="New alias name"
                onChange={(_e, newValue) => {
                  setNewAliasName(newValue);
                }}
              />
            </Stack>
          ) : (
            <Text>Alias: {alias.alias}</Text>
          )}
          <ManagedListSeparator text="Choose by Instance ID or App name" />
          <TextField placeholder="Filter by Instance ID or App name" onChange={onSearchInstanceTextChange} />
          <Stack>
            <div style={{ height: 310, overflow: 'auto' }} data-is-scrollable="true">
              <DetailsList
                isHeaderVisible={false}
                columns={[
                  {
                    key: 'select',
                    name: 'Select',
                    minWidth: 10,
                    maxWidth: 15,
                    onRender: (d: AppInstance) => (
                      <div style={{ textAlign: 'center' }}>
                        <Checkbox
                          name="id"
                          checked={d.id === refSelectedInstance.current?.id}
                          styles={checkboxStylesCircle}
                          onChange={() => onSelectItem(d)}
                        />
                      </div>
                    ),
                  },
                  {
                    fieldName: 'id',
                    key: 'title',
                    minWidth: 50,
                    name: '',
                    onRender: (d: AppInstance) => (
                      <>
                        {(d as any).appName} - {d.id}- {d.owner} - {getDate(d.createTime)?.toLocaleDateString() || ''}{' '}
                        {getDate(d.createTime)?.toLocaleTimeString() || ''}
                      </>
                    ),
                  },
                ]}
                items={instanceItems}
              />
            </div>
            {refSelectedInstance.current && (
              <Stack>
                <ManagedListSeparator text="Additional configuration" />
                <Toggle
                  inlineLabel
                  label="Primary"
                  styles={{
                    label: {
                      padding: 0,
                    },
                  }}
                  defaultChecked={isPrimary}
                  onChange={togglePrimary}
                />
              </Stack>
            )}

            {selectedItem && (alias?.alias || newAliasName) && (
              <ManagedListWrapper>
                {isPrimary ? (
                  <MessageBar messageBarType={0}>
                    This app instance will have an alias at{' '}
                    <Link href={getAliasUrl(selectedItem, alias, newAliasName)}>
                      {getAliasUrl(selectedItem, alias, newAliasName)}
                    </Link>{' '}
                    which redirects to <Link href={selectedItem.location}>{selectedItem.location}</Link>
                  </MessageBar>
                ) : (
                  <MessageBar messageBarType={0}>
                    The URL of this app instance will be{' '}
                    <Link href={getAliasUrl(selectedItem, alias, newAliasName)}>
                      {getAliasUrl(selectedItem, alias, newAliasName)}
                    </Link>
                  </MessageBar>
                )}
              </ManagedListWrapper>
            )}
          </Stack>
        </Stack>
      </Stack>
    </ManagedListConfigPanel>
  );
}
