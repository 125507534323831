import { V1AdjustedDAIProfile, V1EngineType } from '../../../../../aiem';
import { LabelIconTooltip, OutOfVersionSeverity } from '../../LabelIconTooltip/LabelIconTooltip';
import { MetadataLabelCell, MetadataRow, MetadataValueCell } from '../../MetadataTable/MetadataTable';

interface DisplayPresetEngineSizeProps {
  loading?: boolean;
  engineType?: V1EngineType;
  profile?: V1AdjustedDAIProfile;
  cpu?: number;
  gpu?: number;
  nodeCount?: number | null;
  memoryValueGb?: string | number;
  storageValueGb?: string | number;
}

export default function EngineSizeTableRows({
  loading,
  engineType,
  profile,
  cpu,
  gpu,
  nodeCount,
  memoryValueGb,
  storageValueGb,
}: DisplayPresetEngineSizeProps) {
  return (
    <>
      <MetadataRow>
        <MetadataLabelCell colspan={3}>
          {engineType === V1EngineType.H2O ? 'CPUs per Node' : 'CPU Units'}
        </MetadataLabelCell>
        <MetadataValueCell loading={loading}>
          <LabelIconTooltip
            id={'cpu-info'}
            data-test="cpu-info"
            label={cpu}
            severity={OutOfVersionSeverity.Warning}
            tooltip={profile?.adjustedCpuReason || ''}
          />
        </MetadataValueCell>
      </MetadataRow>
      <MetadataRow>
        <MetadataLabelCell colspan={3}>
          {engineType === V1EngineType.H2O ? 'GPUs per Node' : 'GPU Units'}
        </MetadataLabelCell>
        <MetadataValueCell loading={loading}>
          <LabelIconTooltip
            id={'gpu-info'}
            data-test="gpu-info"
            label={gpu}
            severity={OutOfVersionSeverity.Warning}
            tooltip={profile?.adjustedGpuReason}
          />
        </MetadataValueCell>
      </MetadataRow>
      {engineType === V1EngineType.H2O && (
        <MetadataRow>
          <MetadataLabelCell colspan={3}>Number of Nodes</MetadataLabelCell>
          <MetadataValueCell loading={loading}>{nodeCount}</MetadataValueCell>
        </MetadataRow>
      )}
      <MetadataRow>
        <MetadataLabelCell colspan={3}>
          {engineType === V1EngineType.H2O ? 'Memory per Node' : 'Memory'}
        </MetadataLabelCell>
        <MetadataValueCell loading={loading}>
          <LabelIconTooltip
            id={'memory-info'}
            data-test="memory-info"
            label={`${memoryValueGb} GiB`}
            severity={OutOfVersionSeverity.Warning}
            tooltip={profile?.adjustedMemoryBytesReason || undefined}
          />
        </MetadataValueCell>
      </MetadataRow>
      {engineType === V1EngineType.DriverlessAi && (
        <MetadataRow>
          <MetadataLabelCell colspan={3}>Storage</MetadataLabelCell>
          <MetadataValueCell loading={loading}>
            <LabelIconTooltip
              id={'storage-info'}
              data-test="storage-info"
              label={`${storageValueGb} GiB`}
              severity={OutOfVersionSeverity.Warning}
              tooltip={profile?.adjustedStorageBytesReason || undefined}
            />
          </MetadataValueCell>
        </MetadataRow>
      )}
    </>
  );
}
