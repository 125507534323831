import { IComponent, useClassNames } from '@h2oai/ui-kit';
import { ReactNode } from 'react';

import { ILeftPanelStyles, leftPanelStylesDefault } from './LeftPanel.styles';

interface ILeftPanelClassNames {
  root: string;
}
export interface ILeftPanelProps extends IComponent<ILeftPanelStyles> {
  content?: ReactNode;
}

export function LeftPanel({ styles, content }: ILeftPanelProps) {
  const classNames = useClassNames<ILeftPanelStyles, ILeftPanelClassNames>('LeftPanel', leftPanelStylesDefault, styles);
  if (content) {
    return <div className={classNames.root}>{content}</div>;
  }
  return null;
}
