/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/adjusted_dai_profile_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RpcStatus,
    RpcStatusFromJSON,
    RpcStatusToJSON,
    V1GetAdjustedDAIProfileResponse,
    V1GetAdjustedDAIProfileResponseFromJSON,
    V1GetAdjustedDAIProfileResponseToJSON,
    V1ListAdjustedDAIProfilesResponse,
    V1ListAdjustedDAIProfilesResponseFromJSON,
    V1ListAdjustedDAIProfilesResponseToJSON,
} from '../models';

export interface AdjustedDAIProfileServiceGetAdjustedDAIProfileRequest {
    name: string;
}

export interface AdjustedDAIProfileServiceListAdjustedDAIProfilesRequest {
    parent: string;
    pageSize?: number;
    pageToken?: string;
}

/**
 * 
 */
export class AdjustedDAIProfileServiceApi extends runtime.BaseAPI {

    /**
     * Returns a specific DAIEngine.
     */
    async adjustedDAIProfileServiceGetAdjustedDAIProfileRaw(requestParameters: AdjustedDAIProfileServiceGetAdjustedDAIProfileRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1GetAdjustedDAIProfileResponse>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling adjustedDAIProfileServiceGetAdjustedDAIProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1GetAdjustedDAIProfileResponseFromJSON(jsonValue));
    }

    /**
     * Returns a specific DAIEngine.
     */
    async adjustedDAIProfileServiceGetAdjustedDAIProfile(requestParameters: AdjustedDAIProfileServiceGetAdjustedDAIProfileRequest, initOverrides?: RequestInit): Promise<V1GetAdjustedDAIProfileResponse> {
        const response = await this.adjustedDAIProfileServiceGetAdjustedDAIProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a collection of adjustedDAIProfiles within a workspace.
     */
    async adjustedDAIProfileServiceListAdjustedDAIProfilesRaw(requestParameters: AdjustedDAIProfileServiceListAdjustedDAIProfilesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1ListAdjustedDAIProfilesResponse>> {
        if (requestParameters.parent === null || requestParameters.parent === undefined) {
            throw new runtime.RequiredError('parent','Required parameter requestParameters.parent was null or undefined when calling adjustedDAIProfileServiceListAdjustedDAIProfiles.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['pageToken'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/{parent}/adjustedDAIProfiles`.replace(`{${"parent"}}`, encodeURIComponent(String(requestParameters.parent))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1ListAdjustedDAIProfilesResponseFromJSON(jsonValue));
    }

    /**
     * Returns a collection of adjustedDAIProfiles within a workspace.
     */
    async adjustedDAIProfileServiceListAdjustedDAIProfiles(requestParameters: AdjustedDAIProfileServiceListAdjustedDAIProfilesRequest, initOverrides?: RequestInit): Promise<V1ListAdjustedDAIProfilesResponse> {
        const response = await this.adjustedDAIProfileServiceListAdjustedDAIProfilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
