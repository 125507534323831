import { BaseButton, FontSizes, FontWeights, ITextFieldProps, Stack, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Image, Loader, LoaderType, TextField, IconButton as UIKitIconButton } from '@h2oai/ui-kit';
import { MouseEventHandler, useCallback, useRef, useState } from 'react';

import { AIEngine, V1EngineType, concatId, getIdFromName, getVariant, tryName } from '../../../../aiem';
import { defaultEngineType } from '../../../../aiem/defaults';
import { useEngine } from '../../../../aiem/hooks';
import { useDebouncedCallback, usePromiseCallback } from '../../../../utils/hooks';
import { getRandomEngineName, validateId } from '../AIEMPanelEdit/AIEMPanelEdit';
import { DisplayAndIdRuleField } from '../DisplayAndIdRuleField/DisplayAndIdRuleField';
import { useAIEMFormAttributes } from '../EngineConfiguration/EngineConfiguration';
import { LabelIconTooltip } from '../LabelIconTooltip/LabelIconTooltip';

const validateFirstLetterId = (id: string | undefined | null): boolean => {
  const item = id?.charAt(0);
  return Boolean(item) && /^[a-z]?$/.test(item!) && item === item?.toLowerCase();
};

const validateLastLetterId = (id: string | undefined | null): boolean => {
  const item = id?.slice(-1);
  return Boolean(item) && /^[a-z0-9]?$/.test(item!) && item === item?.toLowerCase();
};

const validateContainLetterId = (id: string | undefined | null): boolean => Boolean(id) && /^[a-z0-9-]+$/.test(id!);

const validateLengthId = (id: string | undefined | null): boolean =>
  Boolean(id) && Boolean(id && id?.length > 0) && Boolean(id && id?.length < 61);

enum IdModeType {
  editableView = 'editableView',
  edit = 'edit',
  view = 'view',
}

type IconButtonProps = {
  iconName: string;
  onClick:
    | MouseEventHandler<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | HTMLSpanElement>
    | undefined;
  title?: string;
};

function IconButton(props: IconButtonProps) {
  return (
    <Stack styles={{ root: { marginTop: 8 } }}>
      <UIKitIconButton {...props} />
    </Stack>
  );
}

export type DisplayAndIdProps = {
  editableId?: boolean;
  engine?: AIEngine;
  onDisplayNameChange: (value: string) => void;
  onIdChange: (value: string) => void;
  validateID: (validate: boolean) => void;
};

export function DisplayAndId({ editableId, engine, onDisplayNameChange, onIdChange, validateID }: DisplayAndIdProps) {
  const { formRowHeight, inputContainerProps, inputRowProps } = useAIEMFormAttributes(),
    idTextStyles = { root: { fontSize: FontSizes.small, paddingTop: 10 } },
    { checkEngineId, engineTypeLogo } = useEngine(),
    [idMode, setIdMode] = useState<IdModeType>(editableId ? IdModeType.editableView : IdModeType.view),
    [currentDisplayName, setCurrentDisplayName] = useState<string | undefined>(engine?.displayName),
    [currentId, setCurrentId] = useState<string | undefined>(getIdFromName(engine?.name) || engine?.id),
    [idAvailable, setIdAvailable] = useState<boolean | null | undefined>(true),
    [hasIdBeenEdited, setHasIdBeenEdited] = useState<boolean>(false),
    [editingId, setEditingId] = useState<string | undefined>(``),
    [firstDisplayFocus, { setFalse: onDisplayBlur }] = useBoolean(true),
    [checkingId, { setTrue: showChecking, setFalse: hideChecking }] = useBoolean(false),
    [validId, setValidId] = useState<boolean>(true),
    [validFirstLetterId, setFirstLetterId] = useState<boolean>(true),
    [validLastLetterId, setValidLastLetterId] = useState<boolean>(true),
    [validContainLetterId, setValidContainLetterId] = useState<boolean>(true),
    [validLengthId, setValidLengthId] = useState<boolean>(true),
    successMessage = { successMessage: `Engine ID available to use` },
    unavailableErrorMessage = { errorMessage: `Engine ID already in use` },
    invalidIdErrorMessage = {
      errorMessage: `Please ensure the following rules are followed`,
    },
    validateAllRules = (value: string | undefined) => {
      setFirstLetterId(validateFirstLetterId(value));
      setValidLastLetterId(validateLastLetterId(value));
      setValidContainLetterId(validateContainLetterId(value));
      setValidLengthId(validateLengthId(value));
    },
    editId = () => {
      setIdMode(IdModeType.edit);
      setEditingId(currentId);
      setValidId(validateId(currentId));
      validateAllRules(currentId);
    },
    testDisplay = useCallback(
      async (displayName: string) => {
        const idPair = tryName(displayName as string),
          { id } = idPair;
        let { variant } = idPair,
          candidateId = id || tryName(getRandomEngineName(engine?.engineType || defaultEngineType)).id;
        while (true) {
          const available = await checkEngineId(engine?.engineType, candidateId);
          if (available) {
            setCurrentId(candidateId);
            onIdChange(candidateId);
            setIdAvailable(true);
            setValidId(validateId(candidateId));
            validateAllRules(candidateId);
            break;
          } else {
            variant = getVariant(variant);
          }
          candidateId = concatId(id, variant);
        }
      },
      [checkEngineId, engine?.engineType, onIdChange]
    ),
    testId = useCallback(
      async (value: string) => {
        showChecking();
        const available = await checkEngineId(engine?.engineType, value);
        setIdAvailable(available);
        validateID(Boolean(available));
      },
      [checkEngineId, engine?.engineType, setIdAvailable, showChecking]
    );

  const [checkIdErrorMessage, setCheckIdErrorMessage] = useState(``);
  const [useTest] = usePromiseCallback(
    (value: string, idCheck = false) => (idCheck ? testId(value) : testDisplay(value)),
    [testDisplay, testId],
    {
      onError: (workerError) => {
        setCheckIdErrorMessage(workerError.message.message);
      },
      onSuccess: () => {
        setCheckIdErrorMessage(``);
      },
      onSettled: hideChecking,
    }
  );
  const debounceDisplayCallback = useDebouncedCallback(useTest, 500),
    debounceDisplayCallbackRef = useRef(debounceDisplayCallback);
  debounceDisplayCallbackRef.current = debounceDisplayCallback;

  const debounceIdCallback = useDebouncedCallback(useTest, 600),
    debounceIdCallbackRef = useRef(debounceIdCallback);
  debounceIdCallbackRef.current = debounceIdCallback;

  const handleDisplayChange = (event: any) => {
      const displayName = event?.target?.value;
      setCurrentDisplayName(displayName);
      onDisplayNameChange(displayName);
      if (!hasIdBeenEdited && idMode === IdModeType.editableView) {
        debounceDisplayCallback(displayName);
      }
    },
    handleIdChange = (event: any) => {
      const value = event?.target?.value;
      setEditingId(value);
      setHasIdBeenEdited(value !== currentId);
      const valid = validateId(value);
      setValidId(valid);
      validateAllRules(value);
      if (valid) {
        debounceIdCallback(value, true);
      }
      onIdChange(value);
    };

  const onRenderDescription = () => {
    return (
      <Stack styles={{ root: { height: 10, width: 20, paddingTop: 4 } }}>
        <Loader type={LoaderType.progressIndicator} />
      </Stack>
    );
  };

  return (
    <Stack horizontal>
      <div style={{ margin: '8px 8px 0 0' }}>
        <Image
          src={engineTypeLogo[engine?.engineType || V1EngineType.H2O].src}
          alt="Engine logo"
          width={60}
          height={60}
          styles={{
            root: {
              backgroundColor: engineTypeLogo[V1EngineType.H2O].backgroundColor,
            },
          }}
        />
      </div>
      <Stack style={{ marginBottom: idMode === IdModeType.edit ? 10 : 0 }}>
        <Stack {...inputRowProps}>
          <Stack {...inputContainerProps}>
            <TextField
              required
              autoFocus
              styles={{ root: { width: 384 } }}
              label="Display Name"
              data-test="display-name-input"
              value={currentDisplayName}
              onChange={handleDisplayChange}
              onFocus={(event) => {
                if (firstDisplayFocus) event.target.select();
              }}
              onBlur={onDisplayBlur}
              onRenderLabel={(
                labelProps: ITextFieldProps | undefined,
                defaultRender: ((props?: ITextFieldProps | undefined) => JSX.Element | null) | undefined
              ) => (
                <LabelIconTooltip
                  id={labelProps?.id}
                  data-test="display-name-info"
                  label={defaultRender!(labelProps) as any}
                  required
                  tooltip="The display name is free to have all kinds
              of special and alphanumeric characters
              and has no characters limit,
              although simplicity will work in your favor"
                />
              )}
            />
          </Stack>
        </Stack>
        <Stack
          {...inputRowProps}
          styles={{
            root: {
              ...inputRowProps.styles.root,
              height: idMode === IdModeType.edit ? formRowHeight : 20,
            },
          }}
        >
          {idMode !== IdModeType.edit && (
            <>
              <Stack
                styles={{
                  root: { height: 20, width: inputContainerProps.styles.root.width, marginTop: '-24px', marginLeft: 2 },
                }}
              >
                <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { fontSize: '10px' } }}>
                  {currentId && (
                    <Text styles={{ root: { ...idTextStyles.root, fontWeight: FontWeights.semibold } }}>
                      {`ID: ${currentId}`}
                    </Text>
                  )}
                  {idMode === IdModeType.editableView && (
                    <IconButton
                      title={'Edit'}
                      key="editButton"
                      data-test="edit-button"
                      onClick={editId}
                      iconName="Edit"
                    />
                  )}
                </Stack>
              </Stack>
            </>
          )}
          {idMode === IdModeType.edit && (
            <>
              <Stack {...inputContainerProps}>
                <Stack {...inputRowProps}>
                  <Stack styles={{ root: { minWidth: 384, height: 80 } }}>
                    <TextField
                      required
                      data-test="id-input"
                      id="idTextField"
                      value={editingId}
                      onChange={handleIdChange}
                      label="Engine ID"
                      onFocus={(event) => event.target.select()}
                      {...(checkingId
                        ? { onRenderDescription }
                        : validId
                        ? idAvailable
                          ? successMessage
                          : unavailableErrorMessage
                        : invalidIdErrorMessage)}
                      styles={{ root: { minWidth: 230 } }}
                      onRenderLabel={(labelProps, defaultRender) => (
                        <LabelIconTooltip
                          id={labelProps?.id}
                          key="idInfo"
                          data-test="id-info"
                          label={defaultRender!(labelProps) as any}
                          required
                          tooltip="Starts with a letter.
                        Allowed characters: a-z, 0-9 and dashes cannot be used as last characters.
                        Uppercases will be converted to lowercases.
                        It cannot be changed after the Engine Creation."
                        />
                      )}
                    />
                  </Stack>
                </Stack>
                <Stack styles={{ root: { minWidth: 384, height: 80, paddingTop: 5 } }}>
                  <DisplayAndIdRuleField isValid={validFirstLetterId} text={'Must start with lower case letter'} />
                  <DisplayAndIdRuleField
                    isValid={validLastLetterId}
                    text={'Must end with lower case letter or digit'}
                  />
                  <DisplayAndIdRuleField
                    isValid={validContainLetterId}
                    text={'Can contain only lowercase letters, digits or dashes (-)'}
                  />
                  <DisplayAndIdRuleField isValid={validLengthId} text={'Min length 1 char Max length 63 chars'} />
                </Stack>
              </Stack>
              {checkIdErrorMessage && <>{checkIdErrorMessage}</>}
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
