import { IDropdownOption, Stack } from '@fluentui/react';
import { Button, FontWeights, Item, buttonStylesDanger, buttonStylesDefault, itemStylesCategory } from '@h2oai/ui-kit';
import { useEffect, useState } from 'react';

import { Secret, Secret_Visibility } from '../../ai.h2o.cloud.appstore';
import { BadgeCell } from '../ListPages/BadgeCell';
import Cell from '../ListPages/Cell';
import { ManagedList } from '../ManagedList/ManagedList';
import { visibilityItem, visibilityList } from '../SecretConfigPanel/SecretConfigPanel';

export const visibilityOptions: IDropdownOption<visibilityItem>[] = [
  { key: Secret_Visibility.VISIBILITY_UNSPECIFIED, text: 'Unspecified' },
  ...visibilityList,
  { key: Secret_Visibility.PRIVATE, text: 'Private' },
];

export interface IManagedSecretsListProps {
  secrets: Secret[];
  loading?: [string, boolean];
  editSecret: (alias: Secret) => void;
  deleteSecret: (alias: Secret) => void;
}

export const getSecretUniqueId = (item: Secret) => item.name + item.visibility;

export function ManagedSecretsList(props: IManagedSecretsListProps) {
  const { secrets, loading, editSecret, deleteSecret } = props,
    [loadingMap, setLoadingMap] = useState<{ [key: string]: boolean }>({}),
    actionColumnsWidth = 150,
    listData = secrets.sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    if (loading) {
      const [loadingId, loadingState] = loading;
      setLoadingMap({ ...loadingMap, [loadingId]: loadingState });
    }
  }, [loading, listData]);
  return (
    <ManagedList
      key={JSON.stringify(loadingMap)}
      itemList={listData}
      columns={[
        {
          key: 'name',
          name: 'Name',
          fieldName: 'name',
          minWidth: 150,
          maxWidth: 400,
        },
        {
          key: 'data',
          name: 'Secret Key(s)',
          fieldName: 'data',
          minWidth: 150,
          maxWidth: 430,
          onRender: ({ data }: Secret) => {
            return (
              <Cell>
                <Stack tokens={{ childrenGap: 8 }}>
                  <span style={{ fontWeight: FontWeights.bold, marginBottom: 4 }}>{'Secret Key(s)'}</span>
                  <Stack horizontal verticalAlign="center">
                    {Object.keys(data).map((key) => (
                      <Item key={key} data={{ name: key }} styles={[itemStylesCategory]} />
                    ))}
                  </Stack>
                </Stack>
              </Cell>
            );
          },
        },
        {
          key: 'visibility',
          name: 'Visibility',
          fieldName: 'visibility',
          minWidth: 120,
          maxWidth: 800,
          onRender: ({ visibility }: Secret) => {
            const visibilitySet = visibilityOptions.find((x) => x.key === visibility);
            return <BadgeCell title="Visibility" badgeLabel={visibilitySet?.text || ''} />;
          },
        },
        {
          key: 'actions',
          name: 'Actions',
          minWidth: actionColumnsWidth,
          maxWidth: actionColumnsWidth,
          onRender: (item: Secret) => {
            return (
              <Cell>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                  <Button
                    text="Edit"
                    loading={loadingMap[getSecretUniqueId(item)] || false}
                    data-test="secret-edit-button"
                    styles={[buttonStylesDefault]}
                    onClick={() => {
                      editSecret(item);
                    }}
                  />
                  <Button
                    text="Delete"
                    loading={loadingMap[getSecretUniqueId(item)] || false}
                    data-test="secret-delete-button"
                    styles={[buttonStylesDanger]}
                    onClick={() => {
                      deleteSecret(item);
                    }}
                  />
                </Stack>
              </Cell>
            );
          },
        },
      ]}
    />
  );
}
