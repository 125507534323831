/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/dai_engine_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * All possible DAIEngine states.
 * 
 *  - STATE_UNSPECIFIED: DAIEngine state is unspecified or unknown.
 *  - STATE_STARTING: DAIEngine is starting.
 *  - STATE_RUNNING: DAIEngine is running and can be used.
 *  - STATE_PAUSING: DAIEngine is pausing.
 *  - STATE_PAUSED: DAIEngine is paused and can be resumed again.
 *  - STATE_FAILED: DAIEngine has failed and can be resumed again.
 *  - STATE_DELETING: DAIEngine is being deleted.
 *  - STATE_CONNECTING: DAIEngine is establishing a connection with the AI Engine Manager.
 * @export
 * @enum {string}
 */
export enum V1DAIEngineState {
    Unspecified = 'STATE_UNSPECIFIED',
    Starting = 'STATE_STARTING',
    Running = 'STATE_RUNNING',
    Pausing = 'STATE_PAUSING',
    Paused = 'STATE_PAUSED',
    Failed = 'STATE_FAILED',
    Deleting = 'STATE_DELETING',
    Connecting = 'STATE_CONNECTING'
}

export function V1DAIEngineStateFromJSON(json: any): V1DAIEngineState {
    return V1DAIEngineStateFromJSONTyped(json, false);
}

export function V1DAIEngineStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1DAIEngineState {
    return json as V1DAIEngineState;
}

export function V1DAIEngineStateToJSON(value?: V1DAIEngineState | null): any {
    return value as any;
}

