/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/dai_engine_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineObject,
    InlineObjectFromJSON,
    InlineObjectToJSON,
    InlineObject1,
    InlineObject1FromJSON,
    InlineObject1ToJSON,
    InlineObject2,
    InlineObject2FromJSON,
    InlineObject2ToJSON,
    InlineObject3,
    InlineObject3FromJSON,
    InlineObject3ToJSON,
    RpcStatus,
    RpcStatusFromJSON,
    RpcStatusToJSON,
    V1CreateDAIEngineResponse,
    V1CreateDAIEngineResponseFromJSON,
    V1CreateDAIEngineResponseToJSON,
    V1DAIEngine,
    V1DAIEngineFromJSON,
    V1DAIEngineToJSON,
    V1DAIEngineServiceDownloadLogsResponse,
    V1DAIEngineServiceDownloadLogsResponseFromJSON,
    V1DAIEngineServiceDownloadLogsResponseToJSON,
    V1DAIEngineServiceMigrateCreatorResponse,
    V1DAIEngineServiceMigrateCreatorResponseFromJSON,
    V1DAIEngineServiceMigrateCreatorResponseToJSON,
    V1DAIEngineServiceUpgradeVersionResponse,
    V1DAIEngineServiceUpgradeVersionResponseFromJSON,
    V1DAIEngineServiceUpgradeVersionResponseToJSON,
    V1DeleteDAIEngineResponse,
    V1DeleteDAIEngineResponseFromJSON,
    V1DeleteDAIEngineResponseToJSON,
    V1GetDAIEngineResponse,
    V1GetDAIEngineResponseFromJSON,
    V1GetDAIEngineResponseToJSON,
    V1ListDAIEnginesResponse,
    V1ListDAIEnginesResponseFromJSON,
    V1ListDAIEnginesResponseToJSON,
    V1PauseDAIEngineResponse,
    V1PauseDAIEngineResponseFromJSON,
    V1PauseDAIEngineResponseToJSON,
    V1ResumeDAIEngineResponse,
    V1ResumeDAIEngineResponseFromJSON,
    V1ResumeDAIEngineResponseToJSON,
    V1UpdateDAIEngineResponse,
    V1UpdateDAIEngineResponseFromJSON,
    V1UpdateDAIEngineResponseToJSON,
} from '../models';

export interface DAIEngineServiceCreateDAIEngineRequest {
    parent: string;
    daiEngineId: string;
    body: V1DAIEngine;
    validateOnly?: boolean;
}

export interface DAIEngineServiceDeleteDAIEngineRequest {
    name: string;
    allowMissing?: boolean;
    validateOnly?: boolean;
}

export interface DAIEngineServiceDownloadLogsRequest {
    daiEngine: string;
    body: object;
}

export interface DAIEngineServiceGetDAIEngineRequest {
    name: string;
}

export interface DAIEngineServiceListDAIEnginesRequest {
    parent: string;
    pageSize?: number;
    pageToken?: string;
    orderBy?: string;
    filter?: string;
}

export interface DAIEngineServiceMigrateCreatorRequest {
    daiEngine: string;
    body: InlineObject;
}

export interface DAIEngineServicePauseDAIEngineRequest {
    name: string;
    body: InlineObject2;
}

export interface DAIEngineServiceResumeDAIEngineRequest {
    name: string;
    body: InlineObject3;
}

export interface DAIEngineServiceUpdateDAIEngineRequest {
    daiEngineName: string;
    updateMask: string;
    body: V1DAIEngine;
    allowMissing?: boolean;
    validateOnly?: boolean;
}

export interface DAIEngineServiceUpgradeVersionRequest {
    daiEngine: string;
    body: InlineObject1;
}

/**
 * 
 */
export class DAIEngineServiceApi extends runtime.BaseAPI {

    /**
     * Creates a new DAIEngine within a specified workspace.
     */
    async dAIEngineServiceCreateDAIEngineRaw(requestParameters: DAIEngineServiceCreateDAIEngineRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1CreateDAIEngineResponse>> {
        if (requestParameters.parent === null || requestParameters.parent === undefined) {
            throw new runtime.RequiredError('parent','Required parameter requestParameters.parent was null or undefined when calling dAIEngineServiceCreateDAIEngine.');
        }

        if (requestParameters.daiEngineId === null || requestParameters.daiEngineId === undefined) {
            throw new runtime.RequiredError('daiEngineId','Required parameter requestParameters.daiEngineId was null or undefined when calling dAIEngineServiceCreateDAIEngine.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling dAIEngineServiceCreateDAIEngine.');
        }

        const queryParameters: any = {};

        if (requestParameters.daiEngineId !== undefined) {
            queryParameters['daiEngineId'] = requestParameters.daiEngineId;
        }

        if (requestParameters.validateOnly !== undefined) {
            queryParameters['validateOnly'] = requestParameters.validateOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/{parent}/daiEngines`.replace(`{${"parent"}}`, encodeURIComponent(String(requestParameters.parent))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: V1DAIEngineToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1CreateDAIEngineResponseFromJSON(jsonValue));
    }

    /**
     * Creates a new DAIEngine within a specified workspace.
     */
    async dAIEngineServiceCreateDAIEngine(requestParameters: DAIEngineServiceCreateDAIEngineRequest, initOverrides?: RequestInit): Promise<V1CreateDAIEngineResponse> {
        const response = await this.dAIEngineServiceCreateDAIEngineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a DAIEngine.
     */
    async dAIEngineServiceDeleteDAIEngineRaw(requestParameters: DAIEngineServiceDeleteDAIEngineRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1DeleteDAIEngineResponse>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling dAIEngineServiceDeleteDAIEngine.');
        }

        const queryParameters: any = {};

        if (requestParameters.allowMissing !== undefined) {
            queryParameters['allowMissing'] = requestParameters.allowMissing;
        }

        if (requestParameters.validateOnly !== undefined) {
            queryParameters['validateOnly'] = requestParameters.validateOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1DeleteDAIEngineResponseFromJSON(jsonValue));
    }

    /**
     * Deletes a DAIEngine.
     */
    async dAIEngineServiceDeleteDAIEngine(requestParameters: DAIEngineServiceDeleteDAIEngineRequest, initOverrides?: RequestInit): Promise<V1DeleteDAIEngineResponse> {
        const response = await this.dAIEngineServiceDeleteDAIEngineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download DAIEngine logs.
     */
    async dAIEngineServiceDownloadLogsRaw(requestParameters: DAIEngineServiceDownloadLogsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1DAIEngineServiceDownloadLogsResponse>> {
        if (requestParameters.daiEngine === null || requestParameters.daiEngine === undefined) {
            throw new runtime.RequiredError('daiEngine','Required parameter requestParameters.daiEngine was null or undefined when calling dAIEngineServiceDownloadLogs.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling dAIEngineServiceDownloadLogs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/{daiEngine}:downloadLogs`.replace(`{${"daiEngine"}}`, encodeURIComponent(String(requestParameters.daiEngine))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1DAIEngineServiceDownloadLogsResponseFromJSON(jsonValue));
    }

    /**
     * Download DAIEngine logs.
     */
    async dAIEngineServiceDownloadLogs(requestParameters: DAIEngineServiceDownloadLogsRequest, initOverrides?: RequestInit): Promise<V1DAIEngineServiceDownloadLogsResponse> {
        const response = await this.dAIEngineServiceDownloadLogsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a specific DAIEngine.
     */
    async dAIEngineServiceGetDAIEngineRaw(requestParameters: DAIEngineServiceGetDAIEngineRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1GetDAIEngineResponse>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling dAIEngineServiceGetDAIEngine.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1GetDAIEngineResponseFromJSON(jsonValue));
    }

    /**
     * Returns a specific DAIEngine.
     */
    async dAIEngineServiceGetDAIEngine(requestParameters: DAIEngineServiceGetDAIEngineRequest, initOverrides?: RequestInit): Promise<V1GetDAIEngineResponse> {
        const response = await this.dAIEngineServiceGetDAIEngineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a collection of DAIEngines within a workspace.
     */
    async dAIEngineServiceListDAIEnginesRaw(requestParameters: DAIEngineServiceListDAIEnginesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1ListDAIEnginesResponse>> {
        if (requestParameters.parent === null || requestParameters.parent === undefined) {
            throw new runtime.RequiredError('parent','Required parameter requestParameters.parent was null or undefined when calling dAIEngineServiceListDAIEngines.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['pageToken'] = requestParameters.pageToken;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/{parent}/daiEngines`.replace(`{${"parent"}}`, encodeURIComponent(String(requestParameters.parent))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1ListDAIEnginesResponseFromJSON(jsonValue));
    }

    /**
     * Returns a collection of DAIEngines within a workspace.
     */
    async dAIEngineServiceListDAIEngines(requestParameters: DAIEngineServiceListDAIEnginesRequest, initOverrides?: RequestInit): Promise<V1ListDAIEnginesResponse> {
        const response = await this.dAIEngineServiceListDAIEnginesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Migrate DAIEngine creator. Admin only.
     */
    async dAIEngineServiceMigrateCreatorRaw(requestParameters: DAIEngineServiceMigrateCreatorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1DAIEngineServiceMigrateCreatorResponse>> {
        if (requestParameters.daiEngine === null || requestParameters.daiEngine === undefined) {
            throw new runtime.RequiredError('daiEngine','Required parameter requestParameters.daiEngine was null or undefined when calling dAIEngineServiceMigrateCreator.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling dAIEngineServiceMigrateCreator.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/{daiEngine}:migrateCreator`.replace(`{${"daiEngine"}}`, encodeURIComponent(String(requestParameters.daiEngine))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObjectToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1DAIEngineServiceMigrateCreatorResponseFromJSON(jsonValue));
    }

    /**
     * Migrate DAIEngine creator. Admin only.
     */
    async dAIEngineServiceMigrateCreator(requestParameters: DAIEngineServiceMigrateCreatorRequest, initOverrides?: RequestInit): Promise<V1DAIEngineServiceMigrateCreatorResponse> {
        const response = await this.dAIEngineServiceMigrateCreatorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Pauses a DAIEngine.
     */
    async dAIEngineServicePauseDAIEngineRaw(requestParameters: DAIEngineServicePauseDAIEngineRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1PauseDAIEngineResponse>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling dAIEngineServicePauseDAIEngine.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling dAIEngineServicePauseDAIEngine.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/{name}:pause`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject2ToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1PauseDAIEngineResponseFromJSON(jsonValue));
    }

    /**
     * Pauses a DAIEngine.
     */
    async dAIEngineServicePauseDAIEngine(requestParameters: DAIEngineServicePauseDAIEngineRequest, initOverrides?: RequestInit): Promise<V1PauseDAIEngineResponse> {
        const response = await this.dAIEngineServicePauseDAIEngineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resumes an existing DAIEngine.
     */
    async dAIEngineServiceResumeDAIEngineRaw(requestParameters: DAIEngineServiceResumeDAIEngineRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1ResumeDAIEngineResponse>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling dAIEngineServiceResumeDAIEngine.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling dAIEngineServiceResumeDAIEngine.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/{name}:resume`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject3ToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1ResumeDAIEngineResponseFromJSON(jsonValue));
    }

    /**
     * Resumes an existing DAIEngine.
     */
    async dAIEngineServiceResumeDAIEngine(requestParameters: DAIEngineServiceResumeDAIEngineRequest, initOverrides?: RequestInit): Promise<V1ResumeDAIEngineResponse> {
        const response = await this.dAIEngineServiceResumeDAIEngineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a DAIEngine.
     */
    async dAIEngineServiceUpdateDAIEngineRaw(requestParameters: DAIEngineServiceUpdateDAIEngineRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1UpdateDAIEngineResponse>> {
        if (requestParameters.daiEngineName === null || requestParameters.daiEngineName === undefined) {
            throw new runtime.RequiredError('daiEngineName','Required parameter requestParameters.daiEngineName was null or undefined when calling dAIEngineServiceUpdateDAIEngine.');
        }

        if (requestParameters.updateMask === null || requestParameters.updateMask === undefined) {
            throw new runtime.RequiredError('updateMask','Required parameter requestParameters.updateMask was null or undefined when calling dAIEngineServiceUpdateDAIEngine.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling dAIEngineServiceUpdateDAIEngine.');
        }

        const queryParameters: any = {};

        if (requestParameters.updateMask !== undefined) {
            queryParameters['updateMask'] = requestParameters.updateMask;
        }

        if (requestParameters.allowMissing !== undefined) {
            queryParameters['allowMissing'] = requestParameters.allowMissing;
        }

        if (requestParameters.validateOnly !== undefined) {
            queryParameters['validateOnly'] = requestParameters.validateOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/{daiEngine.name}`.replace(`{${"daiEngine.name"}}`, encodeURIComponent(String(requestParameters.daiEngineName))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: V1DAIEngineToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1UpdateDAIEngineResponseFromJSON(jsonValue));
    }

    /**
     * Updates a DAIEngine.
     */
    async dAIEngineServiceUpdateDAIEngine(requestParameters: DAIEngineServiceUpdateDAIEngineRequest, initOverrides?: RequestInit): Promise<V1UpdateDAIEngineResponse> {
        const response = await this.dAIEngineServiceUpdateDAIEngineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upgrade Driverless AI version.
     */
    async dAIEngineServiceUpgradeVersionRaw(requestParameters: DAIEngineServiceUpgradeVersionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1DAIEngineServiceUpgradeVersionResponse>> {
        if (requestParameters.daiEngine === null || requestParameters.daiEngine === undefined) {
            throw new runtime.RequiredError('daiEngine','Required parameter requestParameters.daiEngine was null or undefined when calling dAIEngineServiceUpgradeVersion.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling dAIEngineServiceUpgradeVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/{daiEngine}:upgradeVersion`.replace(`{${"daiEngine"}}`, encodeURIComponent(String(requestParameters.daiEngine))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject1ToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1DAIEngineServiceUpgradeVersionResponseFromJSON(jsonValue));
    }

    /**
     * Upgrade Driverless AI version.
     */
    async dAIEngineServiceUpgradeVersion(requestParameters: DAIEngineServiceUpgradeVersionRequest, initOverrides?: RequestInit): Promise<V1DAIEngineServiceUpgradeVersionResponse> {
        const response = await this.dAIEngineServiceUpgradeVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
