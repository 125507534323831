/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/h2o_engine_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1H2OEngine,
    V1H2OEngineFromJSON,
    V1H2OEngineFromJSONTyped,
    V1H2OEngineToJSON,
} from './V1H2OEngine';

/**
 * Response message for H2OEngineService.ListH2OEngines.
 * @export
 * @interface V1ListH2OEnginesResponse
 */
export interface V1ListH2OEnginesResponse {
    /**
     * Collection of H2OEngines.
     * @type {Array<V1H2OEngine>}
     * @memberof V1ListH2OEnginesResponse
     */
    h2oEngines?: Array<V1H2OEngine>;
    /**
     * Used to retrieve the next page of results.
     * When unset, no further items are available (this response was the last page).
     * @type {string}
     * @memberof V1ListH2OEnginesResponse
     */
    nextPageToken?: string;
    /**
     * Total number of H2OEngines matched by the List request.
     * @type {number}
     * @memberof V1ListH2OEnginesResponse
     */
    totalSize?: number;
}

export function V1ListH2OEnginesResponseFromJSON(json: any): V1ListH2OEnginesResponse {
    return V1ListH2OEnginesResponseFromJSONTyped(json, false);
}

export function V1ListH2OEnginesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ListH2OEnginesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'h2oEngines': !exists(json, 'h2oEngines') ? undefined : ((json['h2oEngines'] as Array<any>).map(V1H2OEngineFromJSON)),
        'nextPageToken': !exists(json, 'nextPageToken') ? undefined : json['nextPageToken'],
        'totalSize': !exists(json, 'totalSize') ? undefined : json['totalSize'],
    };
}

export function V1ListH2OEnginesResponseToJSON(value?: V1ListH2OEnginesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'h2oEngines': value.h2oEngines === undefined ? undefined : ((value.h2oEngines as Array<any>).map(V1H2OEngineToJSON)),
        'nextPageToken': value.nextPageToken,
        'totalSize': value.totalSize,
    };
}

