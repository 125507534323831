import { PanelType, Separator, Stack } from '@fluentui/react';
import {
  Button,
  Checkbox,
  Link,
  Panel,
  SearchBox,
  buttonStylesPrimary,
  linkStylesBlackThin,
  useTheme,
} from '@h2oai/ui-kit';
import { useCallback, useRef, useState } from 'react';

import { ManagedListSeparator } from '../../../components/ManagedList/ManagedList';

export type CheckboxLogName = {
  key: string;
  checked: boolean;
  found: boolean;
};

type LogNamesPanelProps = {
  logNamesAvailable: string[];
  logNamesExclude: string[];
  onApply: (logNamesExclude: string[]) => void;
  onDismiss: () => void;
};

export const LogNamesPanel = ({ logNamesAvailable, logNamesExclude, onDismiss, onApply }: LogNamesPanelProps) => {
  const [checkboxLogNames, setCheckboxLogNames] = useState<CheckboxLogName[]>(
      (logNamesAvailable || [])?.map((key) => ({
        key,
        checked: !logNamesExclude?.includes(key),
        found: true,
      }))
    ),
    theme = useTheme(),
    logCount = useRef<number>(checkboxLogNames.filter((item) => item.checked).length),
    handleCheckboxChange = (position: number) => {
      setCheckboxLogNames((prevState) => {
        const updatedCheckedState = [...prevState];
        updatedCheckedState[position] = { ...prevState[position], checked: !prevState[position].checked };
        logCount.current = updatedCheckedState.filter((item) => item.checked).length;
        return updatedCheckedState;
      });
    },
    onSearchChange = useCallback(
      (_, searchTerm) =>
        setCheckboxLogNames((list) =>
          list.map((i) => ({ ...i, found: i.key.toLowerCase().includes(String(searchTerm?.toLowerCase())) }))
        ),
      []
    );

  return (
    <Panel
      isLightDismiss
      closeButtonAriaLabel="Close"
      customWidth="450px"
      headerText={`Log Name`}
      isFooterAtBottom={true}
      isOpen={true}
      onDismiss={onDismiss}
      type={PanelType.custom}
      onRenderFooterContent={() => {
        return (
          <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
            <Button text="Close" onClick={onDismiss} />
            <Button
              styles={buttonStylesPrimary}
              text="Apply"
              onClick={() => onApply(checkboxLogNames?.filter(({ checked }) => !checked).map(({ key }) => key))}
              disabled={logCount.current === 0}
            />
          </Stack>
        );
      }}
    >
      <Stack tokens={{ childrenGap: 13 }} styles={{ root: { width: '100%', paddingTop: 10 } }}>
        <SearchBox placeholder="Search" onChange={onSearchChange} />

        <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 10, maxHeight: 18 }}>
          <Link
            styles={linkStylesBlackThin}
            label="Select all"
            disabled={logCount.current === checkboxLogNames.length}
            onClick={() => {
              setCheckboxLogNames((list) => list.map((i) => ({ ...i, checked: true })));
              logCount.current = checkboxLogNames.length;
            }}
          >
            Select all
          </Link>
          <Separator
            vertical
            styles={{
              root: {
                ':after': {
                  backgroundColor: theme.semanticColors?.inputBorder,
                },
              },
            }}
          />
          <Link
            styles={linkStylesBlackThin}
            label="Deselect all"
            disabled={logCount.current === 0}
            onClick={() => {
              setCheckboxLogNames((list) => list.map((i) => ({ ...i, checked: false })));
              logCount.current = 0;
            }}
          >
            Deselect all
          </Link>
        </Stack>
        <ManagedListSeparator
          text={`Selected log name${logCount.current === 1 ? '' : 's'}: ${logCount.current}/${checkboxLogNames.length}`}
        />
        <Stack tokens={{ childrenGap: 10 }}>
          {checkboxLogNames
            ?.filter((i) => i.found)
            .map(({ key, checked }, index) => (
              <Checkbox key={key} label={key} checked={checked} onChange={() => handleCheckboxChange(index)} />
            ))}
        </Stack>
      </Stack>
    </Panel>
  );
};
