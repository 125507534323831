import { DetailsRow, IDetailsRowProps, concatStyleSets } from '@fluentui/react';
import { Loader, LoaderType, loaderStylesProgressIndicatorDefault, useTheme } from '@h2oai/ui-kit';

const loaderHeight = 3;

interface StyledDetailsRowProps extends IDetailsRowProps {
  loading?: boolean;
  deleting?: boolean;
}

export default function StyledDetailsRow(props: StyledDetailsRowProps) {
  const { loading, deleting, styles = {}, ...rest } = props;
  const theme = useTheme(),
    showLoader = loading || deleting,
    detailsRowStyles = {
      root: {
        marginBottom: 8,
        border: `1px solid ${theme.palette?.gray300}`,
        pointerEvents: showLoader ? 'none' : 'unset',
        userSelect: showLoader ? 'none' : 'unset',
      },
      cell: { alignSelf: 'start', paddingTop: 16, paddingBottom: 16 },
    },
    barColor = loading ? theme.semanticColors?.loaderBarIndicator : theme.semanticColors?.loaderBarIndicatorError,
    loaderStyles = {
      root: { position: 'absolute', width: '100%', bottom: '8px' },
      itemProgress: { height: loaderHeight },
      progressBar: {
        height: loaderHeight,
        background: `linear-gradient(to right, ${theme?.palette?.white} 0%, ${barColor} 50%, ${theme?.palette?.white} 100%)`,
      },
      progressTrack: { height: loaderHeight, background: 'transparent' },
    };

  return (
    <div style={{ position: 'relative' }}>
      <DetailsRow disabled={loading || deleting} {...rest} styles={concatStyleSets(detailsRowStyles, styles)} />
      {showLoader && (
        <Loader
          type={LoaderType.progressIndicator}
          styles={concatStyleSets(loaderStylesProgressIndicatorDefault, loaderStyles)}
        />
      )}
    </div>
  );
}
