/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/dai_engine_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1DAIEngine,
    V1DAIEngineFromJSON,
    V1DAIEngineFromJSONTyped,
    V1DAIEngineToJSON,
} from './V1DAIEngine';

/**
 * Response message for DAIEngineService.ListDAIEngines.
 * @export
 * @interface V1ListDAIEnginesResponse
 */
export interface V1ListDAIEnginesResponse {
    /**
     * Collection of DAIEngines.
     * @type {Array<V1DAIEngine>}
     * @memberof V1ListDAIEnginesResponse
     */
    daiEngines?: Array<V1DAIEngine>;
    /**
     * Used to retrieve the next page of results.
     * When unset, no further items are available (this response was the last page).
     * @type {string}
     * @memberof V1ListDAIEnginesResponse
     */
    nextPageToken?: string;
    /**
     * Total number of DAIEngines matched by the List request.
     * @type {number}
     * @memberof V1ListDAIEnginesResponse
     */
    totalSize?: number;
}

export function V1ListDAIEnginesResponseFromJSON(json: any): V1ListDAIEnginesResponse {
    return V1ListDAIEnginesResponseFromJSONTyped(json, false);
}

export function V1ListDAIEnginesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ListDAIEnginesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'daiEngines': !exists(json, 'daiEngines') ? undefined : ((json['daiEngines'] as Array<any>).map(V1DAIEngineFromJSON)),
        'nextPageToken': !exists(json, 'nextPageToken') ? undefined : json['nextPageToken'],
        'totalSize': !exists(json, 'totalSize') ? undefined : json['totalSize'],
    };
}

export function V1ListDAIEnginesResponseToJSON(value?: V1ListDAIEnginesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'daiEngines': value.daiEngines === undefined ? undefined : ((value.daiEngines as Array<any>).map(V1DAIEngineToJSON)),
        'nextPageToken': value.nextPageToken,
        'totalSize': value.totalSize,
    };
}

