import { Card, CardList as H2OCardList, IH2OTheme, IItemMetaData, cardStylesNoGap, useTheme } from '@h2oai/ui-kit';

import { App, AppInstance, AppInstance_Status, TagAssignment } from '../../ai.h2o.cloud.appstore';
import { BaseCategory } from '../../pages/AppStorePage/AppStorePage.models';
import { useApp } from '../../utils/hooks';
import { InstancePauseResumeOpEnum } from '../../utils/utils';
import { InstanceActionButton } from '../InstanceActionButton/InstanceActionButton';

export enum CardType {
  app = 'app',
  instance = 'instance',
}

interface ICard {
  id: string;
  owner: string;
  title: string;
  description: string;
  iconLocation: string;
  categories: string;
  pinned?: boolean;
  liked?: boolean;
  likes?: number;
  status?: string;
  statusColor?: string;
}

export interface ICardListProps {
  data: App[];
  type?: CardType;
  emptyMessage?: string;
  onClick?: (d: any) => void;
  onLike?: (d: any) => void;
  onPin?: (d: any) => void;
  columnCount: number;
  metaDatas?: IItemMetaData[];
  useImageLazyLoader?: boolean;
  hideUserName?: boolean;
  hideCategories?: boolean;
  showStatus?: boolean;
  noGap?: boolean;
  setInstanceSuspension?: (instance: AppInstance, op: InstancePauseResumeOpEnum) => () => void;
}

const getCategories = (tags: TagAssignment[]): string => {
  const tagList = tags.reduce((items: string[], { hidden, isCategory, title }) => {
    if (isCategory && !hidden) {
      items.push(title);
    }
    return items;
  }, []);
  if (!tagList.length) {
    tagList.push(BaseCategory.Other);
  }
  return tagList.join(' | ');
};

const getAppCard = ({ id, owner, title, iconLocation, tags, description, preference }: App): ICard => {
  return {
    id,
    owner,
    title,
    iconLocation: iconLocation ? `/v1/asset/${iconLocation}` : '/logo512.png',
    categories: getCategories(tags),
    description,
    pinned: preference?.pinned,
    liked: preference?.liked,
    likes: preference?.likes,
  };
};

const getAppInstanceCard = ({ id, owner, suspendable, status, appDetails }: AppInstance, theme: IH2OTheme): ICard => {
  const { title, iconLocation, tags, description, preference } = appDetails || {};
  const { pinned, liked, likes } = preference || {};
  const statusTextAndColorMap = new Map([
    [0, { status: 'Not Available', statusColor: theme.palette?.gray300 }],
    [1, { status: 'Running', statusColor: theme.palette?.green200 }],
    [2, { status: 'Stopped', statusColor: theme.palette?.red200 }],
  ]);
  const statusTextAndColor =
    suspendable && [AppInstance_Status.DEPLOYED, AppInstance_Status.SUSPENDED].includes(status)
      ? status === AppInstance_Status.DEPLOYED
        ? statusTextAndColorMap.get(1)
        : statusTextAndColorMap.get(2)
      : statusTextAndColorMap.get(0);
  return {
    id,
    owner,
    title: title || ``,
    iconLocation: iconLocation ? `/v1/asset/${iconLocation}` : '/logo512.png',
    categories: tags ? getCategories(tags) : ``,
    description: description || ``,
    pinned,
    liked,
    likes,
    status: statusTextAndColor?.status,
    statusColor: statusTextAndColor?.statusColor,
  };
};

const getCardData = (type: CardType, data: unknown, theme: IH2OTheme) => {
  switch (type) {
    case CardType.instance:
      return getAppInstanceCard(data as AppInstance, theme);
    default:
      return getAppCard(data as App);
  }
};

export default function CardList({
  data,
  type = CardType.app,
  emptyMessage = 'No apps found for this category.',
  onPin,
  onLike,
  onClick,
  columnCount,
  metaDatas,
  useImageLazyLoader = true,
  hideUserName,
  hideCategories,
  showStatus,
  noGap,
  setInstanceSuspension,
}: ICardListProps) {
  const { getAppBadges } = useApp();
  const theme = useTheme();
  const styles = noGap ? cardStylesNoGap : undefined;
  const itemRenderer = (item: unknown) => {
    const d = getCardData(type, item, theme);
    const badges = getAppBadges(item as App);
    return (
      <Card<ICard>
        styles={styles}
        data-test={`${d.title}--app-tile`}
        idField="id"
        categoryField={hideCategories ? undefined : 'categories'}
        titleField="title"
        descriptionField="description"
        userNameField={hideUserName ? undefined : 'owner'}
        pinnedField={onPin ? 'pinned' : undefined}
        likedField={onLike ? 'liked' : undefined}
        likesField={onLike ? 'likes' : undefined}
        statusField={showStatus ? 'status' : undefined}
        statusColorField={showStatus ? 'statusColor' : undefined}
        imagePathField="iconLocation"
        useImageLazyLoader={useImageLazyLoader}
        data={d}
        badges={badges}
        onClick={onClick ? () => onClick(item) : undefined}
        onLike={() => {
          if (onLike) onLike(item);
        }}
        onPin={() => {
          if (onPin) onPin(item);
        }}
        actions={
          type === CardType.instance ? (
            <InstanceActionButton
              instance={item as AppInstance}
              hideTerminateButton={true}
              hasVisitAppNoTerminatePostponeMenu={true}
              useVisitInsteadOfPauseButton={true}
              setInstanceSuspension={setInstanceSuspension}
            />
          ) : undefined
        }
      />
    );
  };
  return !data?.length ? (
    <span>{emptyMessage}</span>
  ) : (
    <H2OCardList
      styles={styles}
      data={
        data as {
          [key: string]: any;
        }[]
      }
      metaDatas={metaDatas}
      columnCount={columnCount}
      itemRenderer={itemRenderer}
    />
  );
}
