/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as fm from "../../../../fetch.pb"
import * as GoogleProtobufTimestamp from "../../../../google/protobuf/timestamp.pb"
import * as AiH2oLoggingV1Entry from "./entry.pb"
export type ReadLogEntriesRequest = {
  resource?: string
  startTime?: GoogleProtobufTimestamp.Timestamp
  endTime?: GoogleProtobufTimestamp.Timestamp
  search?: string
  searchRegex?: boolean
  logNameExclude?: string[]
  logSourceExclude?: string[]
  pageSize?: number
  pageToken?: string
}

export type ReadLogEntriesResponse = {
  logEntries?: AiH2oLoggingV1Entry.LogEntry[]
  nextPageToken?: string
}

export type WriteLogEntriesRequest = {
  logEntries?: AiH2oLoggingV1Entry.LogEntry[]
}

export type WriteLogEntriesResponse = {
}

export class LogEntryService {
  static ReadLogEntries(req: ReadLogEntriesRequest, initReq?: fm.InitReq): Promise<ReadLogEntriesResponse> {
    return fm.fetchReq<ReadLogEntriesRequest, ReadLogEntriesResponse>(`/v1/logEntries:read?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static WriteLogEntries(req: WriteLogEntriesRequest, initReq?: fm.InitReq): Promise<WriteLogEntriesResponse> {
    return fm.fetchReq<WriteLogEntriesRequest, WriteLogEntriesResponse>(`/v1/logEntries:write`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}