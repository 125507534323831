import { IStyle } from '@fluentui/react';
import { FontSizes, FontWeights, IH2OTheme, useClassNames } from '@h2oai/ui-kit';
import { ReactNode } from 'react';

interface IInstanceLogPageHeaderClassNames {
  root: string;
  err: string;
}

interface IInstanceLogPageHeaderStyles {
  root: IStyle;
}

export const instanceLogPageHeaderStylesDefault = (theme: IH2OTheme): IInstanceLogPageHeaderStyles => ({
  root: {
    padding: `0 100px 20px 110px`,
    color: theme.palette?.gray800,
    backgroundColor: theme.semanticColors?.bodyBackground,
    h1: {
      fontSize: FontSizes.xlarge,
      fontWeight: FontWeights.fw600,
      color: 'inherit',
      padding: 0,
      margin: '0.15rem 0',
    },
    '.instance-id': {
      fontSize: FontSizes.xsmall,
    },
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
  },
});

interface IInstanceLogPageHeaderProps {
  children: ReactNode;
}

export function InstanceLogPageHeader({ children }: IInstanceLogPageHeaderProps) {
  const classNames = useClassNames<IInstanceLogPageHeaderStyles, IInstanceLogPageHeaderClassNames>(
    'InstanceLogPageHeader',
    instanceLogPageHeaderStylesDefault
  );
  return <div className={classNames.root}>{children}</div>;
}
