/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/engine_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * All possible Engine types.
 * 
 *  - TYPE_UNSPECIFIED: Engine type is unspecified or unknown.
 *  - TYPE_DRIVERLESS_AI: Driverless AI Engine.
 *  - TYPE_H2O: H2O Engine.
 * @export
 * @enum {string}
 */
export enum V1EngineType {
    Unspecified = 'TYPE_UNSPECIFIED',
    DriverlessAi = 'TYPE_DRIVERLESS_AI',
    H2O = 'TYPE_H2O'
}

export function V1EngineTypeFromJSON(json: any): V1EngineType {
    return V1EngineTypeFromJSONTyped(json, false);
}

export function V1EngineTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1EngineType {
    return json as V1EngineType;
}

export function V1EngineTypeToJSON(value?: V1EngineType | null): any {
    return value as any;
}

