import { IButtonStyles, IStackStyles, IStyle, initializeIcons } from '@fluentui/react';
import { FontSizes, FontWeights, IH2OTheme, Sizes, defaultSemanticColors } from '@h2oai/ui-kit';

const defaultPagePadding = `${Sizes.contentPaddingTop}px 30px 100px`;

export const DefaultPageStyles: IStyle = {
  padding: defaultPagePadding,
  margin: '0 auto',
  maxWidth: '2000px',
  width: '100%',
  position: 'relative',
};

export interface IPageHeaderClassNames {
  header: string;
  title: string;
  description: string;
}

export interface IPageHeaderStyles {
  header: IStyle;
  title: IStyle;
  description: IStyle;
}

export const PageHeaderStyles: { [key: string]: IStyle } = {
  header: {
    paddingTop: 0,
  },
  title: {
    color: defaultSemanticColors.textQuaternary,
    fontWeight: FontWeights.semiBold,
    fontSize: FontSizes.xlarge,
  },
  description: {
    color: defaultSemanticColors.textQuinary,
    fontSize: FontSizes.xsmall,
  },
};

export const pageHeaderStylesDefault: Partial<IPageHeaderStyles> = PageHeaderStyles;

// *********** Fluent components styles **********
export const stackStylesAppContainer: Partial<IStackStyles> = {
  root: {
    '*': {
      boxSizing: 'border-box',
    },
    minHeight: '100vh',
    '.checkbox__centered': {
      display: 'flex',
      alignItems: 'center',
    },
  },
};

export const stackStylesNoticesBar: Partial<IStackStyles> = {
  root: { paddingBottom: 10, marginTop: -20 },
};

export const stackStylesPage: Partial<IStackStyles> = {
  root: {
    ...DefaultPageStyles,
  },
};

export const stackStylesNoLeftNav: Partial<IStackStyles> = {
  root: {
    ...DefaultPageStyles,
  },
};

export const stackStylesAppStorePage: Partial<IStackStyles> = {
  root: {
    padding: defaultPagePadding,
  },
};

export const stackStylesDocument: Partial<IStackStyles> = {
  root: {
    maxWidth: 1468,
    width: '100%',
    padding: '0 136px',
    paddingTop: Sizes.contentPaddingTop,
    margin: '0 auto',
    alignSelf: 'flex-start',
    li: {
      marginBottom: 5,
    },
  },
};

export const stackStylesLog = (theme: IH2OTheme): Partial<IStackStyles> => ({
  root: {
    ...DefaultPageStyles,
    padding: `${Sizes.contentPaddingTop}px 0 0 0`,
    flex: 1,
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    '.log-container': {
      backgroundColor: theme.palette?.gray900,
      flex: 1,
      overflow: 'auto',
    },
    minWidth: '100%',
  },
});

// ***** ui-kit components need the project styles only.
// The default and common themes are in the components so don't need to copy them ****

export const buttonStylesRow: Partial<IButtonStyles> = {
  root: {
    marginLeft: '8px',
  },
};

export const buttonStylesGray = (theme: IH2OTheme): Partial<IButtonStyles> => ({
  root: {
    borderColor: theme.palette?.gray600,
    color: theme.palette?.gray600,
  },
});

// loads the fonts from `public/fonts` directory
initializeIcons(`/fonts/`);
