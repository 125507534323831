import { Stack } from '@fluentui/react';
import { Button, Checkbox, FontWeights, checkboxStylesCircle } from '@h2oai/ui-kit';

import { Alias, AppInstance } from '../../ai.h2o.cloud.appstore';
import Cell from '../ListPages/Cell';
import { TitleCell } from '../ListPages/TitleCell';
import { ManagedList } from '../ManagedList/ManagedList';

export interface IAliasListItem extends Alias {
  appName: string;
  owner: string;
}

export interface IManagedAliasListProps {
  aliases: Alias[];
  assignAlias: (alias: Alias) => void;
  deleteAlias: (alias: Alias) => void;
  instances: AppInstance[];
  promoteAlias: (alias: Alias) => void;
  unassignAlias: (alias: Alias) => void;
}

export function ManagedAliasList(props: IManagedAliasListProps) {
  const { aliases, assignAlias, deleteAlias, instances, promoteAlias, unassignAlias } = props,
    actionColumnsWidth = 190,
    mergeLists = (aliasItems: Alias[], instanceItems: AppInstance[]): IAliasListItem[] =>
      aliasItems.map((aliasItem) => {
        const instanceItem = instanceItems.find((item) => item.id === aliasItem.instanceId);
        return {
          ...aliasItem,
          appName: instanceItem?.appDetails.title || '',
          owner: instanceItem?.appDetails.owner || '',
        };
      });
  return (
    <ManagedList
      itemList={mergeLists(aliases, instances)}
      columns={[
        {
          key: 'primary',
          name: 'Primary',
          minWidth: 30,
          maxWidth: 75,
          onRender: (item: IAliasListItem) => {
            return item.instanceId ? (
              <Cell>
                <Stack tokens={{ childrenGap: 8 }}>
                  <span style={{ fontWeight: FontWeights.bold, marginBottom: 4 }}>{'Primary'}</span>
                  <Stack horizontal verticalAlign="center">
                    <Checkbox
                      checked={item.primary}
                      styles={checkboxStylesCircle}
                      onChange={() => {
                        if (!item.primary) {
                          promoteAlias(item);
                        }
                      }}
                    />
                  </Stack>
                </Stack>
              </Cell>
            ) : (
              <></>
            );
          },
        },
        {
          key: 'alias',
          name: 'Alias',
          fieldName: 'alias',
          minWidth: 80,
          maxWidth: 200,
          isSorted: true,
          headerClassName: 'is-sortable',
        },
        {
          key: 'appName',
          name: 'App Name',
          fieldName: 'appName',
          minWidth: 100,
          maxWidth: 400,
          onRender: ({ appName }: IAliasListItem) => (
            <TitleCell
              title="App Name"
              subtitle={appName ? appName : String.fromCodePoint(8212)}
              copySubtitle={false}
            />
          ),
        },
        {
          key: 'instanceId',
          name: 'Instance ID',
          fieldName: 'instanceId',
          minWidth: 200,
          maxWidth: 680,
          onRender: ({ instanceId }: IAliasListItem) => (
            <TitleCell
              title="Instance ID"
              subtitle={instanceId ? instanceId : String.fromCodePoint(8212)}
              copySubtitle={false}
            />
          ),
        },
        {
          key: 'instanceOwner',
          name: 'Instance owner',
          fieldName: 'owner',
          minWidth: 100,
          maxWidth: 200,
          onRender: ({ owner }: IAliasListItem) => (
            <TitleCell
              title="Instance owner"
              subtitle={owner ? owner : String.fromCodePoint(8212)}
              copySubtitle={false}
            />
          ),
        },
        {
          key: 'actions',
          name: 'Actions',
          minWidth: actionColumnsWidth,
          maxWidth: actionColumnsWidth,
          onRender: (item: Alias) => (
            <Cell>
              <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                <Button
                  text="Delete"
                  data-test="alias-delete-button"
                  onClick={() => {
                    deleteAlias(item);
                  }}
                />
                {!item.instanceId ? (
                  <div style={{ paddingTop: 2, textAlign: 'center' }}>
                    <Button text="Assign" data-test="alias-assign-button" onClick={() => assignAlias(item)} />
                  </div>
                ) : (
                  <div style={{ paddingTop: 2, textAlign: 'center' }}>
                    <Button text="Unassign" data-test="alias-unassign-button" onClick={() => unassignAlias(item)} />
                  </div>
                )}
              </Stack>
            </Cell>
          ),
        },
      ]}
    />
  );
}
