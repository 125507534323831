import { Stack, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { FontSizes, IconButton, useClassNames, useTheme } from '@h2oai/ui-kit';
import { CSSProperties } from 'react';

import { LogEntry } from '../../../logging-service/gen/ai/h2o/logging/v1/entry.pb';
import { LogRow } from './InfiniteLog';
import { ILogRowClassNames, ILogRowStyles, logRowStylesDefault } from './Row.styles';

const columns = [
  {
    key: 'timestamp',
    name: 'Timestamp',
    className: 'log-timestamp',
    fieldName: 'timestamp',
    minWidth: 212,
    maxWidth: 212,
  },
  {
    key: 'logname',
    name: 'Log Name',
    className: 'log-logname',
    fieldName: 'logName',
    minWidth: 180,
    maxWidth: 180,
  },
  {
    key: 'payload',
    name: '',
    className: 'log-payload',
    fieldName: 'payload',
    minWidth: 300,
    maxWidth: 5000,
  },
];

type LogLabelProps = {
  content?: string;
};

function LogLabel({ content }: LogLabelProps) {
  if (!content) return null;
  return <div style={{ width: 100 }}>{content}</div>;
}

type LogTextProps = {
  content?: string;
  truncate?: boolean;
  expanded?: boolean;
  header?: boolean;
  highlightRegExp?: RegExp;
};

function LogText(props: LogTextProps) {
  const { content, truncate = true, expanded = false, header = false, highlightRegExp } = props;
  const theme = useTheme();
  const classNames = useClassNames<Partial<ILogRowStyles>, ILogRowClassNames>('LogRow', logRowStylesDefault);

  let renderContent: React.ReactNode = content;
  if (highlightRegExp) {
    const items = content?.split(highlightRegExp).filter(Boolean);
    renderContent = items?.map((item, i) => {
      return (
        <span
          key={i}
          style={
            highlightRegExp.test(item)
              ? { color: theme.semanticColors?.buttonPrimaryBackground, fontWeight: 'bold' }
              : undefined
          }
        >
          {item}
        </span>
      );
    });
  }

  const classes: string[] = [classNames.logText];
  if (header) classes.push(classNames.header);
  if (!expanded) classes.push(classNames.collapsed);
  classes.push(expanded && !truncate ? classNames.expandedFullText : classNames.truncate);

  return (
    <Stack className={`log-row ${classNames.root}`}>
      <Text className={classes.join(' ')}>{renderContent}</Text>
    </Stack>
  );
}

type RowProps = {
  row: LogRow;
  header?: boolean;
  highlightRegExp?: RegExp;
  styles?: CSSProperties;
};

export const Row = ({ row, header, highlightRegExp, styles }: RowProps) => {
  const { palette, semanticColors } = useTheme();
  const [expanded, { toggle: toggleExpansion }] = useBoolean(false);
  const classNames = useClassNames<Partial<ILogRowStyles>, ILogRowClassNames>('LogRow', logRowStylesDefault);
  if (!row) return <></>;

  const { entry } = row,
    { logSource, payload, timestamp, logName, resource } = entry || ({} as LogEntry),
    baseTextProps = { expanded, header };

  return (
    <div className={`${classNames.root}  ${expanded ? classNames.expanded : ''}`} style={styles ? styles : undefined}>
      <Stack>
        <Stack horizontal styles={{ root: { paddingLeft: 50 } }}>
          <Stack horizontal tokens={{ childrenGap: 5 }} styles={{ root: { width: '95%' } }}>
            <Stack styles={{ root: { minWidth: 20, maxWidth: 20, paddingRight: '-10px' } }}>
              {!header && timestamp && (
                <IconButton
                  styles={{
                    root: {
                      backgroundColor: palette?.gray800,
                      '&.is-checked': {
                        '.ms-Icon': {
                          color: 'white',
                        },
                      },
                      '&:not(:disabled):hover': {
                        backgroundColor: palette?.gray700,
                      },
                    },
                    icon: {
                      fontSize: FontSizes.xsmall,
                      height: 18,
                      lineHeight: 18,
                      color: semanticColors?.buttonPrimaryDisabledBorder,
                    },
                  }}
                  iconName={expanded ? `ChevronDown` : `ChevronRight`}
                  onClick={() => {
                    toggleExpansion();
                  }}
                />
              )}
            </Stack>
            <Stack
              styles={{
                root: { maxWidth: columns[0].maxWidth, minWidth: columns[0].minWidth, paddingTop: header ? 5 : 0 },
              }}
            >
              <LogText content={timestamp} {...baseTextProps} />
            </Stack>
            <Stack
              styles={{
                root: { maxWidth: columns[1].maxWidth, minWidth: columns[1].minWidth, paddingTop: header ? 5 : 0 },
              }}
            >
              <LogText content={logName} {...baseTextProps} />
            </Stack>
            <Stack styles={{ root: { maxWidth: columns[2].maxWidth, minWidth: columns[2].minWidth } }}>
              {header ? null : (
                <LogText content={payload} truncate={false} highlightRegExp={highlightRegExp} {...baseTextProps} />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {expanded && (
        <Stack
          horizontalAlign="start"
          styles={{
            root: { paddingBottom: 20, paddingLeft: 97, paddingTop: 8 },
          }}
        >
          <Stack horizontal>
            <LogLabel content={'Timestamp: '} />
            <LogText content={timestamp} {...baseTextProps} />
          </Stack>
          <Stack horizontal>
            <LogLabel content={'Resource: '} />
            <LogText content={resource} {...baseTextProps} />
          </Stack>
          <Stack horizontal>
            <LogLabel content={'Log Name: '} />
            <LogText content={logName} {...baseTextProps} />
          </Stack>
          <Stack horizontal>
            <LogLabel content={'Log Source: '} />
            <LogText content={logSource} {...baseTextProps} />
          </Stack>
        </Stack>
      )}
    </div>
  );
};
