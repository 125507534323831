import { IComponent, IH2OTheme, debounce, useClassNames, useTheme } from '@h2oai/ui-kit';
import * as d3Axis from 'd3-axis';
import * as d3Scale from 'd3-scale';
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import * as d3Selection from 'd3-selection';
import { HTMLAttributes, Ref, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { IAxisClassNames, IAxisStyles, axisStylesDefault } from './Axis.styles';

export enum Location {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  CENTER = 'CENTER',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export interface MinMax {
  min: number;
  max: number;
}

export interface AxisUnit {
  yScale: d3Scale.ScaleLinear<number, number, never>;
  yAxis: d3Axis.Axis<d3Scale.NumberValue>;
  xScale: d3Scale.ScaleBand<string>;
  xAxis: d3Axis.Axis<string>;
  labels: string[];
  colorScale: d3Scale.ScaleOrdinal<string, string, never>;
  fields: string[];
  minMax: MinMax;
}

export interface AxisSize {
  width: number;
  height: number;
  rotateXAxisText?: boolean;
  xAxisTextPadding?: number;
  yAxisTextPadding?: number;
  // container - margin size for easy using.
  canvas: { width: number; height: number };
  margin: {
    left: number;
    right: number;
    bottom: number;
    top: number;
  };
}

const initSize = (el: HTMLElement, props: IAxisProps): AxisSize => {
  const { xAxisTextPadding = 0, yAxisTextPadding = 0, rotateXAxisText = false } = props;
  const width = el.offsetWidth;
  const height = el.offsetHeight;
  return {
    width,
    height,
    xAxisTextPadding,
    yAxisTextPadding,
    rotateXAxisText,
    canvas: {
      width,
      height,
    },
    margin: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  };
};

const getMinMax = (fields: Array<string>, data: Array<any>, decorationMaxRate = 1, min?: number): MinMax => {
  const mm = data.reduce(
    (minMax: MinMax, d) => {
      fields.forEach((field) => {
        const val = +d[field];
        if (typeof val === 'number' && !isNaN(val)) {
          minMax.min = Math.min(minMax.min, val);
          minMax.max = Math.max(minMax.max, val);
        }
      });
      return minMax;
    },
    { min: Infinity, max: -Infinity }
  );
  mm.max = mm.max * decorationMaxRate;
  if (min !== undefined) {
    mm.min = min;
  }
  return mm;
};

const getLabels = (data?: Array<any>, labelField?: string): Array<string> =>
  data && labelField ? data.map((d) => d[labelField]) : [];

const getUnit = (props: IAxisProps, axisSize: AxisSize): AxisUnit => {
  const {
    fields,
    data,
    decorationMaxRate,
    ticks,
    labelField,
    scalePadding = 0,
    scalePaddingInner = 0,
    scalePaddingOuter = 0,
    barWidth,
    colors = d3ScaleChromatic.schemeCategory10,
    min,
    stacked,
  } = props;
  const minMax = getMinMax(fields, data, decorationMaxRate, min);
  // y scale
  const yScale = d3Scale.scaleLinear().domain([minMax.min, minMax.max]).rangeRound([axisSize.canvas.height, 0]);
  const yAxis = d3Axis.axisLeft(yScale).ticks(ticks);

  // x scale
  const labels = getLabels(data, labelField);
  let xScale = d3Scale
    .scaleBand()
    .domain(labels)
    .range([0, axisSize.canvas.width])
    .padding(scalePadding)
    .paddingInner(scalePaddingInner)
    .paddingOuter(scalePaddingOuter);

  // apply barWidth if needed
  if (barWidth) {
    const barGroupWidth = xScale.bandwidth();
    const newBarGroupWidth = barWidth * fields.length;
    if (barGroupWidth < newBarGroupWidth) {
      const widthGap = newBarGroupWidth - barGroupWidth;
      const extraWidth = widthGap * labels.length;
      axisSize.width += extraWidth;
      axisSize.canvas.width += extraWidth;
      xScale = d3Scale
        .scaleBand()
        .domain(labels)
        .rangeRound([0, axisSize.canvas.width])
        .padding(scalePadding)
        .paddingInner(scalePaddingInner)
        .paddingOuter(scalePaddingOuter);
    }
  }

  const xAxis = d3Axis.axisBottom(xScale);
  const colorScale = stacked ? d3Scale.scaleOrdinal(colors).domain(fields) : d3Scale.scaleOrdinal(colors);

  return {
    yScale,
    yAxis,
    xScale,
    xAxis,
    labels,
    colorScale,
    fields,
    minMax,
  };
};

const renderChartContainer = (el: HTMLElement, size: AxisSize, theme: IH2OTheme) => {
  // apply margin
  el.innerHTML = '';
  const svg = d3Selection
    .select(el)
    .append('svg')
    .attr('class', 'container')
    .attr('data-test', 'container')
    .attr('width', size.width)
    .attr('height', size.height);
  d3Selection
    .select(el)
    .append('div')
    .attr('class', 'h2o-Axis-tooltip')
    .attr(
      'style',
      `position:absolute;padding:16px;opacity:0;border: 1px solid ${theme.palette?.gray300};background:white;border-radius:4px;pointer-events:none;transition:opacity 300ms;`
    );
  return svg.append('g').attr('transform', `translate(${size.margin.left},${size.margin.top})`);
};

const getSVGSize = (el: HTMLElement, selector: string) => {
  const svg: SVGSVGElement | null = el.querySelector(selector);
  return svg?.getBBox();
};

const renderAxis = (
  svg: d3Selection.Selection<SVGGElement, unknown, null, undefined>,
  location: Location,
  axis: d3Axis.Axis<d3Scale.NumberValue> | d3Axis.Axis<string>,
  classNames: string[] = [],
  size: AxisSize
) => {
  const { xAxisTextPadding = 0, yAxisTextPadding = 0, canvas, rotateXAxisText = false } = size;
  const { width, height } = canvas;
  svg = svg.append('g').attr('class', classNames.join(' ')).attr('data-test', classNames[0]);
  switch (location) {
    case Location.BOTTOM:
      svg = svg.attr('transform', `translate(0,${height + xAxisTextPadding})`).call(axis);
      if (rotateXAxisText) {
        svg.selectAll('.tick text').attr('style', 'transform: rotate(-45deg) translate(-4px,-6px);text-anchor:end;');
      }
      break;
    case Location.RIGHT:
      svg.attr('transform', `translate(${width + yAxisTextPadding},0)`).call(axis);
      break;
    case Location.LEFT:
      svg.attr('transform', `translate(-${yAxisTextPadding},0)`).call(axis);
      break;
  }
};

const renderGrid = (
  svg: d3Selection.Selection<SVGGElement, unknown, null, undefined>,
  direction: 'x' | 'y',
  axis: d3Axis.Axis<d3Scale.NumberValue> | d3Axis.Axis<string>,
  classNames: string[] = [],
  width: number,
  height: number
) => {
  svg = svg.append('g').attr('class', classNames.join(' '));
  let tickSize;
  switch (direction) {
    case 'x':
      svg = svg.attr('transform', `translate(0,${height})`);
      tickSize = -height;
      break;
    case 'y':
      tickSize = -width;
      break;
  }
  const axisWithTicks = axis.tickSize(tickSize);
  if (typeof axisWithTicks !== 'number') svg.call(axisWithTicks.tickFormat(null));
};

const getTranslateX = (el: Element) => {
  const t = el.getAttribute('transform');
  const val = t?.split('translate(')[1].split(',')[0];
  return val ? +val : 0;
};

const applyXAxisTextSize = (el: HTMLElement, size: AxisSize, className: string): AxisSize => {
  const elements = el.querySelectorAll(`.${className} .tick`);
  const elementArray = Array.from(elements);
  const tickWidth = elements.length > 1 ? getTranslateX(elements[1]) - getTranslateX(elements[0]) : -1;
  let lastTextSize: { width: number; height: number } = { width: 0, height: 0 };
  const maxWidth: number = elementArray.reduce((max: number, element: Element) => {
    const { width, height } = (element as SVGSVGElement)?.getBBox();
    lastTextSize = { width, height };
    return Math.max(lastTextSize.width, max);
  }, -Infinity);
  let textHeight = lastTextSize.height;
  if (tickWidth < maxWidth) {
    size.rotateXAxisText = true;
    textHeight = maxWidth;
  }
  size.margin.top = lastTextSize.height / 2;
  size.margin.right = maxWidth / 2;
  size.margin.bottom = (size.rotateXAxisText ? textHeight + 5 : lastTextSize.height) + (size.xAxisTextPadding || 0);
  return { ...size };
};

const getAxisSize = (
  el: HTMLElement,
  svg: d3Selection.Selection<SVGGElement, unknown, null, undefined>,
  location: Location,
  axis: d3Axis.Axis<d3Scale.NumberValue> | d3Axis.Axis<string>,
  size: AxisSize,
  classNames: string[] = []
) => {
  renderAxis(svg, location, axis, classNames, size);
  const { width } = getSVGSize(el, `.${classNames[0]}`) || { width: 0 };
  switch (location) {
    case Location.BOTTOM:
      size = applyXAxisTextSize(el, size, classNames[0]);
      break;
    case Location.LEFT:
      size.margin.left = width + (size.yAxisTextPadding || 0);
      break;
    case Location.RIGHT:
      size.margin.right = width + (size.yAxisTextPadding || 0);
  }
  return { ...size };
};

const updateSize = (size: AxisSize) => {
  const { canvas, height, width, margin } = size;
  const { top = 0, bottom = 0, left = 0, right = 0 } = margin;
  canvas.height = height - top - bottom;
  canvas.width = width - left - right;
  return { ...size };
};

const getSize = (el: HTMLElement, props: IAxisProps, theme: IH2OTheme) => {
  const { data, hasAxis } = props;
  let size = initSize(el, props);
  if (data.length === 0 || !hasAxis) {
    // 2 is a buffer for a border(stroke) width of an area chart or etc
    size.margin = {
      left: 2,
      right: 2,
      top: 2,
      bottom: 2,
    };
    size = updateSize(size);
    return { ...size };
  }
  const unit = getUnit(props, size);
  // *** render for measuring size ***
  const svg = renderChartContainer(el, size, theme);
  // left / right margin
  size = getAxisSize(el, svg, Location.LEFT, unit.yAxis, size, ['y-axis']);
  size = getAxisSize(el, svg, Location.BOTTOM, unit.xAxis, size, ['x-axis']);
  size = updateSize(size);
  // *** re-render with correct size ***
  el.innerHTML = '';
  return { ...size };
};

type TRenderChart = (
  svg: d3Selection.Selection<SVGGElement, unknown, null, undefined>,
  props: IAxisProps,
  size: AxisSize,
  unit: AxisUnit,
  el: HTMLElement,
  animate?: boolean
) => void;

const render = (
  el: HTMLElement | null,
  props: IAxisProps,
  theme: IH2OTheme,
  onRenderChart?: TRenderChart,
  animate?: boolean
) => {
  if (!el) {
    return;
  }
  el.innerHTML = '';
  const size = getSize(el, props, theme);
  const { hasGrid, hasAxis, hasDomain, hasTicks } = props;
  const { canvas } = size;
  const { width, height } = canvas;
  const unit = getUnit(props, size);
  const svg = renderChartContainer(el, size, theme);
  if (hasGrid) {
    renderGrid(svg, 'y', unit.yAxis, ['y-grid'], width, height);
  }
  if (hasAxis) {
    renderAxis(svg, Location.LEFT, unit.yAxis, ['y-axis'], size);
    renderAxis(svg, Location.BOTTOM, unit.xAxis, ['x-axis'], size);
  }
  if (onRenderChart) {
    onRenderChart(svg, props, size, unit, el, animate);
  }

  if (hasGrid) {
    svg.select(`.y-grid .domain`).remove();
    svg.select(`.x-grid .domain`).remove();
  }

  if (!hasDomain) {
    svg.select(`.y-axis .domain`).remove();
    svg.select(`.x-axis .domain`).remove();
  }

  if (!hasTicks) {
    svg.selectAll(`.y-axis .tick line`).remove();
    svg.selectAll(`.x-axis .tick line`).remove();
  }
};

export const showHideAxisTooltip = (e: MouseEvent, el: HTMLElement | null, message: string, hidden = false) => {
  const tooltipEl = el?.querySelector('.h2o-Axis-tooltip') as HTMLElement;
  if (tooltipEl) {
    if (!hidden) {
      tooltipEl.innerHTML = message;
      tooltipEl.style.left = `${e.offsetX}px`;
      tooltipEl.style.top = `${e.offsetY - tooltipEl.offsetHeight}px`;
      tooltipEl.style.opacity = '.9';
    } else {
      tooltipEl.style.opacity = '0';
      tooltipEl.innerHTML = '';
      tooltipEl.style.left = `-10000px`;
    }
  }
};

export const renderTooltipArea = (
  svg: d3Selection.Selection<SVGGElement, unknown, null, undefined>,
  props: IAxisProps,
  size: AxisSize,
  unit: AxisUnit,
  el: HTMLElement
) => {
  const { data, hasTooltip } = props;
  if (!hasTooltip) {
    return;
  }
  const xScaleInner = d3Scale.scaleBand().domain(unit.fields).rangeRound([0, unit.xScale.bandwidth()]).padding(0);
  const selection = svg
    .append('g')
    .selectAll('g')
    .data(unit.labels)
    .enter()
    .append('g')
    .attr('class', 'group tooltip-areas')
    .attr('data-test', 'tooltip-areas')
    .attr('transform', (label: string) => `translate(${unit.xScale(label)},0)`);
  const tooltipAreaSelections = selection
    .selectAll('tooltip-area')
    .data((_d, i) => unit.fields.map((field) => ({ field, value: +data[i][field] })));
  // for updating data
  tooltipAreaSelections.exit().remove();
  const tooltipAreas = tooltipAreaSelections.enter().append('rect');
  tooltipAreas
    .attr('class', 'item tooltip-area')
    .attr('width', () => xScaleInner.bandwidth())
    .attr('x', (d) => xScaleInner(d.field) || 0)
    .attr('y', (d) => (d.value > 0 ? unit.yScale(d.value) : unit.yScale(0)))
    .attr('height', (d) => {
      const h =
        unit.minMax.min < 0
          ? Math.abs(unit.yScale(d.value) - unit.yScale(0))
          : size.canvas.height - unit.yScale(d.value);
      return h < 0 ? 0 : h;
    })
    .attr('fill', 'transparent')
    .on('mouseenter', (e, d) => showHideAxisTooltip(e, el, `${d.field}: ${d.value}`))
    .on('mouseleave', (e) => showHideAxisTooltip(e, el, '', true));
};
export interface IAxisProps extends IComponent<IAxisStyles>, HTMLAttributes<HTMLDivElement> {
  labelField?: string;
  fields: string[];
  hasAxis?: boolean;
  hasDomain?: boolean;
  hasGrid?: boolean;
  hasTicks?: boolean;
  hasTooltip?: boolean;
  hasAnimation?: boolean;
  ticks?: number;
  xAxisTextPadding?: number;
  yAxisTextPadding?: number;
  scalePadding?: number;
  scalePaddingInner?: number;
  scalePaddingOuter?: number;
  decorationMaxRate?: number;
  data: { [key: string]: any }[];
  colors?: string[];
  rotateXAxisText?: boolean;
  min?: number;
  responsive?: boolean;
  watchWidth?: number; // when the container re-rendered and the container width is changed, the chart will be re-rendered.
  // bar
  barWidth?: number;
  stacked?: boolean;
  onRenderChart?: TRenderChart;
}

export const Axis = forwardRef((props: IAxisProps, ref: Ref<HTMLDivElement>) => {
  /* eslint-disable @typescript-eslint/no-unused-vars
     -- elementProps should have only the props of an element. The below props are not for the element.
     They should not be in ...elementProps. */
  const {
    styles,
    className,
    labelField,
    fields,
    hasAxis,
    hasDomain,
    hasGrid,
    hasTicks = true,
    hasTooltip,
    hasAnimation,
    ticks,
    xAxisTextPadding,
    yAxisTextPadding,
    scalePadding,
    scalePaddingInner,
    scalePaddingOuter,
    decorationMaxRate,
    data,
    colors,
    rotateXAxisText,
    min,
    responsive,
    watchWidth,
    barWidth,
    stacked,
    onRenderChart,
    ...elementProps
  } = props;
  const theme = useTheme();
  const refRoot = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => refRoot.current as HTMLDivElement);
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [prevData, setPrevData] = useState<{ [key: string]: any }[] | undefined>();
  const classNames = useClassNames<Partial<IAxisStyles>, IAxisClassNames>('Axis', axisStylesDefault, styles, className);
  const updateSize = useMemo(
    () =>
      debounce(300, () => {
        if (windowWidth !== window.innerHeight) setWindowWidth(window.innerWidth);
      }),
    []
  );
  useEffect(() => {
    if (refRoot.current) {
      refRoot.current.innerHTML = '';
    }
    updateSize();
    if (responsive) {
      window.removeEventListener('resize', updateSize);
      window.addEventListener('resize', updateSize);
    }
    return () => {
      if (responsive) window.removeEventListener('resize', updateSize);
    };
  }, [watchWidth, updateSize]);
  useEffect(() => {
    const showAnimation = data?.length !== prevData?.length;
    render(refRoot.current, props, theme, onRenderChart, showAnimation);
    if (showAnimation) setPrevData(data);
  }, [data, windowWidth, onRenderChart]);
  return <div {...elementProps} ref={refRoot} className={classNames.root} data-test={props['data-test'] || 'axis'} />;
});
