import { IComponent, useClassNames } from '@h2oai/ui-kit';
import markdownit from 'markdown-it';
import { memo } from 'react';

import { ClassNamesFromIStyles } from '../../utils/models';
import { IMarkdownStyles, markdownStylesDefault } from './Markdown.styles';

export interface IMarkdownProps extends IComponent<IMarkdownStyles> {
  inline?: boolean;
  source: string;
  unsafe?: boolean;
}

export const markdown = markdownit({ html: true, linkify: true, typographer: true }),
  markdownSafe = markdownit({ typographer: true, linkify: true }),
  Markdown = memo(({ inline, source, unsafe }: IMarkdownProps) => {
    const classNames = useClassNames<IMarkdownStyles, ClassNamesFromIStyles<IMarkdownStyles>>(
        'Markdown',
        markdownStylesDefault
      ),
      renderer = unsafe ? markdown : markdownSafe,
      htmlString = inline ? renderer.renderInline(source) : renderer.render(source);
    return <div className={classNames.root} dangerouslySetInnerHTML={{ __html: htmlString }} />;
  });
