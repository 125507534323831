import { Icon } from '@fluentui/react';
import { IconName, useClassNames } from '@h2oai/ui-kit';

import {
  IDisplayAndIdRuleField,
  IDisplayAndIdRuleFieldClassNames,
  displayAndIdRuleFieldStyle,
} from './DisplayAndIdRuleField.style';

export type DisplayAndIdRuleFieldProps = {
  text: string;
  isValid: boolean;
};

export function DisplayAndIdRuleField({ text, isValid }: DisplayAndIdRuleFieldProps) {
  const classNames = useClassNames<IDisplayAndIdRuleField, IDisplayAndIdRuleFieldClassNames>(
    'DisplayAndIdRuleField',
    displayAndIdRuleFieldStyle
  );
  return (
    <div className={classNames.root}>
      <Icon className={classNames.icon} iconName={isValid ? IconName.CheckMark : IconName.Cancel} />
      <div className={isValid ? classNames.labelValid : classNames.labelInvalid}>{text}</div>
    </div>
  );
}
