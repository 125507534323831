/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as fm from "../../../../../fetch.pb"
import * as AiH2oCloudDiscoveryV1Service from "./service.pb"
export type GetServiceRequest = {
  name?: string
}

export type GetServiceResponse = {
  service?: AiH2oCloudDiscoveryV1Service.Service
}

export type ListServicesRequest = {
  pageSize?: number
  pageToken?: string
}

export type ListServicesResponse = {
  services?: AiH2oCloudDiscoveryV1Service.Service[]
  nextPageToken?: string
}

export class ServiceService {
  static GetService(req: GetServiceRequest, initReq?: fm.InitReq): Promise<GetServiceResponse> {
    return fm.fetchReq<GetServiceRequest, GetServiceResponse>(`/v1/${req["nameservices"]}?${fm.renderURLSearchParams(req, ["nameservices"])}`, {...initReq, method: "GET"})
  }
  static ListServices(req: ListServicesRequest, initReq?: fm.InitReq): Promise<ListServicesResponse> {
    return fm.fetchReq<ListServicesRequest, ListServicesResponse>(`/v1/services?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
}