import { AboutDialog, ComponentVersion } from '@h2oai/ui-kit';
import logo from '@h2oai/ui-kit/lib/assets/images/logo.svg';
import headerBackgroundImage from '@h2oai/ui-kit/lib/assets/images/modalBanner.png';
import { useCallback, useEffect, useState } from 'react';

import { getEnvironment, listServices } from '../../discovery-service/api';
import { Service } from '../../discovery-service/gen/ai/h2o/cloud/discovery/v1/service.pb';
import { GetEnvironmentUtilResponse } from '../../discovery-service/models';

export interface HaicAboutModalProps {
  hidden?: boolean;
  onDismiss?: () => void;
}

enum fieldProps {
  DISPLAYNAME = 'displayName',
  VERSION = 'version',
}

export function HaicAboutModal(props: HaicAboutModalProps) {
  const { hidden, onDismiss } = props,
    [services, setServices] = useState<Service[]>([]),
    [cloudVersion, setCloudVersion] = useState<string>(''),
    [versionInfo, setVersionInfo] = useState<string>(''),
    loadService = useCallback(async () => {
      const servicesList = await listServices({ pageSize: 1000 });
      if (servicesList) {
        const sortedServicesList = servicesList
          .map((service) => {
            return {
              ...service,
              displayName: service.displayName ? service.displayName : service.name,
              version: service.version ? service.version : 'Unknown version',
            };
          })
          .filter((value, index, self) => {
            return (
              value.displayName &&
              index === self.findIndex((t) => t.displayName === value.displayName && t.version === value.version)
            );
          })
          .sort((a, b) =>
            (a[fieldProps.DISPLAYNAME] || '')
              .toLowerCase()
              .localeCompare((b[fieldProps.DISPLAYNAME] || '').toLowerCase())
          );

        setServices(sortedServicesList);
      }

      const env = await getEnvironment();
      const cloudVersionPropertyValue = env?.h2oCloudVersion || '';
      setCloudVersion(cloudVersionPropertyValue);

      createVersionInfo(env, servicesList);
    }, []),
    createVersionInfo = useCallback((env?: GetEnvironmentUtilResponse, servicesList?: Service[]) => {
      const versionList = [];
      if (env) {
        versionList.push(env);
      }

      if (servicesList) {
        versionList.push(...servicesList);
      }

      setVersionInfo(JSON.stringify(versionList));
    }, []);

  useEffect(() => {
    loadService();
  }, []);

  return (
    <AboutDialog
      services={services as ComponentVersion[]}
      cloudVersion={cloudVersion}
      versionInfo={versionInfo}
      logoUrl={logo}
      licenceUrl={'/license/'}
      headerBackgroundImageUrl={headerBackgroundImage}
      hidden={hidden}
      onDismiss={onDismiss}
    />
  );
}
