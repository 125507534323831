import { useClassNames } from '@h2oai/ui-kit';
import { ChangeEvent, TextareaHTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ClassNamesFromIStyles } from '../../utils/models';
import { ICodeAreaStyles, codeAreaStylesDefault, codeAreaStylesFullWidth } from './CodeArea.styles';

export { type ICodeAreaStyles, codeAreaStylesFullWidth };

// highly optimized to support huge files
export const countNewLines = (str: string) => {
  let count = 0;
  let i = -1;
  while (-1 !== (i = str.indexOf(`\n`, i + 1))) count++;
  return count;
};

interface ICodeAreaProps
  extends Exclude<TextareaHTMLAttributes<HTMLTextAreaElement>, `ref` | `wrap` | `onInput` | `onScroll`> {
  styles?: Partial<ICodeAreaStyles>;
}

export function CodeArea({ className, cols = 40, rows = 10, styles, ...other }: ICodeAreaProps) {
  const lineNoRef = useRef<HTMLTextAreaElement>();
  const synchronizeScrollToLineNo = useCallback((event) => {
    if (lineNoRef.current) {
      lineNoRef.current.scrollTop = event.target.scrollTop;
    }
  }, []);

  const [newLineCount, setNewLineCount] = useState(() =>
    countNewLines((other.value as string) || (other.defaultValue as string) || ``)
  );
  const lineNoContent = useMemo(() => {
    return Array.from({ length: newLineCount + 1 })
      .map((_, i) => i + 1)
      .join(`\n`);
  }, [newLineCount]);
  const controlledComponent = `value` in other;
  const updateLineNoContent = controlledComponent
    ? other.onInput
    : (event: ChangeEvent<HTMLTextAreaElement>) => {
        setNewLineCount(countNewLines(event.target.value));
      };
  useEffect(() => {
    if (controlledComponent) {
      setNewLineCount(countNewLines((other.value as string) || ``));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [other.value]);

  const classNames = useClassNames<ICodeAreaStyles, ClassNamesFromIStyles<ICodeAreaStyles>>(
    'CodeArea',
    codeAreaStylesDefault,
    styles,
    className
  );

  return (
    <div className={classNames.root}>
      <textarea
        {...other}
        className={classNames.codeEditor}
        cols={cols}
        onInput={updateLineNoContent}
        onScroll={synchronizeScrollToLineNo}
        rows={rows}
        wrap="off"
      />
      <textarea
        aria-hidden={true}
        className={classNames.lineNo}
        cols={1 /* will increase when needed */}
        disabled
        ref={lineNoRef as any}
        value={lineNoContent}
        wrap="off"
      />
    </div>
  );
}
