import { IDropdownOption, Stack } from '@fluentui/react';
import {
  CommonStyles,
  Dropdown,
  FontSizes,
  TextWithCopy,
  TextWithCopyType,
  getDate,
  itemStylesCategory,
  textWithCopyStylesBorder,
  textWithCopyStylesFill,
  textWithCopyStylesPlain,
  useClassNames,
} from '@h2oai/ui-kit';
import { CSSProperties, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { App, App_Visibility, Tag } from '../../ai.h2o.cloud.appstore';
import { IAppConfigPanelContentsStyles, appConfigPanelContentsStylesDefault } from './AppConfigPanelContents.styles';

function InfoSection(props: { title: string; children: React.ReactNode }) {
  const classNames = useClassNames<Partial<IAppConfigPanelContentsStyles>, IAppConfigPanelContentsClassNames>(
    'AppConfigPanelContents',
    appConfigPanelContentsStylesDefault
  );

  return (
    <>
      <div className={classNames.infoTitle}>{`${props.title}:`}</div>
      <div className={classNames.infoText}>{props.children}</div>
    </>
  );
}

function formatDate(dateString: string): string {
  const date = getDate(dateString);
  return date
    ? new Intl.DateTimeFormat('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      }).format(date)
    : 'Unknown date';
}

export interface IAppConfigPanelContentsProps {
  app: App;
  tags: Tag[];
  onChange: (
    visibility: App_Visibility,
    selectedCategoryIds: string[],
    selectedBadgeIds: string[],
    selectedTagIds: string[]
  ) => void;
}

interface IAppConfigPanelContentsClassNames {
  root: string;
  infos: string;
  info: string;
  infoTitle: string;
  infoText: string;
  field: string;
}

const onTagChange = (
  option: IDropdownOption | undefined,
  keys: string[],
  setKeys: Dispatch<SetStateAction<string[]>>
): string[] => {
  if (option) {
    const values = option.selected ? [...keys, option.key as string] : keys.filter((key) => key !== option.key);
    setKeys(values);
    return values;
  }
  return keys;
};

function buildTomlSnippet(selectedTagNames: string[]): string {
  if (selectedTagNames.length === 0) return '';
  return `Tags = [${selectedTagNames.map((name) => `"${name}"`).join(', ')}]`;
}

interface TagIdsAndOptions {
  selectedCategoryIds: string[];
  selectedBadgeIds: string[];
  selectedTagIds: string[];
  categoryOptions: IDropdownOption[];
  badgeOptions: IDropdownOption[];
  tagOptions: IDropdownOption[];
}

const getSelectedIdsAndOptions = (selectedIds: Set<string>, tags: Tag[]): TagIdsAndOptions => {
  return tags.reduce(
    (ko: TagIdsAndOptions, tag) => {
      const { id, title, name, isCategory, visitorRoles } = tag;
      //logic is from https://github.com/h2oai/h2o-ai-cloud/blob/master/internal/commands/printer.go#L401
      const ids = isCategory
        ? ko.selectedCategoryIds
        : visitorRoles.length > 0
        ? ko.selectedTagIds
        : ko.selectedBadgeIds;
      const options = isCategory ? ko.categoryOptions : visitorRoles.length > 0 ? ko.tagOptions : ko.badgeOptions;
      if (selectedIds.has(id)) {
        ids.push(id);
      }
      options.push({ key: id, text: title || name, data: tag });
      return ko;
    },
    {
      selectedCategoryIds: [],
      selectedBadgeIds: [],
      selectedTagIds: [],
      categoryOptions: [],
      badgeOptions: [],
      tagOptions: [],
    }
  );
};

export const visibilityToTitle = (visibility: string) =>
  visibility
    .toLowerCase()
    .split('_')
    .map((s) => `${s[0].toUpperCase()}${s.slice(1)}`)
    .join(' ');

const getVisibilityOptions = (): IDropdownOption[] => {
  return Object.keys(App_Visibility)
    .filter((i) => i !== App_Visibility.VISIBILITY_UNSPECIFIED)
    .map((key) => ({
      key,
      text: visibilityToTitle(key),
    }));
};

const onRenderTagOption = (option: IDropdownOption | undefined): JSX.Element => {
  return option ? (
    <div className="dropdown-option" style={{ ...(CommonStyles.truncateString as CSSProperties) }}>
      <span className="dropdown-option__text">
        {option.text || (
          <>
            Unnamed <span style={{ fontSize: FontSizes.xxsmall }}>({option.data.id})</span>
          </>
        )}
      </span>
    </div>
  ) : (
    <div></div>
  );
};

export function AppConfigPanelContents({ app, tags, onChange }: IAppConfigPanelContentsProps) {
  const classNames = useClassNames<Partial<IAppConfigPanelContentsStyles>, IAppConfigPanelContentsClassNames>(
    'AppConfigPanelContents',
    appConfigPanelContentsStylesDefault
  );
  const selectedTagIdSet = useMemo(() => new Set(app.tags.map((t) => t.id)), [app]);
  const { selectedBadgeIds, selectedCategoryIds, selectedTagIds, categoryOptions, tagOptions, badgeOptions } =
    getSelectedIdsAndOptions(selectedTagIdSet, tags);
  const [visibility, setVisibility] = useState<App_Visibility>(app.visibility);
  const [selectedTagKeys, setSelectedTagKeys] = useState<string[]>([]);
  const [selectedBadgeKeys, setSelectedBadgeKeys] = useState<string[]>([]);
  const [selectedCategoryKeys, setSelectedCategoryKeys] = useState<string[]>([]);
  const selectedTagNames = useMemo(() => {
    const allKeys = [...selectedTagKeys, ...selectedBadgeKeys, ...selectedCategoryKeys];
    return tags.filter((tag) => allKeys.includes(tag.id)).map((tag) => tag.name);
  }, [selectedTagKeys, selectedBadgeKeys, selectedCategoryKeys, tags]);
  useEffect(() => {
    if (tags.length) {
      setVisibility(app.visibility);
      setSelectedTagKeys(selectedTagIds);
      setSelectedBadgeKeys(selectedBadgeIds);
      setSelectedCategoryKeys(selectedCategoryIds);
    }
    // eslint-disable-next-line
  }, [app, tags]);

  return (
    <div className={classNames.root}>
      <div className={classNames.infos}>
        <InfoSection title="App Name">
          <TextWithCopy styles={[textWithCopyStylesPlain, textWithCopyStylesFill]} text={app.name} />
        </InfoSection>
        <InfoSection title="App ID">
          <TextWithCopy styles={[textWithCopyStylesPlain, textWithCopyStylesFill]} text={app.id} />
        </InfoSection>
        <InfoSection title="Version">{`v${app.version}`}</InfoSection>
        <InfoSection title="Description">{app.description || 'No description provided'}</InfoSection>
        <InfoSection title="Created At">{formatDate(app.createTime)}</InfoSection>
        <InfoSection title="Updated At">{formatDate(app.updateTime)}</InfoSection>
      </div>
      <div className={classNames.field}>
        <Dropdown
          label="Visibility"
          placeholder="Select Visibility"
          options={getVisibilityOptions()}
          selectedKey={visibility}
          onChange={(_e, option) => {
            if (option) {
              const v = option.key as App_Visibility;
              setVisibility(option.key as App_Visibility);
              onChange(v, selectedCategoryKeys, selectedBadgeKeys, selectedTagKeys);
            }
          }}
        />
      </div>
      <div className={classNames.field}>
        <Dropdown
          selectedItemStyles={itemStylesCategory}
          label="Categories"
          placeholder="Select Categories"
          options={categoryOptions}
          multiSelect
          selectedKeys={selectedCategoryKeys}
          onRenderOption={onRenderTagOption}
          onChange={(_e, option) => {
            const keys = onTagChange(option, selectedCategoryKeys, setSelectedCategoryKeys);
            onChange(visibility, keys, selectedBadgeKeys, selectedTagKeys);
          }}
        />
      </div>
      <div className={classNames.field}>
        <Dropdown
          selectedItemStyles={itemStylesCategory}
          label="Badges"
          placeholder="Select Badges"
          options={badgeOptions}
          multiSelect
          selectedKeys={selectedBadgeKeys}
          onRenderOption={onRenderTagOption}
          onChange={(_e, option) => {
            const keys = onTagChange(option, selectedBadgeKeys, setSelectedBadgeKeys);
            onChange(visibility, selectedCategoryKeys, keys, selectedTagKeys);
          }}
        />
      </div>
      <div className={classNames.field}>
        <Dropdown
          selectedItemStyles={itemStylesCategory}
          label="Authorization Tags"
          placeholder="Select Authorization Tags"
          options={tagOptions}
          multiSelect
          selectedKeys={selectedTagKeys}
          onRenderOption={onRenderTagOption}
          onChange={(_e, option) => {
            const keys = onTagChange(option, selectedTagKeys, setSelectedTagKeys);
            onChange(visibility, selectedCategoryKeys, selectedBadgeKeys, keys);
          }}
        />
      </div>
      {selectedTagNames.length > 0 && (
        <Stack>
          <span>Tag future versions of this app automatically by adding the following to your configuration file:</span>
          <TextWithCopy
            styles={[textWithCopyStylesBorder, { root: { paddingTop: 8 } }]}
            text={buildTomlSnippet(selectedTagNames)}
            hasBorder
            type={TextWithCopyType.code}
          />
        </Stack>
      )}
    </div>
  );
}
