/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/dai_version_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RpcStatus,
    RpcStatusFromJSON,
    RpcStatusToJSON,
    V1ListDAIVersionsResponse,
    V1ListDAIVersionsResponseFromJSON,
    V1ListDAIVersionsResponseToJSON,
} from '../models';

export interface DAIVersionServiceListDAIVersionsRequest {
    pageSize?: number;
    pageToken?: string;
    orderBy?: string;
    filter?: string;
}

/**
 * 
 */
export class DAIVersionServiceApi extends runtime.BaseAPI {

    /**
     * Returns a collection of DAIEngines within a workspace.
     */
    async dAIVersionServiceListDAIVersionsRaw(requestParameters: DAIVersionServiceListDAIVersionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1ListDAIVersionsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['pageToken'] = requestParameters.pageToken;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/daiVersions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1ListDAIVersionsResponseFromJSON(jsonValue));
    }

    /**
     * Returns a collection of DAIEngines within a workspace.
     */
    async dAIVersionServiceListDAIVersions(requestParameters: DAIVersionServiceListDAIVersionsRequest, initOverrides?: RequestInit): Promise<V1ListDAIVersionsResponse> {
        const response = await this.dAIVersionServiceListDAIVersionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
