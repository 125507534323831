import { SortCriterion, sortByMultiple } from '@h2oai/ui-kit';

import { App } from '../../ai.h2o.cloud.appstore';

export enum BaseCategory {
  PinnedApps = 'Pinned Apps',
  MostPopular = 'Most Popular',
  Other = 'Other',
}

export enum SortBy {
  pinned = 'pinned',
  popularity = 'popularity',
  likes = 'likes',
  title = 'title',
  createTime = 'createTime',
}

export type SortFunctions = {
  [K in keyof typeof SortBy]: (a: App, b: App) => number;
};

export const defaultSort = SortBy.popularity,
  pinnedSort: SortCriterion<App> = [['preference', 'pinned'], 'boolean', true],
  popularitySort: SortCriterion<App> = [['preference', 'popularity'], 'number', true],
  likesSort: SortCriterion<App> = [['preference', 'likes'], 'number', true],
  likedSort: SortCriterion<App> = [['preference', 'liked'], 'boolean', true],
  titleSort: SortCriterion<App> = [['title']],
  dateSort: SortCriterion<App> = [['createTime'], 'date', true],
  secondarySort = [titleSort, dateSort],
  onSort: SortFunctions = {
    [SortBy.popularity]: sortByMultiple<App>([popularitySort, ...secondarySort]),
    [SortBy.pinned]: sortByMultiple<App>([pinnedSort, ...secondarySort]),
    [SortBy.likes]: sortByMultiple<App>([likesSort, ...secondarySort]),
    [SortBy.title]: sortByMultiple<App>([...secondarySort]),
    // theoretically, we would never get 2 apps with the exact same creation date, so no need for secondary sort
    [SortBy.createTime]: sortByMultiple<App>([dateSort]),
  };

export type AppCategory = {
  name: string;
  title: string;
  apps: App[];
  count: number;
};

export type SeeAllProps = {
  show: boolean;
  value?: any;
};
