import { Loader, loaderStylesSpinnerXLarge } from '@h2oai/ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import { Protected } from './Protected/Protected';

function AuthPage() {
  const auth = useAuth();
  const logout = useCallback(async () => {
    await auth.removeUser();
    await auth.signoutRedirect({ id_token_hint: auth.user?.id_token });
  }, []);
  const [haveCookie, setHaveCookie] = useState<boolean>(false);

  useEffect(() => {
    if (!auth.isAuthenticated && !auth.isLoading) {
      auth.signinRedirect();
    }
  }, [auth.isLoading, auth.isAuthenticated]);
  useEffect(() => {
    return auth.events.addAccessTokenExpired(() => {
      if (auth.settings.automaticSilentRenew) {
        auth.signinSilent().catch(async (_error: any) => {
          logout();
        });
      } else {
        logout();
      }
    });
  }, []);
  useEffect(() => {
    if (auth.user?.id_token) {
      const controller = new AbortController();
      const signal = controller.signal;
      fetch('/auth/session', {
        method: 'GET',
        signal,
      })
        .then(() => {
          setHaveCookie(true);
        })
        .catch((error) => {
          console.error('There was an error obtaining the session cookie:', error);
        });
      return () => {
        controller.abort();
      };
    }
    return;
  }, [auth.user?.id_token]);

  // TODO: how to handle logout on one tab, while other tab is still open?

  return auth.user?.access_token && haveCookie ? (
    <Protected />
  ) : (
    <Loader styles={loaderStylesSpinnerXLarge} label="Loading..." />
  );
}

export default AuthPage;
