import { INavLink, INavLinkGroup } from '@fluentui/react';
import { Nav, Sizes } from '@h2oai/ui-kit';
import { MouseEvent } from 'react';

import { ALL_CATEGORY } from '../../../../utils/models';
import { AppCategory } from '../../AppStorePage.models';

export interface IAppCategoryListProps {
  onFilterChange: (filterKey: string) => void;
  selected: string;
  appsCategories: AppCategory[];
  appsCount: number;
}

export function AppCategoryList({ onFilterChange, selected, appsCategories, appsCount }: IAppCategoryListProps) {
  const onClick = (ev?: MouseEvent<HTMLElement>, item?: INavLink) => {
      ev?.preventDefault();
      const value = item?.key;
      onFilterChange((value as string) || ALL_CATEGORY);
    },
    groups: INavLinkGroup[] = [
      {
        name: 'Categories',
        links: [{ name: ALL_CATEGORY, title: 'All', count: appsCount }, ...appsCategories].map(
          ({ name, title, count }) => {
            const disabled = !count;
            // TODO: When we get the correct icons, we will add that.
            return {
              'data-test': `${name}--category-link`,
              name: `${title}${count ? ` (${count})` : ''}`,
              url: '',
              key: name,
              onClick,
              disabled,
            };
          }
        ),
      },
    ],
    onRenderGroupHeader = (group?: INavLinkGroup) => <h3>{group?.name || ''}</h3>;
  return (
    <Nav
      styles={{
        link: {
          paddingLeft: Sizes.padding,
          '.ms-Button-flexContainer': {
            paddingLeft: 0,
          },
        },
      }}
      selectedKey={selected}
      onRenderGroupHeader={onRenderGroupHeader}
      groups={groups}
    />
  );
}
