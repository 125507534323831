import { BasicList, IEmptyMessageProps, Link, WidgetItem, WidgetPanel, linkStylesGhost } from '@h2oai/ui-kit';

import { App } from '../../../ai.h2o.cloud.appstore';

export interface IAppListWidgetProps {
  data: App[];
  title: string;
  loadingMessage?: string;
  link: string;
}

export const AppListWidget = ({ title, data, loadingMessage, link }: IAppListWidgetProps) => {
  const emptyMessageProps: IEmptyMessageProps | undefined =
    !data || !data.length ? { message: `You don't have any apps` } : undefined;
  return (
    <WidgetPanel
      title={title}
      linkText="View all"
      linkHref={link}
      loadingMessage={loadingMessage}
      emptyMessageProps={emptyMessageProps}
    >
      <BasicList
        data={data}
        itemRenderer={(d: App, _i, last) => {
          const appHref = `/apps/${d.id}`;
          return (
            <WidgetItem<App>
              idField="id"
              titleField="title"
              imagePathField="iconLocation"
              descriptionField="description"
              last={last}
              data={d}
              imageHref={appHref}
              titleHref={appHref}
              actions={
                <Link styles={linkStylesGhost} href={appHref}>
                  View
                </Link>
              }
            />
          );
        }}
      />
    </WidgetPanel>
  );
};
