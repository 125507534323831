import { IDropdownOption, MessageBarType } from '@fluentui/react';
import { Dropdown, useToast } from '@h2oai/ui-kit';
import { FormEvent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { App_Visibility, FederatedApp, Tag, TagAssignment } from '../../../ai.h2o.cloud.appstore';
import { AdminAppService, AdminTagService } from '../../../services/api';
import { appVisibilityMap, visibilityOptions } from '../../../utils/utils';

const arrayAdd: <I, A extends Array<I>>(item: I) => (array: A) => A = (item) => (array) => array.concat(item) as any;

const arrayRemoveFirst: <I, A extends Array<I>>(item: I) => (array: A) => A = (item) => (array) => {
  const idx = array.indexOf(item);
  if (idx === -1) return array;
  const newArray = array.slice();
  newArray.splice(idx, 1);
  return newArray as any;
};

const getCategoryIdsFromApp = (app: FederatedApp): string[] => app.tags?.map((category) => category.id) || [];

type TagLike = Tag | TagAssignment;
const tagToOption = (tag: TagLike): IDropdownOption<TagLike> => ({
  key: tag.id,
  text: tag.title,
  data: tag,
});

const dropdownStyles = {
  styles: {
    dropdown: { maxWidth: `50ch` },
  },
};

export interface IAppConfigurationPureProps {
  categories: TagLike[];
  categoriesLoading: boolean;
  onCategoriesChange: (event: FormEvent<HTMLDivElement>, tagOption: IDropdownOption<TagLike> | undefined) => void;
  onVisibilityChange: (
    event: FormEvent<HTMLDivElement>,
    visibilityOption: IDropdownOption<undefined> | undefined
  ) => void;
  selectedCategories: TagLike[`id`][] | undefined;
  selectedVisibility: App_Visibility | undefined;
  visibilityLoading: boolean;
}

export function AppConfigurationPure({
  categories,
  categoriesLoading,
  onCategoriesChange,
  onVisibilityChange,
  selectedCategories,
  selectedVisibility,
  visibilityLoading,
}: IAppConfigurationPureProps) {
  return (
    <>
      <Dropdown
        aria-busy={visibilityLoading}
        label="Visible to"
        onChange={onVisibilityChange}
        options={visibilityOptions}
        selectedKey={selectedVisibility}
        {...dropdownStyles}
      />
      <Dropdown
        aria-busy={categoriesLoading}
        label="Category"
        multiSelect
        onChange={onCategoriesChange}
        options={categories.map(tagToOption)}
        selectedKeys={selectedCategories}
        {...dropdownStyles}
      />
    </>
  );
}

export type RequestEvent =
  | { type: `categoryAssign`; payload: TagLike }
  | { type: `categoryUnassign`; payload: TagLike }
  | { type: `visibilityUpdate`; payload: App_Visibility };

function useAppConfiguration(app: FederatedApp) {
  const { id: appId } = app;
  const [loading, setLoading] = useState<Array<`category` | `visibility`>>([]);
  const [categories, setCategories] = useState<TagLike[]>(() => {
    // the full list of all possible categories must be fetched before it appears in the dropdown,
    // if the app has some categories selected, we can use them initially, the dropdown will
    // temporarily contain just the selected categories, and it won't be possible to pick more, but
    // this at least will show the selected categories immediately.
    return app.tags || [];
  });
  useEffect(() => {
    setLoading(arrayAdd(`category`));
    AdminTagService.listCategories({})
      .finally(() => {
        setLoading(arrayRemoveFirst(`category`));
      })
      .then(({ categories }) => setCategories(categories));
  }, []);
  const sendRequest = (event: RequestEvent) => {
    switch (event.type) {
      case 'categoryAssign':
        setLoading(arrayAdd(`category`));
        return AdminTagService.assignTag({ appId, id: event.payload.id }).finally(() => {
          setLoading(arrayRemoveFirst(`category`));
        });
      case 'categoryUnassign':
        setLoading(arrayAdd(`category`));
        return AdminTagService.unassignTag({ appId, id: event.payload.id }).finally(() => {
          setLoading(arrayRemoveFirst(`category`));
        });
      case 'visibilityUpdate':
        setLoading(arrayAdd(`visibility`));
        return AdminAppService.updateApp({ id: appId, visibility: event.payload }).finally(() => {
          setLoading(arrayRemoveFirst(`visibility`));
        });
      default:
        throw new Error(
          `The request handler in AppConfiguration encountered unsupported event:\n\n${JSON.stringify(event, null, 2)}`
        );
    }
  };
  return {
    categories,
    categoriesLoading: loading.includes(`category`),
    sendRequest,
    visibilityLoading: loading.includes(`visibility`),
  };
}

function requestEventToErrorMessage(event: RequestEvent): string {
  switch (event.type) {
    case 'categoryAssign':
      return `Setting of the category "${event.payload.title}" failed.`;
    case 'categoryUnassign':
      return `Unsetting of the category "${event.payload.title}" failed.`;
    case 'visibilityUpdate':
      return `Setting of the visibility "${appVisibilityMap[event.payload]}" failed.`;
  }
}

export function requestEventsFromChanges(
  previous: { categories: TagLike[]; visibility: App_Visibility },
  current: { categories: TagLike[]; visibility: App_Visibility }
): RequestEvent[] {
  const actions: RequestEvent[] = [];
  if (current.visibility !== previous.visibility) {
    actions.push({ type: `visibilityUpdate`, payload: current.visibility });
  }
  const previousCategoriesIds = previous.categories.map((c) => c.id);
  current.categories.forEach((currCategory) => {
    if (!previousCategoriesIds.includes(currCategory.id)) {
      actions.push({ type: `categoryAssign`, payload: currCategory });
    }
  });
  const currentCategoriesIds = current.categories.map((c) => c.id);
  previous.categories.forEach((prevCategory) => {
    if (!currentCategoriesIds.includes(prevCategory.id)) {
      actions.push({ type: `categoryUnassign`, payload: prevCategory });
    }
  });
  return actions;
}

export interface IAppConfigurationSaveOnChangeProps {
  app: FederatedApp;
  refetchApp: () => Promise<any>;
}

export function AppConfigurationSaveOnChange({ app, refetchApp }: IAppConfigurationSaveOnChangeProps) {
  const { addToast } = useToast(),
    { categories, categoriesLoading, sendRequest, visibilityLoading } = useAppConfiguration(app);

  const [selectedCategories, setSelectedCategories] = useState<string[]>(() => getCategoryIdsFromApp(app)),
    onCategoryChange = (_event: any, tagOption: IDropdownOption<TagLike> | undefined): void => {
      if (!tagOption) {
        // clearing the tags is currently impossible in the UI.
        return;
      }
      const category = tagOption.data!;
      // optimistic response approach
      if (tagOption.selected) {
        const requestEvent: RequestEvent = { type: `categoryAssign`, payload: category };
        setSelectedCategories(arrayAdd(category.id));
        sendRequest(requestEvent).then(refetchApp, () => {
          setSelectedCategories(arrayRemoveFirst(category.id));
          addToast({
            messageBarType: MessageBarType.error,
            message: requestEventToErrorMessage(requestEvent),
          });
        });
      } else {
        const requestEvent: RequestEvent = { type: `categoryUnassign`, payload: category };
        setSelectedCategories(arrayRemoveFirst(category.id));
        sendRequest(requestEvent).then(refetchApp, () => {
          setSelectedCategories(arrayAdd(category.id));
          addToast({
            messageBarType: MessageBarType.error,
            message: requestEventToErrorMessage(requestEvent),
          });
        });
      }
    };

  const appRef = useRef<FederatedApp>();
  appRef.current = app;
  const updateVisibilityPromiseRef = useRef<Promise<any>>(),
    [selectedVisibility, setSelectedVisibility] = useState<App_Visibility>(app.visibility),
    onVisibilityChange = (_event: any, visibilityOption: IDropdownOption<undefined> | undefined) => {
      if (!visibilityOption) {
        // empty visibilityOption is currently impossible via a UI.
        return;
      }
      const visibility = visibilityOption!.key as App_Visibility;
      // optimistic response approach
      setSelectedVisibility(visibility);
      const requestEvent: RequestEvent = { type: `visibilityUpdate`, payload: visibility };
      const updateVisibilityPromise = sendRequest(requestEvent).then(refetchApp, () => {
        if (updateVisibilityPromiseRef.current !== updateVisibilityPromise) {
          // during the fetch the visibility was changed once again which makes this promise
          // obsolete.
          return;
        }
        addToast({
          messageBarType: MessageBarType.error,
          message: requestEventToErrorMessage(requestEvent),
        });
        setSelectedVisibility(appRef.current!.visibility);
      });
      updateVisibilityPromiseRef.current = updateVisibilityPromise;
    };

  return (
    <AppConfigurationPure
      categories={categories}
      categoriesLoading={categoriesLoading}
      onCategoriesChange={onCategoryChange}
      onVisibilityChange={onVisibilityChange}
      selectedCategories={selectedCategories}
      selectedVisibility={selectedVisibility}
      visibilityLoading={visibilityLoading}
    />
  );
}

export interface IAppConfigurationSaveOnSubmitProps {
  app: FederatedApp;
  refetchApp: () => Promise<any>;
  render: (renderProps: {
    content: ReactElement;
    handleSubmit: () => Promise<void>;
    isChanged: boolean;
  }) => ReactElement;
}

export function AppConfigurationSaveOnSubmit({ app, refetchApp, render }: IAppConfigurationSaveOnSubmitProps) {
  const { addToast } = useToast();
  const { categories, categoriesLoading, sendRequest, visibilityLoading } = useAppConfiguration(app);

  const [selectedCategories, setSelectedCategories] = useState<string[]>(() => getCategoryIdsFromApp(app));
  const onCategoryChange = (_event: any, tagOption: IDropdownOption<TagLike> | undefined): void => {
    if (!tagOption) {
      // clearing the tags is currently impossible in the UI.
      return;
    }
    const category = tagOption.data!;
    if (tagOption.selected) {
      setSelectedCategories(arrayAdd(category.id));
    } else {
      setSelectedCategories(arrayRemoveFirst(category.id));
    }
  };

  const [selectedVisibility, setSelectedVisibility] = useState<App_Visibility>(app.visibility);
  const onVisibilityChange = (_event: any, visibilityOption: IDropdownOption<undefined> | undefined) => {
    if (!visibilityOption) {
      // empty visibilityOption is currently impossible via a UI.
      return;
    }
    setSelectedVisibility(visibilityOption!.key as App_Visibility);
  };

  const categoriesById = useMemo(
    () =>
      categories.reduce((acc, category) => {
        acc[category.id] = category;
        return acc;
      }, {} as Record<string, TagLike>),
    [categories]
  );
  const requestEvents = useMemo(() => {
    const previous = {
      categories: getCategoryIdsFromApp(app)
        .map((categoryId) => categoriesById[categoryId])
        .filter(Boolean),
      visibility: app.visibility,
    };
    const current = {
      categories: selectedCategories.map((categoryId) => categoriesById[categoryId]).filter(Boolean),
      visibility: selectedVisibility,
    };
    return requestEventsFromChanges(previous, current);
  }, [app, categoriesById, selectedCategories, selectedVisibility]);

  const handleSubmit = useCallback(() => {
    return Promise.allSettled(requestEvents.map(sendRequest)).then((results) => {
      const errorMessages: string[] = results
        .map(({ status }, idx) => {
          if (status === `fulfilled`) return ``;
          const failedRequestEvent = requestEvents[idx];
          return requestEventToErrorMessage(failedRequestEvent);
        })
        .filter(Boolean);
      const someRequestsFulfilled = results.length > errorMessages.length;
      if (someRequestsFulfilled) {
        refetchApp();
      }
      errorMessages.forEach((message) => {
        addToast({ messageBarType: MessageBarType.error, message });
      });
    });
  }, [requestEvents, sendRequest, refetchApp, addToast]);

  const content = (
    <AppConfigurationPure
      categories={categories}
      categoriesLoading={categoriesLoading}
      onCategoriesChange={onCategoryChange}
      onVisibilityChange={onVisibilityChange}
      selectedCategories={selectedCategories}
      selectedVisibility={selectedVisibility}
      visibilityLoading={visibilityLoading}
    />
  );

  return render({ content, handleSubmit, isChanged: requestEvents.length > 0 });
}
