/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/dai_version_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * DAIVersion describes Driverless AI version.
 * @export
 * @interface V1DAIVersion
 */
export interface V1DAIVersion {
    /**
     * Unique identifier of Driverless AI version.
     * For example "1.10.1.2".
     * @type {string}
     * @memberof V1DAIVersion
     */
    readonly version?: string;
    /**
     * Aliases for the specified Driverless AI version.
     * For example {"latest", "prerelease"}.
     * @type {Array<string>}
     * @memberof V1DAIVersion
     */
    readonly aliases?: Array<string>;
    /**
     * Indicates whether DAIVersion is deprecated.
     * @type {boolean}
     * @memberof V1DAIVersion
     */
    readonly deprecated?: boolean;
    /**
     * Additional arbitrary metadata associated with the DAIVersion.
     * 
     * Annotations are key/value pairs.
     * The key must:
     * - be 63 characters or less
     * - begin and end with an alphanumeric character ([a-z0-9A-Z])
     * - with dashes (-), underscores (_), dots (.), and alphanumerics between
     * - regex used for validation is: ^[A-Za-z0-9]([-A-Za-z0-9_.]{0,61}[A-Za-z0-9])?$
     * @type {{ [key: string]: string; }}
     * @memberof V1DAIVersion
     */
    annotations?: { [key: string]: string; };
}

export function V1DAIVersionFromJSON(json: any): V1DAIVersion {
    return V1DAIVersionFromJSONTyped(json, false);
}

export function V1DAIVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1DAIVersion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': !exists(json, 'version') ? undefined : json['version'],
        'aliases': !exists(json, 'aliases') ? undefined : json['aliases'],
        'deprecated': !exists(json, 'deprecated') ? undefined : json['deprecated'],
        'annotations': !exists(json, 'annotations') ? undefined : json['annotations'],
    };
}

export function V1DAIVersionToJSON(value?: V1DAIVersion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annotations': value.annotations,
    };
}

