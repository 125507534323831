import { useTheme } from '@h2oai/ui-kit';

export interface StatsCardProps {
  title: string;
  stat: string | number;
  linkHref?: string;
}

const StatsCard = (props: StatsCardProps) => {
  const theme = useTheme(),
    palette = theme.palette;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: palette?.white,
        height: 120,
        width: 188,
        borderRadius: 4,
        marginTop: 28,
        padding: 16,
      }}
    >
      <span style={{ color: palette?.gray500 }}>{props.title}</span>
      <span style={{ color: palette?.gray900, fontWeight: 500, fontSize: 32, textAlign: 'right' }}>{props.stat}</span>
    </div>
  );
};

export default StatsCard;
