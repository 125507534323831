import { IStyle } from '@fluentui/react';
import { Sizes } from '@h2oai/ui-kit';

export interface ILeftPanelStyles {
  root?: IStyle;
}

export const leftPanelStylesDefault: Partial<ILeftPanelStyles> = {
  root: {
    width: Sizes.navWidth + Sizes.pagesSidePadding,
    paddingLeft: Sizes.pagesSidePadding,
    paddingTop: Sizes.contentPaddingTop,
    overflow: 'auto',
  },
};
