/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/engine_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1Engine,
    V1EngineFromJSON,
    V1EngineFromJSONTyped,
    V1EngineToJSON,
} from './V1Engine';

/**
 * Response message for EngineService.ListEngines.
 * @export
 * @interface V1ListEnginesResponse
 */
export interface V1ListEnginesResponse {
    /**
     * Collection of Engines.
     * @type {Array<V1Engine>}
     * @memberof V1ListEnginesResponse
     */
    engines?: Array<V1Engine>;
    /**
     * Used to retrieve the next page of results.
     * When unset, no further items are available (this response was the last page).
     * @type {string}
     * @memberof V1ListEnginesResponse
     */
    nextPageToken?: string;
    /**
     * Total number of Engines matched by the List request.
     * @type {number}
     * @memberof V1ListEnginesResponse
     */
    totalSize?: number;
}

export function V1ListEnginesResponseFromJSON(json: any): V1ListEnginesResponse {
    return V1ListEnginesResponseFromJSONTyped(json, false);
}

export function V1ListEnginesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ListEnginesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'engines': !exists(json, 'engines') ? undefined : ((json['engines'] as Array<any>).map(V1EngineFromJSON)),
        'nextPageToken': !exists(json, 'nextPageToken') ? undefined : json['nextPageToken'],
        'totalSize': !exists(json, 'totalSize') ? undefined : json['totalSize'],
    };
}

export function V1ListEnginesResponseToJSON(value?: V1ListEnginesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'engines': value.engines === undefined ? undefined : ((value.engines as Array<any>).map(V1EngineToJSON)),
        'nextPageToken': value.nextPageToken,
        'totalSize': value.totalSize,
    };
}

