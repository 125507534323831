/*
 * The `UsageService` generated by protoc-gen-grpc-gateway-ts does not send properly formatted requests.
 * This is a workaround until an updated version of the package properly generates the service.
 */

import {
  CalculateMaxUsageResponse,
  CalculateUsageResponse,
  Granularity,
} from './gen/ai/h2o/cloud/telemetry_read_api/v1/aiunits.pb';
import * as fm from './gen/fetch.pb';

export type CalculateUsageRequest = {
  startTime: string;
  endTime: string;
  granularity: Granularity;
  component: string;
  pageSize?: number;
  pageToken?: string;
};

export type CalculateMaxUsageRequest = {
  component: string;
  startTime: string;
  endTime: string;
};

interface ParsedUsageRequest {
  component: string;
  params: CalculateUsageRequest | CalculateMaxUsageRequest;
}

function parseRequest(req: CalculateUsageRequest | CalculateMaxUsageRequest) {
  const { component = 'components/global', ...params } = req;
  return { component, params } as ParsedUsageRequest;
}

export class TelemetryUsageService {
  static CalculateUsage(req: CalculateUsageRequest, initReq?: fm.InitReq): Promise<CalculateUsageResponse> {
    const { component, params } = parseRequest(req);
    return fm.fetchReq<CalculateUsageRequest, CalculateUsageResponse>(
      `/v1/${component}:calculateUsage?${fm.renderURLSearchParams(params)}`,
      { ...initReq, method: 'GET' }
    );
  }
  static CalculateMaxUsage(req: CalculateMaxUsageRequest, initReq?: fm.InitReq): Promise<CalculateMaxUsageResponse> {
    const { component, params } = parseRequest(req);
    return fm.fetchReq<CalculateMaxUsageRequest, CalculateMaxUsageResponse>(
      `/v1/${component}:calculateMaxUsage?${fm.renderURLSearchParams(params)}`,
      { ...initReq, method: 'GET' }
    );
  }
}
