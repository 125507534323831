/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/h2o_engine_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1H2OEngineSize,
    V1H2OEngineSizeFromJSON,
    V1H2OEngineSizeFromJSONTyped,
    V1H2OEngineSizeToJSON,
} from './V1H2OEngineSize';

/**
 * Response message for H2OEngine sizing request from a compressed dataset dimensions.
 * @export
 * @interface V1CalculateH2OEngineSizeCompressedDatasetResponse
 */
export interface V1CalculateH2OEngineSizeCompressedDatasetResponse {
    /**
     * 
     * @type {V1H2OEngineSize}
     * @memberof V1CalculateH2OEngineSizeCompressedDatasetResponse
     */
    h2oEngineSize?: V1H2OEngineSize;
}

export function V1CalculateH2OEngineSizeCompressedDatasetResponseFromJSON(json: any): V1CalculateH2OEngineSizeCompressedDatasetResponse {
    return V1CalculateH2OEngineSizeCompressedDatasetResponseFromJSONTyped(json, false);
}

export function V1CalculateH2OEngineSizeCompressedDatasetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1CalculateH2OEngineSizeCompressedDatasetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'h2oEngineSize': !exists(json, 'h2oEngineSize') ? undefined : V1H2OEngineSizeFromJSON(json['h2oEngineSize']),
    };
}

export function V1CalculateH2OEngineSizeCompressedDatasetResponseToJSON(value?: V1CalculateH2OEngineSizeCompressedDatasetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'h2oEngineSize': V1H2OEngineSizeToJSON(value.h2oEngineSize),
    };
}

