import { MessageBarType, Stack } from '@fluentui/react';
import { Button, Loader, loaderStylesSpinnerXLarge, useTheme, useToast } from '@h2oai/ui-kit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AIEMOpType, AIEngine, EngineVersion, V1Engine, V1EngineState, V1EngineType, getIdFromName } from '../../aiem';
import { FilterCondition, useEngine, useEngineQueryParams } from '../../aiem/hooks';
import ListPage from '../../components/ListPages/ListPage';
import { stackStylesPage } from '../../themes/themes';
import { useCloudPlatformDiscovery, useDebouncedCallback } from '../../utils/hooks';
import { AIEMConfirmDialog } from './components/AIEMConfirmDialog/AIEMConfirmDialog';
import { AIEMPanel } from './components/AIEMPanel/AIEMPanel';
import { AIEMUpgradeDialog } from './components/AIEMUpgradeDialog/AIEMUpgradeDialog';
import { EngineList } from './components/EngineList/EngineList';
import FilterListActions from './components/FilterListActions';
import { TypeVersionSelection, VersionSelection, conditionsFromParams } from './filter.utils';

const useGetEngines = (
  fetchEngines: (filter?: FilterCondition[]) => Promise<V1Engine[] | undefined>,
  options: { refetchInterval?: number } = {}
) => {
  const [loading, setLoading] = useState(true);
  const [engines, setEngines] = useState<V1Engine[] | undefined>(undefined);
  const [triggerRefetch, setTriggerRefetch] = useState(0);
  const refetch = useCallback(() => setTriggerRefetch((i) => i + 1), []);
  const {
    params,
    setSearchTermParam: setSearchTermHook,
    setTypeVersions: setTypeVersionsHook,
    setStates: setStatesHook,
  } = useEngineQueryParams();
  const conditions = useMemo(() => conditionsFromParams(params), [params]);
  const optionsRef = useRef(options);
  optionsRef.current = options;
  const setSearchTerm = useCallback(
    (search: string) => {
      setLoading(true);
      setSearchTermHook(search);
    },
    [setLoading, setSearchTermHook]
  );
  const setTypeVersions = useCallback(
    (value: TypeVersionSelection) => {
      setLoading(true);
      setTypeVersionsHook(value);
    },
    [setLoading, setTypeVersionsHook]
  );

  const setStates = useCallback(
    (states: string[]) => {
      setLoading(true);
      setStatesHook(states);
    },
    [setLoading, setStatesHook]
  );

  useEffect(() => {
    const stale = { current: false };
    const timer = { current: 0 };
    fetchEngines(conditions)
      .finally(() => {
        if (stale.current) return;
        setLoading(false);
        if (optionsRef.current?.refetchInterval) {
          timer.current = window.setTimeout(refetch, optionsRef.current.refetchInterval);
        }
      })
      .then(
        (list) => {
          if (stale.current) return;
          setEngines(list);
        },
        () => {}
      );
    return () => {
      stale.current = true;
      if (timer.current) {
        window.clearTimeout(timer.current);
      }
    };
  }, [conditions, triggerRefetch]);
  return { data: engines, loading, refetch, params, setSearchTerm, setTypeVersions, setStates };
};

type AIEMPanelState = {
  op: AIEMOpType | null;
  isOpen: boolean;
  engine?: AIEngine;
};

type ConfirmDialogType = {
  op: AIEMOpType.pause | AIEMOpType.delete | AIEMOpType.resume;
};

interface AIEnginesPageProps {
  title: string;
  fetchEngines: (filter?: FilterCondition[]) => Promise<V1Engine[] | undefined>;
  admin?: boolean;
}

const checkEngineStateForBulkAction = ({ type, state }: V1Engine, op: AIEMOpType) =>
  type !== V1EngineType.H2O &&
  ((op === AIEMOpType.pause && state === V1EngineState.Paused) ||
    (op === AIEMOpType.resume && state === V1EngineState.Running));

function AIEnginesPage(props: AIEnginesPageProps) {
  const { fetchEngines, admin } = props,
    { palette } = useTheme(),
    { addToast } = useToast(),
    history = useHistory(),
    location = useLocation(),
    platformDiscovery = useCloudPlatformDiscovery(),
    { listVersions, opOnEngine } = useEngine(),
    [selectedEngineIds, setSelectedEngineIds] = useState<string[]>([]),
    engines = useGetEngines(fetchEngines, { refetchInterval: 5_000 }),
    [panel, setPanel] = useState<AIEMPanelState>({ op: AIEMOpType.create, isOpen: false }),
    [dialog, setDialog] = useState<ConfirmDialogType | null>(null),
    [upgrade, setUpgrade] = useState<AIEMOpType.upgrade | null>(null),
    [actionEngine, setActionEngine] = useState<AIEngine | undefined>(),
    [bulkActionEngine, setBulkActionEngine] = useState<AIEngine[]>([]),
    [typeVersionSelection, setTypeVersionSelection] = useState<TypeVersionSelection>({} as TypeVersionSelection),
    readLogs = useCallback(
      ({ name }: AIEngine) => {
        history.push(`/logs/?name=${name}`);
      },
      [history]
    ),
    openLegacyEngineLogs = useCallback(
      ({ name, type }: AIEngine) => {
        const id = `${getIdFromName(name)}`;
        history.push(`${location.pathname}/log/${type}/${id}`);
      },
      [history, location]
    ),
    successToast = useCallback(
      (displayName: string, verb?: string, op?: AIEMOpType) => {
        const complement =
          op === AIEMOpType.create ? 'has been successfully created and it is now starting!' : `${verb} successfully!`;
        addToast({
          messageBarType: MessageBarType.success,
          message: `Engine "${displayName}" ${complement}`,
        });
      },
      [addToast]
    ),
    bulkSuccessToast = useCallback(
      (verb: string, itemCount: number) => {
        const mainTitle = `${String(itemCount)} ${itemCount > 1 ? 'items' : 'item'} ${verb} successfully!`;
        addToast({
          messageBarType: MessageBarType.success,
          message: mainTitle,
        });
      },
      [selectedEngineIds, addToast]
    ),
    versions = engines.params.versions,
    castVersionSelection = (acc: VersionSelection, { key, type }: EngineVersion): VersionSelection => {
      return {
        ...acc,
        [key!]: type! in versions && key! in versions[type!] && versions[type!][key!],
      } as VersionSelection;
    },
    getVersions = useCallback(async () => {
      const [daiVersions, h2oVersions] = await Promise.all([
        await listVersions(V1EngineType.DriverlessAi, false),
        await listVersions(V1EngineType.H2O, false),
      ]);

      const [daiVersionSelections, h2oVersionSelections] = await Promise.all([
        daiVersions.reduce(castVersionSelection, {}),
        h2oVersions.reduce(castVersionSelection, {}),
      ]);

      const _selection: TypeVersionSelection = {
        [V1EngineType.DriverlessAi]: daiVersionSelections,
        [V1EngineType.H2O]: h2oVersionSelections,
      };
      setTypeVersionSelection(_selection);
    }, []),
    editEngine = useCallback(
      async (engine: AIEngine) => {
        const freshEngine = await opOnEngine(engine, AIEMOpType.get),
          { engineType } = engine;
        setPanel({
          op: AIEMOpType.edit,
          isOpen: !!freshEngine,
          engine: ({ ...freshEngine, engineType } as AIEngine) || engine,
        });
      },
      [opOnEngine]
    ),
    viewEngine = useCallback(
      async (engine: AIEngine) => {
        const { engineType } = engine;
        const freshEngine = await opOnEngine(engine, AIEMOpType.get);
        setPanel({
          op: AIEMOpType.view,
          isOpen: !!freshEngine,
          engine: ({ ...freshEngine, engineType } as AIEngine) || engine,
        });
      },
      [opOnEngine]
    ),
    pauseConfirmedEngine = useCallback(
      async (engine: AIEngine) => {
        const res = await opOnEngine(engine, AIEMOpType.pause);
        setDialog(null);
        if (res) successToast(String(engine.displayName), 'is pausing');
      },
      [successToast, opOnEngine, setDialog]
    ),
    deleteConfirmedEngine = useCallback(
      async (engine: AIEngine) => {
        const res = await opOnEngine(engine, AIEMOpType.delete);
        setDialog(null);
        if (res) successToast(String(engine.displayName), 'is deleting');
      },
      [successToast, opOnEngine]
    ),
    actionEngineErrorMessage = (actionName = 'Action') => {
      addToast({
        messageBarType: MessageBarType.severeWarning,
        message: `${actionName} can't be performed`,
        title: `Can't load engine data.`,
      });
    },
    onConfirmDialog = useCallback(async () => {
      if (!actionEngine?.op) {
        actionEngineErrorMessage();
        return;
      }
      const { op } = actionEngine;
      if (op === AIEMOpType.pause) await pauseConfirmedEngine(actionEngine as AIEngine);
      if (op === AIEMOpType.delete) await deleteConfirmedEngine(actionEngine as AIEngine);
      engines.refetch();
    }, [actionEngine, addToast, engines.refetch, pauseConfirmedEngine, deleteConfirmedEngine]),
    onConfirmBulkDialog = useCallback(async () => {
      if (!bulkActionEngine || !dialog || !dialog.op) {
        actionEngineErrorMessage();
        return;
      }
      if (dialog?.op) {
        setDialog(null);
        const actionWaitList: Promise<any>[] = [];
        try {
          selectedEngineIds.forEach(async (uid) => {
            const engine = engines.data?.find((e) => e.uid === uid);
            if (engine && engine.state !== V1EngineState.Failed && checkEngineStateForBulkAction(engine, dialog.op)) {
              const actionEngine = { ...engine, engineType: engine.type } as AIEngine;
              actionWaitList.push(opOnEngine(actionEngine, dialog.op));
            }
          });

          const res = await Promise.all(actionWaitList);
          if (res && dialog.op === AIEMOpType.pause) bulkSuccessToast('paused', actionWaitList.length);
          if (res && dialog.op === AIEMOpType.delete) bulkSuccessToast('deleted', actionWaitList.length);
          if (res && dialog.op === AIEMOpType.resume) bulkSuccessToast('resumed', actionWaitList.length);
        } catch (error) {
          actionEngineErrorMessage();
        }

        engines.refetch();

        setBulkActionEngine([]);
      }
    }, [dialog, engines.refetch, pauseConfirmedEngine, deleteConfirmedEngine]),
    onConfirmUpgradeDialog = useCallback(
      async (newVersion: string) => {
        if (!actionEngine?.op) {
          actionEngineErrorMessage('Upgrade');
          return;
        }
        actionEngine.engineNewVersion = newVersion;
        const res = await opOnEngine(actionEngine as AIEngine, AIEMOpType.upgrade);
        setUpgrade(null);
        if (res) successToast(String(actionEngine.displayName), 'was upgraded');
      },
      [actionEngine, addToast, opOnEngine]
    ),
    deleteEngine = useCallback(
      (e: AIEngine) => {
        const op = AIEMOpType.delete;
        setBulkActionEngine([]);
        setSelectedEngineIds([]);
        setActionEngine({ ...e, op });
        setDialog({ op });
      },
      [setDialog]
    ),
    upgradeEngine = useCallback(
      (e: AIEngine) => {
        const op = AIEMOpType.upgrade;
        setActionEngine({ ...e, op });
        setUpgrade(op);
      },
      [setDialog]
    ),
    resumeEngine = useCallback(
      async (engine: AIEngine) => {
        setBulkActionEngine([]);
        setSelectedEngineIds([]);
        const res = await opOnEngine(engine, AIEMOpType.resume);
        if (res) successToast(String(engine.displayName), 'is resuming');
        engines.refetch();
      },
      [engines.refetch, successToast, opOnEngine]
    ),
    pauseEngine = useCallback(
      (e: AIEngine) => {
        setBulkActionEngine([]);
        setSelectedEngineIds([]);
        const op = AIEMOpType.pause;
        setActionEngine({ ...e, op });
        setDialog({ op });
      },
      [setDialog, setActionEngine]
    ),
    bulkAction = useCallback(
      async (op: AIEMOpType.delete | AIEMOpType.pause | AIEMOpType.resume) => {
        const selectedEngines =
          (engines.data?.filter(({ uid }) => uid && selectedEngineIds.includes(uid)) as AIEngine[]) || [];
        setBulkActionEngine(selectedEngines);
        setDialog({ op });
      },
      [selectedEngineIds]
    ),
    onDismissDialog = useCallback(() => {
      setDialog(null);
    }, [setDialog]),
    onDismissUpgradeDialog = useCallback(() => {
      setUpgrade(null);
    }, [setUpgrade]),
    onCreateClick = () => {
      setPanel({ op: AIEMOpType.create, isOpen: true });
    },
    dismissPanel = useCallback(() => {
      setPanel({ op: null, isOpen: false, engine: undefined });
    }, []),
    onChange = useCallback((engine: AIEngine) => setActionEngine(engine), []),
    onSavePanel = useCallback(async () => {
      if (panel.op === AIEMOpType.create) {
        const res = await opOnEngine(actionEngine, AIEMOpType.create);
        if (res) successToast(String(actionEngine!.displayName), '', AIEMOpType.create);
      }
      if (panel.op === AIEMOpType.edit) {
        const res = await opOnEngine(actionEngine, AIEMOpType.update);
        if (res) successToast(String(actionEngine!.displayName), 'updated');
      }
      dismissPanel();
      engines.refetch();
    }, [actionEngine, engines.refetch, panel.op, successToast, opOnEngine]);

  useEffect(() => {
    getVersions();
  }, []);

  const debouncedSetSearchParam = useDebouncedCallback(engines.setSearchTerm, 300);
  const [searchKey, setSearchKey] = useState(engines.params.searchTerm);
  const onChangeSearchInput = useCallback(
    (_, value) => {
      const newValue = value || '';
      setSearchKey(newValue);
      debouncedSetSearchParam(newValue);
    },
    [debouncedSetSearchParam, setSearchKey]
  );

  return (
    <ListPage
      title={props.title}
      subtitle={`You have ${engines.data?.length || 0} AI engines`}
      primaryButtonProps={{
        text: 'Create AI Engine',
        onClick: onCreateClick,
      }}
      filterActions={
        <FilterListActions
          selection={typeVersionSelection}
          onChangeTypeVersions={engines.setTypeVersions}
          onChangeStates={engines.setStates}
          onClear={() => {
            setSearchKey('');
            engines.setSearchTerm('');
            engines.setTypeVersions({});
            engines.setStates([]);
          }}
        />
      }
      showData={!!(platformDiscovery?.aiEngineManagerApiUrl && engines.data)}
      listActions={
        <Button
          disabled={selectedEngineIds.length <= 0}
          text="Actions"
          menuItems={[
            {
              'data-test': 'bulk-resume-engine-button',
              key: 'bulk-resume-engine',
              text: 'Resume',
              onClick() {
                bulkAction(AIEMOpType.resume);
              },
            },
            {
              'data-test': 'bulk-pause-engine-button',
              key: 'bulk-pause-engine',
              text: 'Pause',
              onClick() {
                bulkAction(AIEMOpType.resume);
              },
            },
            {
              'data-test': 'bulk-delete-engine-button',
              key: 'bulk-delete-engine',
              text: 'Delete',
              style: { color: palette?.red500 },
              onClick() {
                bulkAction(AIEMOpType.resume);
              },
            },
          ]}
        />
      }
      searchBoxProps={{
        value: searchKey,
        onChange: onChangeSearchInput,
        placeholder: 'Search by Engine Display Name or ID',
        onClear: undefined, // otherwise it wont trigger onChange
      }}
    >
      {platformDiscovery?.aiEngineManagerApiUrl ? (
        !engines.data ? (
          <Loader styles={loaderStylesSpinnerXLarge} label={'Loading AI Engines'} />
        ) : (
          <>
            {panel.isOpen && (
              <AIEMPanel
                onDismiss={dismissPanel}
                op={panel.op || AIEMOpType.create}
                engine={panel.engine!}
                onChange={onChange}
                onSave={onSavePanel}
              />
            )}
            {engines.loading ? (
              <Loader styles={loaderStylesSpinnerXLarge} label={'Loading AI Engines'} />
            ) : (
              <EngineList
                engines={engines.data}
                viewEngine={viewEngine}
                editEngine={editEngine}
                resumeEngine={resumeEngine}
                pauseEngine={pauseEngine}
                deleteEngine={deleteEngine}
                upgradeEngine={upgradeEngine}
                admin={admin}
                readLogs={readLogs}
                openLegacyEngineLogs={openLegacyEngineLogs}
                selectedEngineIds={selectedEngineIds}
                setSelectedEngineIds={setSelectedEngineIds}
              />
            )}
            <AIEMConfirmDialog
              hidden={!dialog}
              engines={bulkActionEngine.length > 0 ? bulkActionEngine : actionEngine ? [actionEngine] : []}
              op={dialog?.op || AIEMOpType.pause}
              onDismiss={onDismissDialog}
              onConfirm={bulkActionEngine.length > 0 ? onConfirmBulkDialog : onConfirmDialog}
            />
            <AIEMUpgradeDialog
              hidden={!upgrade}
              engine={actionEngine}
              onDismiss={onDismissUpgradeDialog}
              onConfirm={onConfirmUpgradeDialog}
            />
          </>
        )
      ) : (
        <Stack styles={stackStylesPage}>
          <h2 style={{ textAlign: 'center' }}>Sorry, AI Engine Manager has not been enabled for this environment.</h2>
        </Stack>
      )}
    </ListPage>
  );
}

export default AIEnginesPage;
