import { Stack } from '@fluentui/react';
import {
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  IButtonStyles,
  IColumn,
  IDetailsFooterProps,
  IDetailsListStyles,
  IRawStyle,
  ITextField,
  SelectionMode,
  mergeStyleSets,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Button, ConfirmDialog, TextField, buttonStylesSmall } from '@h2oai/ui-kit';
import { useMemo, useRef, useState } from 'react';

type Config = Record<string, string>;

const stylesActionButton: Partial<IButtonStyles> = {
  icon: { margin: 0 },
};

const stylesActionButtonSmall = mergeStyleSets(stylesActionButton, buttonStylesSmall);

const stylesScrollParent: IRawStyle = {
  display: `flex`,
  flexDirection: `column`,
  overflow: `hidden`,
};

const stylesPairsListDetailsList: Partial<IDetailsListStyles> = {
  root: [
    stylesScrollParent,
    {
      '& .ms-DetailsRow': { blockSize: `auto` },
      '& [role="grid"]': stylesScrollParent,
    },
  ],
  contentWrapper: [stylesScrollParent, { overflowY: `auto` }],
};

// https://docs.h2o.ai/h2o-ai-cloud/developerguide/cli#creating-and-updating-private-secrets
const regExpConstructor = new RegExp('(^[a-zA-Z0-9-._]*$)', 'gm');

function PairsListAddRow(props: IDetailsFooterProps & { onAddItem: (keyValuePair: [string, string]) => void }) {
  const [key, setKey] = useState(``),
    [value, setValue] = useState(``),
    [newKeyValueError, setNewKeyValueError] = useState<string | undefined>(),
    keyFieldRef = useRef<ITextField>(null),
    clearFields = () => {
      setKey(``);
      setValue(``);
    },
    item = [
      // eslint-disable-next-line react/jsx-key
      <TextField
        ariaLabel="new config key"
        onChange={(_e, newValue) => {
          if (newValue && newValue.length <= 253 && newValue.match(regExpConstructor)) {
            setKey(newValue);
            setNewKeyValueError(undefined);
          } else {
            if (!newValue || newValue.length === 1) {
              setKey('');
            }
            setNewKeyValueError('aA/zZ, "-", "_", "." and max length 253');
          }
        }}
        errorMessage={newKeyValueError}
        ref={keyFieldRef}
        placeholder="key"
        value={key}
      />,
      // eslint-disable-next-line react/jsx-key
      <TextField
        ariaLabel="new config value"
        onChange={(event: any) => {
          setValue(event.target.value);
        }}
        placeholder="simple value"
        value={value}
      />,
      // eslint-disable-next-line react/jsx-key
      <Button
        disabled={!key || !value}
        iconName="add"
        data-test="add-row"
        onClick={() => {
          clearFields();
          props.onAddItem([key, value]);
          keyFieldRef.current?.focus();
        }}
        styles={stylesActionButton}
        title="Add new row"
      />,
    ];

  return <DetailsRow {...props} styles={{ root: { minBlockSize: `4rem` } }} item={item} itemIndex={-1} />;
}

export type PairsListProps = {
  config: Config;
  isEditable: boolean;
  onModifyItem: (keyValuePair: [string, string]) => void;
  onRemoveItem: (key: string) => void;
};

export function PairsList({ onModifyItem, onRemoveItem, config, isEditable }: PairsListProps) {
  const items = useMemo(() => Object.entries(config), [config]),
    [hideDeleteConfirmDialog, { toggle: toggleHideDeleteConfirmDialog }] = useBoolean(true),
    [validConfirmDialog, { setTrue: isValid, setFalse: isInvalid }] = useBoolean(false),
    valueFieldRef = useRef<[string, string]>(['', '']),
    originalValueRef = useRef<string>(''),
    columns = useMemo<IColumn[]>(
      () => [
        {
          key: `key`,
          name: `Key`,
          fieldName: `0`,
          minWidth: 140,
          flexGrow: 1,
        },
        {
          key: `value`,
          name: `Encoded value`,
          fieldName: `1`,
          minWidth: 140,
          flexGrow: 1,
        },
        {
          key: `action`,
          name: `Action`,
          minWidth: 80,
          onRender: (item: typeof items[0]) => {
            return (
              <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 8 }}>
                {(item as any)[2] || (
                  <>
                    {!isEditable && (
                      <Button
                        iconName={'delete'}
                        onClick={() => {
                          onRemoveItem(item[0]);
                        }}
                        styles={stylesActionButtonSmall}
                        title={'Delete row'}
                      />
                    )}
                    <Button
                      iconName={'edit'}
                      onClick={() => {
                        const newItem: [string, string] = [...item];
                        valueFieldRef.current = newItem;
                        originalValueRef.current = newItem[1];
                        isInvalid();
                        toggleHideDeleteConfirmDialog();
                      }}
                      styles={stylesActionButtonSmall}
                      title={'Edit row'}
                    />
                  </>
                )}
              </Stack>
            );
          },
        },
      ],
      [onModifyItem]
    );

  return (
    <>
      <DetailsList
        columns={columns}
        items={items}
        onRenderDetailsFooter={(detailsFooterProps) => (
          <PairsListAddRow {...detailsFooterProps!} onAddItem={onModifyItem} />
        )}
        onShouldVirtualize={() => false}
        selectionMode={SelectionMode.none}
        styles={stylesPairsListDetailsList}
        layoutMode={DetailsListLayoutMode.justified}
      />
      <ConfirmDialog
        hidden={hideDeleteConfirmDialog}
        onDismiss={toggleHideDeleteConfirmDialog}
        title={valueFieldRef.current[0]}
        confirmationButtonDisabled={!validConfirmDialog}
        content={
          <TextField
            label={'Key value (It will be encoded after saving.)'}
            onChange={(_, newValue) => {
              if (newValue && valueFieldRef.current[1] !== newValue && originalValueRef.current !== newValue) {
                valueFieldRef.current[1] = newValue || '';
                if (!validConfirmDialog) isValid();
              } else {
                if (validConfirmDialog) isInvalid();
              }
            }}
          ></TextField>
        }
        onConfirm={() => {
          onModifyItem(valueFieldRef.current);
          toggleHideDeleteConfirmDialog();
        }}
        confirmationButtonText="Save"
      ></ConfirmDialog>
    </>
  );
}
