import { useBoolean } from '@fluentui/react-hooks';
import { ConfirmDialog, Dropdown, FontSizes, useToast } from '@h2oai/ui-kit';
import { useCallback, useEffect, useRef, useState } from 'react';

import { App, AppPreconditionStatus, App_Visibility, Secret, Secret_Visibility } from '../ai.h2o.cloud.appstore';
import ListPage from '../components/ListPages/ListPage';
import {
  ManagedSecretsList,
  getSecretUniqueId,
  visibilityOptions,
} from '../components/ManagedSecretsList/ManagedSecretsList';
import {
  ISecretConfigPanelProps,
  SecretConfigPanel,
  SecretConfigPanelEvent,
  convertAdminSecretDataToREST,
} from '../components/SecretConfigPanel/SecretConfigPanel';
import { AdminSecretsService } from '../services/api';
import { useApp, useRefineData } from '../utils/hooks';
import { getToastErrorMessage } from '../utils/utils';
import { RoutePaths } from './Routes';

const appNameSanitize = (appName?: string) => {
  if (!appName) {
    return '';
  }

  return appName.startsWith('app:') ? appName : `app:${appName}`;
};

const visibilityFilter = (filterVisibility: Secret_Visibility | string, itemVisibility: Secret_Visibility | string) =>
  filterVisibility === Secret_Visibility.VISIBILITY_UNSPECIFIED || filterVisibility === itemVisibility;

const filter = (
  items: Secret[] = [],
  filterText = '',
  visibility: Secret_Visibility | string = Secret_Visibility.VISIBILITY_UNSPECIFIED
): Secret[] => {
  filterText = filterText.trim().toLowerCase();
  return items.filter((d) => {
    return filterText
      ? d.name.toLowerCase().includes(filterText) && visibilityFilter(visibility, d.visibility)
      : visibilityFilter(visibility, d.visibility);
  });
};

function AdminManageSecretsPage() {
  const [secrets, setSecrets] = useState<Secret[]>([]),
    [appList, setAppList] = useState<App[]>([]),
    refFilterText = useRef<string>(),
    [loading, setLoading] = useState<[string, boolean]>(),
    [hideDeleteConfirmDialog, { toggle: toggleHideDeleteConfirmDialog }] = useBoolean(true),
    [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false),
    [secretConfigPanelProps, setSecretConfigPanelProps] = useState<ISecretConfigPanelProps | undefined>(),
    { addToast } = useToast(),
    toastError = (message: string) => {
      addToast(getToastErrorMessage(message, 'AdminManageSecretsPage'));
    },
    refSelectedSecret = useRef<Secret>(),
    { getAdminApps } = useApp(),
    loadApps = useCallback(async () => {
      try {
        const app = await getAdminApps({
          limit: 1000,
          offset: 0,
          visibility: App_Visibility.VISIBILITY_UNSPECIFIED,
          allUsers: true,
          name: '',
          latestVersions: false,
          withPreference: false,
          tags: [],
          conditionsStatus: AppPreconditionStatus.STATUS_UNSPECIFIED,
          visibilities: [],
        });
        setAppList(app);
      } catch (error) {
        if (error instanceof Error) {
          toastError(`An error has occurred while loading app`);
        }
      }
    }, [appList, setAppList]),
    loadSecrets = useCallback(async () => {
      try {
        const { secrets: data } = await AdminSecretsService.listSecrets({
          visibility: Secret_Visibility.VISIBILITY_UNSPECIFIED,
          parent: '',
        });
        setSecrets(data);
      } catch (error) {
        if (error instanceof Error) {
          toastError(`An error has occurred while loading secrets`);
        }
      }
    }, [addToast]),
    deleteSecret = useCallback(
      async (secret: Secret) => {
        setLoading([getSecretUniqueId(secret), true]);
        try {
          await AdminSecretsService.deleteSecret(secret);
          await loadSecrets();
        } catch (error) {
          if (error instanceof Error) {
            toastError(`An error has occurred while deleting a secret(${secret.name})`);
          }
        }
        setLoading([getSecretUniqueId(secret), false]);
      },
      [addToast, loadSecrets]
    ),
    openConfigPanel = useCallback(
      async (secret?: any) => {
        refSelectedSecret.current = secret;
        openPanel();
        const panelProps: ISecretConfigPanelProps = {
          secret,
          appList,
          onClose: dismissPanel,
          onConfirm: confirmPanel,
        };
        setSecretConfigPanelProps(panelProps);
      },
      [setSecretConfigPanelProps, openPanel, dismissPanel]
    ),
    confirmPanel = useCallback<SecretConfigPanelEvent>(
      async (name, visibility, data, parent) => {
        dismissPanel();
        const uniqueId = name !== '' && visibility ? refSelectedSecret.current?.name + visibility : undefined;

        if (uniqueId) setLoading([uniqueId, true]);
        try {
          const basicRequest = {
            data: convertAdminSecretDataToREST(data),
            visibility,
            parent: appNameSanitize(parent),
          };
          if (name) {
            await AdminSecretsService.createSecret({
              name,
              allowMissingParent: visibility !== Secret_Visibility.APP,
              ...basicRequest,
            });
          } else {
            if (refSelectedSecret.current?.name) {
              await AdminSecretsService.updateSecret({
                name: refSelectedSecret.current.name,
                replace: false,
                ...basicRequest,
              });
            }
          }
          await loadSecrets();
        } catch (error) {
          if (name) {
            if (error instanceof Error) {
              toastError(`An error has occurred while creating a secret(${name})`);
            }
          } else {
            if (error instanceof Error && refSelectedSecret.current?.name) {
              toastError(`An error has occurred while updating a secret(${refSelectedSecret.current.name})`);
            }
          }
        }
        if (uniqueId) setLoading([uniqueId, false]);
      },
      [addToast, loading]
    ),
    deleteSecretConfirmDialog = useCallback(async (secret: Secret) => {
      refSelectedSecret.current = secret;
      toggleHideDeleteConfirmDialog();
    }, []),
    {
      data: refinedItems,
      searchKey,
      setSearchKey,
      filterKey,
      setFilterKey,
    } = useRefineData({
      data: secrets,
      onSearch: useCallback((searchNewKey, data: Secret[]) => {
        refFilterText.current = searchNewKey;
        if (searchNewKey === '') return data;
        return filter(data, searchNewKey);
      }, []),
      onFilter: useCallback((filterNewKey, data: Secret[]) => {
        if (filterNewKey === '') return data;
        return filter(data, refFilterText.current, filterNewKey);
      }, []),
    }),
    onChange = useCallback((_, newValue) => setSearchKey(newValue || ''), [setSearchKey]),
    onDropdownChange = useCallback((_, option) => setFilterKey(option?.key || ''), [setFilterKey]);
  useEffect(() => {
    const load = async () => {
      await loadApps();
      await loadSecrets();
    };
    load();
  }, []);
  return (
    <ListPage
      title="Admin App Secrets"
      parentPage={RoutePaths.ADMIN_APPS}
      subtitle={''}
      showData={true}
      primaryButtonProps={{
        text: 'Create secret',
        onClick: async () => {
          await openConfigPanel();
        },
      }}
      searchBoxProps={{
        value: searchKey,
        placeholder: 'Search by secret name',
        onChange,
      }}
      listActions={
        <>
          <Dropdown
            placeholder="All Apps"
            options={visibilityOptions}
            width={280}
            onChange={onDropdownChange}
            selectedKey={filterKey}
          />
        </>
      }
    >
      <ManagedSecretsList
        secrets={refinedItems}
        loading={loading}
        editSecret={openConfigPanel}
        deleteSecret={deleteSecretConfirmDialog}
      />
      <ConfirmDialog
        hidden={hideDeleteConfirmDialog}
        onDismiss={toggleHideDeleteConfirmDialog}
        title="Delete Secret"
        content={
          <div style={{ fontSize: FontSizes.small }}>
            Do you really want to delete the secret ({refSelectedSecret.current?.name})?
          </div>
        }
        onConfirm={() => {
          if (refSelectedSecret.current) {
            deleteSecret(refSelectedSecret.current);
          }
          toggleHideDeleteConfirmDialog();
        }}
        confirmationButtonText="Delete"
      ></ConfirmDialog>
      {isOpen && appList && (
        <SecretConfigPanel
          key={secretConfigPanelProps?.secret?.name}
          secret={refSelectedSecret.current}
          isOpen={isOpen}
          onConfirm={confirmPanel}
          onClose={dismissPanel}
          appList={appList}
        />
      )}
    </ListPage>
  );
}

export default AdminManageSecretsPage;
