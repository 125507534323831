import { IContextualMenuItem } from '@fluentui/react';
import {
  Button,
  IH2OTheme,
  IconName,
  buttonStylesSplitDanger,
  buttonStylesSplitGhost,
  buttonStylesSplitPrimary,
  buttonStylesSplitSecondary,
  buttonStylesSplitSmall,
  loaderStylesSpinnerButtonPrimary,
  loaderStylesSpinnerButtonSecondary,
  useTheme,
} from '@h2oai/ui-kit';

import { AppInstance, AppInstance_Status, AppInstance_Visibility } from '../../ai.h2o.cloud.appstore';
import { useInstance } from '../../utils/hooks';
import { InstancePauseResumeOpEnum, getInstanceButtonTestId, instanceButtonTestIdSuffixes } from '../../utils/utils';

export interface IInstanceActionButtonProps {
  hasVisitAppNoTerminatePostponeMenu?: boolean;
  hideTerminateButton?: boolean;
  onEditInstance?: (instance: AppInstance | null) => () => void;
  onViewInstanceLog?: () => void;
  instance: AppInstance;
  loading?: boolean;
  terminating?: boolean;
  setInstanceSuspension?: (instance: AppInstance, op: InstancePauseResumeOpEnum) => () => void;
  terminateInstance?: (instance: AppInstance) => () => void;
  useVisitInsteadOfPauseButton?: boolean;
}

export const willAutoSuspend = ({ status, suspendable, suspendAfter, visibility }: AppInstance) =>
  status === AppInstance_Status.DEPLOYED && suspendable && suspendAfter && visibility !== AppInstance_Visibility.PUBLIC;

const getMenuItems = (
  theme: IH2OTheme,
  goToAppDetail: (id: string) => () => void,
  showTerminateButton: boolean | undefined,
  loading: boolean | undefined,
  props: IInstanceActionButtonProps
): IContextualMenuItem[] => {
  const {
    instance,
    onViewInstanceLog,
    onEditInstance,
    setInstanceSuspension,
    hasVisitAppNoTerminatePostponeMenu,
    terminateInstance,
  } = props;

  const { appDetails, readOnly, status, suspendable } = instance;
  const { id } = appDetails || {};
  const menuItems = [];

  menuItems.push({
    key: 'appDetail',
    'data-test': getInstanceButtonTestId(instance, instanceButtonTestIdSuffixes.details),
    text: 'View app details',
    onClick: id ? goToAppDetail(id) : undefined,
    disabled: loading || !id,
  });
  if (onViewInstanceLog) {
    menuItems.push({
      key: 'view-instance-logs',
      'data-test': getInstanceButtonTestId(instance, instanceButtonTestIdSuffixes.viewLogs),
      text: 'View instance logs',
      disabled: loading || status !== AppInstance_Status.DEPLOYED,
      onClick: onViewInstanceLog,
    });
  }
  if (!readOnly && onEditInstance) {
    menuItems.push({
      key: 'edit-instance',
      'data-test': getInstanceButtonTestId(instance, instanceButtonTestIdSuffixes.edit),
      text: 'Edit',
      disabled: loading,
      onClick: onEditInstance(instance),
    });
  }
  if (setInstanceSuspension && willAutoSuspend(instance) && !hasVisitAppNoTerminatePostponeMenu) {
    menuItems.push({
      key: 'postpone',
      'data-test': getInstanceButtonTestId(instance, instanceButtonTestIdSuffixes.postpone),
      text: 'Postpone auto-pause',
      disabled: loading,
      onClick: setInstanceSuspension(instance, InstancePauseResumeOpEnum.Postpone),
    });
  }
  if (hasVisitAppNoTerminatePostponeMenu) {
    if (setInstanceSuspension && suspendable && status === AppInstance_Status.DEPLOYED) {
      menuItems.push({
        key: 'pause',
        'data-test': getInstanceButtonTestId(instance, instanceButtonTestIdSuffixes.pause),
        text: 'Pause',
        disabled: loading,
        onClick: setInstanceSuspension(instance, InstancePauseResumeOpEnum.Pause),
      });
    }
  }
  if (terminateInstance && !hasVisitAppNoTerminatePostponeMenu && !showTerminateButton) {
    menuItems.push({
      key: 'terminate',
      'data-test': getInstanceButtonTestId(instance, instanceButtonTestIdSuffixes.terminate),
      text: 'Terminate',
      onClick: terminateInstance(instance),
      style: { color: theme.semanticColors?.buttonDanger },
    });
  }
  return menuItems;
};

export function InstanceActionButton(props: IInstanceActionButtonProps) {
  const {
    instance,
    hideTerminateButton,
    useVisitInsteadOfPauseButton,
    loading = false,
    terminating = false,
    setInstanceSuspension,
    terminateInstance,
  } = props;
  const theme = useTheme();
  const { goToAppDetail, goToInstance } = useInstance();
  const { status, suspendable, readOnly } = instance;
  const showTerminateButton =
    !hideTerminateButton &&
    terminateInstance &&
    (!setInstanceSuspension ||
      !suspendable ||
      ![AppInstance_Status.DEPLOYED, AppInstance_Status.SUSPENDED].includes(status));
  const menuItems = getMenuItems(theme, goToAppDetail, showTerminateButton, loading, props);
  if (terminating) {
    return (
      <Button
        styles={[buttonStylesSplitDanger, buttonStylesSplitSmall]}
        text="Terminate"
        onClick={() => {}}
        split
        menuItems={[]}
        loading
        loaderProps={{
          label: 'Terminating...',
          styles: loaderStylesSpinnerButtonSecondary,
        }}
        data-test={getInstanceButtonTestId(instance, instanceButtonTestIdSuffixes.terminate)}
      />
    );
  } else if (showTerminateButton) {
    return (
      <Button
        styles={[buttonStylesSplitDanger, buttonStylesSplitSmall]}
        split
        onClick={terminateInstance(instance)}
        disabled={readOnly}
        text="Terminate"
        loading={loading}
        loaderProps={{
          label: 'Loading...',
          styles: loaderStylesSpinnerButtonSecondary,
        }}
        data-test={getInstanceButtonTestId(instance, instanceButtonTestIdSuffixes.terminate)}
        menuItems={menuItems}
      />
    );
  } else if (suspendable && [AppInstance_Status.DEPLOYED, AppInstance_Status.SUSPENDED].includes(status)) {
    if (setInstanceSuspension) {
      if (status === AppInstance_Status.DEPLOYED) {
        if (useVisitInsteadOfPauseButton) {
          return (
            <Button
              styles={[buttonStylesSplitGhost, buttonStylesSplitSmall]}
              split
              onClick={goToInstance(instance)}
              iconName={IconName.ArrowUpRight}
              menuIconName={IconName.ChevronDown}
              text="Visit"
              data-test={getInstanceButtonTestId(instance, instanceButtonTestIdSuffixes.visit)}
              menuItems={menuItems}
            />
          );
        } else {
          return (
            <Button
              styles={[buttonStylesSplitSecondary, buttonStylesSplitSmall]}
              split
              onClick={setInstanceSuspension(instance, InstancePauseResumeOpEnum.Pause)}
              iconName={IconName.CirclePause}
              menuIconName={IconName.ChevronDown}
              text="Pause"
              data-test={getInstanceButtonTestId(instance, instanceButtonTestIdSuffixes.pause)}
              menuItems={menuItems}
              loading={loading}
              loaderProps={{
                label: 'Loading...',
                styles: loaderStylesSpinnerButtonSecondary,
              }}
              loaderWithMenuButton
            />
          );
        }
      } else {
        return (
          <Button
            styles={[buttonStylesSplitPrimary, buttonStylesSplitSmall]}
            split
            onClick={setInstanceSuspension(instance, InstancePauseResumeOpEnum.Resume)}
            iconName={IconName.MSNVideos}
            menuIconName={IconName.ChevronDown}
            text="Resume"
            data-test={getInstanceButtonTestId(instance, instanceButtonTestIdSuffixes.resume)}
            menuItems={menuItems}
            loading={loading}
            loaderProps={{
              label: 'Loading...',
              styles: loaderStylesSpinnerButtonPrimary,
            }}
            loaderWithMenuButton
          />
        );
      }
    }
  }
  return (
    <Button
      styles={[buttonStylesSplitGhost, buttonStylesSplitSmall]}
      split
      primaryDisabled
      onClick={() => {}}
      menuIconName={IconName.ChevronDown}
      text="Not Available"
      data-test={getInstanceButtonTestId(instance, instanceButtonTestIdSuffixes.unavailable)}
      menuItems={menuItems}
    />
  );
}
