import { Stack } from '@fluentui/react';
import {
  Button,
  IButtonProps,
  ISearchBoxProps,
  IconName,
  PageHeader,
  SearchBox,
  buttonStylesLink,
  buttonStylesLinkBlack,
  buttonStylesPrimary,
  useHaicPageTitle,
  useTheme,
} from '@h2oai/ui-kit';

import { RoutePaths } from '../../pages/Routes';
import { stackStylesNoLeftNav } from '../../themes/themes';
import StatsCard, { StatsCardProps } from './StatsCard';
export interface ListPageProps {
  title: string;
  subtitle: string;
  showData: boolean;
  hideTableActions?: boolean;
  parentPage?: RoutePaths;
  primaryButtonProps?: IButtonProps;
  statsCards?: StatsCardProps[];
  searchBoxProps?: ISearchBoxProps;
  searchResultsText?: string;
  filterActions?: React.ReactNode;
  listActions?: React.ReactNode;
  children?: React.ReactNode;
}

function ListPage({
  title,
  subtitle,
  showData,
  hideTableActions,
  parentPage,
  primaryButtonProps,
  statsCards,
  searchBoxProps,
  searchResultsText,
  filterActions,
  listActions,
  children,
}: ListPageProps) {
  const theme = useTheme();
  useHaicPageTitle(title);
  return (
    <Stack styles={stackStylesNoLeftNav}>
      {parentPage && (
        <Button
          styles={[buttonStylesLink, buttonStylesLinkBlack]}
          text="Back"
          iconName={IconName.Back}
          href={parentPage}
        />
      )}
      <Stack horizontal horizontalAlign="space-between">
        <Stack.Item disableShrink>
          <PageHeader pageTitle={title} description={subtitle} />
        </Stack.Item>
        {primaryButtonProps && (
          <Stack horizontalAlign="end" styles={{ root: { width: '100%' } }}>
            <Button styles={buttonStylesPrimary} {...primaryButtonProps} />
          </Stack>
        )}
      </Stack>
      {showData ? (
        <>
          {statsCards && (
            <Stack horizontal tokens={{ childrenGap: 16 }}>
              {statsCards.map((card) => (
                <StatsCard key={card.title} title={card.title} stat={card.stat} />
              ))}
            </Stack>
          )}

          {!hideTableActions && (
            <Stack horizontal horizontalAlign="space-between" style={{ marginTop: 20 }}>
              <Stack horizontal verticalAlign="center">
                <Stack horizontal tokens={{ childrenGap: 4 }}>
                  <Stack horizontal tokens={{ childrenGap: 4 }}>
                    <div style={{ width: 400, marginRight: 2 }}>
                      <SearchBox {...searchBoxProps} />
                    </div>
                    {filterActions}
                  </Stack>
                </Stack>
                {searchResultsText && (
                  <span style={{ marginLeft: 8, color: theme.semanticColors?.textSecondary }}>{searchResultsText}</span>
                )}
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 4 }}>
                {listActions}
              </Stack>
            </Stack>
          )}
        </>
      ) : null}
      {children}
    </Stack>
  );
}

export default ListPage;
