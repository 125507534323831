import { IStyle, Stack } from '@fluentui/react';
import { CommonStyles, FontWeights, IconName } from '@h2oai/ui-kit';
import React from 'react';

import { LabelIconTooltip } from '../AIEnginesPage/components/LabelIconTooltip/LabelIconTooltip';
import Cell, { textCellStyles } from './Cell';

export interface MetadataCellContentsProps {
  title?: string;
  metadata?: (string | React.ReactNode)[];
  children?: React.ReactNode;
  tooltip?: string;
}

const stackStyles = {
  root: { width: '100%' },
};

export const baseMetadataTextStyles = {
  ...textCellStyles,
  ...CommonStyles.truncateString,
  color: 'unset',
};

export const metadataTextStyles = {
  ...baseMetadataTextStyles,
  width: 'calc(100% - 20px)',
} as IStyle;

export function MetadataCellContents(props: MetadataCellContentsProps) {
  return (
    <Stack styles={stackStyles} tokens={{ childrenGap: 4 }}>
      <Stack horizontal>
        {props.title && <span style={{ fontWeight: FontWeights.bold, marginBottom: 4 }}>{props.title}</span>}
        <LabelIconTooltip iconName={IconName.Info} tooltip={props.tooltip} />
      </Stack>
      {props.metadata &&
        props.metadata.map((datum, i) => (
          <span key={typeof datum === 'string' ? datum : i} style={metadataTextStyles as React.CSSProperties}>
            {datum}
          </span>
        ))}
      {props.children}
    </Stack>
  );
}

export function MetadataCell(props: MetadataCellContentsProps) {
  return (
    <Cell>
      <MetadataCellContents {...props} />
    </Cell>
  );
}
