import { FontWeights, IEmptyMessageProps, Sizes, WidgetPanel, useTheme } from '@h2oai/ui-kit';

import { TimeSeries, UsageDatum, barGradient } from '../../PlatformUsage/components/TimeSeries';
import { RoutePaths } from '../../Routes';

export interface IPlatformUsageWidgetProps {
  title: string;
  data?: UsageDatum[];
  maxUsageData?: UsageDatum;
  loadingMessage?: string;
}

export const PlatformUsageWidget = ({ title, data, maxUsageData, loadingMessage }: IPlatformUsageWidgetProps) => {
  const theme = useTheme(),
    emptyMessageProps: IEmptyMessageProps | undefined =
      !data || !data.length ? { message: `Platform usage data unavailable` } : undefined;

  return (
    <WidgetPanel
      title={title}
      linkText="More analysis"
      linkHref={RoutePaths.PLATFORM_USAGE}
      loadingMessage={loadingMessage}
      emptyMessageProps={emptyMessageProps}
    >
      {data && maxUsageData?.value && (
        <div style={{ padding: Sizes.padding, height: '100%' }}>
          <div style={{ fontWeight: FontWeights.semiBold }}>Current usage: {maxUsageData?.value || 0} AI Units</div>

          <TimeSeries
            getTooltipMessage={(datum) => `${datum.value.toLocaleString()} AI Units`}
            gradient={barGradient(theme)}
            axisProps={{
              data,
              hasAxis: true,
              hasGrid: true,
              labelField: 'time',
              fields: ['value'],
              min: 0,
              hasTooltip: true,
              hasAnimation: true,
              responsive: true,
            }}
          />
        </div>
      )}
    </WidgetPanel>
  );
};
