import { DriverlessEngineSizeOptionKeyType, H2OEngineSizeOptionKeyType } from '../components/AIEnginesPage/constants';
import { defaultEnginesListRequest, defaultWorkspaceName, defaultBasePath as developmentBasePath } from './defaults';
import {
  AdjustedDAIProfileServiceApi,
  Configuration,
  DAIEngineConstraintSetServiceApi,
  DAIEngineConstraintSetServiceGetDAIEngineConstraintSetRequest,
  DAIEngineServiceApi,
  DAIEngineServiceCreateDAIEngineRequest,
  DAIEngineServiceDeleteDAIEngineRequest,
  DAIEngineServiceGetDAIEngineRequest,
  DAIEngineServiceListDAIEnginesRequest,
  DAIEngineServicePauseDAIEngineRequest,
  DAIEngineServiceResumeDAIEngineRequest,
  DAIEngineServiceUpdateDAIEngineRequest,
  DAIEngineServiceUpgradeVersionRequest,
  DAIVersionServiceApi,
  DAIVersionServiceListDAIVersionsRequest,
  EngineServiceApi,
  EngineServiceListEnginesRequest,
  H2OEngineConstraintSetServiceApi,
  H2OEngineConstraintSetServiceGetH2OEngineConstraintSetRequest,
  H2OEngineServiceApi,
  H2OEngineServiceCalculateH2OEngineSizeCompressedDatasetRequest,
  H2OEngineServiceCalculateH2OEngineSizeRawDatasetRequest,
  H2OEngineServiceCreateH2OEngineRequest,
  H2OEngineServiceDeleteH2OEngineRequest,
  H2OEngineServiceGetH2OEngineRequest,
  H2OEngineServiceListH2OEnginesRequest,
  V1AdjustedDAIProfile,
  V1CreateDAIEngineResponse,
  V1CreateH2OEngineResponse,
  V1DAIEngine,
  V1DAIEngineConstraintSet,
  V1DAIEngineServiceUpgradeVersionResponse,
  V1DAIEngineState,
  V1DAIVersion,
  V1Engine,
  V1EngineState,
  V1EngineType,
  V1GetDAIEngineConstraintSetResponse,
  V1GetDAIEngineResponse,
  V1GetH2OEngineConstraintSetResponse,
  V1GetH2OEngineResponse,
  V1H2OEngine,
  V1H2OEngineConstraintSet,
  V1H2OEngineSize,
  V1H2OEngineState,
  V1H2OVersion,
  V1ListDAIEnginesResponse,
  V1ListEnginesResponse,
  V1ListH2OEnginesResponse,
  V1PauseDAIEngineResponse,
  V1ResumeDAIEngineResponse,
  V1UpdateDAIEngineResponse,
} from './gen';
import { H2OVersionServiceApi, H2OVersionServiceListH2OVersionsRequest } from './gen/apis/H2OVersionServiceApi';

const defaultBasePath = process.env.REACT_APP_USE_MOCK_SERVER ? developmentBasePath : '';

export enum AIEMOpType {
  checkId = 'checkId',
  create = 'create',
  edit = 'edit',
  get = 'get',
  list = 'list',
  view = 'view',
  resume = 'resume',
  pause = 'pause',
  delete = 'delete',
  update = 'update',
  upgrade = 'upgrade',
  constraintSet = 'constraintSet',
  openLog = 'openLog',
  downloadLog = 'downloadLog',
}

export enum ConstraintSetType {
  DAI_ENGINE_CONSTRAINT_SET = 'daiEngineConstraintSet',
  H2O_ENGINE_CONSTRAINT_SET = 'h2oEngineConstraintSet',
}

export type EngineAttributes = {
  engineType?: V1EngineType;
  op?: AIEMOpType;
  id?: string;
  loginUrl?: string;
  engineNewVersion?: string;
};

export type AIEngine = EngineAttributes & V1Engine & V1DAIEngine & V1H2OEngine;

export type EngineConstraintSet = V1DAIEngineConstraintSet & V1H2OEngineConstraintSet;

export type EngineVersionAttributes = {
  isDefault?: boolean;
  key?: string;
  type?: V1EngineType;
};

export interface V1RawConstraintNumeric {
  min?: string;
  max?: string | null;
  default?: string;
}

export interface V1RawConstraintDuration {
  min?: string;
  max?: string | null;
  default?: string;
}

export interface V1RawDAIEngineConstraintSet {
  name?: string;
  cpu?: V1RawConstraintNumeric;
  gpu?: V1RawConstraintNumeric;
  memoryBytes?: V1RawConstraintNumeric;
  storageBytes?: V1RawConstraintNumeric;
  maxIdleDuration?: V1RawConstraintDuration;
  maxRunningDuration?: V1RawConstraintDuration;
}

export interface V1RawH2OEngineConstraintSet {
  name?: string;
  cpu?: V1RawConstraintNumeric;
  gpu?: V1RawConstraintNumeric;
  memoryBytes?: V1RawConstraintNumeric;
  nodeCount?: V1RawConstraintNumeric;
  maxIdleDuration?: V1RawConstraintDuration;
  maxRunningDuration?: V1RawConstraintDuration;
}
export interface V1GetRawDAIEngineConstraintSetResponse {
  daiEngineConstraintSet: V1RawDAIEngineConstraintSet;
}

export interface V1GetRawH2OEngineConstraintSetResponse {
  h2oEngineConstraintSet: V1RawH2OEngineConstraintSet;
}

export type EngineVersion = (V1DAIVersion | V1H2OVersion) & EngineVersionAttributes;

function getConfiguration(basePath: string) {
  return new Configuration({ basePath });
}

function getEngineService(basePath: string) {
  return new EngineServiceApi(getConfiguration(basePath));
}

function getDAIEngineConstraintSetServiceApi(basePath: string) {
  return new DAIEngineConstraintSetServiceApi(getConfiguration(basePath));
}

function getDAIEngineService(basePath: string) {
  return new DAIEngineServiceApi(getConfiguration(basePath));
}

function getDAIVersionService(basePath: string) {
  return new DAIVersionServiceApi(getConfiguration(basePath));
}

function getH2OEngineConstraintSetServiceApi(basePath: string) {
  return new H2OEngineConstraintSetServiceApi(getConfiguration(basePath));
}

function getH2OEngineService(basePath: string) {
  return new H2OEngineServiceApi(getConfiguration(basePath));
}

function getH2OVersionService(basePath: string) {
  return new H2OVersionServiceApi(getConfiguration(basePath));
}

export async function listEngines(basePath: string | undefined) {
  if (typeof basePath !== 'string') {
    return Promise.reject(new Error('No base path URL was provided for the AI Engine Manager request.'));
  }
  return await getEngineService(basePath).engineServiceListEngines(defaultEnginesListRequest);
}

export type CreateEngineRequest = EngineAttributes &
  (DAIEngineServiceCreateDAIEngineRequest | H2OEngineServiceCreateH2OEngineRequest);

export type EngineState = V1EngineState | V1DAIEngineState | V1H2OEngineState;

// all requests
export type EngineListRequest =
  | EngineServiceListEnginesRequest
  | DAIEngineServiceListDAIEnginesRequest
  | H2OEngineServiceListH2OEnginesRequest;
export type EngineCreateRequest = DAIEngineServiceCreateDAIEngineRequest | H2OEngineServiceCreateH2OEngineRequest;
export type EngineGetRequest = DAIEngineServiceGetDAIEngineRequest | H2OEngineServiceGetH2OEngineRequest;
export type EngineUpdateRequest = DAIEngineServiceUpdateDAIEngineRequest;
export type EngineUpgradeRequest = DAIEngineServiceUpgradeVersionRequest;
export type EngineResumeRequest = DAIEngineServiceResumeDAIEngineRequest;
export type EnginePausedRequest = DAIEngineServicePauseDAIEngineRequest;
export type EngineDeleteRequest = DAIEngineServiceDeleteDAIEngineRequest | H2OEngineServiceDeleteH2OEngineRequest;
export type EngineConstraintSetRequest =
  | DAIEngineConstraintSetServiceGetDAIEngineConstraintSetRequest
  | H2OEngineConstraintSetServiceGetH2OEngineConstraintSetRequest;

// generic responses
export type EngineListResponse = {
  engines: Array<V1Engine | V1DAIEngine | V1H2OEngine>;
  nextPageToken?: string;
  totalSize?: number;
};
export type EngineResponse = {
  engine: AIEngine | V1Engine | V1DAIEngine | V1H2OEngine;
};

type ItemOp = (engine: AIEngine, abort?: boolean) => Promise<EngineResponse>;
type ListOp = (request: EngineListRequest) => Promise<EngineListResponse>;
type ConstraintOp = (request: EngineConstraintSetRequest) => Promise<EngineConstraintSet>;
type UpgradeOp = (request: AIEngine) => Promise<V1DAIEngineServiceUpgradeVersionResponse>;

type EndpointTypeFunction = {
  [P in keyof V1EngineType as string]: ItemOp | ListOp | ConstraintOp | UpgradeOp;
};

type Endpoint = {
  [P in keyof AIEMOpType as string]: EndpointTypeFunction;
};

const castEngineListResponse = (
  originalResponse: EngineListResponse,
  sourceField: keyof V1ListEnginesResponse | keyof V1ListDAIEnginesResponse | keyof V1ListH2OEnginesResponse
): EngineListResponse => ({
  engines: originalResponse[sourceField],
  nextPageToken: originalResponse.nextPageToken,
  totalSize: originalResponse.totalSize,
});

const castEngineResponse = (
  originalResponse: EngineResponse,
  sourceField:
    | keyof V1CreateDAIEngineResponse
    | keyof V1CreateH2OEngineResponse
    | keyof V1GetDAIEngineResponse
    | keyof V1GetH2OEngineResponse
    | keyof V1PauseDAIEngineResponse
    | keyof V1ResumeDAIEngineResponse
    | keyof V1UpdateDAIEngineResponse
    | keyof V1DAIEngineServiceUpgradeVersionResponse
) => ({
  engine: originalResponse[sourceField],
});

type EngineConstraintSetSource = keyof V1GetDAIEngineConstraintSetResponse | keyof V1GetH2OEngineConstraintSetResponse;

const castEngineListConstraint = (
  originalResponse: V1GetDAIEngineConstraintSetResponse | V1GetH2OEngineConstraintSetResponse,
  sourceField: EngineConstraintSetSource
) => {
  return originalResponse[sourceField];
};

export const isTransitionalState = (state: EngineState): boolean =>
  [
    String(V1EngineState.Connecting),
    String(V1EngineState.Starting),
    String(V1DAIEngineState.Deleting),
    String(V1EngineState.Pausing),
  ].includes(state);

export const getFullPathFromEngineName = (
  engineName: string,
  engineType: V1EngineType = V1EngineType.DriverlessAi,
  workspaceName = defaultWorkspaceName
) => {
  const engineTypeUrl = `${engineType === V1EngineType.DriverlessAi ? 'dai' : 'h2o'}Engines`;
  return `${workspaceName}/${engineTypeUrl}/${engineName}`;
};

export const getIdFromName = (value: string | null = null) => (!value ? null : value.split('/').reverse()[0]);

// This is the valid regex for it: ^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$
//  source (after make generate): gen/openapiv2/ai/h2o/engine/v1/dai_engine_service.swagger.json:354
export const getRandomNumber = () => Math.floor(Math.random() * 10000);

export const getVariant = (last?: number, candidate = getRandomNumber()): number =>
  !last || last !== candidate ? candidate : getVariant(candidate);

export const extractId = (value: string) =>
  value
    .toLowerCase() // set everything to lowercase
    .replace(/\s+/g, '-') // replace spaces by dashes
    // deletes all non-alphabetical until first alphabetical found character
    // deletes all hyphens and non-alphanumerical characters
    .replace(/^[^a-z]+|[^\w-]|[-]{2,}/g, '')
    .replace(/[-]$/g, ''); // delete last non-alphanumeric character

export const extractWorkspace = (value: string = defaultWorkspaceName) => {
  const name = decodeURI(value).split('/');
  return `${name[0]}/${name[1]}`;
};

export const tryName = (value: string, lastVariant?: number): { id: string; variant: number } => ({
  id: extractId(value),
  variant: lastVariant ? getVariant(lastVariant) : getRandomNumber(),
});

export const concatId = (value: string, variant?: number) => Object.values(tryName(value, variant)).join('-');

export const generateId = (value: string) => concatId(value);

const abortableBag: { [P in keyof AIEMOpType as string]: boolean } = {
  [AIEMOpType.checkId]: false,
  [AIEMOpType.list]: false,
};

let controller = new AbortController(),
  signal = controller.signal;

const abortController = () => {
    controller.abort();
    controller = new AbortController();
    signal = controller.signal;
  },
  considerAborting = (op: AIEMOpType) => {
    if (abortableBag[op]) abortController();
    abortableBag[op] = true;
  },
  abortableRequest = async (request: any, op: AIEMOpType) => {
    considerAborting(op);
    try {
      return await request();
    } catch (error) {
      if ((error as { status: number }).status === 404 || (error as { code: number }).code === 20) {
        return {};
      }
      throw error;
    } finally {
      abortableBag[op] = false;
    }
  };

export const getEndpointCall = (basePath = defaultBasePath, op: AIEMOpType, type = V1EngineType.Unspecified) => {
  const invalidOpError = () => new Error('Invalid Operation: Bad Engine or Parameter Name');
  const endpoints: Endpoint = {
    [AIEMOpType.checkId]: {
      [V1EngineType.DriverlessAi]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        try {
          const request = async () => {
            const req = await getDAIEngineService(basePath).dAIEngineServiceGetDAIEngine({ name }, { signal });
            return req;
          };
          return castEngineResponse(
            (await abortableRequest(request, AIEMOpType.checkId)) as EngineResponse,
            'daiEngine'
          );
        } catch (error: any) {
          if ((error as { status: number })?.status === 403) {
            return { engine: {} as AIEngine };
          }
          throw error;
        }
      },
      [V1EngineType.H2O]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        try {
          const request = async () => {
            const req = await getH2OEngineService(basePath).h2OEngineServiceGetH2OEngine({ name }, { signal });
            return req;
          };
          return castEngineResponse(
            (await abortableRequest(request, AIEMOpType.checkId)) as EngineResponse,
            'h2oEngine'
          );
        } catch (error: any) {
          if ((error as { status: number })?.status === 403) {
            return { engine: {} as AIEngine };
          }
          throw error;
        }
      },
    },
    [AIEMOpType.create]: {
      [V1EngineType.DriverlessAi]: async (engine: AIEngine) => {
        const req: CreateEngineRequest = {
          body: {
            ...(engine as V1DAIEngine),
            state: V1DAIEngineState.Unspecified,
          },
          parent: defaultWorkspaceName,
          daiEngineId: engine.id || generateId(engine.displayName!),
        };
        return castEngineResponse(
          (await getDAIEngineService(basePath).dAIEngineServiceCreateDAIEngine(req)) as EngineResponse,
          'daiEngine'
        );
      },
      [V1EngineType.H2O]: async (engine: AIEngine) => {
        const req: CreateEngineRequest = {
          body: {
            ...(engine as V1H2OEngine),
            state: V1H2OEngineState.Unspecified,
          },
          parent: defaultWorkspaceName,
          h2oEngineId: engine.id || generateId(engine.displayName!),
        };
        return castEngineResponse(
          (await getH2OEngineService(basePath).h2OEngineServiceCreateH2OEngine(req)) as EngineResponse,
          'h2oEngine'
        );
      },
    },
    [AIEMOpType.get]: {
      [V1EngineType.DriverlessAi]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await getDAIEngineService(basePath).dAIEngineServiceGetDAIEngine({ name })) as EngineResponse,
          'daiEngine'
        );
      },
      [V1EngineType.H2O]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await getH2OEngineService(basePath).h2OEngineServiceGetH2OEngine({ name })) as EngineResponse,
          'h2oEngine'
        );
      },
    },
    [AIEMOpType.list]: {
      [V1EngineType.Unspecified]: async (request = defaultEnginesListRequest) => {
        const requestDelegate = async () =>
          (await getEngineService(basePath).engineServiceListEngines(
            request || defaultEnginesListRequest
          )) as EngineListResponse;
        return castEngineListResponse(
          (await abortableRequest(requestDelegate, AIEMOpType.list)) as EngineListResponse,
          'engines'
        );
      },
      [V1EngineType.DriverlessAi]: async (request: EngineListRequest) =>
        castEngineListResponse(
          (await getDAIEngineService(basePath).dAIEngineServiceListDAIEngines(request)) as EngineListResponse,
          'daiEngines'
        ),
      [V1EngineType.H2O]: async (request: EngineListRequest) =>
        castEngineListResponse(
          (await getH2OEngineService(basePath).h2OEngineServiceListH2OEngines(request)) as EngineListResponse,
          'h2oEngines'
        ),
    },
    [AIEMOpType.resume]: {
      [V1EngineType.DriverlessAi]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await getDAIEngineService(basePath).dAIEngineServiceResumeDAIEngine({ name, body: {} })) as EngineResponse,
          'daiEngine'
        );
      },
    },
    [AIEMOpType.pause]: {
      [V1EngineType.DriverlessAi]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await getDAIEngineService(basePath).dAIEngineServicePauseDAIEngine({ name, body: {} })) as EngineResponse,
          'daiEngine'
        );
      },
    },
    [AIEMOpType.delete]: {
      [V1EngineType.DriverlessAi]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await getDAIEngineService(basePath).dAIEngineServiceDeleteDAIEngine({ name })) as EngineResponse,
          'daiEngine'
        );
      },
      [V1EngineType.H2O]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await getH2OEngineService(basePath).h2OEngineServiceDeleteH2OEngine({ name })) as EngineResponse,
          'h2oEngine'
        );
      },
    },
    [AIEMOpType.update]: {
      [V1EngineType.DriverlessAi]: async (engine: AIEngine) => {
        const req: EngineUpdateRequest = {
          body: engine as V1DAIEngine,
          daiEngineName: engine.name!,
          updateMask: '*',
        };
        return castEngineResponse(
          (await getDAIEngineService(basePath).dAIEngineServiceUpdateDAIEngine(req)) as EngineResponse,
          'daiEngine'
        );
      },
    },
    [AIEMOpType.upgrade]: {
      [V1EngineType.DriverlessAi]: async (engine: AIEngine) => {
        if (!engine || !engine.name) throw invalidOpError();
        if (!engine.engineNewVersion) engine.engineNewVersion = 'latest';
        const req: EngineUpgradeRequest = {
          daiEngine: engine.name,
          body: { newVersion: engine.engineNewVersion },
        };
        return castEngineResponse(
          (await getDAIEngineService(basePath).dAIEngineServiceUpgradeVersion(req)) as EngineResponse,
          'daiEngine'
        );
      },
    },
    [AIEMOpType.constraintSet]: {
      [V1EngineType.DriverlessAi]: async (request: EngineConstraintSetRequest) => {
        return castEngineListConstraint(
          await getDAIEngineConstraintSetServiceApi(basePath).dAIEngineConstraintSetServiceGetDAIEngineConstraintSet(
            request
          ),
          ConstraintSetType.DAI_ENGINE_CONSTRAINT_SET
        );
      },
      [V1EngineType.H2O]: async (request: EngineConstraintSetRequest) =>
        castEngineListConstraint(
          await getH2OEngineConstraintSetServiceApi(basePath).h2OEngineConstraintSetServiceGetH2OEngineConstraintSet(
            request
          ),
          ConstraintSetType.H2O_ENGINE_CONSTRAINT_SET
        ),
    },
  };
  return endpoints[op][type];
};

export const constraintSetTypeMap = new Map<V1EngineType, ConstraintSetType>([
  [V1EngineType.DriverlessAi, ConstraintSetType.DAI_ENGINE_CONSTRAINT_SET],
  [V1EngineType.H2O, ConstraintSetType.H2O_ENGINE_CONSTRAINT_SET],
]);

type TypeCondition = {
  [P in keyof V1EngineType as string]: boolean;
};

type StateCondition = {
  [P in keyof V1EngineType as string]: boolean;
};

type OpCondition = {
  types: TypeCondition;
  states: StateCondition;
};

// consider the necessity to implement a new condition with the new op checkId
const opsConditions = new Map<AIEMOpType, OpCondition>([
  [
    AIEMOpType.create,
    {
      types: { [V1EngineType.DriverlessAi]: true, [V1EngineType.H2O]: true },
      states: {},
    },
  ],
  [
    AIEMOpType.edit,
    {
      types: { [V1EngineType.DriverlessAi]: true },
      states: { [V1EngineState.Failed]: true, [V1EngineState.Paused]: true },
    },
  ],
  [
    AIEMOpType.get,
    {
      types: { [V1EngineType.DriverlessAi]: true, [V1EngineType.H2O]: true },
      states: {
        [V1EngineState.Failed]: true,
        [V1EngineState.Paused]: true,
        [V1EngineState.Pausing]: true,
        [V1EngineState.Running]: true,
      },
    },
  ],
  [
    AIEMOpType.list,
    {
      types: { [V1EngineType.DriverlessAi]: true, [V1EngineType.H2O]: true },
      states: {},
    },
  ],
  [
    AIEMOpType.openLog,
    {
      types: { [V1EngineType.DriverlessAi]: true, [V1EngineType.H2O]: true },
      states: { [V1EngineState.Connecting]: true, [V1EngineState.Running]: true, [V1EngineState.Starting]: true },
    },
  ],
  [
    AIEMOpType.resume,
    {
      types: { [V1EngineType.DriverlessAi]: true },
      states: { [V1EngineState.Failed]: true, [V1EngineState.Paused]: true },
    },
  ],
  [
    AIEMOpType.pause,
    {
      types: { [V1EngineType.DriverlessAi]: true, [V1EngineType.H2O]: true },
      states: { [V1EngineState.Connecting]: true, [V1EngineState.Running]: true, [V1EngineState.Starting]: true },
    },
  ],
  [
    AIEMOpType.delete,
    {
      types: { [V1EngineType.DriverlessAi]: true, [V1EngineType.H2O]: true },
      states: {
        [V1EngineState.Connecting]: true,
        [V1EngineState.Failed]: true,
        [V1EngineState.Paused]: true,
        [V1EngineState.Pausing]: true,
        [V1EngineState.Running]: true,
        [V1EngineState.Starting]: true,
      },
    },
  ],
  [
    AIEMOpType.update,
    {
      types: { [V1EngineType.DriverlessAi]: true },
      states: { [V1EngineState.Paused]: true },
    },
  ],
  [
    AIEMOpType.upgrade,
    {
      types: { [V1EngineType.DriverlessAi]: true },
      states: { [V1EngineState.Failed]: true, [V1EngineState.Paused]: true },
    },
  ],
  [
    AIEMOpType.view,
    {
      types: { [V1EngineType.DriverlessAi]: true, [V1EngineType.H2O]: true },
      states: {
        [V1EngineState.Connecting]: true,
        [V1EngineState.Deleting]: true,
        [V1EngineState.Failed]: true,
        [V1EngineState.Paused]: true,
        [V1EngineState.Pausing]: true,
        [V1EngineState.Running]: true,
        [V1EngineState.Starting]: true,
      },
    },
  ],
]);

export const hasOp = (op: AIEMOpType, type?: V1EngineType, state?: EngineState) => {
  if (!type || !state) return false;
  const _op = opsConditions.get(op);
  return _op && _op.types[type] && _op.states[state];
};

type EngineVersionListRequest = DAIVersionServiceListDAIVersionsRequest | H2OVersionServiceListH2OVersionsRequest;

type EngineTypeAttributes = {
  text: string;
  listVersions: (basePath: string, request?: EngineVersionListRequest) => Promise<EngineVersion[]>;
};

export type ValidEngineType = V1EngineType.DriverlessAi | V1EngineType.H2O;

type EngineTypeService = {
  [P in keyof ValidEngineType as string]: EngineTypeAttributes;
};

export const engineTypeService: EngineTypeService = {
  [V1EngineType.DriverlessAi]: {
    text: 'DAI',
    listVersions: async (basePath = defaultBasePath, request = {}): Promise<EngineVersion[]> =>
      (await getDAIVersionService(basePath).dAIVersionServiceListDAIVersions(request))['daiVersions'] || [],
  },
  [V1EngineType.H2O]: {
    text: 'H2O',
    listVersions: async (basePath = defaultBasePath, request = {}): Promise<EngineVersion[]> =>
      (await getH2OVersionService(basePath).h2OVersionServiceListH2OVersions(request))['h2oVersions'] || [],
  },
};

export type EngineSizeOption = V1AdjustedDAIProfile & {
  key: DriverlessEngineSizeOptionKeyType | H2OEngineSizeOptionKeyType;
  text: string;
  dataTest: string;
  nodeCount?: number;
};

export type EngineSizeDatasetRequest = H2OEngineServiceCalculateH2OEngineSizeCompressedDatasetRequest &
  H2OEngineServiceCalculateH2OEngineSizeRawDatasetRequest;

export const CalculateH2OEngineSizeCompressedDataset = async (
  basePath = defaultBasePath,
  request = {} as H2OEngineServiceCalculateH2OEngineSizeCompressedDatasetRequest
) =>
  (
    await getH2OEngineService(basePath).h2OEngineServiceCalculateH2OEngineSizeCompressedDataset({
      ...request,
      workspace: request.workspace || defaultWorkspaceName,
    })
  ).h2oEngineSize || ({} as V1H2OEngineSize);

export const CalculateH2OEngineSizeRawDataset = async (
  basePath = defaultBasePath,
  request = {} as H2OEngineServiceCalculateH2OEngineSizeRawDatasetRequest
) =>
  (
    await getH2OEngineService(basePath).h2OEngineServiceCalculateH2OEngineSizeRawDataset({
      ...request,
      workspace: request.workspace || defaultWorkspaceName,
    })
  ).h2oEngineSize || ({} as V1H2OEngineSize);

export type CalculatedH2OEngineSizeAttributes = {
  calculate: typeof CalculateH2OEngineSizeCompressedDataset | typeof CalculateH2OEngineSizeRawDataset;
  label: string;
};

export type H2OEngineSizeService = {
  [P in keyof H2OEngineSizeOptionKeyType as string]: CalculatedH2OEngineSizeAttributes;
};

export const calculatedEngineSizes: H2OEngineSizeService = {
  [H2OEngineSizeOptionKeyType.raw]: {
    label: 'Raw',
    calculate: CalculateH2OEngineSizeRawDataset,
  },
  [H2OEngineSizeOptionKeyType.compressed]: {
    label: 'Compressed',
    calculate: CalculateH2OEngineSizeCompressedDataset,
  },
};

export const isCalculatedEngineSize = (value: H2OEngineSizeOptionKeyType) =>
  Object.keys(calculatedEngineSizes).includes(value);

export const listDAIProfiles = async (basePath = defaultBasePath) => {
  return await new AdjustedDAIProfileServiceApi(
    getConfiguration(basePath)
  ).adjustedDAIProfileServiceListAdjustedDAIProfiles({
    parent: defaultWorkspaceName,
  });
};
