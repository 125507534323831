import {
  AIEngine,
  EngineSizeOption,
  H2OEngineServiceCalculateH2OEngineSizeCompressedDatasetRequest,
  H2OEngineServiceCalculateH2OEngineSizeRawDatasetRequest,
} from '../../aiem';
import { gibibytesToBytes } from './utils';

export enum DriverlessEngineSizeOptionKeyType {
  small = 'small',
  medium = 'medium',
  large = 'large',
  xl = 'xl',
  xxl = 'xxl',
  custom = 'custom',
}

export enum H2OEngineSizeOptionKeyType {
  default = 'default',
  custom = 'custom',
  raw = 'raw',
  compressed = 'compressed',
}

export const H2OCalculatingEngineSizeOptionKey: Array<
  H2OEngineSizeOptionKeyType.raw | H2OEngineSizeOptionKeyType.compressed
> = [H2OEngineSizeOptionKeyType.raw, H2OEngineSizeOptionKeyType.compressed];

export const customDAIEngineSizeOption: EngineSizeOption = {
  key: DriverlessEngineSizeOptionKeyType.custom,
  text: 'Custom',
  dataTest: 'dai-custom',
};

export const DriverlessEngineSizeOptions: EngineSizeOption[] = [
  {
    key: DriverlessEngineSizeOptionKeyType.small,
    text: 'View data, interpretability, results',
    dataTest: 'dai-m5.xlarge',
    cpu: 6,
    gpu: 0,
    memoryBytes: gibibytesToBytes(22),
    storageBytes: gibibytesToBytes(64),
  },
  {
    key: DriverlessEngineSizeOptionKeyType.medium,
    text: 'AutoML',
    dataTest: 'dai-m5.4xlarge',
    cpu: 14,
    gpu: 0,
    memoryBytes: gibibytesToBytes(54),
    storageBytes: gibibytesToBytes(64),
  },
  {
    key: DriverlessEngineSizeOptionKeyType.large,
    text: 'AutoML fast or on 10+GB of data',
    dataTest: 'dai-m5.12xlarge',
    cpu: 46,
    gpu: 0,
    memoryBytes: gibibytesToBytes(182),
    storageBytes: gibibytesToBytes(64),
  },
  {
    key: DriverlessEngineSizeOptionKeyType.xl,
    text: 'NLP or Image AutoML experiments',
    dataTest: 'dai-g4dn.4xlarge',
    cpu: 14,
    gpu: 1,
    memoryBytes: gibibytesToBytes(54),
    storageBytes: gibibytesToBytes(64),
  },
  {
    key: DriverlessEngineSizeOptionKeyType.xxl,
    text: 'NLP or Image AutoML experiments on 10+GB of data',
    dataTest: 'dai-g4dn.12xlarge',
    cpu: 46,
    gpu: 4,
    memoryBytes: gibibytesToBytes(182),
    storageBytes: gibibytesToBytes(64),
  },
  customDAIEngineSizeOption,
];

export const H2OEngineSizeOptions: EngineSizeOption[] = [
  {
    key: H2OEngineSizeOptionKeyType.custom,
    text: 'Custom',
    dataTest: 'h2o-custom',
  },
  {
    key: H2OEngineSizeOptionKeyType.raw,
    text: 'Custom for Raw Data',
    dataTest: 'h2o-custom-raw',
  },
  {
    key: H2OEngineSizeOptionKeyType.compressed,
    text: 'Custom for Compressed Data',
    dataTest: 'h2o-custom-compressed',
  },
];
export const DefaultDriverlessEngineSize = DriverlessEngineSizeOptionKeyType.custom;
export const DefaultH2OEngineSize = H2OEngineSizeOptionKeyType.custom;

const driverlessDefaultSize = DriverlessEngineSizeOptions.find((o) => o.key === DefaultDriverlessEngineSize);
const h2oDefaultSize = H2OEngineSizeOptions.find((o) => o.key === DefaultH2OEngineSize);

const emptyEngineSharedFields = {
  id: undefined,
  displayName: undefined,
  version: '',
  maxIdleDuration: undefined,
  maxRunningDuration: undefined,
  annotations: undefined,
};

export const EmptyDAIEngine: AIEngine = {
  ...emptyEngineSharedFields,
  cpu: driverlessDefaultSize?.cpu,
  gpu: driverlessDefaultSize?.gpu,
  memoryBytes: driverlessDefaultSize?.memoryBytes,
  storageBytes: driverlessDefaultSize?.storageBytes,
};

export const EmptyH2OEngine: AIEngine = {
  ...emptyEngineSharedFields,
  cpu: h2oDefaultSize?.cpu,
  gpu: h2oDefaultSize?.gpu,
  memoryBytes: h2oDefaultSize?.memoryBytes,
  storageBytes: h2oDefaultSize?.storageBytes,
  nodeCount: 1,
};

export const defaultH2OCompressedEngineSizeRequest: Partial<H2OEngineServiceCalculateH2OEngineSizeCompressedDatasetRequest> =
  {
    rowsCount: '10000',
    columnsCount: '10',
  };

export const defaultH2ORawEngineSizeRequest: Partial<H2OEngineServiceCalculateH2OEngineSizeRawDatasetRequest> = {
  datasetSizeBytes: gibibytesToBytes(10),
};
