import { Stack } from '@fluentui/react';
import { BasicList, Item, itemStylesTag } from '@h2oai/ui-kit';
import { useEffect, useState } from 'react';

import { App } from '../../ai.h2o.cloud.appstore';
import { useApp } from '../../utils/hooks';

// TODO: this should be exported from ui-kit
interface CardBadge {
  color: `#${string}`;
  label: string;
}

interface AppAttributeBadgeProps {
  app: App;
}

export function AppAttributeBadge(props: AppAttributeBadgeProps) {
  const { app } = props,
    { getAppBadges } = useApp(),
    [badges, setBadges] = useState<CardBadge[]>([]),
    height = 20;

  useEffect(() => {
    if (app && app.attributes) setBadges(getAppBadges(app));
  }, [app]);

  return (
    <Stack horizontal tokens={{ childrenGap: 5 }} styles={{ root: { height } }}>
      {badges && badges.length > 0 && (
        <BasicList
          id="app-attribute-badges"
          data={badges}
          itemRenderer={(badge) => (
            <Item
              data={{
                hidden: false,
                isCategory: true,
                style: {
                  borderLeft: `4px solid ${badge.color}`,
                  backgroundColor: `${badge.color}30`,
                },
                title: badge.label,
              }}
              hasTooltip={false}
              styleField="style"
              labelField="title"
              styles={itemStylesTag}
            />
          )}
        />
      )}
    </Stack>
  );
}
