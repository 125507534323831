import {
  DetailsHeader,
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  IButtonStyles,
  IColumn,
  IDetailsFooterProps,
  IDetailsListStyles,
  IRawStyle,
  ITextField,
  SelectionMode,
  mergeStyleSets,
  mergeStyles,
} from '@fluentui/react';
import { Button, FontSizes, IH2OTheme, Link, Pivot, TextField, buttonStylesSmall, useThemeStyles } from '@h2oai/ui-kit';
import { ChangeEvent, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

/* eslint-disable @typescript-eslint/ban-ts-comment, import/no-webpack-loader-syntax */
// @ts-ignore
import TomlParser from 'workerize-loader-5!../../../../workers/tomlParser';
/* eslint-enable */

import { useDebouncedCallback, usePromiseCallback } from '../../../../utils/hooks';
import { CodeArea, ICodeAreaStyles } from '../../../CodeArea/CodeArea';

type Config = Record<string, string>;

const tomlParser = TomlParser();

type TOMLParserError = { message: { message: string } };

const getParseError = (error: Error | TOMLParserError) =>
  typeof error.message === `object` ? error.message.message : error.message;

const mapValuesToString = (obj: Record<string, unknown>): Record<string, string> =>
  Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, String(value)]));

const hasNestedData = (obj: Record<string, unknown>): boolean =>
  Object.values(obj).some((value) => value && typeof value === `object`);

const stylesCodeArea: Partial<ICodeAreaStyles> = {
  root: { flexGrow: 1, width: 'fit-content' },
  codeEditor: { resize: `none` },
};
const stylesErrorMessage = (theme: IH2OTheme) => ({
  color: theme.semanticColors?.inputErrorMessageText,
  fontSize: FontSizes.xxsmall,
  marginBlockStart: `-1.25rem`,
  minBlockSize: `1.5rem`,
});

type CodeEditorProps = {
  config: Config;
  onConfigChange: (newConfig: Config) => void;
};

const ID_CODE_EDITOR_ERROR_MESSAGE = `code-editor-error-message`;
const ID_CODE_EDITOR_HINT = `code-editor-hint`;

function CodeEditor(props: CodeEditorProps) {
  const [rawToml, setRawToml] = useState(`loading...`);
  const [configToRawToml, configToRawTomlLoading] = usePromiseCallback(
    (config: Config) => tomlParser?.stringify(config),
    [],
    {
      onError: (error) => {
        console.error(
          `The advanced config for the engine failed to parse config:`,
          props.config,
          `Received error:`,
          error
        );
      },
      onSuccess: (toml) => {
        setRawToml(toml);
      },
    }
  );
  useEffect(() => {
    configToRawToml(props.config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [parseTomlErrorMessage, setParseTomlErrorMessage] = useState(``);
  const [parseToml] = usePromiseCallback(
    (input: string) =>
      tomlParser.parse(input).then((data: Record<string, unknown>) => {
        if (hasNestedData(data)) {
          throw new Error(`Nested data (TOML tables) are not supported.`);
        }
        return mapValuesToString(data);
      }),
    [],
    {
      onError: (error) => {
        setParseTomlErrorMessage(getParseError(error));
      },
      onSuccess: () => {
        setParseTomlErrorMessage(``);
      },
      onSettled: ({ data }) => {
        if (data) {
          props.onConfigChange(data);
        }
      },
    }
  );
  const parseAndValidateToml = useDebouncedCallback(parseToml, 300);
  const parseAndValidateTomlRef = useRef(parseAndValidateToml);
  parseAndValidateTomlRef.current = parseAndValidateToml;
  useLayoutEffect(
    () => () => {
      parseAndValidateTomlRef.current?.flush();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <>
      <div id={ID_CODE_EDITOR_HINT}>
        You can add a configuration here using{' '}
        <a href="https://toml.io/en/" target="_blank" rel="noreferrer">
          TOML syntax
        </a>
        . Note that all comments will be lost when you leave the editor.
      </div>
      <CodeArea
        aria-describedby={`${ID_CODE_EDITOR_ERROR_MESSAGE} ${ID_CODE_EDITOR_HINT}`}
        aria-invalid={Boolean(parseTomlErrorMessage)}
        aria-label="Engine advanced configuration raw content"
        aria-busy={configToRawTomlLoading}
        disabled={configToRawTomlLoading}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
          const newValue = event.target.value;
          setRawToml(newValue);
          parseAndValidateToml(newValue);
        }}
        styles={stylesCodeArea}
        value={rawToml}
      />
      <div
        aria-live="polite"
        className={mergeStyles(useThemeStyles(stylesErrorMessage))}
        id={ID_CODE_EDITOR_ERROR_MESSAGE}
      >
        {parseTomlErrorMessage}
      </div>
    </>
  );
}

const stylesActionButton: Partial<IButtonStyles> = {
  icon: { margin: 0 },
};

function PairsListAddRow(props: IDetailsFooterProps & { onAddItem: (keyValuePair: [string, string]) => void }) {
  const [key, setKey] = useState(``);
  const [value, setValue] = useState(``);
  const keyFieldRef = useRef<ITextField>(null);
  const clearFields = () => {
    setKey(``);
    setValue(``);
  };
  const item = [
    // eslint-disable-next-line react/jsx-key
    <TextField
      ariaLabel="new config key"
      onChange={(event: any) => {
        setKey(event.target.value);
      }}
      ref={keyFieldRef}
      placeholder="key"
      value={key}
    />,
    // eslint-disable-next-line react/jsx-key
    <TextField
      ariaLabel="new config value"
      onChange={(event: any) => {
        setValue(event.target.value);
      }}
      placeholder="value"
      value={value}
    />,
    // eslint-disable-next-line react/jsx-key
    <Button
      disabled={!key || !value}
      iconName="add"
      onClick={() => {
        clearFields();
        props.onAddItem([key, value]);
        keyFieldRef.current?.focus();
      }}
      styles={stylesActionButton}
      title="Add new row"
    />,
  ];

  return <DetailsRow {...props} styles={{ root: { minBlockSize: `4rem` } }} item={item} itemIndex={-1} />;
}

const stylesActionButtonSmall = mergeStyleSets(stylesActionButton, buttonStylesSmall);

// this style is used to limit max-height of the PairsList component and make
// only its "contentWrapper" a scroll parent. The height must be limited all the
// way up from the "contentWrapper" and some containers, e.g. Viewport, which is
// part of the DetailsList, cannot be styled through the DetailsList, so the
// assignment of this ruleset to all appropriate places is slight complicated.
const stylesScrollParent: IRawStyle = {
  display: `flex`,
  flexDirection: `column`,
  overflow: `hidden`,
};

const stylesPairsListDetailsList: Partial<IDetailsListStyles> = {
  root: [
    stylesScrollParent,
    {
      '& .ms-DetailsRow': { blockSize: `auto` },
      '& [role="grid"]': stylesScrollParent,
    },
  ],
  contentWrapper: [stylesScrollParent, { overflowY: `auto` }],
};

export type PairsListProps = {
  config: Config;
  onAddItem: (keyValuePair: [string, string]) => void;
  onRemoveItem: (key: string) => void;
};

function PairsList({ onAddItem, onRemoveItem, config }: PairsListProps) {
  const items = useMemo(() => Object.entries(config), [config]);
  const columns = useMemo<IColumn[]>(
    () => [
      {
        key: `key`,
        name: `Key`,
        fieldName: `0`,
        minWidth: 130,
        flexGrow: 1,
      },
      {
        key: `value`,
        name: `Value`,
        fieldName: `1`,
        minWidth: 130,
        flexGrow: 1,
      },
      {
        key: `action`,
        name: `Action`,
        minWidth: 50,
        onRender: (item: typeof items[0]) => {
          return (
            <div style={{ textAlign: `end` }}>
              {(item as any)[2] || (
                <Button
                  iconName="remove"
                  onClick={() => {
                    onRemoveItem(item[0] as string);
                  }}
                  styles={stylesActionButtonSmall}
                  title="Delete row"
                />
              )}
            </div>
          );
        },
      },
    ],
    [onRemoveItem]
  );

  return (
    <DetailsList
      ariaLabelForGrid="Engine advanced configuration key-value entries"
      columns={columns}
      items={items}
      onRenderDetailsHeader={(detailsHeaderProps) => {
        return detailsHeaderProps ? (
          <DetailsHeader {...detailsHeaderProps} styles={{ root: { paddingTop: 0 } }} />
        ) : null;
      }}
      onRenderDetailsFooter={(detailsFooterProps) => <PairsListAddRow {...detailsFooterProps!} onAddItem={onAddItem} />}
      onShouldVirtualize={() => false}
      selectionMode={SelectionMode.none}
      styles={stylesPairsListDetailsList}
      layoutMode={DetailsListLayoutMode.justified}
    />
  );
}

const advancedConfigurationStyles = {
  root: [
    {
      blockSize: `100%`,
      display: `flex`,
      flexFlow: `column nowrap`,
      gap: `1.5rem`,
    },
    { '& > .ms-Viewport': stylesScrollParent },
  ],
  topContent: {
    display: `flex`,
    alignItems: `end`,
    justifyContent: `space-between`,
  },
};
const advancedConfigurationClassNames = mergeStyleSets(advancedConfigurationStyles);

export type AdvancedConfigurationProps = {
  config: Record<string, string>;
  onConfigChange: (newConfig: Record<string, string>) => void;
};

export function AdvancedConfiguration({ config, onConfigChange }: AdvancedConfigurationProps) {
  const addItem = (keyValuePair: [string, string]) => {
    onConfigChange({ ...config, ...Object.fromEntries([keyValuePair]) });
  };
  const removeItem = (key: string) => {
    const { [key]: _removed, ...other } = config;
    onConfigChange(other);
  };

  return (
    <div className={advancedConfigurationClassNames.root}>
      <Link
        href="https://docs.h2o.ai/driverless-ai/latest-stable/docs/userguide/config_toml.html#using-the-config-toml-file"
        target="_blank"
      >
        DAI Config Documentation
      </Link>
      <Pivot
        styles={{ root: { marginBottom: 16 } }}
        pivotContainerStyles={{ root: { marginBottom: 12 } }}
        items={[
          {
            key: 'table',
            iconName: 'Table',
            headerText: 'Table View',
            content: <PairsList config={config} onAddItem={addItem} onRemoveItem={removeItem} />,
          },
          {
            key: 'code',
            iconName: 'Code',
            headerText: 'Code View',
            content: <CodeEditor config={config} onConfigChange={onConfigChange} />,
          },
        ]}
      />
    </div>
  );
}
