import { MessageBarType } from '@fluentui/react';
import { MessageBar } from '@h2oai/ui-kit';

type ErrProps = {
  err: string;
  onDismissErr: () => void;
  className?: string;
};

export function Err({ err, onDismissErr }: ErrProps) {
  return (
    // TODO: update the way we add this to layout so that the max-width is appropriate for each page
    <MessageBar messageBarType={MessageBarType.error} onDismiss={onDismissErr}>
      {err || `Unknown error occurred.`}
    </MessageBar>
  );
}
