/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/internal_h2o_version_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Policy for downloading an image in K8s.
 * 
 *  - IMAGE_PULL_POLICY_UNSPECIFIED: Unspecified image pull policy.
 *  - IMAGE_PULL_POLICY_ALWAYS: Every time the container is launched, the container image registry is queried
 * to resolve the name to an image digest.
 *  - IMAGE_PULL_POLICY_NEVER: Do not try to fetch the image. If the image is already present locally,
 * the container will start. If the image is not present, container startup will fail.
 *  - IMAGE_PULL_POLICY_IF_NOT_PRESENT: The image is pulled only if it is not already present locally.
 * @export
 * @enum {string}
 */
export enum V1ImagePullPolicy {
    Unspecified = 'IMAGE_PULL_POLICY_UNSPECIFIED',
    Always = 'IMAGE_PULL_POLICY_ALWAYS',
    Never = 'IMAGE_PULL_POLICY_NEVER',
    IfNotPresent = 'IMAGE_PULL_POLICY_IF_NOT_PRESENT'
}

export function V1ImagePullPolicyFromJSON(json: any): V1ImagePullPolicy {
    return V1ImagePullPolicyFromJSONTyped(json, false);
}

export function V1ImagePullPolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ImagePullPolicy {
    return json as V1ImagePullPolicy;
}

export function V1ImagePullPolicyToJSON(value?: V1ImagePullPolicy | null): any {
    return value as any;
}

