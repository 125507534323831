import {
  Card,
  CardList,
  IEmptyMessageProps,
  ImageLazyLoader,
  Sizes,
  WidgetPanel,
  cardListStylesDashboard,
  cardListStylesNoGap,
  cardStylesNoGap,
  widgetPanelStylesCardList,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { App } from '../../../ai.h2o.cloud.appstore';
import { RoutePaths } from '../../Routes';

export interface IAppCardListWidgetProps {
  windowWidth: number;
  data: App[];
  title: string;
  loadingMessage?: string;
}

export const AppCardListWidget = ({ title, windowWidth, data, loadingMessage }: IAppCardListWidgetProps) => {
  const [columnCount, setColumnCount] = useState(1);
  const [isContentReady, setIsContentReady] = useState(false);
  const history = useHistory();
  const onRefChange = useCallback(
    (el: HTMLDivElement) => {
      if (el && el.clientWidth) {
        const count = Math.ceil(el.clientWidth / Sizes.cardWidthOfWidget);
        if (count !== columnCount) {
          setColumnCount(count);
        }
      }
    },
    // eslint-disable-next-line
    [columnCount, windowWidth]
  );
  const emptyMessageProps: IEmptyMessageProps | undefined =
    !data || !data.length ? { message: `You don't have any apps` } : undefined;
  useEffect(() => {
    setIsContentReady(true);
  }, []);
  return (
    <WidgetPanel
      styles={widgetPanelStylesCardList}
      title={title}
      linkText="View all"
      linkHref={RoutePaths.APPSTORE}
      loadingMessage={loadingMessage}
      emptyMessageProps={emptyMessageProps}
    >
      <CardList
        ref={onRefChange}
        styles={[cardListStylesNoGap, cardListStylesDashboard]}
        idField="id"
        nameField="title"
        data={data.slice(0, columnCount)}
        columnCount={columnCount}
        itemRenderer={(app: App) => (
          <Card<App>
            styles={cardStylesNoGap}
            data={app}
            data-test={`${app.name}--app-tile`}
            idField="id"
            titleField="title"
            imagePathField="iconLocation"
            useImageLazyLoader
            onClick={() => history.push(`/apps/${app.id}`)}
            onPin={() => null}
            onLike={() => null}
          />
        )}
      />
      <ImageLazyLoader
        containerSelector=".h2o-CardList-root"
        isBackgroundImage
        isReady={isContentReady}
        errorImagePath="logo512.png"
        onLoaded={() => setIsContentReady(false)}
      />
    </WidgetPanel>
  );
};
