import { MessageBar, MessageBarType } from '@fluentui/react';
import { Loader, loaderStylesSpinnerXLarge } from '@h2oai/ui-kit';

import EmptyStateMessage from '../EmptyStateMessage/EmptyStateMessage';

type NoAppsDisplayProps = {
  errorMsg: string | null;
  loading: boolean | null;
  downloadInProgress: boolean;
  onDismissErr: () => void;
};

export default function NoAppsDisplay({ errorMsg, loading, downloadInProgress, onDismissErr }: NoAppsDisplayProps) {
  if (loading) return <Loader styles={loaderStylesSpinnerXLarge} label="Loading apps..." />;
  if (downloadInProgress) return <Loader styles={loaderStylesSpinnerXLarge} label="The app is being downloaded..." />;
  if (errorMsg) {
    return (
      <div>
        <MessageBar styles={{ root: { marginTop: 16 } }} messageBarType={MessageBarType.error} onDismiss={onDismissErr}>
          {errorMsg}
        </MessageBar>
      </div>
    );
  }
  return (
    <EmptyStateMessage title="Oops seems you don't have any apps" message="Click on Upload App to add your first app" />
  );
}
