import { memo } from 'react';

import { AIEMOpType, AIEngine } from '../../../../aiem';
import { AIEMPanelEdit } from '../AIEMPanelEdit/AIEMPanelEdit';
import { AIEMPanelView } from '../AIEMPanelView/AIEMPanelView';

// NOTES on conversions:
// The API accepts strings only for engine parameters.
// The API provides only strings for engine parameter values, constraints, and defaults.
// The UI controls sometimes use numbers (Slider), and sometimes use strings (Spinner)
// The UI controls need to show human-usable units (hours, not seconds; gibibytes, not bytes)
// We probably want to convert the units for these controls (both type and magnitude)
// immediately before inputting the data into the control, and immediately when outputting - not several layers up
// So - until the data gets to the control, it should remain in the same format as the API,
// and when the data comes out of the control, it should be immediately converted into the API data format (string, and seconds/bytes)

export type AIEMPanelProps = {
  onDismiss: () => void;
  op: AIEMOpType;
  engine: AIEngine;
  onChange: (engine: AIEngine) => void;
  onSave: () => void;
};

const AIEMPanelComponent = ({ onDismiss, engine, op, onChange, onSave }: AIEMPanelProps) => {
  switch (op) {
    case AIEMOpType.create: {
      return (
        <AIEMPanelEdit
          operationCreate={true}
          engine={engine}
          onSave={onSave}
          onChange={onChange}
          onDismiss={onDismiss}
        />
      );
    }
    case AIEMOpType.edit:
      return (
        <AIEMPanelEdit
          data-test="aiem-panel"
          engine={engine}
          onSave={onSave}
          onChange={onChange}
          onDismiss={onDismiss}
        />
      );
    case AIEMOpType.view:
      return <AIEMPanelView data-test="aiem-panel" engine={engine} onDismiss={onDismiss} />;
    default:
      throw new Error(`The operation "${op}" is not supported by AIEMPanel.`);
  }
};

export const AIEMPanel = memo(AIEMPanelComponent);
