import { Stack } from '@fluentui/react';
import { FontWeights, ITextWithCopyStyles, TextWithCopy, textWithCopyStylesPlain } from '@h2oai/ui-kit';

import Cell, { textCellStyles } from './Cell';

interface TitleCellContentsProps {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
  copyTitle?: boolean;
  copySubtitle?: boolean;
}

export const copyTextCellStyles: ITextWithCopyStyles = {
  ...textWithCopyStylesPlain,
  root: { ...(textWithCopyStylesPlain.root as Record<string, any>), fontSize: 'inherit', maxWidth: 335 },
};

export function TitleCellContents(props: TitleCellContentsProps) {
  const { title, subtitle, children, copyTitle = false, copySubtitle = true } = props;
  return (
    <Stack>
      <span style={{ fontWeight: FontWeights.bold, marginBottom: 4 }}>
        {copyTitle ? <TextWithCopy text={title} styles={copyTextCellStyles} /> : title}
      </span>
      <span style={textCellStyles}>
        {copySubtitle ? <TextWithCopy text={subtitle} styles={copyTextCellStyles} /> : subtitle}
      </span>
      {children}
    </Stack>
  );
}

export function TitleCell(props: TitleCellContentsProps) {
  return (
    <Cell>
      <TitleCellContents {...props} />
    </Cell>
  );
}
