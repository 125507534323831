import { ITextStyles, Stack } from '@fluentui/react';
import {
  Button,
  IButtonProps,
  IH2OTheme,
  IconName,
  PageHeader,
  Sizes,
  buttonStylesLink,
  buttonStylesLinkBlack,
  buttonStylesPrimary,
  useTheme,
} from '@h2oai/ui-kit';
import { useHistory } from 'react-router-dom';

import { RoutePaths } from '../../pages/Routes';
import { stackStylesNoLeftNav } from '../../themes/themes';

export const stackStylesRoot = (theme: IH2OTheme): Partial<ITextStyles> => ({
  root: {
    borderRadius: Sizes.borderRadius,
    padding: `1.5rem`,
    alignItems: 'stretch',
    backgroundColor: theme.palette?.white,
  },
});

export interface ISubpageProps {
  children?: React.ReactNode;
  parentPage: RoutePaths;
  primaryButtonProps?: IButtonProps;
  subtitle?: string;
  title: string;
}

export function Subpage(props: ISubpageProps) {
  const { children, parentPage, primaryButtonProps, subtitle, title } = props,
    history = useHistory(),
    theme = useTheme();
  return (
    <Stack styles={stackStylesNoLeftNav}>
      <Button
        styles={[buttonStylesLink, buttonStylesLinkBlack]}
        text="Back"
        iconName={IconName.Back}
        onClick={() => (window.history.state ? history.goBack() : history.push(parentPage))}
      />
      <Stack styles={stackStylesRoot(theme)}>
        <Stack horizontal horizontalAlign="space-between">
          <Stack.Item disableShrink>
            <PageHeader pageTitle={title} description={subtitle} />
          </Stack.Item>
          <Stack horizontalAlign="end" styles={{ root: { width: '100%' } }}>
            <Button styles={buttonStylesPrimary} {...primaryButtonProps} />
          </Stack>
        </Stack>
        {children}
      </Stack>
    </Stack>
  );
}
