/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/h2o_engine_constraint_set_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RpcStatus,
    RpcStatusFromJSON,
    RpcStatusToJSON,
    V1GetH2OEngineConstraintSetResponse,
    V1GetH2OEngineConstraintSetResponseFromJSON,
    V1GetH2OEngineConstraintSetResponseToJSON,
} from '../models';

export interface H2OEngineConstraintSetServiceGetH2OEngineConstraintSetRequest {
    name: string;
}

/**
 * 
 */
export class H2OEngineConstraintSetServiceApi extends runtime.BaseAPI {

    /**
     * Returns the current H2OEngineConstraintSet.
     */
    async h2OEngineConstraintSetServiceGetH2OEngineConstraintSetRaw(requestParameters: H2OEngineConstraintSetServiceGetH2OEngineConstraintSetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1GetH2OEngineConstraintSetResponse>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling h2OEngineConstraintSetServiceGetH2OEngineConstraintSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1GetH2OEngineConstraintSetResponseFromJSON(jsonValue));
    }

    /**
     * Returns the current H2OEngineConstraintSet.
     */
    async h2OEngineConstraintSetServiceGetH2OEngineConstraintSet(requestParameters: H2OEngineConstraintSetServiceGetH2OEngineConstraintSetRequest, initOverrides?: RequestInit): Promise<V1GetH2OEngineConstraintSetResponse> {
        const response = await this.h2OEngineConstraintSetServiceGetH2OEngineConstraintSetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
