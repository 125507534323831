import { IStyle } from '@fluentui/react';
import { FontSizes, FontWeights, IH2OTheme, LineHeights } from '@h2oai/ui-kit';

export interface IMarkdownStyles {
  root?: IStyle;
}

export const markdownStylesDefault = (theme: IH2OTheme): Partial<IMarkdownStyles> => ({
  root: {
    h1: {
      fontSize: FontSizes.xlarge,
      fontWeights: FontWeights.bold,
    },
    h2: {
      fontSize: FontSizes.large,
      fontWeights: FontWeights.semiBold,
    },
    h3: {
      fontWeights: FontWeights.semiBold,
    },
    h4: {
      fontWeights: 500,
    },
    img: {
      maxWidth: `100%`,
    },
    p: {
      color: theme.semanticColors?.textQuinary,
      lineHeight: LineHeights.medium,
      fontSize: FontSizes.textPrimary,
    },
    ul: {
      listStyleType: 'disc',
    },
    'ul li::marker': {
      color: theme.semanticColors?.textQuinary,
      fontSize: FontSizes.xlarge,
    },
    'ul li': {
      color: theme.semanticColors?.textQuinary,
      fontSize: theme.semanticColors?.textQuaternary,
    },
  },
});
