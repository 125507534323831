import { useCallback } from 'react';

import { AppInstance_Visibility, AppPreconditionStatus, App_Visibility } from '../ai.h2o.cloud.appstore';
import AppListPage from '../components/AppListPage/AppListPage';
import { AdminAppService } from '../services/api';
import { useApp } from '../utils/hooks';

export default function AdminAppsPage() {
  const { getAdminApps } = useApp();

  const fetchApps = useCallback(
    async () =>
      getAdminApps({
        limit: 1000,
        offset: 0,
        visibility: App_Visibility.VISIBILITY_UNSPECIFIED,
        allUsers: true,
        name: '',
        latestVersions: false,
        withPreference: false,
        tags: [],
        conditionsStatus: AppPreconditionStatus.STATUS_UNSPECIFIED,
        visibilities: [],
      }),
    []
  );

  const fetchInstances = useCallback(async () => {
    const result = await AdminAppService.listAppInstances({
      appId: '',
      includeAppDetails: true,
      visibility: AppInstance_Visibility.VISIBILITY_UNSPECIFIED,
      allUsers: true,
    });
    return result.instances;
  }, []);

  return <AppListPage title="Admin Apps" fetchApps={fetchApps} fetchInstances={fetchInstances} isAdmin />;
}
