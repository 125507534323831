import { defaultBasePath } from './constants';
import {
  ClientService,
  GetClientRequest,
  GetClientResponse,
  ListClientsRequest,
  ListClientsResponse,
} from './gen/ai/h2o/cloud/discovery/v1/client_api.pb';
import { EnvironmentService } from './gen/ai/h2o/cloud/discovery/v1/environment_api.pb';
import {
  GetServiceRequest,
  GetServiceResponse,
  ListServicesRequest,
  ListServicesResponse,
  ServiceService,
} from './gen/ai/h2o/cloud/discovery/v1/service_api.pb';
import {
  GetClientUtilResponse,
  GetEnvironmentUtilResponse,
  GetServiceUtilResponse,
  ListClientsUtilResponse,
  ListServicesUtilResponse,
  _GetEnvironmentResponse,
} from './models';

const basePath = process.env.REACT_APP_USE_MOCK_SERVER ? defaultBasePath : '';
const requestHeaders = {
  pathPrefix: `${basePath}/.ai.h2o.cloud.discovery`,
};

export async function getEnvironment(): Promise<GetEnvironmentUtilResponse> {
  try {
    const response = await EnvironmentService.GetEnvironment({}, requestHeaders);
    return (response as _GetEnvironmentResponse).environment;
  } catch (error: unknown) {
    console.error('Failed to get discovery service environment...');
    console.error(error);
    return undefined;
  }
}

export const getClient = async (clientName: string): Promise<GetClientUtilResponse> => {
  try {
    const response = await ClientService.GetClient({ nameclients: clientName } as GetClientRequest, requestHeaders);
    return (response as GetClientResponse).client;
  } catch (error: unknown) {
    console.error(`Failed to get discovery service client "${clientName}"...`);
    console.error(error);
    return undefined;
  }
};

export const listClients = async (params: ListClientsRequest): Promise<ListClientsUtilResponse> => {
  try {
    const response = await ClientService.ListClients(params || {}, requestHeaders);
    return (response as ListClientsResponse).clients;
  } catch (error: unknown) {
    console.error('Failed to list discovery service clients...');
    console.error(error);
    return undefined;
  }
};

export const getService = async (serviceName: string): Promise<GetServiceUtilResponse> => {
  try {
    const response = await ServiceService.GetService(
      { nameservices: serviceName } as GetServiceRequest,
      requestHeaders
    );
    return (response as GetServiceResponse).service;
  } catch (error: unknown) {
    console.error(`Failed to get discovery service client "${serviceName}"...`);
    console.error(error);
    return undefined;
  }
};

export const listServices = async (params: ListServicesRequest): Promise<ListServicesUtilResponse> => {
  try {
    const response = await ServiceService.ListServices(params || {}, requestHeaders);
    return (response as ListServicesResponse).services;
  } catch (error: unknown) {
    console.error('Failed to list discovery service services...');
    console.error(error);
    return undefined;
  }
};
