/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/dai_engine_constraint_set_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1DAIEngineConstraintSet,
    V1DAIEngineConstraintSetFromJSON,
    V1DAIEngineConstraintSetFromJSONTyped,
    V1DAIEngineConstraintSetToJSON,
} from './V1DAIEngineConstraintSet';

/**
 * Response message for getting the current DAIEngineConstraintSet.
 * @export
 * @interface V1GetDAIEngineConstraintSetResponse
 */
export interface V1GetDAIEngineConstraintSetResponse {
    /**
     * 
     * @type {V1DAIEngineConstraintSet}
     * @memberof V1GetDAIEngineConstraintSetResponse
     */
    daiEngineConstraintSet?: V1DAIEngineConstraintSet;
}

export function V1GetDAIEngineConstraintSetResponseFromJSON(json: any): V1GetDAIEngineConstraintSetResponse {
    return V1GetDAIEngineConstraintSetResponseFromJSONTyped(json, false);
}

export function V1GetDAIEngineConstraintSetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1GetDAIEngineConstraintSetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'daiEngineConstraintSet': !exists(json, 'daiEngineConstraintSet') ? undefined : V1DAIEngineConstraintSetFromJSON(json['daiEngineConstraintSet']),
    };
}

export function V1GetDAIEngineConstraintSetResponseToJSON(value?: V1GetDAIEngineConstraintSetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'daiEngineConstraintSet': V1DAIEngineConstraintSetToJSON(value.daiEngineConstraintSet),
    };
}

