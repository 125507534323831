import { AIEngine, EngineConstraintSet, V1AdjustedDAIProfile } from '../../../../../aiem';
import { bytesToGibibytes } from '../../../utils';
import { MetadataTable, MetadataTableBody } from '../../MetadataTable/MetadataTable';
import EngineSizeTableRows from './EngineSizeTableRows';

interface DisplayPresetEngineSizeProps {
  engine: AIEngine;
  constraintSet?: EngineConstraintSet;
  profile?: V1AdjustedDAIProfile;
  memoryValueGb: number;
  storageValueGb: number;
}

export default function DisplayPresetEngineSize({
  engine,
  constraintSet,
  profile,
  memoryValueGb,
  storageValueGb,
}: DisplayPresetEngineSizeProps) {
  return (
    <MetadataTable>
      <MetadataTableBody>
        <EngineSizeTableRows
          loading={!constraintSet}
          engineType={engine?.engineType}
          profile={profile}
          cpu={profile?.cpu || engine.cpu}
          gpu={profile?.gpu || engine.gpu}
          nodeCount={engine.nodeCount}
          memoryValueGb={bytesToGibibytes(profile?.memoryBytes) || memoryValueGb}
          storageValueGb={bytesToGibibytes(profile?.storageBytes) || storageValueGb}
        />
      </MetadataTableBody>
    </MetadataTable>
  );
}
