import { IImageStyleProps, IImageStyles, IStyleFunctionOrObject, Image, ImageFit } from '@fluentui/react';

import Cell from './Cell';

interface IconCellContentsProps {
  src: string;
  size?: number;
  styles?: React.CSSProperties;
}

export function IconCellContents(props: IconCellContentsProps) {
  const { styles = {}, size = 40 } = props;
  const imageStyles = { root: { height: size, width: size, flexShrink: 0, ...styles } } as IStyleFunctionOrObject<
    IImageStyleProps,
    IImageStyles
  >;
  return <Image imageFit={ImageFit.centerContain} src={props.src} styles={imageStyles} />;
}

export function IconCell(props: IconCellContentsProps) {
  return (
    <Cell>
      <IconCellContents {...props} />
    </Cell>
  );
}
