import { ITextProps, Stack, Text } from '@fluentui/react';
import { ConfirmDialog, FontWeights } from '@h2oai/ui-kit';

import { AIEMOpType, AIEngine, V1EngineType, getIdFromName } from '../../../../aiem';

export type AIEMConfirmDialogProps = {
  engines?: AIEngine[] | null;
  hidden: boolean;
  op: AIEMOpType.pause | AIEMOpType.delete | AIEMOpType.resume;
  onConfirm: () => unknown;
  onDismiss: () => unknown;
};

enum ActionList {
  PAUSE = 'pause',
  SHUTDOWN = 'shut down',
  DELETE = 'delete',
  RESUME = 'resume',
}

const copyMap = {
  resume: {
    title: 'Resume AI Engine',
    description: '',
    action: ActionList.RESUME,
    buttonText: 'Resume',
  },
  pause: {
    title: 'Pause AI Engine',
    description:
      'Any running machine learning experiments or interpretation jobs will be paused and need to be manually restarted when resuming your instance.',
    action: ActionList.PAUSE,
    buttonText: 'Pause',
  },
  shutdown: {
    title: 'Shut Down AI Engine',
    description: 'H2O-3 engines cannot be resumed after shutting down. Export any models you may want to access later.',
    action: ActionList.SHUTDOWN,
    buttonText: 'Shut down',
  },
  delete: {
    title: 'Delete AI Engine',
    description: 'Deleting your engine will erase both your configuration and data.',
    action: ActionList.DELETE,
    buttonText: 'Delete',
  },
};

const getActionName = (op: AIEMOpType, engines?: AIEngine[] | null) => {
  switch (op) {
    case AIEMOpType.pause:
      if (engines?.length === 1 && engines[0].type === V1EngineType.H2O) {
        return ActionList.SHUTDOWN;
      } else {
        return ActionList.PAUSE;
      }
    case AIEMOpType.delete:
      return ActionList.DELETE;
    case AIEMOpType.resume:
    default:
      return ActionList.RESUME;
  }
};

const getHelpText = (op: ActionList) => {
  switch (op) {
    case ActionList.PAUSE:
      return 'This bulk action will pause any selected Driverless AI instance. H2O-3 clusters will be ignored as they cannot be resumed but only permanently shutdown.';
    case ActionList.RESUME:
      return 'This bulk action will resume any selected Driverless AI instance. H2O-3 clusters will be ignored as they cannot be resumed but only permanently shutdown.';
    default:
      return;
  }
};

export const AIEMConfirmDialog = ({ engines, hidden, op, onConfirm, onDismiss }: AIEMConfirmDialogProps) => {
  const action = getActionName(op),
    actionCopy = copyMap[action],
    helpText = getHelpText(action),
    boldTextStyle: ITextProps = { styles: { root: { fontWeight: FontWeights.bold } } },
    actionItemName = engines?.length === 1 ? engines[0].displayName : `${engines?.length} items`,
    content = (
      <Stack tokens={{ childrenGap: 10 }}>
        <Text {...boldTextStyle}>{`Are you sure you want to ${actionCopy.action} ${actionItemName}?`}</Text>
        <Text>{actionCopy.description}</Text>
        {engines && engines.length > 1 && helpText && <Text>{helpText}</Text>}
        <Stack
          styles={{
            root: {
              display: 'grid',
              gridTemplateColumns: '150px 200px',
              gridTemplateRows: 'auto',
            },
          }}
        >
          {engines && engines.length === 1 && (
            <>
              <Text {...boldTextStyle}>Name:</Text>
              <Text>{engines[0].displayName}</Text>
              <Text {...boldTextStyle}>ID:</Text>
              <Text>{getIdFromName(engines[0].name) || ''}</Text>
              <Text {...boldTextStyle}>Created at:</Text>
              <Text>{engines[0].createTime?.toLocaleString()}</Text>
            </>
          )}
        </Stack>
      </Stack>
    );
  return (
    <ConfirmDialog
      confirmationButtonText={actionCopy.buttonText}
      hidden={hidden}
      content={content}
      title={actionCopy.title}
      onConfirm={onConfirm}
      onDismiss={onDismiss}
    />
  );
};
