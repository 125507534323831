import { IIconProps, Icon, MessageBarType, Stack, Text, mergeStyleSets } from '@fluentui/react';
import { Button, FontSizes, FontWeights, IH2OTheme, Sizes, buttonStylesGhost, useTheme, useToast } from '@h2oai/ui-kit';
import { useEffect, useState } from 'react';

import { AppPrecondition, AppPreconditionStatus } from '../../../../ai.h2o.cloud.appstore';
import { AppPivotRightCard } from '../../../../components/AppPivot/AppPivot';
import { AdminPreconditionService } from '../../../../services/api';

const LIST_HEADING_ID = `preconditions_heading`,
  LIST_SUBHEADING_ID = `precondition_subheading`;

const getListItemIcon = (status: AppPreconditionStatus, theme: IH2OTheme): IIconProps => {
  switch (status) {
    case AppPreconditionStatus.FAILED:
      return {
        ariaLabel: `Status failed`,
        iconName: `SkypeCircleMinus`,
        style: { color: theme.palette?.red500 },
      };
    case AppPreconditionStatus.PASSED:
      return {
        ariaLabel: `Status passed`,
        iconName: `SkypeCircleCheck`,
        style: { color: theme.palette?.green500 },
      };
    case AppPreconditionStatus.WARNING:
      return {
        ariaLabel: `Status warning`,
        iconName: `WarningSolid`,
        style: { color: theme.palette?.yellow500 },
      };

    case AppPreconditionStatus.STATUS_UNSPECIFIED:
      return {
        ariaLabel: `Status unspecified`,
        iconName: `SkypeCircleMinus`,
        style: { color: theme.palette?.gray500 },
      };
  }
};

const preconditionListStyles = mergeStyleSets({
  ul: {
    columnGap: `0.5rem`,
    display: `grid`,
    gridTemplateColumns: `auto 1fr`,
    listStyle: `none`,
    margin: 0,
    padding: 0,
    rowGap: `1rem`,
    '& > li': {
      display: `contents`,
    },
    '& > li > i': {
      gridColumn: `1`,
      marginTop: `0.1rem`,
      userSelect: `none`,
    },
    '& > li > div': {
      gridColumn: `2`,
    },
  },
});

function PreconditionsList({ preconditions }: { preconditions: AppPrecondition[] }) {
  const theme = useTheme();
  return (
    <ul aria-labelledby={LIST_HEADING_ID} aria-describedby={LIST_SUBHEADING_ID} className={preconditionListStyles.ul}>
      {preconditions.map(({ description, displayName, status }) => {
        return (
          <li key={displayName}>
            <Icon {...getListItemIcon(status, theme)} />
            <div>{[displayName, description].filter(Boolean).join(`: `)}</div>
          </li>
        );
      })}
    </ul>
  );
}

const textStylesRightContentHeading = (theme: IH2OTheme) => ({
  root: {
    color: theme.semanticColors?.textQuaternary,
    fontSize: FontSizes.xsmall,
    fontWeight: FontWeights.bold as const,
    marginBottom: `1rem`,
  },
});

const textStylesRightContentSubheading = (theme: IH2OTheme) => ({
  root: {
    color: theme.semanticColors?.textQuinary,
    fontSize: FontSizes.xsmall,
  },
});

export interface IPreconditionsPureProps {
  onReloadPreconditions: () => void;
  preconditionsLoading: boolean;
  preconditions: AppPrecondition[];
}

export function PreconditionsPure({
  onReloadPreconditions,
  preconditions,
  preconditionsLoading,
}: IPreconditionsPureProps) {
  const theme = useTheme();
  return (
    <AppPivotRightCard>
      <Stack tokens={{ childrenGap: '1.5rem' }}>
        <div>
          <Button
            buttonContainerStyles={{
              root: {
                float: `right`,
                marginTop: `-${Sizes.buttonPaddingTopBottom}px`,
              },
            }}
            disabled={preconditionsLoading}
            loading={preconditionsLoading}
            onClick={onReloadPreconditions}
            text="Reevaluate"
            styles={[
              buttonStylesGhost,
              {
                root: {
                  backgroundColor: `transparent`,
                },
              },
            ]}
          />
          <Text block id={LIST_HEADING_ID} styles={textStylesRightContentHeading(theme)}>
            Preconditions
          </Text>
          <Text block id={LIST_SUBHEADING_ID} styles={textStylesRightContentSubheading(theme)}>
            The application can&apos;t be run by users until all of the following preconditions have been satisfied.
            Please follow the Installation guide and the instructions below to address any failed preconditions:
          </Text>
        </div>
        <PreconditionsList preconditions={preconditions} />
      </Stack>
    </AppPivotRightCard>
  );
}

export interface IPreconditionsProps {
  id: string; // the FederatedApp id
}

export function Preconditions({ id }: IPreconditionsProps) {
  const [preconditions, setPreconditions] = useState<AppPrecondition[]>([]),
    [loading, setLoading] = useState(true),
    { addToast } = useToast();
  const loadPreconditions = () => {
    setLoading(true);
    return AdminPreconditionService.refreshAppPreconditions({ id })
      .finally(() => {
        setLoading(false);
      })
      .then((result) => {
        setPreconditions(result.conditions);
      });
  };
  useEffect(() => {
    loadPreconditions().catch(() => {
      addToast({
        messageBarType: MessageBarType.error,
        message: `Failed to load a list of preconditions.`,
      });
    });
  }, [id]);
  return (
    <PreconditionsPure
      onReloadPreconditions={() => {
        loadPreconditions().catch(() => {
          addToast({
            messageBarType: MessageBarType.error,
            message: `Failed to reload a list of preconditions.`,
          });
        });
      }}
      preconditionsLoading={loading}
      preconditions={preconditions}
    />
  );
}
