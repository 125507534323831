/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/engine_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1EngineState,
    V1EngineStateFromJSON,
    V1EngineStateFromJSONTyped,
    V1EngineStateToJSON,
} from './V1EngineState';
import {
    V1EngineType,
    V1EngineTypeFromJSON,
    V1EngineTypeFromJSONTyped,
    V1EngineTypeToJSON,
} from './V1EngineType';

/**
 * 
 * @export
 * @interface V1Engine
 */
export interface V1Engine {
    /**
     * Engine resource name.
     * @type {string}
     * @memberof V1Engine
     */
    readonly name?: string;
    /**
     * Globally unique identifier of the resource.
     * @type {string}
     * @memberof V1Engine
     */
    readonly uid?: string;
    /**
     * Name of an entity that created the Engine.
     * @type {string}
     * @memberof V1Engine
     */
    readonly creator?: string;
    /**
     * Human-readable name of creator.
     * @type {string}
     * @memberof V1Engine
     */
    readonly creatorDisplayName?: string;
    /**
     * 
     * @type {V1EngineType}
     * @memberof V1Engine
     */
    type?: V1EngineType;
    /**
     * 
     * @type {V1EngineState}
     * @memberof V1Engine
     */
    state?: V1EngineState;
    /**
     * Indicates whether changes to the resource are in progress.
     * If true, current state of the Engine does not match the intended state.
     * @type {boolean}
     * @memberof V1Engine
     */
    readonly reconciling?: boolean;
    /**
     * Time when the Engine creation was requested.
     * @type {Date}
     * @memberof V1Engine
     */
    readonly createTime?: Date;
    /**
     * Time when the Engine was deleted.
     * @type {Date}
     * @memberof V1Engine
     */
    readonly deleteTime?: Date | null;
    /**
     * Time when the Engine was last updated.
     * @type {Date}
     * @memberof V1Engine
     */
    readonly updateTime?: Date | null;
    /**
     * Time when the Engine was last resumed (or started for the first time).
     * @type {Date}
     * @memberof V1Engine
     */
    readonly resumeTime?: Date | null;
    /**
     * The URL address to initiate login flow.
     * @type {string}
     * @memberof V1Engine
     */
    readonly loginUrl?: string;
    /**
     * Additional arbitrary metadata associated with the Engine. Annotations are key/value pairs.
     * @type {{ [key: string]: string; }}
     * @memberof V1Engine
     */
    readonly annotations?: { [key: string]: string; };
    /**
     * Human-readable name of the Engine. Contains at most 63 characters and is not unique.
     * @type {string}
     * @memberof V1Engine
     */
    readonly displayName?: string;
    /**
     * Engine version identifier.
     * For example: "1.10.1" or "latest".
     * @type {string}
     * @memberof V1Engine
     */
    readonly version?: string;
    /**
     * Indicates, whether the used version is deprecated.
     * @type {boolean}
     * @memberof V1Engine
     */
    readonly deprecatedVersion?: boolean;
    /**
     * The amount of CPU units in total requested by this Engine.
     * @type {number}
     * @memberof V1Engine
     */
    readonly cpu?: number;
    /**
     * The amount of GPU units in total requested by this Engine.
     * @type {number}
     * @memberof V1Engine
     */
    readonly gpu?: number;
    /**
     * The amount of memory in bytes requested by this Engine.
     * @type {string}
     * @memberof V1Engine
     */
    readonly memoryBytes?: string;
    /**
     * The amount of storage in bytes requested by this Engine.
     * @type {string}
     * @memberof V1Engine
     */
    readonly storageBytes?: string;
    /**
     * Indicates, whether the used version can be upgraded to a later one.
     * @type {boolean}
     * @memberof V1Engine
     */
    readonly upgradeAvailable?: boolean;
    /**
     * Maximum time the engine can be idle.
     * @type {string}
     * @memberof V1Engine
     */
    readonly maxIdleDuration?: string | null;
    /**
     * Maximum time the engine can be running.
     * @type {string}
     * @memberof V1Engine
     */
    readonly maxRunningDuration?: string | null;
    /**
     * Current time the engine is idle.
     * @type {string}
     * @memberof V1Engine
     */
    readonly currentIdleDuration?: string | null;
    /**
     * Current time the engine is running.
     * @type {string}
     * @memberof V1Engine
     */
    readonly currentRunningDuration?: string | null;
}

export function V1EngineFromJSON(json: any): V1Engine {
    return V1EngineFromJSONTyped(json, false);
}

export function V1EngineFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1Engine {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'creator': !exists(json, 'creator') ? undefined : json['creator'],
        'creatorDisplayName': !exists(json, 'creatorDisplayName') ? undefined : json['creatorDisplayName'],
        'type': !exists(json, 'type') ? undefined : V1EngineTypeFromJSON(json['type']),
        'state': !exists(json, 'state') ? undefined : V1EngineStateFromJSON(json['state']),
        'reconciling': !exists(json, 'reconciling') ? undefined : json['reconciling'],
        'createTime': !exists(json, 'createTime') ? undefined : (new Date(json['createTime'])),
        'deleteTime': !exists(json, 'deleteTime') ? undefined : (json['deleteTime'] === null ? null : new Date(json['deleteTime'])),
        'updateTime': !exists(json, 'updateTime') ? undefined : (json['updateTime'] === null ? null : new Date(json['updateTime'])),
        'resumeTime': !exists(json, 'resumeTime') ? undefined : (json['resumeTime'] === null ? null : new Date(json['resumeTime'])),
        'loginUrl': !exists(json, 'loginUrl') ? undefined : json['loginUrl'],
        'annotations': !exists(json, 'annotations') ? undefined : json['annotations'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'deprecatedVersion': !exists(json, 'deprecatedVersion') ? undefined : json['deprecatedVersion'],
        'cpu': !exists(json, 'cpu') ? undefined : json['cpu'],
        'gpu': !exists(json, 'gpu') ? undefined : json['gpu'],
        'memoryBytes': !exists(json, 'memoryBytes') ? undefined : json['memoryBytes'],
        'storageBytes': !exists(json, 'storageBytes') ? undefined : json['storageBytes'],
        'upgradeAvailable': !exists(json, 'upgradeAvailable') ? undefined : json['upgradeAvailable'],
        'maxIdleDuration': !exists(json, 'maxIdleDuration') ? undefined : json['maxIdleDuration'],
        'maxRunningDuration': !exists(json, 'maxRunningDuration') ? undefined : json['maxRunningDuration'],
        'currentIdleDuration': !exists(json, 'currentIdleDuration') ? undefined : json['currentIdleDuration'],
        'currentRunningDuration': !exists(json, 'currentRunningDuration') ? undefined : json['currentRunningDuration'],
    };
}

export function V1EngineToJSON(value?: V1Engine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': V1EngineTypeToJSON(value.type),
        'state': V1EngineStateToJSON(value.state),
    };
}

