import { Stack } from '@fluentui/react';
import { Carousel, IComponent, ImagesCarouselDialog, Sizes, useClassNames, useTheme } from '@h2oai/ui-kit';
import { ReactNode, useMemo, useState } from 'react';

import { ClassNamesFromIStyles } from '../../utils/models';
import { IAppPivotStyles, appPivotStylesDefault } from './AppPivot.styles';

type NonEmptyArray<T> = [T, ...T[]];

interface IGalleryProps {
  images: NonEmptyArray<string>;
}

/**
 *  Renders an image carousel, selected image is opened in modal overlay window
 */
function Gallery({ images }: IGalleryProps) {
  const [selectedScreenshot, setSelectedScreenshot] = useState<{ index: number } | null>(null),
    preparedImages = useMemo(() => {
      const paths = images.map((src) => `/v1/asset/${src}`);
      return {
        carousel: paths.map((src) => ({ id: src })),
        paths,
      };
    }, [images]);

  return (
    <>
      <ImagesCarouselDialog items={preparedImages.paths} selectedIndex={selectedScreenshot} />
      <Carousel
        data={preparedImages.carousel}
        height="262px"
        onClickItem={(index: number | undefined) => {
          if (index !== undefined) {
            setSelectedScreenshot({ index });
          }
        }}
      />
    </>
  );
}

interface IAppPivotRightCardProps {
  children: ReactNode;
}

export function AppPivotRightCard({ children }: IAppPivotRightCardProps) {
  const theme = useTheme();
  return (
    <div
      style={{
        background: theme.palette?.gray200,
        borderRadius: `${Sizes.borderRadius}px`,
        padding: `1.5rem`,
      }}
    >
      {children}
    </div>
  );
}

interface IAppPivotProps extends IComponent<IAppPivotStyles> {
  mainContent: ReactNode;
  images?: string[];
  rightContent?: ReactNode;
}

export function AppPivot({ mainContent, images, rightContent, styles }: IAppPivotProps) {
  const classNames = useClassNames<IAppPivotStyles, ClassNamesFromIStyles<IAppPivotStyles>>(
    'AppPivot',
    appPivotStylesDefault,
    styles
  );
  const theme = useTheme();
  return (
    <Stack
      styles={{
        root: {
          backgroundColor: theme.palette?.white,
          borderRadius: Sizes.borderRadius,
          borderTopLeftRadius: 0,
          padding: `1.5rem`,
        },
      }}
      tokens={{ childrenGap: `1.5rem` }}
    >
      {!!images?.length && <Gallery images={images as NonEmptyArray<string>} />}
      <Stack horizontal wrap tokens={{ childrenGap: 20 }}>
        {mainContent && <div className={classNames.description}>{mainContent}</div>}
        {rightContent && (
          <Stack tokens={{ childrenGap: `${1 / 1.5}rem` }} className={classNames.rightContent}>
            {rightContent}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
