/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/h2o_engine_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1H2OEngineState,
    V1H2OEngineStateFromJSON,
    V1H2OEngineStateFromJSONTyped,
    V1H2OEngineStateToJSON,
} from './V1H2OEngineState';

/**
 * 
 * @export
 * @interface V1H2OEngine
 */
export interface V1H2OEngine {
    /**
     * H2OEngine resource name.
     * @type {string}
     * @memberof V1H2OEngine
     */
    readonly name?: string;
    /**
     * Globally unique identifier of the resource.
     * @type {string}
     * @memberof V1H2OEngine
     */
    readonly uid?: string;
    /**
     * Name of na entity that created the H2OEngine.
     * @type {string}
     * @memberof V1H2OEngine
     */
    readonly creator?: string;
    /**
     * Human-readable name of creator.
     * @type {string}
     * @memberof V1H2OEngine
     */
    readonly creatorDisplayName?: string;
    /**
     * 
     * @type {V1H2OEngineState}
     * @memberof V1H2OEngine
     */
    state?: V1H2OEngineState;
    /**
     * Indicates whether changes to the resource are in progress.
     * If true, current state of the H2OEngine does not match the intended state.
     * @type {boolean}
     * @memberof V1H2OEngine
     */
    readonly reconciling?: boolean;
    /**
     * Time when the H2OEngine creation was requested.
     * @type {Date}
     * @memberof V1H2OEngine
     */
    readonly createTime?: Date;
    /**
     * Time when the H2OEngine was deleted.
     * @type {Date}
     * @memberof V1H2OEngine
     */
    readonly deleteTime?: Date | null;
    /**
     * Time when the H2OEngine was last updated.
     * @type {Date}
     * @memberof V1H2OEngine
     */
    readonly updateTime?: Date | null;
    /**
     * The URL address to access the engine API.
     * @type {string}
     * @memberof V1H2OEngine
     */
    readonly apiUrl?: string;
    /**
     * The URL address to initiate login flow.
     * @type {string}
     * @memberof V1H2OEngine
     */
    readonly loginUrl?: string;
    /**
     * Additional arbitrary metadata associated with the H2OEngine.
     * 
     * Annotations are key/value pairs.
     * The key must:
     * - be 63 characters or less
     * - begin and end with an alphanumeric character ([a-z0-9A-Z])
     * - with dashes (-), underscores (_), dots (.), and alphanumerics between
     * - regex used for validation is: ^[A-Za-z0-9]([-A-Za-z0-9_.]{0,61}[A-Za-z0-9])?$
     * @type {{ [key: string]: string; }}
     * @memberof V1H2OEngine
     */
    annotations?: { [key: string]: string; };
    /**
     * Human-readable name of the H2OEngine. Must contain at most 63 characters. Does not have to be unique.
     * @type {string}
     * @memberof V1H2OEngine
     */
    displayName?: string;
    /**
     * H2OEngine version identifier. Version must be specified during engine creation but cannot be changed later.
     * For example: "3.38.0.1" or "latest".
     * @type {string}
     * @memberof V1H2OEngine
     */
    version: string;
    /**
     * The amount of CPU units per node requested by this H2OEngine.
     * @type {number}
     * @memberof V1H2OEngine
     */
    cpu?: number | null;
    /**
     * The amount of GPU units per node requested by this H2OEngine.
     * @type {number}
     * @memberof V1H2OEngine
     */
    gpu?: number | null;
    /**
     * The amount of memory in bytes per node requested by this H2OEngine.
     * @type {string}
     * @memberof V1H2OEngine
     */
    memoryBytes?: string | null;
    /**
     * Maximum time an H2OEngine can be idle. When exceeded, the H2OEngine will pause.
     * @type {string}
     * @memberof V1H2OEngine
     */
    maxIdleDuration?: string | null;
    /**
     * Maximum time an H2OEngine can be running. When exceeded, the H2OEngine will pause.
     * @type {string}
     * @memberof V1H2OEngine
     */
    maxRunningDuration?: string | null;
    /**
     * Current time the H2OEngine is idle.
     * @type {string}
     * @memberof V1H2OEngine
     */
    readonly currentIdleDuration?: string | null;
    /**
     * Current time the H2OEngine is running.
     * @type {string}
     * @memberof V1H2OEngine
     */
    readonly currentRunningDuration?: string | null;
    /**
     * The number of nodes requested by this H2OEngine.
     * @type {number}
     * @memberof V1H2OEngine
     */
    nodeCount?: number | null;
}

export function V1H2OEngineFromJSON(json: any): V1H2OEngine {
    return V1H2OEngineFromJSONTyped(json, false);
}

export function V1H2OEngineFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1H2OEngine {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'creator': !exists(json, 'creator') ? undefined : json['creator'],
        'creatorDisplayName': !exists(json, 'creatorDisplayName') ? undefined : json['creatorDisplayName'],
        'state': !exists(json, 'state') ? undefined : V1H2OEngineStateFromJSON(json['state']),
        'reconciling': !exists(json, 'reconciling') ? undefined : json['reconciling'],
        'createTime': !exists(json, 'createTime') ? undefined : (new Date(json['createTime'])),
        'deleteTime': !exists(json, 'deleteTime') ? undefined : (json['deleteTime'] === null ? null : new Date(json['deleteTime'])),
        'updateTime': !exists(json, 'updateTime') ? undefined : (json['updateTime'] === null ? null : new Date(json['updateTime'])),
        'apiUrl': !exists(json, 'apiUrl') ? undefined : json['apiUrl'],
        'loginUrl': !exists(json, 'loginUrl') ? undefined : json['loginUrl'],
        'annotations': !exists(json, 'annotations') ? undefined : json['annotations'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'version': json['version'],
        'cpu': !exists(json, 'cpu') ? undefined : json['cpu'],
        'gpu': !exists(json, 'gpu') ? undefined : json['gpu'],
        'memoryBytes': !exists(json, 'memoryBytes') ? undefined : json['memoryBytes'],
        'maxIdleDuration': !exists(json, 'maxIdleDuration') ? undefined : json['maxIdleDuration'],
        'maxRunningDuration': !exists(json, 'maxRunningDuration') ? undefined : json['maxRunningDuration'],
        'currentIdleDuration': !exists(json, 'currentIdleDuration') ? undefined : json['currentIdleDuration'],
        'currentRunningDuration': !exists(json, 'currentRunningDuration') ? undefined : json['currentRunningDuration'],
        'nodeCount': !exists(json, 'nodeCount') ? undefined : json['nodeCount'],
    };
}

export function V1H2OEngineToJSON(value?: V1H2OEngine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': V1H2OEngineStateToJSON(value.state),
        'annotations': value.annotations,
        'displayName': value.displayName,
        'version': value.version,
        'cpu': value.cpu,
        'gpu': value.gpu,
        'memoryBytes': value.memoryBytes,
        'maxIdleDuration': value.maxIdleDuration,
        'maxRunningDuration': value.maxRunningDuration,
        'nodeCount': value.nodeCount,
    };
}

