import {
  DirectionalHint,
  ITextStyles,
  ITooltipHostStyles,
  ITooltipProps,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { FontSizes, IconButton, IconName, useTheme } from '@h2oai/ui-kit';

export enum OutOfVersionSeverity {
  Warning = 'warning',
  Info = 'info',
  Text = 'text',
}

export type LabelInfoTooltipProps = {
  id?: string;
  label?: string | React.ReactNode;
  required?: boolean;
  tooltip?: string;
  iconName?: IconName;
  severity?: OutOfVersionSeverity;
};

export const LabelIconTooltip = ({
  label = '',
  tooltip = '',
  iconName = IconName.Info,
  required = false,
  severity = OutOfVersionSeverity.Text,
}: LabelInfoTooltipProps) => {
  const { semanticColors } = useTheme(),
    tooltipHostStyles: ITooltipHostStyles = {
      root: {
        display: 'inline',
        ...(required
          ? {
              '&.ms-TooltipHost': {
                marginLeft: '-10px',
                paddingTop: '5px',
              },
            }
          : {}),
        '.ms-Icon': {
          color:
            severity === OutOfVersionSeverity.Warning
              ? semanticColors?.messageBarIconWarning
              : severity === OutOfVersionSeverity.Info
              ? semanticColors?.messageBarIconInfo
              : semanticColors?.messageBarTitleText,
        },
      },
    },
    tooltipTextStyles: ITextStyles = { root: { color: semanticColors?.tooltipText, fontSize: FontSizes.xsmall } },
    tooltipProps: ITooltipProps = {
      directionalHint: DirectionalHint.rightCenter,
      styles: {
        content: { backgroundColor: semanticColors?.tooltipBackground, paddingLeft: 10 },
      },
      onRenderContent: () => (
        <Stack>
          <Text styles={tooltipTextStyles}>{tooltip}</Text>
        </Stack>
      ),
    };
  return (
    <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 5 }}>
      {label}
      {tooltip && (
        <TooltipHost
          tooltipProps={tooltipProps}
          styles={tooltipHostStyles}
          calloutProps={{
            styles: {
              beakCurtain: { backgroundColor: semanticColors?.tooltipBackground },
              beak: { backgroundColor: semanticColors?.tooltipBackground },
            },
          }}
        >
          <IconButton styles={{ root: { backgroundColor: semanticColors?.contentBackground } }} iconName={iconName} />
        </TooltipHost>
      )}
    </Stack>
  );
};
