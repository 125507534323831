import { IStyle } from '@fluentui/react';

export interface IAppPivotStyles {
  description?: IStyle;
  rightContent?: IStyle;
}

export const appPivotStylesDefault: Partial<IAppPivotStyles> = {
  description: {
    flexBasis: `60ch`,
    flexGrow: 1000,
  },
  rightContent: {
    flexBasis: `60ch`,
    flexGrow: 1,
    minWidth: 0,
  },
};

export const appPivotStylesEqualColumns: Partial<IAppPivotStyles> = {
  description: { flexGrow: 1 },
};
