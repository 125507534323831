import { useCallback, useEffect, useState } from 'react';

import { App, AppPreconditionStatus, App_Visibility } from '../../../ai.h2o.cloud.appstore';
import { useApp } from '../../../utils/hooks';
import { getAppIconImagePath } from '../../../utils/utils';
import { RoutePaths } from '../../Routes';
import { AppListWidget } from '../AppListWidget/AppListWidget';

export interface IMyAppListWidgetProps {
  title: string;
}

export const MyAppListWidget = ({ title }: IMyAppListWidgetProps) => {
  const { getApps } = useApp();
  const [data, setData] = useState<App[]>([]);
  const [loadingMessage, setLoadingMessage] = useState('Loading');
  const loadData = useCallback(async () => {
    const apps = await getApps({
      limit: 5,
      offset: 0,
      visibility: App_Visibility.VISIBILITY_UNSPECIFIED,
      allUsers: false,
      name: '',
      latestVersions: false,
      withPreference: false,
      tags: [],
      conditionsStatus: AppPreconditionStatus.STATUS_UNSPECIFIED,
      visibilities: [],
    });
    apps.forEach((app) => (app.iconLocation = getAppIconImagePath(app.iconLocation)));
    setData(apps);
    setLoadingMessage('');
  }, [getApps]);
  useEffect(() => {
    loadData();
  }, [loadData]);
  return <AppListWidget title={title} link={RoutePaths.MYAPPS} data={data} loadingMessage={loadingMessage} />;
};
