import { Image, ImageFit, mergeStyleSets, mergeStyles } from '@fluentui/react';
import { Button, Item, buttonStylesGhost, itemStylesCategory, useClassNames } from '@h2oai/ui-kit';
import { useHistory } from 'react-router-dom';

import { FederatedApp } from '../../../../ai.h2o.cloud.appstore';
import { PillVisibility } from '../../../../components/PillVisibility/PillVisibility';
import { isCategoryTag } from '../../../../utils/utils';
import { RoutePaths } from '../../../Routes';
import {
  IFederatedAppsListRowClassNames,
  IFederatedAppsListRowStyles,
  federatedAppsListRowStylesDefault,
} from '../FederatedAppsList.styles';
import { FederatedAppsStatusIcon } from '../FederatedAppsStatusIcon/FederatedAppsStatusIcon';

const resetList = mergeStyles({
  listStyle: `none`,
  margin: 0,
  padding: 0,
});

const classNames = mergeStyleSets({
  categoryList: [
    resetList,
    {
      display: `flex`,
      flexFlow: `row wrap`,
      gap: `0.5rem`,
    },
  ],
});

export interface IFederatedAppsListRowProps {
  app: FederatedApp;
  onEdit: () => void;
}

export function FederatedAppsListRow({ app, onEdit }: IFederatedAppsListRowProps) {
  const styles = useClassNames<IFederatedAppsListRowStyles, IFederatedAppsListRowClassNames>(
    'FederatedAppsListRow',
    federatedAppsListRowStylesDefault
  );
  const categories = app.tags?.filter(isCategoryTag);
  const history = useHistory();
  return !app ? null : (
    <div className={styles.root}>
      <div className={'image'}>
        <Image src={`/v1/asset/${app.iconLocation}`} width={32} height={32} imageFit={ImageFit.cover} />
      </div>
      <div className={'name'}>{app.id}</div>
      <div className={'version'}>{app.version}</div>
      <div className={'status'}>
        <FederatedAppsStatusIcon app={app} />
      </div>
      <div className={'category'}>
        {categories?.length > 0 && (
          <ul aria-label={`Categories to which the app ${app.name} belongs`} className={classNames.categoryList}>
            {categories.map(({ id, title, description }) => (
              <li title={description} key={id}>
                <Item data={{ text: title }} labelField="text" styles={itemStylesCategory} />
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className={'visibility'}>
        <PillVisibility visibility={app.visibility} withIcon />
      </div>
      <div className={'actions'}>
        {app.runnable && <Button onClick={onEdit} text="Edit" styles={buttonStylesGhost} />}
        <Button
          onClick={() => {
            history.push(`${RoutePaths.ADMIN_MARKETPLACE_APPS}/${app.id}`);
          }}
          text="View"
          styles={buttonStylesGhost}
        />
      </div>
      <div className={'chevron'} />
    </div>
  );
}
