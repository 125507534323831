/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as fm from "../../../../../fetch.pb"
import * as GoogleProtobufTimestamp from "../../../../../google/protobuf/timestamp.pb"
import * as GoogleTypeInterval from "../../../../../google/type/interval.pb"

export enum Granularity {
  GRANULARITY_UNSPECIFIED = "GRANULARITY_UNSPECIFIED",
  YEAR = "YEAR",
  MONTH = "MONTH",
  WEEK = "WEEK",
  DAY = "DAY",
  HOUR = "HOUR",
}

export type ListComponentsRequest = {
  pageSize?: number
  pageToken?: string
}

export type ListComponentsResponse = {
  components?: Component[]
  nextPageToken?: string
}

export type Component = {
  name?: string
}

export type CalculateUsageRequest = {
  startTime?: GoogleProtobufTimestamp.Timestamp
  endTime?: GoogleProtobufTimestamp.Timestamp
  granularity?: Granularity
  component?: string
  pageSize?: number
  pageToken?: string
}

export type CalculateUsageResponse = {
  usage?: Usage[]
  nextPageToken?: string
}

export type Usage = {
  milliAiUnits?: string
  interval?: GoogleTypeInterval.Interval
}

export type CalculateMaxUsageRequest = {
  component?: string
  startTime?: GoogleProtobufTimestamp.Timestamp
  endTime?: GoogleProtobufTimestamp.Timestamp
}

export type MaxUsage = {
  milliAiUnits?: string
  timestamp?: GoogleProtobufTimestamp.Timestamp
}

export type CalculateMaxUsageResponse = {
  maxUsage?: MaxUsage
}

export type GetQuotaRequest = {
}

export type GetQuotaResponse = {
  quota?: Quota
}

export type Quota = {
  milliAiUnits?: string
}

export class ComponentService {
  static ListComponents(req: ListComponentsRequest, initReq?: fm.InitReq): Promise<ListComponentsResponse> {
    return fm.fetchReq<ListComponentsRequest, ListComponentsResponse>(`/v1/components?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
}
export class UsageService {
  static CalculateUsage(req: CalculateUsageRequest, initReq?: fm.InitReq): Promise<CalculateUsageResponse> {
    return fm.fetchReq<CalculateUsageRequest, CalculateUsageResponse>(`/v1/${req["componentcomponents"]}:calculateUsage?${fm.renderURLSearchParams(req, ["componentcomponents"])}`, {...initReq, method: "GET"})
  }
  static CalculateMaxUsage(req: CalculateMaxUsageRequest, initReq?: fm.InitReq): Promise<CalculateMaxUsageResponse> {
    return fm.fetchReq<CalculateMaxUsageRequest, CalculateMaxUsageResponse>(`/v1/${req["componentcomponents"]}:calculateMaxUsage?${fm.renderURLSearchParams(req, ["componentcomponents"])}`, {...initReq, method: "GET"})
  }
}
export class QuotaService {
  static GetQuota(req: GetQuotaRequest, initReq?: fm.InitReq): Promise<GetQuotaResponse> {
    return fm.fetchReq<GetQuotaRequest, GetQuotaResponse>(`/v1/quota?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
}