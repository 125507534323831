/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/h2o_engine_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Message representing recommended H2OEngine size within a given workspace constraints.
 * @export
 * @interface V1H2OEngineSize
 */
export interface V1H2OEngineSize {
    /**
     * The number of nodes recommended for the engine.
     * @type {string}
     * @memberof V1H2OEngineSize
     */
    nodeCount?: string;
    /**
     * The amount of memory in bytes per node recommended for the engine.
     * @type {string}
     * @memberof V1H2OEngineSize
     */
    memoryBytes?: string;
}

export function V1H2OEngineSizeFromJSON(json: any): V1H2OEngineSize {
    return V1H2OEngineSizeFromJSONTyped(json, false);
}

export function V1H2OEngineSizeFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1H2OEngineSize {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nodeCount': !exists(json, 'nodeCount') ? undefined : json['nodeCount'],
        'memoryBytes': !exists(json, 'memoryBytes') ? undefined : json['memoryBytes'],
    };
}

export function V1H2OEngineSizeToJSON(value?: V1H2OEngineSize | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nodeCount': value.nodeCount,
        'memoryBytes': value.memoryBytes,
    };
}

