import {
  DefaultButton,
  IButtonStyles,
  IContextualMenuProps,
  IDropdownOption,
  ISelectableOption,
  Stack,
} from '@fluentui/react';
import { Checkbox, CommonStyles, Dropdown, FontSizes, IH2OTheme, IconButton, useTheme } from '@h2oai/ui-kit';
import { CSSProperties, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { V1EngineType } from '../../../aiem';
import { filterSummarizeSelection, useEngine, useEngineQueryParams } from '../../../aiem/hooks';
import { useQueryParams } from '../../../utils/hooks';
import { TypeVersionSelection, TypeVersionSummary, VersionSelection } from '../filter.utils';

type ValidTypes = V1EngineType.DriverlessAi | V1EngineType.H2O;

type IContextualMenuItemTypeVersionData = {
  type: ValidTypes;
  version: string;
  checked: boolean;
};

type VersionSelectionsMap = {
  [P in V1EngineType as string]: VersionSelection;
};

type FilterListActionsProps = {
  selection: TypeVersionSelection;
  summary?: TypeVersionSummary;
  onChangeTypeVersions: (selection: TypeVersionSelection) => void;
  onChangeStates: (states: string[]) => void;
  onClear?: () => void;
};

const getLabel = (selected = 0, total = 0) => `${selected} of ${total}`;

function getSubMenuContent(
  type: ValidTypes,
  menuVersionSelections: VersionSelectionsMap,
  onToggleVersion: (
    ev: FormEvent<HTMLElement | HTMLInputElement> | undefined,
    data: IContextualMenuItemTypeVersionData
  ) => void
) {
  const versionSelections = menuVersionSelections[type];

  if (!versionSelections) return [];

  const keys = Object.keys(versionSelections);
  const onRender = () => {
    return (
      <>
        {keys.map((key) => (
          <div
            key={`${key}`}
            className="ms-ContextualMenu-link"
            style={{ ...(CommonStyles.flexCenterVertically as CSSProperties) }}
          >
            <Checkbox
              className="ms-ContextualMenu-checkbox"
              label={key}
              checked={versionSelections[key]}
              onChange={(ev, checked = false) => {
                onToggleVersion && onToggleVersion(ev, { type, version: key, checked });
              }}
            />
          </div>
        ))}
      </>
    );
  };

  return [
    {
      key: type,
      text: type,
      canCheck: true,
      checked: false,
      onRender,
    },
  ];
}

const FilterListActions = ({ selection, onChangeTypeVersions, onChangeStates, onClear }: FilterListActionsProps) => {
  const { EngineStateMap } = useEngine(),
    theme = useTheme();

  const buttonStylesDropdown = (theme: IH2OTheme): IButtonStyles => ({
    root: {
      color: theme.semanticColors?.inputReadOnlyText,
      backgroundColor: theme.palette?.white,
      borderColor: theme.semanticColors?.inputBorder,
      borderRadius: 4,
      minWidth: 220,
    },
    rootFocused: {
      backgroundColor: theme.palette?.white,
      borderColor: theme.semanticColors?.inputActiveBorder,
      color: theme.semanticColors?.inputReadOnlyText,
    },
    rootPressed: {
      backgroundColor: theme.palette?.white,
      borderColor: theme.semanticColors?.inputActiveBorder,
      color: theme.semanticColors?.inputReadOnlyText,
    },
    rootHovered: {
      backgroundColor: theme.palette?.white,
      borderColor: theme.semanticColors?.inputActiveBorder,
      color: theme.semanticColors?.inputReadOnlyText,
    },
    label: {
      color: theme.semanticColors?.inputReadOnlyText,
      fontSize: FontSizes.input,
      fontWeight: 'normal',
    },
  });

  const {
      params: { states: statesParam, versions: versionsParam },
      setTypeVersions,
      setStates,
    } = useEngineQueryParams(),
    { clearParams } = useQueryParams();

  const hasFilter = Boolean(Object.keys(versionsParam)?.length || statesParam?.length);

  const daiVersionSelections = selection[V1EngineType.DriverlessAi],
    h2oVersionSelections = selection[V1EngineType.H2O];

  const [menuVersionSelections, setMenuVersionSelections] = useState<VersionSelectionsMap>({
    [V1EngineType.DriverlessAi]: daiVersionSelections,
    [V1EngineType.H2O]: daiVersionSelections,
  });
  const [summary, setSummary] = useState<TypeVersionSummary>({} as TypeVersionSummary),
    { totalVersions, totalSelected } = summary;

  const statesOptions: IDropdownOption[] = Array.from(EngineStateMap, ([_, { id: key, title: text }]) => ({
    key,
    text,
    selected: Boolean(statesParam?.includes(key)),
  }));

  const onToggleVersion = useCallback(
    (ev: FormEvent<HTMLElement | HTMLInputElement> | undefined, data: IContextualMenuItemTypeVersionData): void => {
      ev && ev.preventDefault();
      const { type, version, checked } = (data || {}) as IContextualMenuItemTypeVersionData;

      if (type && version) {
        setMenuVersionSelections((currentMenuVersionSelections) => {
          const versionSelectionsOfType = currentMenuVersionSelections[type];
          const result = {
            ...currentMenuVersionSelections,
            [type]: {
              ...versionSelectionsOfType,
              [version]: checked,
            },
          };
          setTypeVersions(result);
          onChangeTypeVersions(result);
          const { summary: _summary } = filterSummarizeSelection(result);
          setSummary(_summary);
          return result;
        });
      }
    },
    [setMenuVersionSelections]
  );

  const [selectedStates, setSelectedStates] = useState<string[] | null>(statesParam);

  const onToggleState = useCallback(
    (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<ISelectableOption> | undefined) => {
      const { key } = option || {};
      setSelectedStates((states: string[] | null) => {
        const newStates = !states
          ? [key as string]
          : states?.includes(key as string)
          ? states.filter((s) => s !== key)
          : [...states!, key as string];
        setStates(newStates);
        onChangeStates(newStates);
        return newStates;
      });
    },
    [setSelectedStates]
  );

  const onClearFilters = useCallback(() => {
    // clear versions
    setMenuVersionSelections((currentSelection) => {
      const result = Object.keys(currentSelection).reduce((acc, type) => {
        const typeVersionsObj = currentSelection[type];
        const typeVersions = Object.keys(typeVersionsObj).reduce((acc2, version) => {
          return {
            ...acc2,
            [version]: false,
          };
        }, {});
        return { ...acc, [type]: typeVersions };
      }, {});

      setTypeVersions(result);
      onChangeTypeVersions(result);
      const { summary: _summary } = filterSummarizeSelection(result);
      setSummary(_summary);
      return result;
    });

    // clear states
    setSelectedStates(null);
    setStates([]);

    // clear outside filters
    onClear && onClear();

    clearParams();
  }, []);

  const menuProps: IContextualMenuProps = useMemo(() => {
    const types = summary?.types || {};
    const daiSummary = getLabel(
        types[V1EngineType.DriverlessAi]?.totalSelected,
        types[V1EngineType.DriverlessAi]?.totalVersions
      ),
      h2oSummary = getLabel(types[V1EngineType.H2O]?.totalSelected, types[V1EngineType.H2O]?.totalVersions);
    return {
      shouldFocusOnMount: true,
      items: [
        {
          key: V1EngineType.DriverlessAi,
          text: `Driverless AI: ${daiSummary}`,
          subMenuProps: {
            items: getSubMenuContent(V1EngineType.DriverlessAi, menuVersionSelections, onToggleVersion),
          },
        },
        {
          key: V1EngineType.H2O,
          text: `H2O 3: ${h2oSummary}`,
          subMenuProps: {
            items: getSubMenuContent(V1EngineType.H2O, menuVersionSelections, onToggleVersion),
          },
        },
      ],
    };
  }, [menuVersionSelections]);

  useEffect(() => {
    setMenuVersionSelections({
      [V1EngineType.DriverlessAi]: daiVersionSelections,
      [V1EngineType.H2O]: h2oVersionSelections,
    });
  }, [daiVersionSelections, h2oVersionSelections]);

  useEffect(() => {
    const { summary: initialSummary } = filterSummarizeSelection(selection);
    setSummary(initialSummary);
  }, [selection]);

  return (
    <Stack horizontal tokens={{ childrenGap: 5 }} styles={{ root: { paddingTop: 5 } }}>
      {h2oVersionSelections && daiVersionSelections && (
        <>
          <DefaultButton
            text={`Engine & Version: ${getLabel(totalSelected, totalVersions)}`}
            menuProps={menuProps}
            styles={buttonStylesDropdown(theme)}
          />
          <Dropdown
            placeholder="State"
            onChange={onToggleState}
            options={statesOptions}
            width={280}
            selectedKeys={selectedStates}
            multiSelect
          />
          {hasFilter && (
            <Stack
              styles={{
                root: {
                  padding: 5,
                },
              }}
            >
              <IconButton
                aria-details="Clear all filters"
                iconName="ClearFilter"
                onClick={onClearFilters}
                styles={{
                  icon: {
                    fontSize: FontSizes.medium,
                  },
                }}
              />
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};

export default FilterListActions;
