import { IColumn, IDetailsRowProps, IGroup, Separator } from '@fluentui/react';
import { SortInfo, setDetailsListSortColumnAndGetSortInfo, sortList, useTheme } from '@h2oai/ui-kit';
import { useCallback, useEffect, useRef, useState } from 'react';

import StyledDetailsList from '../ListPages/StyledDetailsList';
import StyledDetailsRow from '../ListPages/StyledDetailsRow';
import { TitleCell } from '../ListPages/TitleCell';

export interface IManagedListProps<T> {
  columns?: IColumn[];
  groupPropName?: string;
  itemList: T[];
  title?: string;
}

function dataPreparation<T>(items: T[], groupPropName?: string) {
  if (!groupPropName) return;

  const groups: IGroup[] = [];
  const itemIndexList = {};
  const itemCountList = {};
  const addGroup = (groupName: string, startIndex: number, count: number) => {
    groups.push({
      key: groupName,
      name: groupName,
      startIndex,
      count,
      isCollapsed: false,
    });
  };

  for (const [index, item] of items.entries()) {
    const currentItemName = item[groupPropName];

    if (!itemIndexList[currentItemName] && itemIndexList[currentItemName] !== 0) {
      itemIndexList[currentItemName] = index;
    }

    if (!itemCountList[currentItemName]) {
      itemCountList[currentItemName] = 1;
    } else {
      ++itemCountList[currentItemName];
    }
  }

  for (const item of Object.keys(itemIndexList)) {
    addGroup(item, itemIndexList[item], itemCountList[item]);
  }

  return [...new Set(groups)];
}

export function ManagedList<T extends Object>(props: IManagedListProps<T>) {
  const { columns: rawColumns, groupPropName, itemList } = props,
    [items, setItems] = useState<T[]>(itemList),
    refAllItems = useRef<T[]>(itemList),
    refItems = useRef<T[]>(itemList),
    [groups, setGroups] = useState<IGroup[] | undefined>(dataPreparation(items, groupPropName)),
    refSort = useRef<SortInfo<T>>(),
    cols: IColumn[] = [],
    [columns, setColumns] = useState<IColumn[]>(cols),
    sort = useCallback(
      (data = refItems.current) =>
        (refItems.current =
          refSort.current && refSort.current.isSorted
            ? sortList<T>(data, refSort.current.fieldName, refSort.current.isSortedDescending)
            : refAllItems.current),
      []
    ),
    onColumnClick = useCallback(
      (_e, col: IColumn) => {
        const sortInfo = setDetailsListSortColumnAndGetSortInfo<T>(col, columns);
        if (sortInfo) {
          refSort.current = sortInfo;
          setColumns([...columns]);
          setItems(sort());
        }
      },
      [columns, sort]
    ),
    load = useCallback(() => {
      refItems.current = sort();
      setItems(refItems.current);
      if (groupPropName) {
        setGroups(dataPreparation(refItems.current, groupPropName));
      }
    }, [sort]),
    onRenderRow = useCallback((props?: IDetailsRowProps) => {
      return props ? (
        <StyledDetailsRow
          deleting={props.item.deleting}
          loading={props.item.editing}
          styles={{ cell: { alignSelf: 'center' } }}
          {...props}
        />
      ) : null;
    }, []),
    onRenderCol = (item: T, _index?: number, column?: IColumn) => {
      if (!column) return <></>;
      return <TitleCell title={column?.name || ''} subtitle={item[column?.fieldName || ''] as string} />;
    };

  useEffect(() => {
    if (rawColumns) {
      const sortableCols = rawColumns.filter((c) => c.isSorted);
      if (sortableCols.length > 0) {
        sortableCols.forEach((c) => (c.onColumnClick = onColumnClick));
      }
      cols.push(...rawColumns);
    }

    refAllItems.current = itemList;
    load();
  }, [itemList, load]);

  return (
    <>
      {items?.length > 0 && (
        <StyledDetailsList
          dataTest="managed-list"
          columns={columns}
          items={items}
          groups={groups}
          groupProps={{ isAllGroupsCollapsed: false }}
          onRenderRow={onRenderRow}
          onRenderItemColumn={onRenderCol}
          noHeader={true}
        />
      )}
    </>
  );
}

export function ManagedListWrapper(props: { children?: React.ReactNode }) {
  const { children } = props;
  return <div style={{ padding: '20px 3px' }}>{children}</div>;
}

export function ManagedListSeparator(props: { text: string }) {
  const { text } = props,
    theme = useTheme();
  return (
    <ManagedListWrapper>
      <Separator
        styles={{
          content: { padding: '3px 6px' },
          root: {
            ':before': {
              backgroundColor: theme.semanticColors?.inputBorder,
            },
          },
        }}
      >
        {text}
      </Separator>
    </ManagedListWrapper>
  );
}
