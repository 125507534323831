import { Stack } from '@fluentui/react';
import './EULA.css';

// Use the value in whatever format and render it.
export const Eula = () => (
  <Stack styles={{ root: { height: '60vh', overflow: 'auto' } }}>
    <h2>H2O.ai, Inc.</h2>
    <h2>Terms and Conditions (v.7)</h2>
    <p>
      <b>(Last Updated: August 10, 2023)</b>
    </p>
    <p>
      <span>
        TERMS AND CONDITIONS CONTAINED HEREIN (&ldquo;AGREEMENT&rdquo;) APPLY TO ALL USE OF THE SOFTWARE AND SERVICES
        (COLLECTIVELY, THE &ldquo;SOLUTION&rdquo;) PROVIDED BY H2O.AI, INC. (&ldquo;H2O&rdquo;) TO YOU AND THE
        ORGANIZATION YOU REPRESENT (TOGETHER, &ldquo;CUSTOMER&rdquo;). BY ACCESSING OR USING ANY OF H2O&rsquo;S SOFTWARE
        OR SERVICES, CUSTOMER AGREES TO ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT. THIS AGREEMENT WILL BE DEEMED
        EFFECTIVE ON THE DATE IT IS AGREED TO BY CUSTOMER (SUCH AS BY EXECUTION OF AN ORDER DOCUMENT, AS DEFINED IN
        SECTION 1) (&ldquo;EFFECTIVE DATE&rdquo;). IN THE EVENT THERE IS A SEPARATELY NEGOTIATED AND EXECUTED MASTER
        AGREEMENT BETWEEN H2O AND CUSTOMER WITH RESPECT TO PROCUREMENT OF THE SOLUTION, SUCH AGREEMENT SHALL CONTROL AND
        THIS AGREEMENT WILL NOT APPLY.
      </span>
    </p>
    <ol>
      <li>
        <span className="clause-highlight">ACCESS. </span>
        <span>
          <p>
            Subject to the terms of this Agreement, H2O hereby grants Customer a limited, personal, non-sublicensable,
            non-transferable, nonexclusive license to use the Solution only for Customer&rsquo;s internal use for the
            purpose for which it is provided and only in accordance with any H2O-provided documentation (including as
            provided electronically).
          </p>
          <p>
            The Solution may include access to H2O&rsquo;s software-as-a-service solution (&ldquo;Hosted
            Solution&rdquo;), software (&ldquo;Software&rdquo;), and/or related services, as agreed to by the parties in
            an Order Document. An &ldquo;Order Document&rdquo; is a schedule, statement of work and/or other ordering
            document agreed to by the parties (and may include an online purchase process made available by H2O).
          </p>
          <p>
            If the Solution includes the provision of any Software, Customer will only use it in object code form. The
            Solution may be accessed by the number of users agreed to by H2O and Customer during the purchase process
            (e.g., on an order form). Such users will be specific individuals named by Customer during the purchase
            process, or subsequently, in writing (email is sufficient). Once such named individuals are selected by
            Customer, they may be changed only on written notice to H2O (email is sufficient). The named individuals
            will not allow any other Customer personnel to use their Solution log-in credentials (passwords, etc.). In
            the event Customer purchases additional modules or add-ons to Solution (including, without limitation, any
            features or functions that H2O charges other customers for), they will be deemed &ldquo;Solution&rdquo; and,
            therefore, subject to this Agreement (unless H2O provides them pursuant to different terms and conditions).
            With respect to any Customer proposed modifications, derivatives, enhancements or improvements to the
            Solution (&ldquo;Feedback&rdquo;), Customer hereby grants H2O a perpetual, irrevocable, royalty-free, fully
            paid-up, sub-licensable, right and license to use, display, reproduce, distribute and otherwise fully
            exploit such Feedback for any purposes. All Feedback is provided by Customer &ldquo;AS IS.&rdquo; Certain
            portions of Software may be subject to the publicly available Apache 2.0 license (such portions will be
            identified upon Customer&rsquo;s request). In the event of a conflict between this Agreement and the Apache
            license, the Apache license will control.
          </p>
        </span>
      </li>
      <li>
        <span className="clause-highlight">RESTRICTIONS. </span>
        <span>
          Except as expressly and unambiguously permitted by this Agreement, Customer shall not, nor permit anyone else
          to, directly or indirectly: (i) copy, modify, resell or distribute the Solution; (ii) reverse engineer,
          disassemble, decompile or otherwise attempt to discover the source code or structure, sequence and
          organization of the Solution (except the foregoing will not apply to the extent prohibited by applicable local
          law); (iii) rent, lease, or use the Solution for timesharing or service bureau purposes, or otherwise use the
          Solution on behalf of any third party; (iv) use the Solution to develop any other product or services whether
          delivered internally or as an external service offering or (v) use the Solution for performing comparisons or
          other &ldquo;benchmarking&rdquo; activities, either alone or in connection with any other Solution (and
          Customer will not publish or disclose any such performance information or comparisons). Customer shall
          maintain and not remove or obscure any proprietary notices on or in the Solution. As between the parties,
          title, ownership rights, and intellectual property rights, in and to the Solution, and any copies or portions
          thereof, shall remain in H2O or (as applicable) its suppliers/licensors. Customer understands that H2O may
          modify or discontinue offering the Solution at any time (provided that, if Customer has purchased a term-based
          license, such discontinuance will only take place at the end of the then- current term). The Solution is
          protected by the copyright laws and treaties. This Agreement does not give Customer any rights not expressly
          granted herein. On at least five (5) days&rsquo; prior written notice, Customer may (itself or through a third
          party designated by H2O) audit and inspect Customer&rsquo;s books, records, systems and facilities as it deems
          appropriate to verify Customer&rsquo;s compliance with this Agreement. Audits will not be conducted more than
          once each year (but more times are allowed if H2O reasonably suspects a breach). Customer will provide
          reasonable cooperation with any audit.
        </span>
      </li>
      <li>
        <span className="clause-highlight">CONFIDENTIALITY. </span>
        <span>
          All information regarding the Solution, or otherwise disclosed by H2O, that is identified as confidential, or
          that Customer should reasonably understand to be confidential, is H2O&rsquo;s &ldquo;Confidential
          Information.&rdquo; Customer will hold all Confidential Information in confidence and not disclose it to any
          third party, unless otherwise specifically approved by H2O in writing. Customer will only use the Confidential
          Information for the purposes of its relationship with H2O hereunder, including as necessary to use the
          Solution for the purposes for which it is provided. This Section 3 will not apply to information that is or
          becomes generally available to the public without the fault of Customer. On H2O&rsquo;s request at any time,
          Customer will promptly return to H2O (or, at H2O&rsquo;s request, destroy) all Confidential Information. If
          requested by H2O, Customer will provide H2O with written certification that it has complied with this Section.
        </span>
      </li>
      <li>
        <span className="clause-highlight">SUPPORT AND UPGRADES. </span>
        <span>
          During the term of this Agreement H2O will provide Customer with Solution support according to H2O&rsquo;s
          general support practices and procedures. Customer acknowledges that the Solution contains features that allow
          H2O to remotely and automatically identify, track and analyze certain aspects of use and performance of
          Solution and/or the systems on which it is installed, as well as the operator and operating environment
          (including problems and issues that arise in connection therewith). H2O&rsquo;s support terms are outlined in
          our H2O Customer Support and SLA Policy (located at{' '}
          <a href="https://h2o.ai/legal/sla-customer-support-policy/">
            https://h2o.ai/legal/sla-customer-support-policy/
          </a>
          ).
        </span>
      </li>
      <li>
        <span className="clause-highlight">FEES. </span>
        <span>
          To the extent the Solution is procured via a reseller – and Customer pays such reseller for the Solution –
          Customer will not owe fees directly to H2O hereunder. With respect to any other procurement of the Solution,
          Customer shall pay H2O (or its applicable authorized reseller) the fees (if any) for the Solution as agreed to
          by the parties in writing at the time of purchase. Customer shall also pay all sales, use, withholding tax,
          value-added and other taxes, tariffs and duties of any type assessed against H2O (except for taxes on
          H2O&rsquo;s income). All fees are payable within thirty (30) days from the date of invoice, are non-refundable
          and payable in U.S. dollars. If Customer provides H2O with credit card information for payment, (i) Customer
          acknowledges that an invoice may not be sent, (ii) Customer authorizes H2O to charge the credit card for the
          Solution and (iii) Customer will ensure that the credit card number (and all information provided to H2O for
          processing the card) will remain updated and accurate. If Customer uses &lsquo;cloud credits&rsquo; (or
          similar credits authorized by H2O for use hereunder) for payment of subscription fees, Customer will authorize
          the cloud provider to pay H2O the full value of the subscription fee (and Customer is responsible for ensuring
          such payment is made). If the order is a multi-year order, Customer is responsible to pay the full value for
          all years regardless of payment method (i.e., &lsquo;cloud credits&rsquo; or monetary payment). For example,
          in a three-year deal, Customer may opt to use &rsquo;cloud credits&rsquo; for Year 1 but pay by U.S. Dollars
          directly to H2O for Year 2 and Year 3 (subject to the payment terms above). Late fees will be subject to a
          finance charge of the lesser of (i) 1.5% per thirty (30) day period and (ii) the greatest amount allowed by
          applicable law. In addition, Customer will promptly reimburse H2O for all costs of collection (including
          attorneys&rsquo; fees).
        </span>
      </li>
      <li>
        <span className="clause-highlight">INDEMNITY. </span>
        <span>
          Customer shall indemnify and hold harmless H2O from any claims, damages, losses, penalties, fines,
          liabilities, costs and fees (including reasonable attorney fees) arising from Customer&rsquo;s use of the
          Solution as well as from Customer&rsquo;s negligence, willful misconduct or failure to comply with any term of
          this Agreement.
        </span>
      </li>
      <li>
        <span className="clause-highlight">LIMITED WARRANTY. </span>
        <span>
          H2O warrants to Customer that the Solution will conform materially with the Documentation. Customer
          acknowledges that H2O does not otherwise warrant that the Solution is free of errors or defects or that use
          will be uninterrupted. In the event of a breach of this Section, Customer&rsquo;s sole remedy, and H2O&rsquo;s
          exclusive liability, shall be for H2O to use commercially reasonable efforts to correct any such defect(s);
          provided that, Customer specifically notifies H2O in writing of the defect(s) within thirty (30) days from the
          date Customer first experiences the defect. Such corrections will be in accordance with H2O&rsquo;s standard
          practices (such as bug fix queues and release cycles) that it provides to its other general Customers. This
          Section 7 will not apply if the Solution is provided on a trial, evaluation, or otherwise free basis
          (&ldquo;Evaluation Basis&rdquo;).
        </span>
      </li>
      <li>
        <span className="clause-highlight">WARRANTY DISCLAIMER. </span>
        <span>
          EXCEPT AS EXPRESSLY SET FORTH IN SECTION 7, H2O AND ITS SUPPLIERS PROVIDE THE SOLUTION &ldquo;AS IS&rdquo; AND
          WITHOUT WARRANTY OF ANY KIND, AND HEREBY DISCLAIMS ALL EXPRESS OR IMPLIED WARRANTIES, INCLUDING WITHOUT
          LIMITATION WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE, ACCURACY,
          RELIABILITY, AND NON- INFRINGEMENT. CERTAIN ASPECTS OF THE SOLUTION MAY INVOLVE ARTIFICIAL INTELLIGENCE
          (&ldquo;GENERATIVE AI FUNCTIONS&rdquo;). CUSTOMER ACKNOWLEDGES THAT ARTIFICAL INTELLIGENCE SYSTEMS ARE A
          RAPIDLY EVOLVING FIELD. WHILE H2O IS ALWAYS WORKING TO IMPROVE THE SOFTWARE, DUE TO THE PROBABILISTIC NATURE
          OF MACHINE LEARNING, THE SOLUTION MAY PROVIDE INACCURATE OUTPUT OR OTHERWISE NOT ALWAYS PRODUCE THE INTENDED
          RESULTS. AS SUCH, CUSTOMER ACKNOWLEDGES THAT NO WARRANTIES ARE MADE BY H2O WITH RESPECT TO (AND H2O WILL HAVE
          NO LIABILITY WITH RESPECT TO) THE OUTPUT (OR CUSTOMER&rsquo;S USE THEREOF) OF GENERATIVE AI FUNCTIONS OF THE
          SOLUTION. IN ADDITION, CUSTOMER AGREES IT WILL NOT USE ANY GENERATIVE AI FUNCTIONS TO POWER, OR MAKE ANY
          DECISIONS RELATED TO, ANY PRODUCTS OR SERVICES THAT MAY INVOLVE PERSONAL INJURY OR ILLEGALITY (SUCH AS MEDICAL
          DEVICES, NUCLEAR PLANT OPERATIONS, WEAPONS APPLICATIONS, ETC.). THIS DISCLAIMER OF WARRANTY CONSTITUTES AN
          ESSENTIAL PART OF THIS AGREEMENT. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS
          SO THE FOREGOING LIMITATIONS MAY NOT APPLY TO CUSTOMER. NOTWITHSTANDING ANY OF THE FOREGOING (INCLUDING
          SECTION 7) – NO WARRANTIES OF ANY KIND ARE MADE WITH RESPECT TO THE SOLUTION IF IT IS PROVIDED ON AN
          EVALUATION BASIS.
        </span>
      </li>
      <li>
        <span className="clause-highlight">LIMITATION OF LIABILITY. </span>
        <span>
          UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY, INCLUDING, BUT NOT LIMITED TO, TORT, CONTRACT, NEGLIGENCE,
          STRICT LIABILITY, OR OTHERWISE, SHALL H2O OR ITS SUPPLIERS OR RESELLERS BE LIABLE TO CUSTOMER OR ANY OTHER
          PERSON IN CONNECTION WITH THE SOLUTION OR ANY OTHER SUBJECT MATTER OF THIS AGREEMENT FOR ANY: (I) INDIRECT,
          SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY CHARACTER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST
          PROFITS, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, COMPUTER FAILURE OR MALFUNCTION, (II) ANY
          AMOUNTS IN THE AGGREGATE IN EXCESS OF THE FEES PAID BY CUSTOMER HEREUNDER DURING THE TWELVE (12) MONTH PERIOD
          PRIOR TO THE DATE THE CAUSE OF ACTION ACCRUES (BUT IF NO FEES HAVE BEEN PAID, SUCH AS IF THE SOLUTION IS
          PROVIDED ON AN EVALUATION BASIS, THE DAMAGES CAP WILL BE US$1,000.00), (III) THE COST OF PROCUREMENT OF
          SUBSTITUTE TECHNOLOGY OR SERVICES OR (IV) MATTERS BEYOND ITS REASONABLE CONTROL. THE FOREGOING LIMITATIONS
          SHALL APPLY EVEN IF H2O SHALL HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGES. SOME STATES DO NOT ALLOW
          THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSION MAY
          NOT APPLY TO CUSTOMER.
        </span>
      </li>
      <li>
        <span className="clause-highlight">TERM AND TERMINATION. </span>
        <span>
          This Agreement is effective as of the Effective Date and, unless otherwise agreed to by the parties in
          writing, will have the initial subscription term of one (1) year. Thereafter, it will automatically renew for
          successive renewal subscription terms of equal length to its initial subscription term, unless either party
          provides the other party with written notice of non-renewal at least sixty (60) days prior to the expiration
          of the then-current subscription term. The initial subscription term and all renewals are referred to
          collectively as the &ldquo;Subscription Term.&rdquo; Notwithstanding the foregoing, if the Solution is
          provided on an Evaluation Basis, it will, unless otherwise agreed in writing, have a term of thirty (30) days.
          If provided on an Evaluation Basis either party may terminate this Agreement at any time, for any or no
          reason, on two (2) days&rsquo; email notice. In addition, either party may terminate this Agreement on written
          notice if the other party (a) fails to cure any material breach of this Agreement within thirty (30) days
          after written notice (ten (10) days in the case of non-payment or in the event Customer breaches any license
          or use restrictions) provided that, the breach notice must provide sufficient detail regarding the breach and
          expressly state the intent to terminate if not cured; (b) ceases operation without a successor or (c) seeks
          protection under any bankruptcy, receivership, trust deed, creditors&rsquo; arrangement, composition, or
          comparable proceeding, or if any such proceeding is instituted against that party (and not dismissed within
          ninety (90) days thereafter). In addition, H2O may suspend access to the Solution, with or without notice, if
          Customer is more than ten (10) days late with any payment hereunder. With respect to Customer&rsquo;s breach
          of its payment obligations, or any license or use restrictions, electronic notice to Customer is sufficient
          hereunder. Any termination of this Agreement shall also terminate the licenses granted hereunder. Upon
          termination of this Agreement for any reason, Customer shall destroy and remove from all computers, hard
          drives, networks, and other storage media all copies of the Solution, and shall, if requested by H2O, so
          certify to H2O in writing that such actions have occurred. Sections 2 and 4 through 15, and all accrued rights
          to payment, shall survive termination of this Agreement.
        </span>
      </li>
      <li>
        <span className="clause-highlight">GOVERNMENT USE. </span>
        <span>
          If Customer is part of an agency, department, or other entity of the United States Government
          (&ldquo;Government&rdquo;), the use, duplication, reproduction, release, modification, disclosure or transfer
          of the Solution is restricted in accordance with the Federal Acquisition Regulations as applied to civilian
          agencies and the Defense Federal Acquisition Regulation Supplement as applied to military agencies. The
          Solution is &ldquo;commercial computer Solution&rdquo; and &ldquo;commercial computer Solution
          documentation.&rdquo; In accordance with such provisions, any use of the Solution by the Government shall be
          governed solely by the terms of this Agreement.
        </span>
      </li>
      <li>
        <span className="clause-highlight">EXPORT CONTROLS. </span>
        <span>
          Customer shall comply with all export laws and restrictions and regulations of the Department of Commerce, the
          United States Department of Treasury Office of Foreign Assets Control (&ldquo;OFAC&rdquo;), or other United
          States or foreign agency or authority, and Customer shall not export, or allow the export or re-export of the
          Solution in violation of any such restrictions, laws or regulations. By installing or using the Solution,
          Customer agrees to the foregoing and represents and warrants that Customer is not located in, under the
          control of, or a national or resident of any restricted country.
        </span>
      </li>
      <li>
        <span className="clause-highlight">NOTICE. </span>
        <span>
          Any notice or communication required or permitted under this Agreement shall be in writing to the parties at:
          (i) if to Customer, the address listed by Customer during the Solution registration process (or as otherwise
          provided by Customer) and (ii) if to H2O: H2O.ai, Inc., 2307 Leghorn Street, Mountain View, CA 94043, Attn:
          Contract Administration (or at such other address as may be given by H2O at any time) and shall be deemed to
          have been received by the addressee (iii) if given by hand, immediately upon receipt; (iv) if given by
          overnight courier service, the first business day following dispatch or (v) if given by registered or
          certified mail, postage prepaid and return receipt requested, the second business day after such notice is
          deposited in the mail. In addition, to be effective, legal notices to H2O (such as for breach) must also be
          provided in email to: <a href="mailto:legal@h2o.ai?subject=Attn:%20Legal%20Notice">legal@h2o.ai</a> (subject
          heading: Attn: Legal Notice) (but notwithstanding earlier receipt via email, legal notices will be deemed
          received when the physical notice is received as set forth in the preceding sentence). Notwithstanding the
          foregoing, an electronic notice to Customer (such as via email) is sufficient to the extent related to breach
          or termination.
        </span>
      </li>
      <li>
        <span className="clause-highlight">LOGO USAGE. </span>
        <span>
          During the term of this Agreement and for a reasonable wind-down period thereafter, H2O may use
          Customer&rsquo;s name and logo on its website and in marketing materials as part of a general list of
          customers.
        </span>
      </li>
      <li>
        <span className="clause-highlight">GENERAL. </span>
        <span>
          This Agreement represents the complete agreement concerning the Solution between the parties, to the exclusion
          of all other terms and supersedes all prior agreements and representations between them; provided, however,
          that if there is already a mutually-signed agreement between H2O and Customer (not including any Customer
          purchase order or similar document) covering Customer&rsquo;s purchase of a license to use the Solution, then
          the express terms of that signed agreement will govern to the extent they are expressly contrary to this
          Agreement. Any additional terms or conditions contained in any Customer purchase order or other ordering
          document (such as a link to Customer&rsquo;s online terms and conditions) will not apply – even if the
          document is accepted or performed on by H2O. This Agreement may be amended only by a writing executed by both
          parties. If any provision of this Agreement is held to be unenforceable for any reason, such provision shall
          be reformed only to the extent necessary to make it enforceable. The failure of H2O to act with respect to a
          breach of this Agreement by Customer or others does not constitute a waiver and shall not limit H2O&rsquo;s
          rights with respect to such breach or any subsequent breaches. This Agreement is personal to Customer and may
          not be assigned or transferred for any reason whatsoever without H2O&rsquo;s consent and any action or conduct
          in violation of the foregoing shall be void and without effect. H2O expressly reserves the right to assign
          this Agreement and to delegate any of its obligations hereunder. This Agreement is subject to the processes
          outlined in H2O&rsquo;s Data Processing Agreement (located at{' '}
          <a href="https://h2o.ai/legal/dpa/">https://h2o.ai/legal/dpa/</a>). This Agreement shall be governed by and
          construed under California law as such law applies to agreements between California residents entered into and
          to be performed within California, without regard to the Uniform Computer Information Transactions Act.
          Without limiting Company&rsquo;s right to seek injunctive or other equitable relief in court, either party may
          elect (by written notice given prior filing of a complaint or, in the case of the defendant, prior to
          answering a complaint) to resolve a dispute by binding arbitration in the English language in San Francisco,
          California under the Rules and Procedures of the Judicial Arbitration and Mediation Service, Inc.
          (&ldquo;JAMS&rdquo;); the decision of the arbitrator will be enforceable in any court. The JAMS Streamlined
          Arbitration Rules &amp; Procedures will be used if available. Subject to the foregoing, all disputes will be
          subject to the sole and exclusive jurisdiction of, and venue in, the state and federal courts located in San
          Francisco, California.
        </span>
      </li>
    </ol>
  </Stack>
);
