import { DetailsHeader, IDetailsHeaderProps, SelectionMode } from '@fluentui/react';
import { DetailsList } from '@h2oai/ui-kit';

import { App } from '../../ai.h2o.cloud.appstore';

function AppVersionList(props: { apps: App[] }) {
  return (
    <DetailsList
      compact
      columns={[
        {
          key: 'name',
          name: 'App Name',
          fieldName: 'name',
          minWidth: 200,
        },
        {
          key: 'version',
          name: 'Version',
          fieldName: 'version',
          minWidth: 120,
        },
      ]}
      items={props.apps}
      selectionMode={SelectionMode.none}
      onRenderDetailsHeader={(props: IDetailsHeaderProps | undefined) => {
        if (props) {
          return (
            <DetailsHeader
              {...props}
              styles={{
                root: {
                  background: 'none',
                  paddingTop: 8,
                },
              }}
            />
          );
        }
        return null;
      }}
      styles={{
        contentWrapper: {
          maxHeight: 180,
          overflow: 'scroll',
        },
      }}
    />
  );
}

export default AppVersionList;
