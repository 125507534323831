import { IDropdownOption, Stack } from '@fluentui/react';
import { IconName, SearchBox, Sizes, useHaicPageTitle } from '@h2oai/ui-kit';
import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';

import {
  App,
  AppPreconditionStatus,
  App_Visibility,
  ListCategoriesResponse,
  TagAssignment,
} from '../../ai.h2o.cloud.appstore';
import { TagService } from '../../services/api';
import { stackStylesAppStorePage } from '../../themes/themes';
import { QueryParamProps, useApp, useError, useLeftPanel, useQueryParams } from '../../utils/hooks';
import { ALL_CATEGORY, MOST_POPULAR } from '../../utils/models';
import { categoryTitleFromName } from '../../utils/utils';
import ErrorPage from '../ErrorPage';
import { AppCategory, BaseCategory, SeeAllProps, SortBy, defaultSort, onSort } from './AppStorePage.models';
import { AppCategoryList } from './components/AppCategoryList/AppCategoryList';
import AppStoreContent from './components/AppStoreContent/AppStoreContent';

const initAppCategory = (name: string, title: string = name) => ({
  name,
  title,
  apps: [],
  count: 0,
});

const updateAppCategoryMap = (appCategoryMap: Map<string, AppCategory>, app: App, category: string) => {
  const categoryApps = appCategoryMap.get(category);
  if (categoryApps) {
    categoryApps.count++;
    // UI is readonly, so I don't think `app` will be mutated, but did shallow copy.
    const count = categoryApps.apps.length;
    if (
      category === BaseCategory.PinnedApps ||
      (category === BaseCategory.MostPopular && count < Sizes.cardMaxColumnCount * 2 - 4) ||
      count < Sizes.cardMaxColumnCount
    ) {
      categoryApps.apps.push({ ...app });
    }
  }
};

const hasCategory = (tags: TagAssignment[], category: string) => {
  let others = true;
  const hasCategory = tags.some((tag) => {
    if (!tag.hidden && tag.isCategory) {
      others = false;
      if (tag.name === category) {
        return true;
      }
    }
    return false;
  });

  return hasCategory || (category === BaseCategory.Other && others);
};

export const getSortedAppsAndCategoryAppsMap = (categoryTags: ListCategoriesResponse, applications: App[]) => {
  const categories = categoryTags?.categories || [];
  const appCategoryMap = new Map<string, AppCategory>([
    [BaseCategory.PinnedApps, initAppCategory(BaseCategory.PinnedApps)],
    [BaseCategory.MostPopular, initAppCategory(BaseCategory.MostPopular)],
  ]);
  categories.forEach(({ name, title }) => appCategoryMap.set(name, initAppCategory(name, title)));
  appCategoryMap.set(BaseCategory.Other, initAppCategory(BaseCategory.Other));
  applications.sort(onSort[defaultSort]).forEach((app) => {
    const pinned = app.preference?.pinned;
    let hasCategory = false;
    app.tags.forEach((tag) => {
      if (!tag.hidden && tag.isCategory) {
        hasCategory = true;
        updateAppCategoryMap(appCategoryMap, app, tag.name);
      }
    });
    if (!hasCategory) {
      updateAppCategoryMap(appCategoryMap, app, BaseCategory.Other);
    }
    if (pinned) {
      updateAppCategoryMap(appCategoryMap, app, BaseCategory.PinnedApps);
    }
    updateAppCategoryMap(appCategoryMap, app, BaseCategory.MostPopular);
  });
  return { sortedApps: applications, appCategoryMap };
};

function AppStorePage() {
  const setLeftPanelProps = useLeftPanel();
  // Query Param-based State Management
  const { params: urlParams, clearParams: clearQueryParams, setParams: setQueryParams } = useQueryParams(),
    // NOTE: There are no `setSearchText` and `setFilterBy` helpers because all related use cases
    // required multiple params to be set, so had to use `setQueryParams` directly.
    searchText = urlParams.get('q') || '',
    category = urlParams.get('category'),
    filterBy = !category || category === MOST_POPULAR ? ALL_CATEGORY : category,
    sortParam = urlParams.get('sort'),
    sortBy = !!sortParam && !!SortBy[sortParam] ? SortBy[sortParam] : defaultSort,
    allCategoryMap = useMemo(() => new Set<string>([ALL_CATEGORY, MOST_POPULAR]), []),
    setSortBy = useCallback(
      (value: string) => setQueryParams({ name: 'sort', value: value === defaultSort ? '' : value }),
      [setQueryParams]
    );

  // Other local variables
  const [loadingMsg, setLoadingMsg] = useState(''),
    [showSections, setShowSections] = useState<boolean>(!searchText.trim() && filterBy === ALL_CATEGORY),
    [err, setErr, onDismissErr] = useError(),
    [apps, setApps] = useState<App[]>([]),
    [filteredApps, setFilteredApps] = useState<App[]>([]),
    { getApps, updateLikes, updatePin } = useApp(),
    [appsCategories, setAppsCategories] = useState<AppCategory[]>([]),
    [categoryAppsMap, setCategoryAppsMap] = useState<Map<string, AppCategory>>(new Map<string, AppCategory>()),
    sortOptions: IDropdownOption[] = [
      { key: SortBy.popularity, text: 'Most popular first', data: { icon: IconName.LikeSolid } },
      { key: SortBy.pinned, text: 'Pinned first', data: { icon: IconName.PinnedSolid } },
      { key: SortBy.likes, text: 'Most liked first', data: { icon: IconName.HeartFill } },
      { key: SortBy.title, text: 'Title A-Z', data: { icon: IconName.Sort } },
      { key: SortBy.createTime, text: 'Newest first', selected: true, data: { icon: IconName.Calendar } },
    ],
    [previousSortBy, setPreviousSortBy] = useState<SortBy>(),
    searchTitle = !!searchText ? `Search term "${searchText}"` : null,
    filterByDisplay = categoryTitleFromName(filterBy, appsCategories),
    listTitle =
      !!searchText && !!filterBy && !allCategoryMap.has(filterBy)
        ? `${filterByDisplay} — ${searchTitle}`
        : searchTitle || filterByDisplay;

  // Callbacks
  const loadApps = useCallback(async () => {
      setLoadingMsg('Loading App Store...');
      try {
        const [applications, categoryTags] = await Promise.all([
          getApps({
            limit: 1000,
            offset: 0,
            visibility: App_Visibility.ALL_USERS,
            allUsers: true,
            name: '',
            latestVersions: true,
            withPreference: true,
            tags: [],
            conditionsStatus: AppPreconditionStatus.STATUS_UNSPECIFIED,
            visibilities: [],
          }),
          TagService.listCategories({}),
        ]);
        const { sortedApps, appCategoryMap } = getSortedAppsAndCategoryAppsMap(categoryTags, applications);
        setApps(sortedApps);
        setAppsCategories(Array.from(appCategoryMap.values()));
        setCategoryAppsMap(appCategoryMap);
      } catch (error: unknown) {
        if (error instanceof Error) setErr({ ...error });
      } finally {
        setLoadingMsg('');
      }
    }, [setErr, getApps]),
    filterSortApps = useCallback(() => {
      if (!apps?.length) return;
      let allApps = apps;
      if (filterBy === ALL_CATEGORY && !searchText.trim()) {
        // no filter and default sort
        setShowSections(true);
      } else {
        allApps = apps.filter(({ title, description, tags }: App) => {
          // Search by Text
          // Filter by Category & All
          // Sort by Dropdown: popularity, pinned, likes, title, createdAt
          const matchesCategory = allCategoryMap.has(filterBy) || hasCategory(tags, filterBy),
            text = searchText.toLowerCase(),
            matchesSearch = title.toLowerCase().includes(text) || description.toLowerCase().includes(text);
          return matchesCategory && (!searchText || matchesSearch);
        });
        setShowSections(false);
      }
      setFilteredApps(allApps);
    }, [apps, setFilteredApps, searchText, filterBy, allCategoryMap]),
    onPinApp = useCallback(
      async (app: App) => {
        await updatePin(app);
        await loadApps();
      },
      [loadApps, updatePin]
    ),
    onLikeApp = useCallback(
      async (app: App) => {
        await updateLikes(app);
        await loadApps();
      },
      [loadApps, updateLikes]
    ),
    onSortChange = useCallback(
      (_e: FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (!option) return;
        const { key } = option;
        setSortBy(SortBy[key]);
      },
      [setSortBy]
    ),
    onSearchChange = useCallback(
      (value: string) => {
        const params: QueryParamProps[] = [{ name: 'q', value }];
        if (filterBy === ALL_CATEGORY) {
          if (!!value) {
            if (!!previousSortBy) {
              params.push({ name: 'sort', value: previousSortBy === defaultSort ? '' : previousSortBy });
            }
            setPreviousSortBy(undefined);
          } else {
            if (sortBy !== defaultSort) setPreviousSortBy(sortBy);
            params.push({ name: 'sort', value: defaultSort });
          }
        }
        setQueryParams(params);
      },
      [filterBy, sortBy, previousSortBy, setQueryParams]
    ),
    onSeeAll = useCallback(
      ({ show, value }: SeeAllProps) => {
        if (show && value) {
          if (value === MOST_POPULAR) {
            if (sortBy !== defaultSort) setPreviousSortBy(sortBy);
            setQueryParams([
              { name: 'category', value: MOST_POPULAR },
              { name: 'sort', value: defaultSort },
            ]);
          } else {
            setQueryParams([
              { name: 'category', value: value === ALL_CATEGORY ? '' : value },
              ...(!!previousSortBy
                ? [{ name: 'sort', value: previousSortBy === defaultSort ? '' : previousSortBy }]
                : []),
            ]);
            setPreviousSortBy(undefined);
          }
          setShowSections(false);
        }
      },
      [sortBy, previousSortBy, setQueryParams]
    ),
    returnToAllCategory = useCallback(() => {
      setShowSections(true);
      clearQueryParams();
      filterSortApps();
      if (sortBy !== defaultSort) setPreviousSortBy(sortBy);
    }, [clearQueryParams, filterSortApps, sortBy]),
    onFilterChange = useCallback(
      (value: string) => {
        if (value === ALL_CATEGORY) {
          returnToAllCategory();
        } else {
          setQueryParams([
            { name: 'category', value: value === ALL_CATEGORY ? '' : value },
            ...(!!previousSortBy
              ? [{ name: 'sort', value: previousSortBy === defaultSort ? '' : previousSortBy }]
              : []),
          ]);
          setPreviousSortBy(undefined);
        }
      },
      [returnToAllCategory, previousSortBy, setQueryParams]
    );

  // Side effects
  useEffect(() => {
    loadApps();
  }, [loadApps]);
  useEffect(() => {
    filterSortApps();
  }, [filterSortApps, filterBy, searchText]);
  useEffect(() => {
    setLeftPanelProps({
      content: (
        <AppCategoryList
          onFilterChange={onFilterChange}
          selected={filterBy}
          appsCategories={appsCategories.filter(
            (cat) => cat.name !== BaseCategory.PinnedApps && cat.name !== BaseCategory.MostPopular
          )}
          appsCount={apps?.length || 0}
        />
      ),
    });
    return () => setLeftPanelProps({ content: undefined });
  }, [filterBy, appsCategories, apps, onFilterChange, setLeftPanelProps]);
  useHaicPageTitle('App Store');
  return err ? (
    <ErrorPage {...err} />
  ) : (
    <Stack styles={stackStylesAppStorePage} tokens={{ childrenGap: 'auto' }}>
      <div style={{ paddingBottom: 10 }}>
        <SearchBox value={searchText} onTextChange={onSearchChange} />
      </div>
      <AppStoreContent
        categoryAppsMap={categoryAppsMap}
        appsCategories={appsCategories}
        filterBy={filterBy}
        filteredApps={filteredApps}
        listTitle={listTitle}
        loadingMsg={loadingMsg}
        onDismissError={onDismissErr}
        onLikeApp={onLikeApp}
        onPinApp={onPinApp}
        onSeeAll={onSeeAll}
        onSortChange={onSortChange}
        showSections={showSections}
        sortBy={sortBy}
        sortOptions={sortOptions}
      />
    </Stack>
  );
}

export default AppStorePage;
