/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/h2o_engine_constraint_set_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1H2OEngineConstraintSet,
    V1H2OEngineConstraintSetFromJSON,
    V1H2OEngineConstraintSetFromJSONTyped,
    V1H2OEngineConstraintSetToJSON,
} from './V1H2OEngineConstraintSet';

/**
 * Response message for getting the current H2OEngineConstraintSet.
 * @export
 * @interface V1GetH2OEngineConstraintSetResponse
 */
export interface V1GetH2OEngineConstraintSetResponse {
    /**
     * 
     * @type {V1H2OEngineConstraintSet}
     * @memberof V1GetH2OEngineConstraintSetResponse
     */
    h2oEngineConstraintSet?: V1H2OEngineConstraintSet;
}

export function V1GetH2OEngineConstraintSetResponseFromJSON(json: any): V1GetH2OEngineConstraintSetResponse {
    return V1GetH2OEngineConstraintSetResponseFromJSONTyped(json, false);
}

export function V1GetH2OEngineConstraintSetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1GetH2OEngineConstraintSetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'h2oEngineConstraintSet': !exists(json, 'h2oEngineConstraintSet') ? undefined : V1H2OEngineConstraintSetFromJSON(json['h2oEngineConstraintSet']),
    };
}

export function V1GetH2OEngineConstraintSetResponseToJSON(value?: V1GetH2OEngineConstraintSetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'h2oEngineConstraintSet': V1H2OEngineConstraintSetToJSON(value.h2oEngineConstraintSet),
    };
}

