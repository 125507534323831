import { IStyle } from '@fluentui/react';
import { Sizes } from '@h2oai/ui-kit';

export interface IProtectedStyles {
  root?: IStyle;
  main?: IStyle;
  contents?: IStyle;
  contentsBody?: IStyle;
}

export const protectedStylesDefault: IProtectedStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    '.h2o-AppBar-root': {
      zIndex: 1,
    },
    '.h2o-LeftPanel-root': {
      position: 'absolute',
      left: 0,
      top: Sizes.headerHeight,
      zIndex: 1,
    },
  },
  main: {
    flex: 1,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '&.has-left-panel': {
      '.h2o-Protected-contents': {
        paddingLeft: Sizes.navWidth + Sizes.pagesSidePadding,
      },
    },
  },
  contents: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  contentsBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
};
