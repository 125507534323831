import { IStyle } from '@fluentui/react';
import { IH2OTheme } from '@h2oai/ui-kit';

export interface IAxisStyles {
  root: IStyle;
}

export interface IAxisClassNames {
  root: string;
}

export const axisStylesDefault = (theme: IH2OTheme): Partial<IAxisStyles> => ({
  root: {
    position: 'relative',
    height: '100%',
    text: {
      color: theme.semanticColors?.textPrimary,
    },
    '.y-axis': {
      '.tick': {
        line: { stroke: theme.palette?.gray300, strokeDasharray: 4 },
      },
      path: {
        stroke: theme.palette?.gray300,
      },
      text: {
        color: theme?.semanticColors?.textPrimary,
      },
    },
    '.y-grid': {
      '.tick': {
        line: { stroke: theme.palette?.gray300, strokeDasharray: 4 },
        text: {
          display: 'none',
        },
      },
    },
    '.x-axis': {
      path: {
        stroke: theme.palette?.gray300,
      },
      text: {
        color: theme.semanticColors?.textPrimary,
      },
    },
  },
});
