/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/adjusted_dai_profile_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1AdjustedDAIProfile,
    V1AdjustedDAIProfileFromJSON,
    V1AdjustedDAIProfileFromJSONTyped,
    V1AdjustedDAIProfileToJSON,
} from './V1AdjustedDAIProfile';

/**
 * Response message for List method.
 * @export
 * @interface V1ListAdjustedDAIProfilesResponse
 */
export interface V1ListAdjustedDAIProfilesResponse {
    /**
     * Collection of AdjustedDAIProfiles.
     * @type {Array<V1AdjustedDAIProfile>}
     * @memberof V1ListAdjustedDAIProfilesResponse
     */
    adjustedDaiProfiles?: Array<V1AdjustedDAIProfile>;
    /**
     * Used to retrieve the next page of results.
     * When unset, no further items are available (this response was the last page).
     * @type {string}
     * @memberof V1ListAdjustedDAIProfilesResponse
     */
    nextPageToken?: string;
    /**
     * Total number of AdjustedDAIProfiles matched by the List request.
     * @type {number}
     * @memberof V1ListAdjustedDAIProfilesResponse
     */
    totalSize?: number;
}

export function V1ListAdjustedDAIProfilesResponseFromJSON(json: any): V1ListAdjustedDAIProfilesResponse {
    return V1ListAdjustedDAIProfilesResponseFromJSONTyped(json, false);
}

export function V1ListAdjustedDAIProfilesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ListAdjustedDAIProfilesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adjustedDaiProfiles': !exists(json, 'adjustedDaiProfiles') ? undefined : ((json['adjustedDaiProfiles'] as Array<any>).map(V1AdjustedDAIProfileFromJSON)),
        'nextPageToken': !exists(json, 'nextPageToken') ? undefined : json['nextPageToken'],
        'totalSize': !exists(json, 'totalSize') ? undefined : json['totalSize'],
    };
}

export function V1ListAdjustedDAIProfilesResponseToJSON(value?: V1ListAdjustedDAIProfilesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adjustedDaiProfiles': value.adjustedDaiProfiles === undefined ? undefined : ((value.adjustedDaiProfiles as Array<any>).map(V1AdjustedDAIProfileToJSON)),
        'nextPageToken': value.nextPageToken,
        'totalSize': value.totalSize,
    };
}

