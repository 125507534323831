import {
  Dialog,
  DialogContent,
  DialogFooter,
  IDialogContentStyles,
  IDialogStyles,
  IDropdownOption,
  SpinnerSize,
  TextField,
} from '@fluentui/react';
import {
  Button,
  Dropdown,
  EventChanged,
  FileUpload,
  FontSizes,
  FontWeights,
  IComponent,
  IH2OTheme,
  ILoaderProps,
  IStyleFunctionOrObject,
  Loader,
  buttonStylesPrimary,
  capitalize,
  useThemeStyles,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useState } from 'react';

import { buttonStylesRow } from '../../themes/themes';
import { Err } from '../Err/Err';

export const dialogStylesInstanceVisibility = (theme: IH2OTheme): Partial<IDialogStyles> => ({
  main: {
    minWidth: 567,
    maxWidth: 567,
    minHeight: 250,
    '& .ms-Dialog-button--close, .ms-Button-icon': {
      fontSize: FontSizes.xxsmall,
      fontWeight: FontWeights.semiBold,
      color: theme.semanticColors?.textPrimary,
    },
  },
});

export const dialogContentStylesInstanceVisibility: Partial<IDialogContentStyles> = {
  content: { '& .ms-Dialog-inner': { padding: '0px 0px 54px' } },
  header: { maxHeight: 24 },
};

export enum FieldType {
  TEXT = 'TEXT',
  FILE = 'FILE',
  DROPDOWN = 'DROPDOWN',
}
export interface DropdownFieldType<T> extends Field<T> {
  type: FieldType.DROPDOWN;
  options: IDropdownOption[];
}
export interface FileFieldType<T> extends Field<T> {
  type: FieldType.FILE;
  multiple?: boolean;
  fileExtensions?: string[];
  maxFileSize?: number;
  maxSize?: number;
}
export interface TextFieldType<T> extends Field<T> {
  type: FieldType.TEXT;
  icon: string;
  regex?: RegExp;
}

type Field<T> = {
  prop: keyof T;
  type: string;
  label?: string;
  required?: boolean;
  errMsg?: string;
  dataTest?: string;
};

export interface DialogProps<T> extends IComponent<IDialogStyles> {
  dialogContentStyles?: IStyleFunctionOrObject<IDialogContentStyles> | IStyleFunctionOrObject<IDialogContentStyles>[];
  title: string;
  isHidden: boolean;
  toggleHideDialog: () => void;
  onSubmit: (newModel: T) => void;
  fields: Array<TextFieldType<T> | FileFieldType<T> | DropdownFieldType<T>>;
  initialModel?: T;
  dataTest?: string;
  submitLabel?: string;
  loading?: ILoaderProps;
  err?: string;
  onCancel?: () => void;
  onDismissErr?: () => void;
  minWidth?: number;
}

function CreateUpdateDialog<T>(props: DialogProps<T>) {
  const { loading, onCancel, toggleHideDialog, styles, dialogContentStyles } = props,
    [model, setModel] = useState<T>({} as T),
    [errors, setErrors] = useState({}),
    checkDisabled = useCallback(
      (source: T) => {
        const hasControlledError = !!props.err && !!props.onDismissErr,
          isLoading = !!loading,
          someRequiredMissing = props.fields.map((f) => f.prop).some((prop) => !source[prop]);
        return someRequiredMissing || hasControlledError || isLoading || Object.values(errors).some((val) => val);
      },
      [errors, props.err, props.fields, loading, props.onDismissErr]
    ),
    [isDisabled, setIsDisabled] = useState(checkDisabled(model)),
    onChange =
      (f: Field<T>) =>
      ({ target }: EventChanged) => {
        const val = (target as HTMLInputElement).value;
        const prop = f.prop as string;
        if (f.type === FieldType.TEXT && (f as TextFieldType<T>).regex) {
          const newErrors = {};
          newErrors[prop] = (f as TextFieldType<T>).regex!.test(val)
            ? ''
            : f.errMsg || `Invalid value for ${f.label || capitalize(prop)}.`;
          setErrors({ ...errors, ...newErrors });
        }
        setModel({ ...model, [prop]: val });
      },
    onFileChange =
      ({ multiple, prop }: FileFieldType<T>) =>
      (files: File[]) => {
        const updatedVal = multiple ? files : files[0];
        setModel({ ...model, [prop]: updatedVal });
      },
    onDropdownChange =
      ({ prop }: DropdownFieldType<T>) =>
      (_e: EventChanged, option?: IDropdownOption) => {
        if (!option) return;
        setModel({ ...model, [prop]: option.key });
      },
    onDialogSubmit = () => props.onSubmit(model),
    handleCancel = onCancel && loading ? onCancel : toggleHideDialog,
    getDialogContent = () =>
      props.fields.map((field, i) => {
        const prop = field.prop as string;
        switch (field.type) {
          case FieldType.TEXT:
            const textfield = field as TextFieldType<T>;
            return (
              <TextField
                key={`${prop}-${i}`}
                label={textfield.label || capitalize(prop)}
                required={textfield.required}
                value={model[prop]}
                errorMessage={errors[prop]}
                onChange={onChange(textfield)}
                iconProps={{ iconName: textfield.icon }}
                data-test={textfield.dataTest}
              />
            );
          case FieldType.FILE:
            const filefield = field as FileFieldType<T>;
            return (
              <FileUpload
                key={`${prop}-${i}`}
                label={filefield.label}
                uploadCallback={onFileChange(field)}
                maxFileSize={filefield.maxFileSize}
                maxSize={filefield.maxSize}
                fileExtensions={filefield.fileExtensions}
                data-test={filefield.dataTest}
              />
            );
          case FieldType.DROPDOWN:
            const dropdownField = field as DropdownFieldType<T>;
            return (
              <Dropdown
                key={`${prop}-${i}`}
                label={dropdownField.label}
                selectedKey={model[prop]}
                onChange={onDropdownChange(dropdownField)}
                options={dropdownField.options}
                required={dropdownField.required}
                data-test={dropdownField.dataTest!}
              />
            );

          default:
            throw new Error('Unsupported field type');
        }
      }),
    _styles = useThemeStyles<IDialogStyles>(undefined, styles),
    _dialogContentStyles = useThemeStyles<IDialogContentStyles>(undefined, dialogContentStyles);

  useEffect(() => setModel(props.initialModel || ({} as T)), [props.initialModel]);
  useEffect(() => setIsDisabled(checkDisabled(model)), [model, checkDisabled]);

  return (
    <Dialog
      hidden={props.isHidden}
      onDismiss={loading ? undefined : toggleHideDialog}
      dialogContentProps={{ title: props.title }}
      minWidth={props.minWidth || 567}
      data-test={props.dataTest}
      modalProps={{ isBlocking: true }}
      styles={_styles}
    >
      <DialogContent styles={_dialogContentStyles} data-set={props.dataTest}>
        {props.err && props.onDismissErr ? (
          <Err err={props.err} onDismissErr={props.onDismissErr} />
        ) : loading ? (
          <Loader {...loading} size={SpinnerSize.large} />
        ) : (
          getDialogContent()
        )}
      </DialogContent>
      <DialogFooter>
        <Button
          styles={buttonStylesRow}
          data-test="create-update-dialog-cancel-button"
          onClick={handleCancel}
          text="Cancel"
        />
        <Button
          styles={buttonStylesPrimary}
          data-test="create-update-dialog-ok-button"
          onClick={onDialogSubmit}
          disabled={isDisabled}
          text={props.submitLabel || (props.initialModel ? 'Update' : 'Create')}
        />
      </DialogFooter>
    </Dialog>
  );
}

export default CreateUpdateDialog;
