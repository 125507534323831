/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/h2o_engine_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RpcStatus,
    RpcStatusFromJSON,
    RpcStatusToJSON,
    V1CalculateH2OEngineSizeCompressedDatasetResponse,
    V1CalculateH2OEngineSizeCompressedDatasetResponseFromJSON,
    V1CalculateH2OEngineSizeCompressedDatasetResponseToJSON,
    V1CalculateH2OEngineSizeRawDatasetResponse,
    V1CalculateH2OEngineSizeRawDatasetResponseFromJSON,
    V1CalculateH2OEngineSizeRawDatasetResponseToJSON,
    V1CreateH2OEngineResponse,
    V1CreateH2OEngineResponseFromJSON,
    V1CreateH2OEngineResponseToJSON,
    V1DeleteH2OEngineResponse,
    V1DeleteH2OEngineResponseFromJSON,
    V1DeleteH2OEngineResponseToJSON,
    V1GetH2OEngineResponse,
    V1GetH2OEngineResponseFromJSON,
    V1GetH2OEngineResponseToJSON,
    V1H2OEngine,
    V1H2OEngineFromJSON,
    V1H2OEngineToJSON,
    V1H2OEngineServiceDownloadLogsResponse,
    V1H2OEngineServiceDownloadLogsResponseFromJSON,
    V1H2OEngineServiceDownloadLogsResponseToJSON,
    V1ListH2OEnginesResponse,
    V1ListH2OEnginesResponseFromJSON,
    V1ListH2OEnginesResponseToJSON,
} from '../models';

export interface H2OEngineServiceCalculateH2OEngineSizeCompressedDatasetRequest {
    workspace: string;
    rowsCount: string;
    columnsCount: string;
}

export interface H2OEngineServiceCalculateH2OEngineSizeRawDatasetRequest {
    workspace: string;
    datasetSizeBytes: string;
}

export interface H2OEngineServiceCreateH2OEngineRequest {
    parent: string;
    h2oEngineId: string;
    body: V1H2OEngine;
    validateOnly?: boolean;
}

export interface H2OEngineServiceDeleteH2OEngineRequest {
    name: string;
    allowMissing?: boolean;
    validateOnly?: boolean;
}

export interface H2OEngineServiceDownloadLogsRequest {
    h2oEngine: string;
    body: object;
}

export interface H2OEngineServiceGetH2OEngineRequest {
    name: string;
}

export interface H2OEngineServiceListH2OEnginesRequest {
    parent: string;
    pageSize?: number;
    pageToken?: string;
    orderBy?: string;
    filter?: string;
}

/**
 * 
 */
export class H2OEngineServiceApi extends runtime.BaseAPI {

    /**
     * Returns a recommended number of nodes and memory within a given workspace constraints for an H2OEngine based on the dimensions of the compressed dataset. (-- api-linter: core::0136::http-uri-suffix=disabled     aip.dev/not-precedent: Not declaring URI suffix for this static service custom method without scope. --)
     */
    async h2OEngineServiceCalculateH2OEngineSizeCompressedDatasetRaw(requestParameters: H2OEngineServiceCalculateH2OEngineSizeCompressedDatasetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1CalculateH2OEngineSizeCompressedDatasetResponse>> {
        if (requestParameters.workspace === null || requestParameters.workspace === undefined) {
            throw new runtime.RequiredError('workspace','Required parameter requestParameters.workspace was null or undefined when calling h2OEngineServiceCalculateH2OEngineSizeCompressedDataset.');
        }

        if (requestParameters.rowsCount === null || requestParameters.rowsCount === undefined) {
            throw new runtime.RequiredError('rowsCount','Required parameter requestParameters.rowsCount was null or undefined when calling h2OEngineServiceCalculateH2OEngineSizeCompressedDataset.');
        }

        if (requestParameters.columnsCount === null || requestParameters.columnsCount === undefined) {
            throw new runtime.RequiredError('columnsCount','Required parameter requestParameters.columnsCount was null or undefined when calling h2OEngineServiceCalculateH2OEngineSizeCompressedDataset.');
        }

        const queryParameters: any = {};

        if (requestParameters.rowsCount !== undefined) {
            queryParameters['rowsCount'] = requestParameters.rowsCount;
        }

        if (requestParameters.columnsCount !== undefined) {
            queryParameters['columnsCount'] = requestParameters.columnsCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/{workspace}:calculateH2OEngineSizeCompressedDataset`.replace(`{${"workspace"}}`, encodeURIComponent(String(requestParameters.workspace))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1CalculateH2OEngineSizeCompressedDatasetResponseFromJSON(jsonValue));
    }

    /**
     * Returns a recommended number of nodes and memory within a given workspace constraints for an H2OEngine based on the dimensions of the compressed dataset. (-- api-linter: core::0136::http-uri-suffix=disabled     aip.dev/not-precedent: Not declaring URI suffix for this static service custom method without scope. --)
     */
    async h2OEngineServiceCalculateH2OEngineSizeCompressedDataset(requestParameters: H2OEngineServiceCalculateH2OEngineSizeCompressedDatasetRequest, initOverrides?: RequestInit): Promise<V1CalculateH2OEngineSizeCompressedDatasetResponse> {
        const response = await this.h2OEngineServiceCalculateH2OEngineSizeCompressedDatasetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a recommended number of nodes and memory within a given workspace constraints for an H2OEngine based on the raw size of the dataset. (-- api-linter: core::0136::http-uri-suffix=disabled     aip.dev/not-precedent: Not declaring URI suffix for this static service custom method without scope. --)
     */
    async h2OEngineServiceCalculateH2OEngineSizeRawDatasetRaw(requestParameters: H2OEngineServiceCalculateH2OEngineSizeRawDatasetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1CalculateH2OEngineSizeRawDatasetResponse>> {
        if (requestParameters.workspace === null || requestParameters.workspace === undefined) {
            throw new runtime.RequiredError('workspace','Required parameter requestParameters.workspace was null or undefined when calling h2OEngineServiceCalculateH2OEngineSizeRawDataset.');
        }

        if (requestParameters.datasetSizeBytes === null || requestParameters.datasetSizeBytes === undefined) {
            throw new runtime.RequiredError('datasetSizeBytes','Required parameter requestParameters.datasetSizeBytes was null or undefined when calling h2OEngineServiceCalculateH2OEngineSizeRawDataset.');
        }

        const queryParameters: any = {};

        if (requestParameters.datasetSizeBytes !== undefined) {
            queryParameters['datasetSizeBytes'] = requestParameters.datasetSizeBytes;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/{workspace}:calculateH2OEngineSizeRawDataset`.replace(`{${"workspace"}}`, encodeURIComponent(String(requestParameters.workspace))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1CalculateH2OEngineSizeRawDatasetResponseFromJSON(jsonValue));
    }

    /**
     * Returns a recommended number of nodes and memory within a given workspace constraints for an H2OEngine based on the raw size of the dataset. (-- api-linter: core::0136::http-uri-suffix=disabled     aip.dev/not-precedent: Not declaring URI suffix for this static service custom method without scope. --)
     */
    async h2OEngineServiceCalculateH2OEngineSizeRawDataset(requestParameters: H2OEngineServiceCalculateH2OEngineSizeRawDatasetRequest, initOverrides?: RequestInit): Promise<V1CalculateH2OEngineSizeRawDatasetResponse> {
        const response = await this.h2OEngineServiceCalculateH2OEngineSizeRawDatasetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates and starts a new H2OEngine within a specified workspace.
     */
    async h2OEngineServiceCreateH2OEngineRaw(requestParameters: H2OEngineServiceCreateH2OEngineRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1CreateH2OEngineResponse>> {
        if (requestParameters.parent === null || requestParameters.parent === undefined) {
            throw new runtime.RequiredError('parent','Required parameter requestParameters.parent was null or undefined when calling h2OEngineServiceCreateH2OEngine.');
        }

        if (requestParameters.h2oEngineId === null || requestParameters.h2oEngineId === undefined) {
            throw new runtime.RequiredError('h2oEngineId','Required parameter requestParameters.h2oEngineId was null or undefined when calling h2OEngineServiceCreateH2OEngine.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling h2OEngineServiceCreateH2OEngine.');
        }

        const queryParameters: any = {};

        if (requestParameters.h2oEngineId !== undefined) {
            queryParameters['h2oEngineId'] = requestParameters.h2oEngineId;
        }

        if (requestParameters.validateOnly !== undefined) {
            queryParameters['validateOnly'] = requestParameters.validateOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/{parent}/h2oEngines`.replace(`{${"parent"}}`, encodeURIComponent(String(requestParameters.parent))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: V1H2OEngineToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1CreateH2OEngineResponseFromJSON(jsonValue));
    }

    /**
     * Creates and starts a new H2OEngine within a specified workspace.
     */
    async h2OEngineServiceCreateH2OEngine(requestParameters: H2OEngineServiceCreateH2OEngineRequest, initOverrides?: RequestInit): Promise<V1CreateH2OEngineResponse> {
        const response = await this.h2OEngineServiceCreateH2OEngineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a H2OEngine.
     */
    async h2OEngineServiceDeleteH2OEngineRaw(requestParameters: H2OEngineServiceDeleteH2OEngineRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1DeleteH2OEngineResponse>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling h2OEngineServiceDeleteH2OEngine.');
        }

        const queryParameters: any = {};

        if (requestParameters.allowMissing !== undefined) {
            queryParameters['allowMissing'] = requestParameters.allowMissing;
        }

        if (requestParameters.validateOnly !== undefined) {
            queryParameters['validateOnly'] = requestParameters.validateOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1DeleteH2OEngineResponseFromJSON(jsonValue));
    }

    /**
     * Deletes a H2OEngine.
     */
    async h2OEngineServiceDeleteH2OEngine(requestParameters: H2OEngineServiceDeleteH2OEngineRequest, initOverrides?: RequestInit): Promise<V1DeleteH2OEngineResponse> {
        const response = await this.h2OEngineServiceDeleteH2OEngineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download H2OEngine logs.
     */
    async h2OEngineServiceDownloadLogsRaw(requestParameters: H2OEngineServiceDownloadLogsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1H2OEngineServiceDownloadLogsResponse>> {
        if (requestParameters.h2oEngine === null || requestParameters.h2oEngine === undefined) {
            throw new runtime.RequiredError('h2oEngine','Required parameter requestParameters.h2oEngine was null or undefined when calling h2OEngineServiceDownloadLogs.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling h2OEngineServiceDownloadLogs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/{h2OEngine}:downloadLogs`.replace(`{${"h2oEngine"}}`, encodeURIComponent(String(requestParameters.h2oEngine))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1H2OEngineServiceDownloadLogsResponseFromJSON(jsonValue));
    }

    /**
     * Download H2OEngine logs.
     */
    async h2OEngineServiceDownloadLogs(requestParameters: H2OEngineServiceDownloadLogsRequest, initOverrides?: RequestInit): Promise<V1H2OEngineServiceDownloadLogsResponse> {
        const response = await this.h2OEngineServiceDownloadLogsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a specific H2OEngine.
     */
    async h2OEngineServiceGetH2OEngineRaw(requestParameters: H2OEngineServiceGetH2OEngineRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1GetH2OEngineResponse>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling h2OEngineServiceGetH2OEngine.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1GetH2OEngineResponseFromJSON(jsonValue));
    }

    /**
     * Returns a specific H2OEngine.
     */
    async h2OEngineServiceGetH2OEngine(requestParameters: H2OEngineServiceGetH2OEngineRequest, initOverrides?: RequestInit): Promise<V1GetH2OEngineResponse> {
        const response = await this.h2OEngineServiceGetH2OEngineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a collection of H2OEngines within a workspace.
     */
    async h2OEngineServiceListH2OEnginesRaw(requestParameters: H2OEngineServiceListH2OEnginesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1ListH2OEnginesResponse>> {
        if (requestParameters.parent === null || requestParameters.parent === undefined) {
            throw new runtime.RequiredError('parent','Required parameter requestParameters.parent was null or undefined when calling h2OEngineServiceListH2OEngines.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['pageToken'] = requestParameters.pageToken;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/{parent}/h2oEngines`.replace(`{${"parent"}}`, encodeURIComponent(String(requestParameters.parent))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1ListH2OEnginesResponseFromJSON(jsonValue));
    }

    /**
     * Returns a collection of H2OEngines within a workspace.
     */
    async h2OEngineServiceListH2OEngines(requestParameters: H2OEngineServiceListH2OEnginesRequest, initOverrides?: RequestInit): Promise<V1ListH2OEnginesResponse> {
        const response = await this.h2OEngineServiceListH2OEnginesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
