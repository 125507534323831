import { Stack } from '@fluentui/react';
import { ConfirmDialog, H2OThemeProvider, toHash } from '@h2oai/ui-kit';
import { useEffect, useMemo, useState } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { BrowserRouter } from 'react-router-dom';

import { Eula, EulaWrapper } from '../components/Eula';
import AuthPage from '../pages/AuthPage';
import { stackStylesAppContainer } from '../themes/themes';
import { NotificationProvider } from '../utils/contexts';

export const EULA_LOCALSTORAGE_KEY = 'eulaAccepted';

function App() {
  const eula = useMemo(() => toHash(ReactDOMServer.renderToString(Eula())), []);
  const [acceptedEula, setAcceptedEula] = useState<boolean>(false);
  const [existingEula, setExistingEula] = useState<boolean>(false);
  const acceptEula = () => {
    if (!acceptedEula) {
      localStorage.setItem(EULA_LOCALSTORAGE_KEY, eula);
      setAcceptedEula(true);
    }
  };
  useEffect(() => {
    // automatically accept EULA if we are using mocked data, and not using a local identity provider
    if (process.env.REACT_APP_USE_MOCK_SERVER && !process.env.REACT_APP_USE_IDP) {
      acceptEula();
    }
    const storedEula = localStorage.getItem(EULA_LOCALSTORAGE_KEY);
    if (storedEula) {
      setExistingEula(true);
    }
    if (storedEula === eula) {
      setAcceptedEula(true);
    }
  }, [eula]);
  return (
    <Stack styles={stackStylesAppContainer} data-test="app-main-container">
      <H2OThemeProvider>
        <BrowserRouter>
          <NotificationProvider>
            {acceptedEula ? (
              <AuthPage />
            ) : (
              <ConfirmDialog
                data-test="login-dialog"
                title="EULA"
                hidden={false}
                content={EulaWrapper(existingEula)}
                confirmationButtonText="I agree"
                onConfirm={acceptEula}
                maxWidth={1000}
                minWidth="50%"
                suppressDismissalButton={true}
                styles={{ root: { 'button[aria-label="Close"]': { visibility: 'hidden' } } }}
              />
            )}
          </NotificationProvider>
        </BrowserRouter>
      </H2OThemeProvider>
    </Stack>
  );
}

export default App;
