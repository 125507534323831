/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/h2o_engine_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * All possible H2OEngine states.
 * 
 *  - STATE_UNSPECIFIED: H2OEngine state is unspecified or unknown.
 *  - STATE_STARTING: H2OEngine is starting.
 *  - STATE_RUNNING: H2OEngine is running and can be used.
 *  - STATE_PAUSING: H2OEngine is pausing.
 *  - STATE_PAUSED: H2OEngine is paused and CANNOT be resumed again.
 *  - STATE_FAILED: H2OEngine has failed.
 *  - STATE_DELETING: H2OEngine is being deleted.
 *  - STATE_CONNECTING: H2OEngine is establishing a connection with the AI Engine Manager.
 * @export
 * @enum {string}
 */
export enum V1H2OEngineState {
    Unspecified = 'STATE_UNSPECIFIED',
    Starting = 'STATE_STARTING',
    Running = 'STATE_RUNNING',
    Pausing = 'STATE_PAUSING',
    Paused = 'STATE_PAUSED',
    Failed = 'STATE_FAILED',
    Deleting = 'STATE_DELETING',
    Connecting = 'STATE_CONNECTING'
}

export function V1H2OEngineStateFromJSON(json: any): V1H2OEngineState {
    return V1H2OEngineStateFromJSONTyped(json, false);
}

export function V1H2OEngineStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1H2OEngineState {
    return json as V1H2OEngineState;
}

export function V1H2OEngineStateToJSON(value?: V1H2OEngineState | null): any {
    return value as any;
}

