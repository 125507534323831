import { SpinnerSize } from '@fluentui/react';
import { IComponent, IH2OTheme, Loader, LoaderType, useClassNames } from '@h2oai/ui-kit';
import * as d3Ease from 'd3-ease';
import * as d3Selection from 'd3-selection';
import * as d3Shape from 'd3-shape';
import { HTMLAttributes, Ref, forwardRef, useCallback } from 'react';
import 'd3-transition'; // for .transition().

import { Axis, AxisSize, AxisUnit, IAxisProps, showHideAxisTooltip } from './Axis';
import { ITimeSeriesClassNames, ITimeSeriesStyles, timeSeriesStylesDefault } from './TimeSeries.styles';

export function barGradient(theme: IH2OTheme) {
  return {
    start: theme.palette?.yellow600 || '#FFD659',
    end: theme.palette?.yellow400 || '#E6AC00',
  };
}

export function getDaysAgo(days: number): Date {
  if (days === 0) return new Date();
  return new Date(Date.now() - 1000 * 60 * 60 * 24 * days);
}

export function getMinutesAgo(minutes: number): Date {
  if (minutes === 0) return new Date();
  return new Date(Date.now() - 1000 * 60 * 60 * minutes);
}

export interface UsageDatum {
  value: number;
  time: string;
}

interface Gradient {
  start: string;
  end: string;
}

const animDuration = 300;

function setGradient(
  svg: d3Selection.Selection<SVGGElement, unknown, null, undefined>,
  gradient: Gradient | undefined
) {
  if (gradient) {
    const defs = svg.append('defs');
    const bgGradient = defs.append('linearGradient').attr('id', 'bg-gradient').attr('gradientTransform', 'rotate(90)');
    bgGradient.append('stop').attr('stop-color', gradient.end).attr('offset', '0%');
    bgGradient.append('stop').attr('stop-color', gradient.start).attr('offset', '100%');
  }
}

const renderBars = (
  svg: d3Selection.Selection<SVGGElement, unknown, null, undefined>,
  axisProps: IAxisProps,
  animate: boolean | undefined,
  size: AxisSize,
  unit: AxisUnit,
  gradient: Gradient | undefined,
  el: HTMLElement | null,
  getTooltipMessage?: (datum: any) => string
) => {
  const { data, hasTooltip, hasAnimation, stacked } = axisProps;

  setGradient(svg, gradient);

  const chartData = stacked ? (d3Shape.stack().keys(unit.labels)(data) as Array<any>) : unit.labels;

  const selection = svg
    .append('g')
    .selectAll('g')
    .data(chartData)
    .enter()
    .append('g')
    .attr('class', 'group rects')
    .attr('data-test', 'rects')
    .attr('transform', (_, index) => `translate(${unit.xScale(unit.labels[index])},0)`);

  const rectSelection = selection
    .selectAll('rect')
    .data((_d, i) => unit.fields.map((field) => ({ field, value: +data[i][field] })));

  // for updating data
  rectSelection.exit().transition().duration(animDuration).remove();

  let rects = rectSelection.enter().append('rect');

  rects = rects
    .attr('class', 'item rect')
    .attr('width', () => unit.xScale.bandwidth())
    .attr('x', (d) => unit.xScale(d.field) || 0)
    .attr('fill', (d) => (gradient ? 'url(#bg-gradient)' : unit.colorScale(d.field)))
    .attr('title', (d) => `${d.field}: ${d.value}`);

  if (hasAnimation && animate) {
    rects
      .attr('y', unit.minMax.min < 0 ? size.height - size.margin.bottom : unit.yScale(unit.minMax.min))
      .attr('height', 0)
      .transition()
      .duration(animDuration)
      .ease(d3Ease.easeQuadIn)
      .attr('y', (d) => (d.value > 0 ? unit.yScale(d.value) : unit.yScale(0)))
      .attr('height', (d) => {
        const h =
          unit.minMax.min < 0
            ? Math.abs(unit.yScale(d.value) - unit.yScale(0))
            : size.canvas.height - unit.yScale(d.value);
        return h < 0 ? 0 : h;
      });
  } else {
    rects
      .attr('y', (d) => (d.value > 0 ? unit.yScale(d.value) : unit.yScale(0)))
      .attr('height', (d) => {
        const h =
          unit.minMax.min < 0
            ? Math.abs(unit.yScale(d.value) - unit.yScale(0))
            : size.canvas.height - unit.yScale(d.value);
        return h < 0 ? 0 : h;
      });
  }

  if (hasTooltip) {
    rects
      .on('mouseenter', (e, d) =>
        showHideAxisTooltip(e, el, getTooltipMessage ? getTooltipMessage(d) : `${d.field}: ${d.value}`)
      )
      .on('mouseleave', (e) => showHideAxisTooltip(e, el, '', true));
  }
};

export type TimeSeriesAxisProps = Omit<
  IAxisProps,
  'decorationMaxRate' | 'scalePadding' | 'scalePaddingInner' | 'scalePaddingOuter'
>;

export interface ITimeSeriesProps extends IComponent<ITimeSeriesStyles>, HTMLAttributes<HTMLDivElement> {
  axisProps: TimeSeriesAxisProps;
  gradient?: Gradient;
  getTooltipMessage?: (datum: any) => string;
  loadingMessage?: string;
}

export const TimeSeries = forwardRef((props: ITimeSeriesProps, ref: Ref<HTMLDivElement>) => {
  const { styles, className, loadingMessage, axisProps, gradient, getTooltipMessage, ...elementProps } = props;
  const classNames = useClassNames<Partial<ITimeSeriesStyles>, ITimeSeriesClassNames>(
    'TimeSeries',
    timeSeriesStylesDefault,
    styles,
    className
  );
  const onRenderChart = useCallback(
    (svg, axisProps, size, unit, el, animate) => {
      return renderBars(svg, axisProps, animate, size, unit, gradient, el, getTooltipMessage);
    },
    [gradient, getTooltipMessage]
  );

  return (
    <div {...elementProps} ref={ref} className={classNames.root} data-test={props['data-test'] || 'timeSeries'}>
      {loadingMessage && (
        <Loader
          loaderContainerStyles={{ root: { position: 'absolute', zIndex: 1, height: '100%', width: '100%' } }}
          styles={{
            root: { height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' },
          }}
          type={LoaderType.spinner}
          size={SpinnerSize.large}
          label={loadingMessage}
        />
      )}
      <div
        style={
          loadingMessage
            ? { opacity: 0.5, pointerEvents: 'none', width: '100%', height: '100%' }
            : { width: '100%', height: '100%' }
        }
      >
        <Axis
          {...axisProps}
          decorationMaxRate={1}
          scalePadding={1}
          scalePaddingInner={0.5}
          scalePaddingOuter={0.5}
          onRenderChart={onRenderChart}
        />
      </div>
    </div>
  );
});
