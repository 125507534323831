/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as fm from "../../../../fetch.pb"
import * as GoogleProtobufTimestamp from "../../../../google/protobuf/timestamp.pb"
export type FetchLogNamesRequest = {
  resource?: string
  startTime?: GoogleProtobufTimestamp.Timestamp
  endTime?: GoogleProtobufTimestamp.Timestamp
}

export type FetchLogNamesResponse = {
  logNames?: string[]
}

export class InternalService {
  static FetchLogNames(req: FetchLogNamesRequest, initReq?: fm.InitReq): Promise<FetchLogNamesResponse> {
    return fm.fetchReq<FetchLogNamesRequest, FetchLogNamesResponse>(`/v1/internal:fetchLogNames?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
}