import { ChoiceGroup, Text, mergeStyleSets } from '@fluentui/react';
import {
  Checkbox,
  DetailsList,
  IH2OTheme,
  IPanelProps,
  MessageBar,
  TextField,
  checkboxStylesCircle,
  debounce,
  useTheme,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { App, Secret, Secret_DataEntry, Secret_Visibility } from '../../ai.h2o.cloud.appstore';
import { ManagedListSeparator, ManagedListWrapper } from '../ManagedList/ManagedList';
import { ManagedListConfigPanel } from '../ManagedListConfigPanel/ManagedListConfigPanel';
import { PairsList } from '../PairList/PairList';

export type AdminSecretsDataUI = { [key: string]: string };

export type visibilityItem = { key: Secret_Visibility; text: string };

export const visibilityList: visibilityItem[] = [
  { key: Secret_Visibility.ALL_USERS, text: 'All apps' },
  { key: Secret_Visibility.APP, text: 'Specific app' },
];

const convertAdminSecretDataToUI = (data?: Secret_DataEntry[]) => {
  return data ? (JSON.parse(JSON.stringify(data)) as AdminSecretsDataUI) : {};
};

export const convertAdminSecretDataToREST = (data?: AdminSecretsDataUI) => {
  return data ? (JSON.parse(JSON.stringify(data)) as Secret_DataEntry[]) : [];
};

type AdminSecretVisibility = Secret_Visibility.ALL_USERS | Secret_Visibility.APP;

export const appNameUISanitize = (appName?: string) => {
  if (!appName) {
    return '';
  }

  return appName.replace('app:', '');
};

export type SecretConfigPanelEvent = (
  name: string | undefined,
  visibility: AdminSecretVisibility,
  data: { [key: string]: string },
  appId?: string
) => void;

const isObjectEmpty = (objectName: {}) => Object.keys(objectName).length === 0;

export interface ISecretConfigPanelProps extends IPanelProps {
  appList: App[];
  secret?: Secret;
  onClose: () => void;
  onConfirm: SecretConfigPanelEvent;
}

// https://docs.h2o.ai/h2o-ai-cloud/developerguide/cli#creating-and-updating-private-secrets
const regExpConstructor = new RegExp('(^[a-z0-9-]*$)', 'gm');

const roundCheckChoice = (theme: IH2OTheme) =>
  mergeStyleSets({
    root: {
      '.ms-Checkbox-checkmark': { background: theme.palette?.black, borderRadius: '50%', color: theme.palette?.black },
      ':hover .ms-Checkbox-checkmark': {
        background: theme.palette?.black,
        color: theme.palette?.black,
        opacity: 0.6,
      },
      ':hover.is-checked .ms-Checkbox-checkmark': {
        opacity: 1,
      },
    },
  });

export function SecretConfigPanel(props: ISecretConfigPanelProps) {
  const { appList, secret, onConfirm, onClose, ..._hProps } = props,
    theme = useTheme(),
    data = convertAdminSecretDataToUI(secret?.data),
    [selectedAppItem, setSelectedAppItem] = useState<App | undefined>(),
    [config, setConfig] = useState<{ [key: string]: string }>(data),
    [newSecretName, setNewSecretName] = useState<string>(''),
    [newSecretNameError, setNewSecretNameError] = useState<string | undefined>(),
    [appItems, setAppItems] = useState<App[]>(appList),
    [selectedVisibility, setSelectedVisibility] = useState<AdminSecretVisibility>(
      (secret?.visibility as AdminSecretVisibility) || Secret_Visibility.ALL_USERS
    ),
    checkedForm = useCallback(
      () =>
        (!newSecretName && !secret?.name) ||
        isObjectEmpty(config) ||
        (selectedVisibility === Secret_Visibility.APP && !selectedAppItem),
      [newSecretName, config, selectedVisibility, selectedAppItem]
    ),
    filterApp = useCallback(
      (_e, text: string) =>
        setAppItems(appList.filter((app) => app.name.toLocaleLowerCase().includes(text.toLocaleLowerCase()))),
      []
    ),
    onSearchInstanceTextChange = useMemo(() => debounce(300, filterApp), [filterApp]),
    modifyItem = (keyValuePair: [string, string]) => {
      const newConfig = { ...config };
      newConfig[keyValuePair[0]] = btoa(keyValuePair[1]);
      setConfig(newConfig);
    },
    removeItem = (key: string) => {
      const newConfig = { ...config };
      delete newConfig[key];
      setConfig(newConfig);
    };

  useEffect(() => {
    if (secret) {
      const selectedApp = appList.find((x) => appNameUISanitize(secret?.parent) === x.name);
      setSelectedAppItem(selectedApp);
    }
  }, []);
  return (
    <ManagedListConfigPanel
      {..._hProps}
      buttonTitleEdit="Update"
      isEditable={!!secret}
      isDisabled={checkedForm()}
      titleCreate="Create secret"
      titleEdit="Update secret"
      onDismiss={onClose}
      onDone={() => {
        onConfirm(newSecretName, selectedVisibility, config, selectedAppItem?.name);
      }}
    >
      <ManagedListWrapper>
        <MessageBar messageBarType={0}>All secret values are encoded.</MessageBar>
      </ManagedListWrapper>
      {!secret ? (
        <TextField
          label="Secret name"
          required={true}
          placeholder="Choose secret name"
          onChange={(_e, newValue) => {
            if (newValue || newValue === '') {
              setNewSecretName(newValue);

              if (newValue.length <= 64 && newValue.match(regExpConstructor)) {
                setNewSecretNameError(undefined);
              } else {
                setNewSecretNameError(
                  'Only small alphanumeric characters and - are allowed. Maximum length is 63 characters.'
                );
              }
            }
          }}
          errorMessage={newSecretNameError}
          value={newSecretName}
        />
      ) : (
        <Text>Secret name: {secret.name}</Text>
      )}
      <ManagedListSeparator text="Secret content" />
      <PairsList config={config || {}} onModifyItem={modifyItem} onRemoveItem={removeItem} isEditable={!!secret} />
      <ManagedListSeparator text="Additional configuration" />
      <div>
        <ChoiceGroup
          options={visibilityList}
          defaultSelectedKey={selectedVisibility}
          label="Visibility"
          required={true}
          disabled={!!secret}
          onChange={(_, value) => {
            setSelectedVisibility((value?.key as AdminSecretVisibility) || Secret_Visibility.ALL_USERS);
          }}
        />
        {selectedVisibility === Secret_Visibility.APP && secret && selectedAppItem && (
          <ManagedListWrapper>
            {selectedAppItem.name} - {selectedAppItem.visibility} - {selectedAppItem.owner}
          </ManagedListWrapper>
        )}
        {selectedVisibility === Secret_Visibility.APP && !secret && (
          <ManagedListWrapper>
            <TextField
              placeholder="Filter by App name"
              onChange={onSearchInstanceTextChange}
              iconProps={{ iconName: 'Filter' }}
            />
            <div style={{ height: 310, overflow: 'auto' }} data-is-scrollable="true">
              <DetailsList
                isHeaderVisible={false}
                columns={[
                  {
                    key: 'select',
                    name: 'Select',
                    minWidth: 10,
                    maxWidth: 15,
                    onRender: (d: App) => (
                      <div style={{ textAlign: 'center' }}>
                        <Checkbox
                          name="id"
                          checked={d.id === selectedAppItem?.id}
                          styles={[checkboxStylesCircle, roundCheckChoice(theme)]}
                          onChange={() => setSelectedAppItem(d)}
                        />
                      </div>
                    ),
                  },
                  {
                    fieldName: 'id',
                    key: 'title',
                    minWidth: 50,
                    name: '',
                    onRender: (d: App) => (
                      <>
                        {d.name} - {d.visibility} - {d.owner}
                      </>
                    ),
                  },
                ]}
                items={appItems}
              />
            </div>
          </ManagedListWrapper>
        )}
      </div>
    </ManagedListConfigPanel>
  );
}
