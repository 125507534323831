/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/dai_engine_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1DAIEngineState,
    V1DAIEngineStateFromJSON,
    V1DAIEngineStateFromJSONTyped,
    V1DAIEngineStateToJSON,
} from './V1DAIEngineState';

/**
 * 
 * @export
 * @interface V1DAIEngine
 */
export interface V1DAIEngine {
    /**
     * DAIEngine resource name.
     * @type {string}
     * @memberof V1DAIEngine
     */
    readonly name?: string;
    /**
     * Globally unique identifier of the resource.
     * @type {string}
     * @memberof V1DAIEngine
     */
    readonly uid?: string;
    /**
     * Name of an entity that created the DAIEngine.
     * @type {string}
     * @memberof V1DAIEngine
     */
    readonly creator?: string;
    /**
     * Human-readable name of creator.
     * @type {string}
     * @memberof V1DAIEngine
     */
    readonly creatorDisplayName?: string;
    /**
     * 
     * @type {V1DAIEngineState}
     * @memberof V1DAIEngine
     */
    state?: V1DAIEngineState;
    /**
     * Indicates whether changes to the resource are in progress.
     * If true, current state of the DAIEngine does not match the intended state.
     * @type {boolean}
     * @memberof V1DAIEngine
     */
    readonly reconciling?: boolean;
    /**
     * Time when the DAIEngine creation was requested.
     * @type {Date}
     * @memberof V1DAIEngine
     */
    readonly createTime?: Date;
    /**
     * Time when the DAIEngine was deleted.
     * @type {Date}
     * @memberof V1DAIEngine
     */
    readonly deleteTime?: Date | null;
    /**
     * Time when the DAIEngine was last updated.
     * @type {Date}
     * @memberof V1DAIEngine
     */
    readonly updateTime?: Date | null;
    /**
     * Time when the DAIEngine was last resumed (or started for the first time).
     * @type {Date}
     * @memberof V1DAIEngine
     */
    readonly resumeTime?: Date | null;
    /**
     * The URL address to access the engine API.
     * @type {string}
     * @memberof V1DAIEngine
     */
    readonly apiUrl?: string;
    /**
     * The URL address to initiate login flow.
     * @type {string}
     * @memberof V1DAIEngine
     */
    readonly loginUrl?: string;
    /**
     * Additional arbitrary metadata associated with the DAIEngine.
     * 
     * Annotations are key/value pairs.
     * The key must:
     * - be 63 characters or less
     * - begin and end with an alphanumeric character ([a-z0-9A-Z])
     * - with dashes (-), underscores (_), dots (.), and alphanumerics between
     * - regex used for validation is: ^[A-Za-z0-9]([-A-Za-z0-9_.]{0,61}[A-Za-z0-9])?$
     * @type {{ [key: string]: string; }}
     * @memberof V1DAIEngine
     */
    annotations?: { [key: string]: string; };
    /**
     * Human-readable name of the DAIEngine. Must contain at most 63 characters. Does not have to be unique.
     * @type {string}
     * @memberof V1DAIEngine
     */
    displayName?: string;
    /**
     * Driverless AI version identifier. Version may be specified during engine creation but cannot be changed later.
     * For example: "1.10.1" or "latest".
     * @type {string}
     * @memberof V1DAIEngine
     */
    version?: string | null;
    /**
     * The amount of CPU units requested by this DAIEngine.
     * @type {number}
     * @memberof V1DAIEngine
     */
    cpu?: number | null;
    /**
     * The amount of GPU units requested by this DAIEngine.
     * @type {number}
     * @memberof V1DAIEngine
     */
    gpu?: number | null;
    /**
     * The amount of memory in bytes requested by this DAIEngine.
     * @type {string}
     * @memberof V1DAIEngine
     */
    memoryBytes?: string | null;
    /**
     * The amount of storage requested by this DAIEngine.
     * @type {string}
     * @memberof V1DAIEngine
     */
    storageBytes?: string | null;
    /**
     * Additional Driverless AI configuration.
     * 
     * Temporary: Some config keys are reserved and their value cannot be changed.
     * Attempting to set or override a reserved key will have no effect, no error
     * will be raised.
     * Entries with reserved keys will not be available in this field (they are
     * hidden).
     * @type {{ [key: string]: string; }}
     * @memberof V1DAIEngine
     */
    config?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof V1DAIEngine
     */
    maxIdleDuration?: string | null;
    /**
     * Maximum time the DAIEngine can be running. When exceeded, the DAIEngine will pause.
     * @type {string}
     * @memberof V1DAIEngine
     */
    maxRunningDuration?: string | null;
    /**
     * Indicates, whether the used version can be upgraded to a later one.
     * @type {boolean}
     * @memberof V1DAIEngine
     */
    readonly upgradeAvailable?: boolean;
    /**
     * Current time the DAIEngine is idle.
     * @type {string}
     * @memberof V1DAIEngine
     */
    readonly currentIdleDuration?: string | null;
    /**
     * Current time the DAIEngine is running.
     * @type {string}
     * @memberof V1DAIEngine
     */
    readonly currentRunningDuration?: string | null;
}

export function V1DAIEngineFromJSON(json: any): V1DAIEngine {
    return V1DAIEngineFromJSONTyped(json, false);
}

export function V1DAIEngineFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1DAIEngine {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'creator': !exists(json, 'creator') ? undefined : json['creator'],
        'creatorDisplayName': !exists(json, 'creatorDisplayName') ? undefined : json['creatorDisplayName'],
        'state': !exists(json, 'state') ? undefined : V1DAIEngineStateFromJSON(json['state']),
        'reconciling': !exists(json, 'reconciling') ? undefined : json['reconciling'],
        'createTime': !exists(json, 'createTime') ? undefined : (new Date(json['createTime'])),
        'deleteTime': !exists(json, 'deleteTime') ? undefined : (json['deleteTime'] === null ? null : new Date(json['deleteTime'])),
        'updateTime': !exists(json, 'updateTime') ? undefined : (json['updateTime'] === null ? null : new Date(json['updateTime'])),
        'resumeTime': !exists(json, 'resumeTime') ? undefined : (json['resumeTime'] === null ? null : new Date(json['resumeTime'])),
        'apiUrl': !exists(json, 'apiUrl') ? undefined : json['apiUrl'],
        'loginUrl': !exists(json, 'loginUrl') ? undefined : json['loginUrl'],
        'annotations': !exists(json, 'annotations') ? undefined : json['annotations'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'cpu': !exists(json, 'cpu') ? undefined : json['cpu'],
        'gpu': !exists(json, 'gpu') ? undefined : json['gpu'],
        'memoryBytes': !exists(json, 'memoryBytes') ? undefined : json['memoryBytes'],
        'storageBytes': !exists(json, 'storageBytes') ? undefined : json['storageBytes'],
        'config': !exists(json, 'config') ? undefined : json['config'],
        'maxIdleDuration': !exists(json, 'maxIdleDuration') ? undefined : json['maxIdleDuration'],
        'maxRunningDuration': !exists(json, 'maxRunningDuration') ? undefined : json['maxRunningDuration'],
        'upgradeAvailable': !exists(json, 'upgradeAvailable') ? undefined : json['upgradeAvailable'],
        'currentIdleDuration': !exists(json, 'currentIdleDuration') ? undefined : json['currentIdleDuration'],
        'currentRunningDuration': !exists(json, 'currentRunningDuration') ? undefined : json['currentRunningDuration'],
    };
}

export function V1DAIEngineToJSON(value?: V1DAIEngine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': V1DAIEngineStateToJSON(value.state),
        'annotations': value.annotations,
        'displayName': value.displayName,
        'version': value.version,
        'cpu': value.cpu,
        'gpu': value.gpu,
        'memoryBytes': value.memoryBytes,
        'storageBytes': value.storageBytes,
        'config': value.config,
        'maxIdleDuration': value.maxIdleDuration,
        'maxRunningDuration': value.maxRunningDuration,
    };
}

