import { mergeStyleSets } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  AppBar,
  AppStoreMenuName,
  BookTimeDialog,
  ConfirmDialog,
  FontSizes,
  FontWeights,
  IAppBarStyles,
  Link,
  MenuItem,
  Sizes,
  appBarStylesThick,
  getMenuItems,
  getPersonaProps,
  getUserMenuItems,
  useTheme,
} from '@h2oai/ui-kit';
import logo from '@h2oai/ui-kit/lib/assets/images/logo.svg';
import { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useHistory, useLocation } from 'react-router-dom';

import { AppInstance_Status, AppInstance_Visibility } from '../../ai.h2o.cloud.appstore';
import { RoutePaths } from '../../pages/Routes';
import { useEnv, useInstance } from '../../utils/hooks';
import { HaicAboutModal } from '../HaicAboutModal/HaicAboutModal';

const getMenuItemKey = (pathname: string, menuItems: MenuItem[]): string | undefined =>
  menuItems.reduce((key: string | undefined, item: MenuItem) => (item.to === pathname ? item.key : key), undefined);

export function Navigation() {
  const auth = useAuth();
  const { palette } = useTheme();
  const logout = useCallback(async () => {
    // close modal
    setInstancePromptHidden(true);
    await auth.removeUser();
    await auth.signoutRedirect({ id_token_hint: auth.user?.id_token });
  }, []);
  const env = useEnv(),
    history = useHistory(),
    { pathname } = useLocation(),
    [instancePromptHidden, setInstancePromptHidden] = useState(true),
    [hiddenAbout, { setFalse: showAbout, setTrue: hideAbout }] = useBoolean(true),
    [instanceCount, setInstanceCount] = useState<null | number>(null),
    [menuItems, setMenuItems] = useState<MenuItem[]>(),
    { getInstancesList } = useInstance(),
    goToMyInstances = useCallback(() => {
      setInstancePromptHidden(true);
      history.push('/instances');
    }, [history, setInstancePromptHidden]),
    onLogoutClick = useCallback(async () => {
      let count;
      try {
        const instances = await getInstancesList({
          appId: '',
          includeAppDetails: false,
          allUsers: false,
          visibility: AppInstance_Visibility.VISIBILITY_UNSPECIFIED,
        });
        count = instances.filter((instance) =>
          [
            AppInstance_Status.DEPLOYED,
            AppInstance_Status.PENDING,
            AppInstance_Status.STATUS_UNSPECIFIED,
            AppInstance_Status.STATUS_UNKNOWN,
          ].includes(instance.status)
        ).length;
      } catch (err) {
        console.error(err);
        count = null;
      }
      setInstanceCount(count);
      count !== 0 ? setInstancePromptHidden(false) : await logout();
    }, [instanceCount, logout, getInstancesList]),
    [bookTimeHidden, setBookTimeHidden] = useState<boolean>(true);
  useEffect(() => {
    if (env?.menu) {
      setMenuItems(getMenuItems(env?.menu, { hasMenu: false }));
    }
  }, [env?.menu]);

  return (
    <>
      {!env?.publicModeEnabled && menuItems && (
        <AppBar
          styles={appBarStylesThick}
          logoImageProps={{
            src: logo,
            alt: 'logo',
          }}
          personaProps={getPersonaProps(env?.menu || {})}
          userMenuItems={getUserMenuItems(env?.menu || {}, { hasMenu: false }, onLogoutClick, history, showAbout)}
          onLogoClick={() => history.push('/')}
          selectedMenuItemKey={getMenuItemKey(pathname, menuItems)}
          menuItems={menuItems}
          onMenuItemClick={(_e, item) => {
            if (item.key === AppStoreMenuName.collaborate) {
              setBookTimeHidden(false);
              _e.preventDefault();
              _e.stopPropagation();
            }
          }}
        />
      )}
      {env?.publicModeEnabled && (
        <AppBar
          styles={mergeStyleSets(appBarStylesThick, {
            root: { paddingRight: Sizes.height },
            title: { color: palette?.gray900, fontWeight: FontWeights.fw500 },
          } as IAppBarStyles)}
          logoImageProps={{
            src: logo,
            alt: 'logo',
          }}
          title={'Generative AI'}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Link
              href={RoutePaths.HOME}
              styles={[{ root: { color: palette?.gray900, fontSize: FontSizes.medium, textDecoration: 'none' } }]}
            >
              {'Sign in >'}
            </Link>
          </div>
        </AppBar>
      )}
      <ConfirmDialog
        hidden={instancePromptHidden}
        title="Terminate instances before logout?"
        content={
          <>
            You have <b>{instanceCount ?? 'Unkown'}</b> running app instance{instanceCount === 1 ? '' : 's'} that are
            consuming cluster resources, do you wish to review and terminate them before logging out?
          </>
        }
        confirmationButtonText="No, logout"
        dismissalButtonText="Yes, go to My Instances"
        onDismiss={goToMyInstances}
        onConfirm={logout}
      />
      <HaicAboutModal hidden={hiddenAbout} onDismiss={hideAbout} />
      <BookTimeDialog url={env?.menu?.bookTimeLink} onDismiss={() => setBookTimeHidden(true)} hidden={bookTimeHidden} />
    </>
  );
}
