/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/adjusted_dai_profile_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1AdjustedDAIProfile,
    V1AdjustedDAIProfileFromJSON,
    V1AdjustedDAIProfileFromJSONTyped,
    V1AdjustedDAIProfileToJSON,
} from './V1AdjustedDAIProfile';

/**
 * Response message for Get method.
 * @export
 * @interface V1GetAdjustedDAIProfileResponse
 */
export interface V1GetAdjustedDAIProfileResponse {
    /**
     * 
     * @type {V1AdjustedDAIProfile}
     * @memberof V1GetAdjustedDAIProfileResponse
     */
    adjustedDaiProfile?: V1AdjustedDAIProfile;
}

export function V1GetAdjustedDAIProfileResponseFromJSON(json: any): V1GetAdjustedDAIProfileResponse {
    return V1GetAdjustedDAIProfileResponseFromJSONTyped(json, false);
}

export function V1GetAdjustedDAIProfileResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1GetAdjustedDAIProfileResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adjustedDaiProfile': !exists(json, 'adjustedDaiProfile') ? undefined : V1AdjustedDAIProfileFromJSON(json['adjustedDaiProfile']),
    };
}

export function V1GetAdjustedDAIProfileResponseToJSON(value?: V1GetAdjustedDAIProfileResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adjustedDaiProfile': V1AdjustedDAIProfileToJSON(value.adjustedDaiProfile),
    };
}

