/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/engine_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * All possible Engine states.
 * 
 *  - STATE_UNSPECIFIED: Engine state is unspecified or unknown
 *  - STATE_STARTING: Engine is starting.
 *  - STATE_RUNNING: Engine is running and can be used.
 *  - STATE_PAUSING: Engine is pausing.
 *  - STATE_PAUSED: Engine is paused and can be resumed again.
 *  - STATE_FAILED: Engine has failed and can be resumed again.
 *  - STATE_DELETING: Engine is being deleted.
 *  - STATE_CONNECTING: Engine is establishing a connection with the AI Engine Manager.
 * @export
 * @enum {string}
 */
export enum V1EngineState {
    Unspecified = 'STATE_UNSPECIFIED',
    Starting = 'STATE_STARTING',
    Running = 'STATE_RUNNING',
    Pausing = 'STATE_PAUSING',
    Paused = 'STATE_PAUSED',
    Failed = 'STATE_FAILED',
    Deleting = 'STATE_DELETING',
    Connecting = 'STATE_CONNECTING'
}

export function V1EngineStateFromJSON(json: any): V1EngineState {
    return V1EngineStateFromJSONTyped(json, false);
}

export function V1EngineStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1EngineState {
    return json as V1EngineState;
}

export function V1EngineStateToJSON(value?: V1EngineState | null): any {
    return value as any;
}

