import { MessageBarType, Stack } from '@fluentui/react';
import { MessageBar } from '@h2oai/ui-kit';

import { Eula } from './EULA';
export const EULA_UPDATED_NOTICE =
  'Our Terms & Conditions have changed. Please take a moment to read and agree to our revised Terms & Conditions in order to continue to use the software.';

export const EulaWrapper = (changed?: boolean) => {
  const eula = Eula();
  const eulaWithMessage: JSX.Element = (
    <Stack>
      <MessageBar messageBarType={MessageBarType.info} messageBarContainerStyles={{ root: { marginBottom: '1rem' } }}>
        {EULA_UPDATED_NOTICE}
      </MessageBar>
      {eula}
    </Stack>
  );
  if (changed) {
    return eulaWithMessage;
  } else {
    return eula;
  }
};
