/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/engine_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RpcStatus,
    RpcStatusFromJSON,
    RpcStatusToJSON,
    V1ListEnginesResponse,
    V1ListEnginesResponseFromJSON,
    V1ListEnginesResponseToJSON,
} from '../models';

export interface EngineServiceListEnginesRequest {
    parent: string;
    pageSize?: number;
    pageToken?: string;
    orderBy?: string;
    filter?: string;
}

/**
 * 
 */
export class EngineServiceApi extends runtime.BaseAPI {

    /**
     * Returns a collection of Engines within a workspace.
     */
    async engineServiceListEnginesRaw(requestParameters: EngineServiceListEnginesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1ListEnginesResponse>> {
        if (requestParameters.parent === null || requestParameters.parent === undefined) {
            throw new runtime.RequiredError('parent','Required parameter requestParameters.parent was null or undefined when calling engineServiceListEngines.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['pageToken'] = requestParameters.pageToken;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/{parent}/engines`.replace(`{${"parent"}}`, encodeURIComponent(String(requestParameters.parent))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1ListEnginesResponseFromJSON(jsonValue));
    }

    /**
     * Returns a collection of Engines within a workspace.
     */
    async engineServiceListEngines(requestParameters: EngineServiceListEnginesRequest, initOverrides?: RequestInit): Promise<V1ListEnginesResponse> {
        const response = await this.engineServiceListEnginesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
