/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/h2o_version_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RpcStatus,
    RpcStatusFromJSON,
    RpcStatusToJSON,
    V1ListH2OVersionsResponse,
    V1ListH2OVersionsResponseFromJSON,
    V1ListH2OVersionsResponseToJSON,
} from '../models';

export interface H2OVersionServiceListH2OVersionsRequest {
    pageSize?: number;
    pageToken?: string;
    orderBy?: string;
    filter?: string;
}

/**
 * 
 */
export class H2OVersionServiceApi extends runtime.BaseAPI {

    /**
     * Returns a collection of H2OEngines within a workspace.
     */
    async h2OVersionServiceListH2OVersionsRaw(requestParameters: H2OVersionServiceListH2OVersionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<V1ListH2OVersionsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['pageToken'] = requestParameters.pageToken;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/h2oVersions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1ListH2OVersionsResponseFromJSON(jsonValue));
    }

    /**
     * Returns a collection of H2OEngines within a workspace.
     */
    async h2OVersionServiceListH2OVersions(requestParameters: H2OVersionServiceListH2OVersionsRequest, initOverrides?: RequestInit): Promise<V1ListH2OVersionsResponse> {
        const response = await this.h2OVersionServiceListH2OVersionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
