/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/h2o_engine_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProtobufAny,
    ProtobufAnyFromJSON,
    ProtobufAnyFromJSONTyped,
    ProtobufAnyToJSON,
} from './ProtobufAny';

/**
 * Message that represents an arbitrary HTTP body. It should only be used for
 * payload formats that can't be represented as JSON, such as raw binary or
 * an HTML page.
 * 
 * 
 * This message can be used both in streaming and non-streaming API methods in
 * the request as well as the response.
 * 
 * It can be used as a top-level request field, which is convenient if one
 * wants to extract parameters from either the URL or HTTP template into the
 * request fields and also want access to the raw HTTP body.
 * 
 * Example:
 * 
 *     message GetResourceRequest {
 *       // A unique request id.
 *       string request_id = 1;
 * 
 *       // The raw HTTP body is bound to this field.
 *       google.api.HttpBody http_body = 2;
 * 
 *     }
 * 
 *     service ResourceService {
 *       rpc GetResource(GetResourceRequest)
 *         returns (google.api.HttpBody);
 *       rpc UpdateResource(google.api.HttpBody)
 *         returns (google.protobuf.Empty);
 * 
 *     }
 * 
 * Example with streaming methods:
 * 
 *     service CaldavService {
 *       rpc GetCalendar(stream google.api.HttpBody)
 *         returns (stream google.api.HttpBody);
 *       rpc UpdateCalendar(stream google.api.HttpBody)
 *         returns (stream google.api.HttpBody);
 * 
 *     }
 * 
 * Use of this type only changes how the request and response bodies are
 * handled, all other features will continue to work unchanged.
 * @export
 * @interface ApiHttpBody
 */
export interface ApiHttpBody {
    /**
     * The HTTP Content-Type header value specifying the content type of the body.
     * @type {string}
     * @memberof ApiHttpBody
     */
    contentType?: string;
    /**
     * The HTTP request/response body as raw binary.
     * @type {string}
     * @memberof ApiHttpBody
     */
    data?: string;
    /**
     * Application specific response metadata. Must be set in the first response
     * for streaming APIs.
     * @type {Array<ProtobufAny>}
     * @memberof ApiHttpBody
     */
    extensions?: Array<ProtobufAny>;
}

export function ApiHttpBodyFromJSON(json: any): ApiHttpBody {
    return ApiHttpBodyFromJSONTyped(json, false);
}

export function ApiHttpBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiHttpBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'extensions': !exists(json, 'extensions') ? undefined : ((json['extensions'] as Array<any>).map(ProtobufAnyFromJSON)),
    };
}

export function ApiHttpBodyToJSON(value?: ApiHttpBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contentType': value.contentType,
        'data': value.data,
        'extensions': value.extensions === undefined ? undefined : ((value.extensions as Array<any>).map(ProtobufAnyToJSON)),
    };
}

