import {
  DAIEngineServiceUpgradeVersionRequest,
  EngineServiceListEnginesRequest,
  V1ConstraintNumeric,
  V1EngineType,
} from './gen';

export const defaultBasePath = 'http://localhost:9000';

export const defaultEngineType = V1EngineType.DriverlessAi;

export const defaultLatest = 'latest';

export const defaultOIDCSuffix = `openid/login`;

export const defaultDAIEngineDisplayName = 'New DAI Engine';

export const defaultEngineId = 'aiem01';

export const defaultWorkspaceID = 'default';

export const defaultWorkspaceName = `workspaces/${defaultWorkspaceID}`;

export const defaultEngineUserId = `ae2f99fc-55b5-49a1-9300-e5c895cd8873`;

export const defaultVersion = '1.10.6';

export const defaultConstraintSet: V1ConstraintNumeric = {
  min: '0',
  max: '0',
  _default: '0',
};

export const defaultListRequest: EngineServiceListEnginesRequest | object = {
  pageSize: 1000, // if page size is greater than 1000 it will be coerced back to 1000, pagination must be implemented in a later issue because DetailsList pagination is yet to be implemented.
  orderBy: '',
  pageToken: '', // Leave unset to receive the initial page. To list any subsequent pages use the value of 'next_page_token'\nreturned from the Response
  filter: '',
};

export const defaultEnginesListRequest: EngineServiceListEnginesRequest = {
  parent: defaultWorkspaceName, // `workspaces/default`
  ...defaultListRequest,
};

export const defaultUpgradeVersionRequest: DAIEngineServiceUpgradeVersionRequest = {
  daiEngine: '',
  body: {
    newVersion: 'latest',
  },
};
