import { IRawStyle, mergeStyles } from '@fluentui/react';
import { defaultSemanticColors } from '@h2oai/ui-kit';

// taken from https://gist.github.com/MoOx/9137295
export const styleResetButton = mergeStyles({
  background: `transparent`,
  border: `none`,
  /* inherit font & color from ancestor */
  color: `inherit`,
  font: `inherit`,
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  lineHeight: `normal`,
  margin: 0,
  overflow: `visible`,
  padding: 0,
  width: `auto`,
  /* Corrects font smoothing for webkit */
  '-webkit-font-smoothing': `inherit`,
  '-moz-osx-font-smoothing': `inherit`,
  /* Corrects inability to style clickable `input` types in iOS */
  '-webkit-appearance': `none`,
  /* Remove excess padding and border in Firefox 4+ */
  '::-moz-focus-inner': {
    border: 0,
    padding: 0,
  },
  ':focus-visible': {
    outline: `none`,
  },
});

export const styleResetLink = mergeStyles({
  textDecoration: `none`,
  color: `inherit`,
});

export const styleBreakout = ({
  focusStyles = { border: `1px solid ${defaultSemanticColors.inputActiveBorder}` },
  focusIndicatedForKeyboardOnly,
  borderRadius = 0,
  hoverStyles = { boxShadow: `0px 0px 20px #9CA3AF80` },
}: {
  borderRadius?: number | string;
  focusStyles?: IRawStyle;
  focusIndicatedForKeyboardOnly?: boolean;
  hoverStyles?: IRawStyle;
} = {}): IRawStyle => ({
  position: `static`,
  '::after': {
    borderRadius,
    content: `''`,
    height: `100%`,
    left: 0,
    position: `absolute`,
    top: 0,
    width: `100%`,
  },
  [focusIndicatedForKeyboardOnly ? ':focus-visible' : `:focus`]: {
    '::after': focusStyles,
  },
  ':hover': {
    '::after': hoverStyles,
  },
});
