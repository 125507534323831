import { IStyle } from '@fluentui/react';
import { IH2OTheme } from '@h2oai/ui-kit';

export interface ICodeAreaStyles {
  root: IStyle;
  lineNo: IStyle;
  codeEditor: IStyle;
}

export const codeAreaStylesDefault = (theme: IH2OTheme): ICodeAreaStyles => {
  return {
    root: {
      display: 'inline-flex',
      flexFlow: 'row-reverse nowrap',
      fontFamily: 'lucida console, courier new, courier, monospace',
      overflow: `hidden`,
      '& > textarea': {
        outline: 'none !important', // prevent user-agent focus indication
        paddingBlock: `1.5rem`,
      },
    },
    lineNo: {
      backgroundColor: theme.palette?.gray200,
      border: `1px solid ${theme.palette?.gray300}`,
      borderInlineEndColor: `${theme.palette?.gray300} !important`,
      borderRadius: 4,
      borderEndEndRadius: 0,
      borderStartEndRadius: 0,
      color: theme.palette?.gray600,
      flex: '0 0 auto',
      margin: 0,
      overflow: 'hidden',
      paddingInline: '1rem',
      resize: 'none',
      textAlign: 'end',
    },
    codeEditor: {
      backgroundColor: theme.palette?.white,
      border: `1px solid ${theme.palette?.gray300}`,
      borderRadius: 4,
      borderInlineStartWidth: 0,
      borderStartStartRadius: 0,
      borderEndStartRadius: 0,
      color: theme.palette?.gray800,
      flex: '1 0 auto',
      overflow: 'auto',
      paddingInline: `0.5rem`,
      '&:focus, &:focus ~ *': {
        borderColor: theme.palette?.blue300,
      },
      '&:invalid, &:invalid ~ *, &[aria-invalid="true"], &[aria-invalid="true"] ~ *': {
        borderColor: theme.semanticColors?.inputErrorBorder,
      },
    },
  };
};

export const codeAreaStylesFullWidth = {
  root: { display: `flex` },
};
