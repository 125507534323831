import { useCallback } from 'react';

import { AppInstance } from '../../ai.h2o.cloud.appstore';
import { InstanceList } from '../../components/InstanceList/InstanceList';
import { RoutePaths } from '../../pages/Routes';
import { useRefineData } from '../../utils/hooks';
import { InstanceListType } from '../../utils/models';
import { matchesSearchString } from '../../utils/utils';
import ListPage from '../ListPages/ListPage';
import { InstanceListProps } from './InstanceList';

interface SearchableInstanceListPageProps extends InstanceListProps {
  title: string;
  subtitle: string;
}

function SearchableInstanceList(props: SearchableInstanceListPageProps) {
  const { title, subtitle, instances, type, ...rest } = props,
    isAdmin = type === InstanceListType.admin,
    {
      data: refinedInstances,
      searchKey,
      setSearchKey,
    } = useRefineData({
      data: instances,
      onSearch: useCallback((searchKey, data: AppInstance[]) => {
        if (searchKey === '') return data;
        return data.filter((instance) =>
          matchesSearchString(
            [
              instance?.appDetails?.name,
              instance?.appDetails?.title,
              instance?.appDetails?.id,
              instance?.appDetails?.description,
              instance?.appDetails?.longDescription,
              instance?.appDetails?.version,
              instance?.id,
            ],
            searchKey
          )
        );
      }, []),
    }),
    onChangeSearchInput = useCallback((_, newValue) => setSearchKey(newValue || ''), [setSearchKey]);

  return (
    <ListPage
      title={title}
      subtitle={subtitle}
      showData
      primaryButtonProps={
        isAdmin
          ? {
              text: 'Manage Aliases',
              href: RoutePaths.ADMIN_ALIASES,
            }
          : undefined
      }
      searchBoxProps={{
        value: searchKey,
        onChange: onChangeSearchInput,
        placeholder: 'Search by app name, title, ID, version, description',
      }}
      searchResultsText={
        searchKey ? `${refinedInstances.length} result${refinedInstances.length === 1 ? '' : 's'} found` : undefined
      }
    >
      <InstanceList instances={refinedInstances} type={type} {...rest} />
    </ListPage>
  );
}

export default SearchableInstanceList;
