import { IconName, Item, itemStylesCategory } from '@h2oai/ui-kit';

import { AppInstance_Visibility, App_Visibility } from '../../ai.h2o.cloud.appstore';
import { instanceVisibilityMap } from '../../utils/utils';

const visibilityIcons = {
  [AppInstance_Visibility.ALL_USERS]: IconName.Group,
  [AppInstance_Visibility.PRIVATE]: IconName.Lock,
};

export interface IPillVisibilityProps {
  visibility: App_Visibility | AppInstance_Visibility;
  withIcon?: boolean;
}

export function PillVisibility({ visibility, withIcon }: IPillVisibilityProps) {
  return (
    <Item
      data={{
        text: instanceVisibilityMap[visibility],
        icon: visibilityIcons[visibility] || IconName.Unknown,
        iconStyle: { color: `inherit` },
      }}
      iconNameField={withIcon ? `icon` : undefined}
      iconStyleField="iconStyle"
      labelField="text"
      styles={itemStylesCategory}
    />
  );
}
