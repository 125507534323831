import { BasicCard, CardList, ICardListProps, Sizes, useTheme } from '@h2oai/ui-kit';
import { useCallback, useState } from 'react';

import { V1EngineType, ValidEngineType } from '../../../../aiem';
import { useEngine } from '../../../../aiem/hooks';

interface EngineTypeCard {
  id: string;
  title: string;
  description: string;
  imagePath: string;
  engineType: V1EngineType;
}

export interface IEngineTypeSelectionProps {
  onEngineSelection: (engineType: V1EngineType | undefined) => void;
  engineType?: ValidEngineType;
}

export function EngineSelection({ onEngineSelection, engineType }: IEngineTypeSelectionProps) {
  const { engineTypeLogo } = useEngine(),
    { palette } = useTheme();
  const cards: EngineTypeCard[] = [
    {
      id: V1EngineType.DriverlessAi as string,
      title: 'Driverless AI',
      description:
        'Delivers industry leading autoML capabilities specifically designed to use AI to make AI, with automation ' +
        'encompassing data science best practices across key functional areas like data visualization, feature engineering, ' +
        'model development and validation, model documentation, machine learning interpretability and more.',
      imagePath: engineTypeLogo[V1EngineType.DriverlessAi].src,
      engineType: V1EngineType.DriverlessAi,
    },
    {
      id: V1EngineType.H2O as string,
      title: 'H2O',
      description:
        'H2O-3 is an open source, in-memory, distributed, fast, and scalable machine learning and predictive analytics ' +
        'platform that allows you to build machine learning models on big data and provides easy productionalization of ' +
        'those models in an enterprise environment.',
      imagePath: engineTypeLogo[V1EngineType.H2O].src,
      engineType: V1EngineType.H2O,
    },
  ];
  const [selectedId, setSelectedId] = useState<string>(String(engineType));
  const selectEngine = useCallback((card: EngineTypeCard) => {
    setSelectedId(card.id);
    onEngineSelection(card.engineType);
  }, []);
  const listConfig: ICardListProps<EngineTypeCard> = {
    columnCount: 1,
    idField: 'id',
    nameField: 'title',
    data: cards,
    gap: Sizes.padding,
    itemRenderer: (item: EngineTypeCard) => (
      <BasicCard<EngineTypeCard>
        data={item}
        data-test={`basic-card-${item.id}`}
        idField="id"
        titleField="title"
        imagePathField="imagePath"
        descriptionField="description"
        selected={item.id === selectedId}
        onClick={selectEngine}
        height={'auto'}
        styles={{
          thumbnail: { backgroundColor: engineTypeLogo[item.id].backgroundColor || 'none', backgroundSize: 'contain' },
          root: { backgroundColor: palette?.white, '&:hover': { backgroundColor: palette?.gray200 } },
        }}
      />
    ),
  };
  return <CardList {...listConfig} />;
}
