import { MessageBarType } from '@fluentui/react';
import { useToast } from '@h2oai/ui-kit';
import { useCallback } from 'react';

import { useCloudPlatformDiscovery } from '../utils/hooks';
import { handleErrMsg } from '../utils/utils';
import { defaultMaxLogEntriesCount } from './defaults';
import { DownloadLogRequest, DownloadLogService } from './DownloadLogService';
import {
  LogEntryService,
  ReadLogEntriesRequest,
  ReadLogEntriesResponse,
} from './gen/ai/h2o/logging/v1/entry_service.pb';
import { FetchLogNamesRequest, InternalService } from './gen/ai/h2o/logging/v1/internal_service.pb';

export function useLogs() {
  const { addToast } = useToast(),
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    basePath = cloudPlatformDiscovery?.loggingServicesApiUrl || '',
    addErrorToast = useCallback(
      (error: Error, baseError = 'Logging Services Error'): void => {
        addToast({
          messageBarType: MessageBarType.error,
          message: `${baseError}: ${handleErrMsg(error.message)}`,
        });
      },
      [addToast]
    );

  const fetchLogNames = async (request: FetchLogNamesRequest): Promise<string[] | undefined> => {
    try {
      const { logNames } = (await InternalService.FetchLogNames(request, { pathPrefix: basePath })) || {};
      return logNames;
    } catch (error: unknown) {
      addErrorToast(error as Error);
      return undefined;
    }
  };

  const readLogs = async (request: ReadLogEntriesRequest): Promise<ReadLogEntriesResponse | undefined> => {
    try {
      return await LogEntryService.ReadLogEntries(request, { pathPrefix: basePath });
    } catch (error: unknown) {
      addErrorToast(error as Error);
      return undefined;
    }
  };

  const downloadLogs = async (request: DownloadLogRequest) => {
    try {
      const response = await DownloadLogService.DownloadLog(
        { ...request, maxLogEntriesCount: request.maxLogEntriesCount || defaultMaxLogEntriesCount },
        basePath
      );
      return await response.blob();
    } catch (error: unknown) {
      addErrorToast(error as Error);
      return undefined;
    }
  };

  return { basePath, downloadLogs, fetchLogNames, readLogs };
}
