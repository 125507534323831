import { IStyle } from '@fluentui/react';
import { FontSizes, IH2OTheme, LineHeights, Sizes } from '@h2oai/ui-kit';

export interface IDisplayAndIdRuleFieldClassNames {
  root: string;
  icon: string;
  labelValid: string;
  labelInvalid: string;
}

export interface IDisplayAndIdRuleField {
  root?: IStyle;
  icon?: IStyle;
  labelValid?: IStyle;
  labelInvalid?: IStyle;
}

export const displayAndIdRuleFieldStyle = (theme: IH2OTheme): Partial<IDisplayAndIdRuleField> => ({
  root: {
    fontSize: FontSizes.xxsmall,
  },
  icon: {
    marginRight: Sizes.buttonPaddingLeftRightSmall / 2,
    lineHeight: LineHeights.xxsmall + 4,
    height: LineHeights.xxsmall,
    overflow: 'hidden',
  },
  labelValid: {
    color: theme.semanticColors?.inputSuccessMessageText,
    lineHeight: LineHeights.xxsmall,
    height: LineHeights.xxsmall,
    display: 'inline-block',
  },
  labelInvalid: {
    color: theme.semanticColors?.inputErrorMessageText,
    lineHeight: LineHeights.xxsmall,
    height: LineHeights.xxsmall,
    display: 'inline-block',
  },
});
