import { Callout, DirectionalHint, Stack, Target } from '@fluentui/react';
import { BasicList, Button, CommonStyles, IH2OTheme, Item, buttonStylesLink, itemStylesCategory } from '@h2oai/ui-kit';
import { useMemo, useState } from 'react';

import { TagAssignment } from '../../ai.h2o.cloud.appstore';

const MAX_TAG_LABEL_WIDTH = 300;
const CHAR_WIDTH_MAX = 8;
const EST_TAG_PADDING = 4;
const EST_TAG_MARGIN = 4;
const RESERVED_WIDTH = 20;

export default function TagCell(props: {
  tags: TagAssignment[];
  currentWidth: number;
  theme: IH2OTheme;
  isAdmin: boolean;
}) {
  const { tags, currentWidth, theme, isAdmin } = props,
    { categoryText: categoryTextColor } = theme.semanticColors!,
    // use categoryText color to match color of tag Items
    buttonElementStyle = { color: categoryTextColor },
    [calloutTarget, setCalloutTarget] = useState<Target | null>(null),
    { displayTags, overflowTags } = useMemo(() => {
      const filteredTags = tags.filter((tag) => isAdmin || !tag.hidden);
      const overflowTags = [...filteredTags];
      const displayTags = [];
      let remainingWidth = currentWidth;
      for (const tag of filteredTags) {
        const expectedMaxDivWidth = EST_TAG_MARGIN + EST_TAG_PADDING * 2 + CHAR_WIDTH_MAX * tag.title.length;
        remainingWidth -= expectedMaxDivWidth;
        if (remainingWidth < RESERVED_WIDTH) break;
        displayTags.push(overflowTags.shift() as TagAssignment);
      }
      return { displayTags, overflowTags };
    }, [tags, currentWidth, isAdmin]);

  return (
    <Stack horizontal verticalAlign="center">
      {displayTags.map((tag) => (
        <Item
          key={tag.id}
          styles={[
            itemStylesCategory,
            {
              root: { maxWidth: MAX_TAG_LABEL_WIDTH },
              labelButton: { overflow: 'hidden' },
              label: { overflow: 'hidden' },
              labelText: { ...CommonStyles.truncateString, maxWidth: MAX_TAG_LABEL_WIDTH },
            },
          ]}
          idField="id"
          labelField="title"
          titleField="description"
          data={tag}
          data-test="tag-badge"
        />
      ))}
      {overflowTags.length ? (
        <>
          <Button
            text={`+${overflowTags.length}`}
            onClick={(e) => setCalloutTarget(e as Target)}
            data-test="display-tag-tooltip"
            styles={[
              buttonStylesLink,
              {
                root: buttonElementStyle,
                rootHovered: buttonElementStyle,
                rootFocused: buttonElementStyle,
                rootPressed: buttonElementStyle,
              },
            ]}
          />
          {calloutTarget && (
            <Callout
              directionalHint={DirectionalHint.rightCenter}
              role="dialog"
              setInitialFocus
              target={calloutTarget}
              gapSpace={12}
              onDismiss={() => setCalloutTarget(null)}
              styles={{
                root: {
                  overflow: 'visible',
                },
              }}
            >
              <BasicList
                itemRenderer={(item, _, isLast) => (
                  <div style={{ marginBottom: isLast ? undefined : 8 }}>{item.title}</div>
                )}
                styles={{ root: { padding: '0 16px', minWidth: 180 } }}
                data={overflowTags}
                idField="id"
              />
            </Callout>
          )}
        </>
      ) : null}
    </Stack>
  );
}
