import { IDropdownOption, Stack, Text } from '@fluentui/react';
import {
  Button,
  FontSizes,
  IconName,
  Loader,
  Pivot,
  buttonStylesDefaultWidth,
  buttonStylesLink,
  buttonStylesLinkBlack,
  buttonStylesPrimary,
  loaderStylesSpinnerXLarge,
  sort,
  useHaicPageTitle,
  useTheme,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AppPreconditionStatus, App_Visibility, FederatedApp } from '../../../ai.h2o.cloud.appstore';
import { AppDetailHeader } from '../../../components/AppDetailHeader/AppDetailHeader';
import { AppPivot } from '../../../components/AppPivot/AppPivot';
import { appPivotStylesEqualColumns } from '../../../components/AppPivot/AppPivot.styles';
import { Err } from '../../../components/Err/Err';
import { Markdown } from '../../../components/Markdown/Markdown';
import { AdminFederatedAppService, AppService } from '../../../services/api';
import { stackStylesPage } from '../../../themes/themes';
import { useError } from '../../../utils/hooks';
import { handleErrMsg } from '../../../utils/utils';
import ErrorPage from '../../ErrorPage';
import { RoutePaths } from '../../Routes';
import { AppConfigurationSaveOnChange } from '../AppConfiguration/AppConfiguration';
import { Preconditions } from './Preconditions/Preconditions';

const appsToVersions = (
  apps: Array<{
    id: string;
    version: string;
    visibility: App_Visibility;
  }>
): IDropdownOption<{ icon: IconName }>[] =>
  apps
    .map(({ id, version, visibility }) => ({
      key: id,
      text: visibility === App_Visibility.PRIVATE ? `${version}  (private)` : version,
      data: { icon: IconName.Accounts },
    }))
    .sort(sort<IDropdownOption>(['text'], 'semver'));

function AdminMarketplaceAppDetailContent({ app, refetchApp }: { app: FederatedApp; refetchApp: () => Promise<any> }) {
  const history = useHistory(),
    theme = useTheme(),
    [err, setErr, onDismissErr] = useError(),
    [versions, setVersions] = useState<IDropdownOption[]>([]);
  useEffect(() => {
    const loadVersions = async () => {
      try {
        const appsResponse = await AppService.listApps({
          limit: 1000,
          offset: 0,
          visibility: App_Visibility.VISIBILITY_UNSPECIFIED,
          allUsers: true,
          name: app.name,
          latestVersions: false,
          withPreference: false,
          tags: [],
          conditionsStatus: AppPreconditionStatus.STATUS_UNSPECIFIED,
          visibilities: [],
        });
        setVersions(appsToVersions(appsResponse.apps));
        // use type "any" to allow optional chaining until TypeScript catch up with the ECMAScript
        // see https://github.com/microsoft/TypeScript/issues/37700
      } catch (error: any) {
        setErr({ message: `An error occurred while loading app versions: ${handleErrMsg(error?.message)}` });
      }
    };
    loadVersions();
  }, [app.name]);
  useHaicPageTitle(app.name);
  return (
    <Stack styles={stackStylesPage}>
      {err?.message && <Err err={err.message} onDismissErr={onDismissErr} />}
      <Button
        styles={[buttonStylesLink, buttonStylesLinkBlack]}
        text="Back"
        iconName={IconName.Back}
        onClick={() => history.push(RoutePaths.ADMIN_MARKETPLACE_APPS)}
      />
      <Stack tokens={{ childrenGap: `1.5rem` }}>
        <AppDetailHeader
          actionButton={
            <Button iconName={IconName.Play} text="Run App" styles={[buttonStylesPrimary, buttonStylesDefaultWidth]} />
          }
          app={app}
          footer={
            <Text
              styles={{
                root: {
                  color: theme.palette?.gray500,
                  fontSize: FontSizes.textPrimary,
                  paddingTop: 8,
                },
              }}
            >
              {app.owner}
            </Text>
          }
          description={app.description}
          iconLocation={app.iconLocation}
          tags={app.tags}
          title={app.title}
          versions={versions}
        />
        <Pivot
          items={[
            {
              headerText: 'App Details',
              content: <AppPivot mainContent={<Markdown source={app.longDescription} />} />,
            },
            {
              headerText: `Installation guide`,
              content: (
                <AppPivot
                  mainContent={<Markdown source={app.setupGuide} />}
                  rightContent={<Preconditions id={app.id} />}
                  styles={appPivotStylesEqualColumns}
                />
              ),
            },
            {
              headerText: `App configuration`,
              content: (
                <AppPivot
                  mainContent={
                    <Stack tokens={{ childrenGap: `1.5rem` }}>
                      <Text styles={{ root: { color: theme.palette?.gray600 } }}>
                        You can set the app visibility and edit app category here.
                      </Text>
                      <AppConfigurationSaveOnChange app={app} refetchApp={refetchApp} />
                    </Stack>
                  }
                />
              ),
            },
          ]}
        />
      </Stack>
    </Stack>
  );
}

export interface IAdminMarketplaceAppDetailPageProps {
  id: string;
}

export function AdminMarketplaceAppDetailPage({ id }: IAdminMarketplaceAppDetailPageProps) {
  const [federatedApp, setFederatedApp] = useState<FederatedApp>(),
    [err, setErr] = useError(),
    loadFederatedApp = useCallback(async () => {
      try {
        const federatedAppResponse = await AdminFederatedAppService.getFederatedApp({ id });
        setFederatedApp(federatedAppResponse.app);
      } catch (error) {
        setErr({ ...(error instanceof Error && error) });
      }
    }, [id, setErr]);
  useEffect(() => {
    loadFederatedApp();
  }, [id]);

  if (err) {
    return <ErrorPage code={err.code} message={err.message} />;
  }
  if (federatedApp) {
    return <AdminMarketplaceAppDetailContent app={federatedApp} refetchApp={loadFederatedApp} />;
  }
  return <Loader styles={loaderStylesSpinnerXLarge} label="Loading App" />;
}
