import {
  DirectionalHint,
  IIconStyles,
  IRenderFunction,
  ITooltipHostProps,
  ITooltipHostStyles,
  ITooltipProps,
  Icon,
  TooltipHost,
} from '@fluentui/react';
import { FontSizes, FontWeights, IconName, LineHeights, useTheme } from '@h2oai/ui-kit';
import { useCallback } from 'react';

interface AdditionalInfoIconProps extends ITooltipHostProps {
  directionalHint?: DirectionalHint;
  onRenderContent: IRenderFunction<ITooltipProps>;
}

export default function AdditionalInfoIcon(props: AdditionalInfoIconProps) {
  const { directionalHint = DirectionalHint.rightCenter, onRenderContent, ...rest } = props,
    theme = useTheme(),
    iconStyles: IIconStyles = {
      root: {
        lineHeight: LineHeights.xlarge,
        color: theme.semanticColors?.textQuaternary,
        fontWeight: FontWeights.normal,
        fontSize: FontSizes.small,
        cursor: 'pointer',
      },
    },
    tooltipHostStyles: ITooltipHostStyles = {
      root: { display: 'inline' },
    },
    tooltipProps: ITooltipProps = {
      directionalHint,
      styles: {
        content: { backgroundColor: theme.semanticColors?.tooltipBackground },
      },
      onRenderContent: useCallback(
        () => <div style={{ padding: '0 12px' }}>{onRenderContent()}</div>,
        [onRenderContent]
      ),
    };

  return (
    <TooltipHost
      {...rest}
      tooltipProps={tooltipProps}
      styles={tooltipHostStyles}
      calloutProps={{
        styles: {
          beakCurtain: { backgroundColor: theme.semanticColors?.tooltipBackground },
          beak: { backgroundColor: theme.semanticColors?.tooltipBackground },
        },
      }}
    >
      <Icon data-test="additional-info-icon" styles={iconStyles} iconName={IconName.Info}></Icon>
    </TooltipHost>
  );
}
