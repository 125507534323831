import {
  CheckboxVisibility,
  ColumnActionsMode,
  IColumn,
  IDetailsRowProps,
  ITextStyles,
  Stack,
  Text,
} from '@fluentui/react';
import {
  FontSizes,
  IButtonProps,
  Loader,
  TextWithCopy,
  getDate,
  loaderStylesSpinnerXLarge,
  sortList,
  textWithCopyStylesPlain,
  useTheme,
} from '@h2oai/ui-kit';
import { MouseEvent, useCallback, useEffect, useState } from 'react';

import {
  Alias,
  AppInstance,
  AppInstance_Status,
  AppInstance_Visibility,
  UpdateAppInstanceRequest,
} from '../../ai.h2o.cloud.appstore';
import { useInstance, useUser } from '../../utils/hooks';
import { InstanceListType } from '../../utils/models';
import { InstancePauseResumeOpEnum, getInstanceAppTitleVersion, specifiedVisibilityOptions } from '../../utils/utils';
import { instanceStatusMap, instanceVisibilityIconMap, instanceVisibilityMap } from '../../utils/utils';
import { AutoPauseLabel } from '../AutoPauseLabel/AutoPauseLabel';
import CreateUpdateDialog, {
  DropdownFieldType,
  FieldType,
  dialogContentStylesInstanceVisibility,
  dialogStylesInstanceVisibility,
} from '../CreateUpdateDialog/CreateUpdateDialog';
import EmptyStateMessage from '../EmptyStateMessage/EmptyStateMessage';
import { InstanceActionButton, willAutoSuspend } from '../InstanceActionButton/InstanceActionButton';
import { ActionCell } from '../ListPages/ActionCell';
import { BadgeCell } from '../ListPages/BadgeCell';
import { IconCell } from '../ListPages/IconCell';
import { MetadataCell } from '../ListPages/MetadataCell';
import StyledDetailsList from '../ListPages/StyledDetailsList';
import StyledDetailsRow from '../ListPages/StyledDetailsRow';
import { TitleCell } from '../ListPages/TitleCell';

const iconSize = 64;
const { STATUS_UNKNOWN, PENDING, DEPLOYED, FAILED, SUSPENDED } = AppInstance_Status;

const columns: { [key: string]: IColumn } = {
  icon: {
    key: 'icon',
    name: '',
    className: 'instance-icon',
    fieldName: 'iconLocation',
    minWidth: iconSize,
    maxWidth: iconSize,
    columnActionsMode: ColumnActionsMode.disabled,
  },
  title: {
    key: 'title-id-state',
    name: '',
    fieldName: 'title',
    minWidth: 200,
    maxWidth: 800,
  },
  owner: {
    key: 'owner',
    name: 'Owner',
    fieldName: 'owner',
    minWidth: 160,
    maxWidth: 160,
  },
  details: {
    key: 'details',
    name: 'Details',
    fieldName: 'details',
    minWidth: 240,
    maxWidth: 240,
  },
  status: {
    key: 'status',
    name: 'Status',
    fieldName: 'status',
    minWidth: 88,
    maxWidth: 88,
  },
  visibility: {
    key: 'visibility',
    name: 'Visibility',
    fieldName: 'visibility',
    minWidth: 112,
    maxWidth: 112,
  },
  actions: {
    key: 'actions',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 260,
    maxWidth: 260,
    columnActionsMode: ColumnActionsMode.disabled,
  },
};

const columnsByTypeMap = new Map<InstanceListType, IColumn[]>();
columnsByTypeMap.set(InstanceListType.app, [
  columns.icon,
  { ...columns.title, minWidth: 140, maxWidth: 800 },
  columns.owner,
  { ...columns.details, minWidth: 160, maxWidth: 160 },
  columns.status,
  columns.visibility,
  columns.actions,
]);
columnsByTypeMap.set(InstanceListType.admin, [
  columns.icon,
  columns.title,
  columns.owner,
  columns.details,
  columns.status,
  columns.visibility,
  columns.actions,
]);
columnsByTypeMap.set(InstanceListType.my, [
  columns.icon,
  columns.title,
  columns.details,
  columns.status,
  columns.visibility,
  columns.actions,
]);

const getInstanceIdAliasMap = (aliases: Alias[] = []): Map<string, string> => {
  const instanceIdAliasMap = new Map<string, string>();
  aliases.forEach(({ instanceId, alias, primary }: Alias) => {
    if (primary) {
      instanceIdAliasMap.set(instanceId, alias);
    }
  });
  return instanceIdAliasMap;
};
const visibilityDialogFields: DropdownFieldType<UpdateAppInstanceRequest>[] = [
  {
    prop: 'visibility',
    type: FieldType.DROPDOWN,
    label: 'Visible to',
    required: true,
    dataTest: 'instance-visibility-dropdown',
    options: specifiedVisibilityOptions,
  },
];

export type InstanceListProps = {
  type: InstanceListType;
  hideInstanceLogButton?: boolean;
  aliases?: Alias[];
  instances?: AppInstance[];
  loadingMsg: string;
  loading?: [string, boolean];
  terminating?: [string, boolean];
  terminateInstance: (instance: AppInstance) => () => void;
  updateVisibility?: (req: UpdateAppInstanceRequest) => () => void;
  setInstanceSuspension?: (instance: AppInstance, op: InstancePauseResumeOpEnum) => () => void;
};

export function InstanceList({
  aliases,
  instances,
  terminateInstance,
  type,
  loading,
  loadingMsg,
  terminating,
  hideInstanceLogButton,
  updateVisibility,
  setInstanceSuspension,
}: InstanceListProps) {
  const columns = columnsByTypeMap.get(type) || [];
  const instanceIdAliasMap = getInstanceIdAliasMap(aliases);
  const theme = useTheme(),
    { name: currentUserName } = useUser(),
    [sortedColumns, setSortedColumns] = useState<IColumn[]>(columns),
    [updateInstanceReq, setUpdateInstanceReq] = useState<UpdateAppInstanceRequest | null>(null),
    [instanceList, setInstanceList] = useState<AppInstance[]>(instances || []),
    [loadingMap, setLoadingMap] = useState<{ [key: string]: boolean }>({}),
    [terminatingMap, setTerminatingMap] = useState<{ [key: string]: boolean }>({}),
    { goToInstance, goToInstanceLog } = useInstance(),
    labelStyles: ITextStyles = {
      root: {
        color: theme.semanticColors?.textQuaternary,
        fontSize: FontSizes.small,
        fontWeight: 600,
      },
    },
    contentStyles: ITextStyles = { root: { color: theme.semanticColors?.textQuinary, fontSize: FontSizes.small } },
    toggleVisibilityDialog = useCallback(
      (instance: AppInstance | null) => () => {
        setUpdateInstanceReq(
          instance
            ? {
                id: instance.id,
                visibility: instance.visibility,
              }
            : null
        );
      },
      []
    ),
    onRenderItemColumn = (instance: AppInstance, _index?: number, col?: IColumn) => {
      if (!col) return <span />;
      const autoSuspend = willAutoSuspend(instance),
        { id, appDetails, status, suspendAfter } = instance;
      switch (col.key) {
        case 'icon':
          return (
            <IconCell
              size={iconSize}
              src={appDetails?.iconLocation ? `/v1/asset/${appDetails.iconLocation}` : '/logo512.png'}
            />
          );
        case 'title-id-state':
          return (
            <TitleCell title={getInstanceAppTitleVersion(instance)} subtitle={id}>
              <>
                {type === InstanceListType.admin && (
                  <div style={{ display: 'flex', marginBottom: 2 }}>
                    <div>
                      {instanceIdAliasMap.get(id) ? (
                        <TextWithCopy
                          styles={[textWithCopyStylesPlain, { root: { lineHeight: '23px' } }]}
                          text={`${instanceIdAliasMap.get(id)}.cloud.h2o.ai`}
                          header={'Alias:'}
                        />
                      ) : (
                        <span
                          style={{
                            display: 'inline-block',
                            marginRight: 8,
                            fontSize: FontSizes.textPrimary,
                            lineHeight: '20px',
                          }}
                        >
                          Alias: No primary alias
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {autoSuspend && <AutoPauseLabel suspendAfter={suspendAfter} />}
              </>
            </TitleCell>
          );
        case 'owner':
          return <MetadataCell title="Owner" metadata={[instance.owner]} />;
        case 'details':
          const dateCreated: Date | undefined = getDate(instance.createTime);
          const dateUpdated: Date | undefined = getDate(instance.updateTime);
          const createdString =
            dateCreated && `Created ${dateCreated.toLocaleDateString()} ${dateCreated.toLocaleTimeString()}`;
          const updatedString =
            dateUpdated && `Updated ${dateUpdated.toLocaleDateString()} ${dateUpdated.toLocaleTimeString()}`;
          const metadata = [];
          if (createdString) metadata.push(createdString);
          if (updatedString) metadata.push(updatedString);
          return <MetadataCell title="Details" metadata={metadata} />;
        case 'status':
          const statusTextColorMap = {
            [DEPLOYED]: theme.palette?.green900,
            [FAILED]: theme.palette?.red900,
            [PENDING]: theme.palette?.yellow900,
            [STATUS_UNKNOWN]: theme.semanticColors?.textPrimary,
            [SUSPENDED]: theme.palette?.red900,
          };

          const statusBackgroundColorMap = {
            [DEPLOYED]: theme.palette?.green100,
            [FAILED]: theme.palette?.red100,
            [PENDING]: theme.palette?.yellow100,
            [STATUS_UNKNOWN]: theme.palette?.gray400,
            [SUSPENDED]: theme.palette?.red100,
          };

          return (
            <BadgeCell
              title="Status"
              dataTest="instance-status-badge"
              badgeLabel={instanceStatusMap[instance.status]}
              badgeBackgroundColor={statusBackgroundColorMap[instance.status]}
              badgeTextColor={statusTextColorMap[instance.status]}
            />
          );
        case 'visibility':
          return (
            <BadgeCell
              title="Visibility"
              dataTest="instance-visibility-badge"
              badgeLabel={instanceVisibilityMap[instance.visibility]}
              badgeIconName={instanceVisibilityIconMap[instance.visibility]}
            />
          );
        case 'actions':
          const visitNotAllowed =
            currentUserName !== instance.owner &&
            (instance.visibility === AppInstance_Visibility.PRIVATE ||
              instance.visibility === AppInstance_Visibility.VISIBILITY_UNSPECIFIED);
          return (
            <ActionCell
              primaryButton={
                <InstanceActionButton
                  instance={instance}
                  loading={loadingMap[instance.id] || false}
                  terminating={terminatingMap[instance.id] || false}
                  onEditInstance={updateVisibility ? toggleVisibilityDialog : undefined}
                  onViewInstanceLog={
                    hideInstanceLogButton ? undefined : goToInstanceLog(instance, type === InstanceListType.admin)
                  }
                  setInstanceSuspension={setInstanceSuspension}
                  terminateInstance={terminateInstance}
                />
              }
              secondaryButtonProps={
                {
                  'data-test': `${instance.id}--visit-instance-button`,
                  text: 'Visit',
                  title: 'Go to app location',
                  onClick: goToInstance(instance),
                  disabled: status !== AppInstance_Status.DEPLOYED || visitNotAllowed,
                } as IButtonProps
              }
            />
          );
      }
      return (
        <Stack horizontalAlign="start" verticalAlign="center" tokens={{ childrenGap: 10 }}>
          <Text styles={labelStyles}>{col.name}</Text>
          <Text styles={contentStyles}>{instance[col.fieldName as keyof string]}</Text>
        </Stack>
      );
    },
    onRenderRow = useCallback((props?: IDetailsRowProps) => {
      return props ? <StyledDetailsRow {...props} /> : null;
    }, []),
    onColumnClick = (_ev?: MouseEvent<HTMLElement>, column?: IColumn) => {
      if (!column || !column.fieldName) return;
      const key = column.fieldName,
        isSortedDescending = column.isSorted ? !column.isSortedDescending : !!column.isSortedDescending;

      const _sortedInstances = instanceList?.length ? instanceList : instances || [];
      setInstanceList(sortList(_sortedInstances, key as keyof AppInstance, isSortedDescending));
      setSortedColumns(
        columns.map((col) => {
          col.isSorted = col.key === column.key;
          if (col.isSorted) {
            col.isSortedDescending = isSortedDescending;
          }
          return col;
        })
      );
    };

  useEffect(() => {
    if (instances) {
      setInstanceList([...instances]);
    }
  }, [instances]);

  useEffect(() => {
    if (loading) {
      const [loadingId, loadingState] = loading;
      setLoadingMap({ ...loadingMap, [loadingId]: loadingState });
    }
    if (terminating) {
      const [terminatingId, terminatingState] = terminating;
      setTerminatingMap({ ...terminatingMap, [terminatingId]: terminatingState });
    }
    setInstanceList([...instanceList]);
  }, [loading, terminating]);

  if (loadingMsg) return <Loader styles={loaderStylesSpinnerXLarge} label={loadingMsg} />;
  if (!instances?.length) return <EmptyStateMessage message="No instances found" />;

  return (
    <>
      <StyledDetailsList
        noHeader
        dataTest="instance-list"
        items={instanceList}
        columns={sortedColumns}
        onRenderItemColumn={onRenderItemColumn}
        onRenderRow={onRenderRow}
        checkboxVisibility={CheckboxVisibility.hidden}
        onColumnHeaderClick={onColumnClick}
      />

      {updateVisibility && (
        <CreateUpdateDialog<UpdateAppInstanceRequest>
          title="Instance visibility"
          fields={visibilityDialogFields}
          onSubmit={(req) => {
            toggleVisibilityDialog(null)();
            updateVisibility(req)();
          }}
          isHidden={!updateInstanceReq}
          initialModel={updateInstanceReq || undefined}
          toggleHideDialog={toggleVisibilityDialog(null)}
          dataTest="update-instance-visibility-open-modal"
          styles={dialogStylesInstanceVisibility}
          dialogContentStyles={dialogContentStylesInstanceVisibility}
        />
      )}
    </>
  );
}
