import { IStyle } from '@fluentui/react';
import { IH2OTheme } from '@h2oai/ui-kit';

export interface IAppConfigPanelContentsStyles {
  root: IStyle;
  infos: IStyle;
  infoTitle: IStyle;
  infoText: IStyle;
  field: IStyle;
}

export const appConfigPanelContentsStylesDefault = (theme: IH2OTheme): IAppConfigPanelContentsStyles => ({
  root: {
    paddingTop: 24,
  },
  infos: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: `8px`,
    overflow: 'hidden',
    marginBottom: 24,
  },
  infoTitle: {
    whiteSpace: 'nowrap',
    lineHeight: 21,
    color: theme.semanticColors?.textQuinary,
  },
  infoText: {
    lineHeight: 21,
    overflow: 'hidden',
  },
  field: {
    paddingBottom: 24,
  },
});
