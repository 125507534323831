import {
  CheckboxVisibility,
  DetailsList,
  IDetailsGroupDividerProps,
  IDetailsRowProps,
  IGroup,
  MessageBarType,
  SearchBox,
  SelectionMode,
  Stack,
} from '@fluentui/react';
import {
  Button,
  PageHeader,
  Panel,
  buttonStylesGhost,
  buttonStylesPrimary,
  groupBy,
  searchBoxStylesDefault,
  sort,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import { useCallback, useMemo, useRef, useState } from 'react';

import { FederatedApp } from '../../../../ai.h2o.cloud.appstore';
import EmptyStateMessage from '../../../../components/EmptyStateMessage/EmptyStateMessage';
import { AppConfigurationSaveOnSubmit } from '../../AppConfiguration/AppConfiguration';
import { detailsListStylesFederatedApps } from '../FederatedAppsList.styles';
import { FederatedAppsListHeader } from '../FederatedAppsListHeader/FederatedAppsListHeader';
import { FederatedAppsListRow } from '../FederatedAppsListRow/FederatedAppsListRow';

export interface IEditAppPanelProps {
  app: FederatedApp;
  onDismiss: () => void;
  refetchApps: () => Promise<any>;
}

function EditAppPanel({ app, onDismiss, refetchApps }: IEditAppPanelProps) {
  const [submitPending, setSubmitPending] = useState(false);
  const { addToast } = useToast();
  return (
    <AppConfigurationSaveOnSubmit
      app={app}
      refetchApp={refetchApps}
      render={({ content, handleSubmit, isChanged }) => {
        return (
          <Panel
            closeButtonAriaLabel={`Dismiss editing of the app: ${app.name}`}
            headerText={app.name}
            isFooterAtBottom
            isOpen
            onDismiss={onDismiss}
            onRenderFooterContent={() => {
              return (
                <>
                  <Button
                    loading={submitPending}
                    disabled={!isChanged || submitPending}
                    onClick={() => {
                      setSubmitPending(true);
                      handleSubmit()
                        .finally(() => {
                          setSubmitPending(false);
                        })
                        .then(onDismiss, () => {
                          addToast({
                            messageBarType: MessageBarType.error,
                            message: `Failed to save changes to the app. Please try again.`,
                          });
                        });
                    }}
                    styles={buttonStylesPrimary}
                    text="Save changes"
                  />
                  <Button styles={buttonStylesGhost} text="Cancel" onClick={onDismiss} />
                </>
              );
            }}
            styles={{
              content: { marginTop: `2.5rem` },
              footerInner: {
                flexFlow: `row-reverse nowrap`,
                display: `flex`,
                gap: `0.5rem`,
              },
              main: {
                maxWidth: `55ch!important`,
                width: `100vw!important`,
              },
            }}
          >
            <Stack tokens={{ childrenGap: `1.5rem` }}>{content}</Stack>
          </Panel>
        );
      }}
    />
  );
}

export interface IFederatedAppsListProps {
  pageTitle: string;
  apps: FederatedApp[];
  refetchApps: () => Promise<any>;
}

export function FederatedAppsList({ pageTitle, apps, refetchApps }: IFederatedAppsListProps): JSX.Element {
  const [searchValue, setSearchValue] = useState<string>(``);
  const onSearch = useCallback((value?: string) => {
    setSearchValue(value || ``);
  }, []);
  const onClearSearch = useCallback(() => {
    setSearchValue(``);
  }, []);

  const groupsRef = useRef<typeof groups | undefined>();
  const { groups, sortedApps, totalApps, totalVersions } = useMemo<{
    // app groupings by name
    groups: Array<IGroup & { data: FederatedApp }>;
    // sorted apps (the complete set)
    sortedApps: FederatedApp[];
    // for display of total number of apps
    totalApps: number;
    totalVersions: number;
  }>(() => {
    const displayedApps = !searchValue
      ? apps
      : apps.filter((app) => app.name?.toLowerCase().includes(searchValue.toLowerCase()));
    if (displayedApps.length === 0) {
      return {
        groups: [],
        sortedApps: [],
        totalApps: 0,
        totalVersions: 0,
      };
    }
    const groupedApps = groupBy<FederatedApp>(displayedApps, 'name');
    const appsSorted: FederatedApp[] = [];
    const sortedGroups: Array<IGroup & { data: FederatedApp }> = [];
    const previousGroupsByAppName = groupsRef.current?.reduce((acc, item) => {
      acc[item.data.name] = item;
      return acc;
    }, {} as Record<string, IGroup>);
    groupedApps.forEach((group, key) => {
      const appsByVersionDesc = group.sort(sort<FederatedApp>(['version'], 'semver')).reverse();
      const latestApp = appsByVersionDesc[0];
      const previousGroup = previousGroupsByAppName?.[latestApp.name];
      sortedGroups.push({
        count: appsByVersionDesc.length,
        data: latestApp,
        isCollapsed: previousGroup?.isCollapsed ?? true,
        key,
        name: key,
        startIndex: appsSorted.length,
      });
      appsSorted.push(...appsByVersionDesc);
    });
    return {
      groups: sortedGroups,
      sortedApps: appsSorted,
      totalApps: groupedApps.size,
      totalVersions: displayedApps.length,
    };
  }, [apps, searchValue]);
  groupsRef.current = groups;

  const theme = useTheme(),
    [editedApp, setEditedApp] = useState<FederatedApp | undefined>(),
    // custom row render
    onRenderRow = useCallback((props: IDetailsRowProps | undefined) => {
      if (!props) return null;
      const rowApp = props.item as FederatedApp;
      return <FederatedAppsListRow app={rowApp} onEdit={() => setEditedApp(rowApp)} />;
    }, []),
    // custom group header
    onRenderGroupHeader = useCallback((props: IDetailsGroupDividerProps | undefined): JSX.Element => {
      const toggleCallback = (): void => {
        props?.onToggleCollapse!(props.group!);
      };
      const headerProps = {
        group: props?.group,
        toggleCollapse: toggleCallback,
      };
      return <FederatedAppsListHeader {...headerProps} />;
    }, []);

  return (
    <>
      {editedApp && (
        <EditAppPanel app={editedApp} onDismiss={() => setEditedApp(undefined)} refetchApps={refetchApps} />
      )}
      <PageHeader pageTitle={pageTitle} description={`${totalApps} apps, ${totalVersions} versions`} />
      <div style={{ maxWidth: '45ch', padding: '20px 0 10px 0' }}>
        <SearchBox
          styles={searchBoxStylesDefault(theme)}
          placeholder="Search by app name"
          onSearch={onSearch}
          onClear={onClearSearch}
        />
      </div>
      <div>
        {!sortedApps.length ? (
          <EmptyStateMessage message="No apps found" />
        ) : (
          <DetailsList
            items={sortedApps}
            setKey="set"
            isHeaderVisible={false}
            onRenderRow={onRenderRow}
            selectionMode={SelectionMode.none}
            checkboxVisibility={CheckboxVisibility.hidden}
            groups={groups}
            groupProps={{
              onRenderHeader: onRenderGroupHeader,
            }}
            styles={detailsListStylesFederatedApps(theme)}
          />
        )}
      </div>
    </>
  );
}
