import { IRawStyle, IStyle } from '@fluentui/react';

import { DefaultPageStyles } from '../../themes/themes';

export interface IHomePageStyles {
  root: IStyle;
}

export const homePageStylesDefault: IHomePageStyles = {
  root: {
    ...(DefaultPageStyles as IRawStyle),
    paddingTop: 0,
  },
};
