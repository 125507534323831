/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ai/h2o/engine/v1/dai_version_service.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1DAIVersion,
    V1DAIVersionFromJSON,
    V1DAIVersionFromJSONTyped,
    V1DAIVersionToJSON,
} from './V1DAIVersion';

/**
 * Response message for DAIVersionService.ListDAIVersions.
 * @export
 * @interface V1ListDAIVersionsResponse
 */
export interface V1ListDAIVersionsResponse {
    /**
     * Collection of DAIVersions.
     * @type {Array<V1DAIVersion>}
     * @memberof V1ListDAIVersionsResponse
     */
    daiVersions?: Array<V1DAIVersion>;
    /**
     * Used to retrieve the next page of results.
     * When unset, no further items are available (this response was the last page).
     * @type {string}
     * @memberof V1ListDAIVersionsResponse
     */
    nextPageToken?: string;
    /**
     * Total number of DAIVersions matched by the List request.
     * @type {number}
     * @memberof V1ListDAIVersionsResponse
     */
    totalSize?: number;
}

export function V1ListDAIVersionsResponseFromJSON(json: any): V1ListDAIVersionsResponse {
    return V1ListDAIVersionsResponseFromJSONTyped(json, false);
}

export function V1ListDAIVersionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ListDAIVersionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'daiVersions': !exists(json, 'daiVersions') ? undefined : ((json['daiVersions'] as Array<any>).map(V1DAIVersionFromJSON)),
        'nextPageToken': !exists(json, 'nextPageToken') ? undefined : json['nextPageToken'],
        'totalSize': !exists(json, 'totalSize') ? undefined : json['totalSize'],
    };
}

export function V1ListDAIVersionsResponseToJSON(value?: V1ListDAIVersionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'daiVersions': value.daiVersions === undefined ? undefined : ((value.daiVersions as Array<any>).map(V1DAIVersionToJSON)),
        'nextPageToken': value.nextPageToken,
        'totalSize': value.totalSize,
    };
}

