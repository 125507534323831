import { IconNamesInput } from '@fluentui/font-icons-mdl2/lib/IconNames';
import { IContextualMenuItem, concatStyleSets } from '@fluentui/react';
import {
  Button,
  IButtonProps,
  IconName,
  buttonStylesSplit,
  buttonStylesSplitDanger,
  buttonStylesSplitGhost,
  buttonStylesSplitPrimary,
  buttonStylesSplitSecondary,
  loaderStylesSpinnerButtonPrimary,
  loaderStylesSpinnerButtonSecondary,
  buttonStylesSplitSmall as uiKitButtonStylesSplitSmall,
  useTheme,
} from '@h2oai/ui-kit';

import {
  AIEMOpType,
  AIEngine,
  EngineState,
  V1DAIEngineState,
  V1EngineState,
  V1EngineType,
  hasOp,
  isTransitionalState,
} from '../../../../aiem';
import { useEngine } from '../../../../aiem/hooks';
import { useCloudPlatformDiscovery } from '../../../../utils/hooks';

export interface EngineActionButtonProps {
  engine: AIEngine;
  viewEngine?: (engine: AIEngine) => void;
  editEngine?: (engine: AIEngine) => void;
  deleteEngine?: (engine: AIEngine) => void;
  pauseEngine?: (engine: AIEngine) => void;
  resumeEngine?: (engine: AIEngine) => void;
  updateEngine?: (engine: AIEngine) => void;
  upgradeEngine?: (engine: AIEngine) => void;
  readLogs?: (engine: AIEngine) => void;
  openLegacyEngineLogs?: (engine: AIEngine) => void;
}

const canResume = (type: V1EngineType, state: EngineState) => hasOp(AIEMOpType.resume, type, state);

const buttonStylesSplitSmall = concatStyleSets(uiKitButtonStylesSplitSmall, { root: { width: 125 } });

export function EngineActionButton(props: EngineActionButtonProps) {
  const { palette } = useTheme(),
    { getEngineStateData } = useEngine(),
    platformDiscovery = useCloudPlatformDiscovery(),
    hasLoggingService = Boolean(platformDiscovery?.loggingServicesApiUrl),
    getMenuItems = ({
      engine,
      viewEngine,
      editEngine,
      deleteEngine,
      pauseEngine,
      upgradeEngine,
      readLogs,
      openLegacyEngineLogs,
    }: EngineActionButtonProps): IContextualMenuItem[] => {
      const hasMenuItem = (op: AIEMOpType) => hasOp(op, engineType!, enumState!),
        menuItems: IContextualMenuItem[] = [],
        style = { color: palette?.gray900 };
      const { upgradeAvailable } = engine;
      if (engineType === V1EngineType.Unspecified) return [];
      if (hasMenuItem(AIEMOpType.view)) {
        menuItems.push({
          key: 'view',
          text: 'View Details',
          onClick: () => viewEngine!(engine),
          style,
        });
      }
      if (hasMenuItem(AIEMOpType.edit)) {
        menuItems.push({
          key: 'edit',
          text: 'Edit',
          onClick: () => editEngine!(engine),
          style,
        });
      }
      if (hasMenuItem(AIEMOpType.pause) && enumState !== String(V1EngineState.Running)) {
        menuItems.push({
          key: 'pause',
          text: engine.type === V1EngineType.H2O ? 'Shut down' : 'Pause',
          onClick: () => pauseEngine!(engine),
          style,
        });
      }
      if (hasLoggingService) {
        menuItems.push({
          key: 'readLogs',
          text: 'Logs',
          onClick: () => readLogs!(engine),
        });
      } else if (hasMenuItem(AIEMOpType.openLog)) {
        menuItems.push({
          key: 'legacyReadLogs',
          text: 'Logs',
          onClick: () => openLegacyEngineLogs!(engine),
        });
      }
      if (hasMenuItem(AIEMOpType.upgrade) && upgradeAvailable) {
        menuItems.push({
          key: 'upgrade',
          text: 'Upgrade',
          onClick: () => upgradeEngine!(engine),
          style,
        });
      }
      if (hasMenuItem(AIEMOpType.delete)) {
        menuItems.push({
          key: 'delete',
          text: 'Delete',
          onClick: () => deleteEngine!(engine),
          style: { color: palette?.red500 },
        });
      }
      return menuItems;
    },
    { engine, viewEngine, resumeEngine, pauseEngine } = props,
    { engineType, state: enumState, deprecatedVersion: deprecated } = engine,
    starting = String(enumState) === V1EngineState.Starting,
    stateData = getEngineStateData(enumState),
    { title } = stateData || { title: '' },
    styles = [buttonStylesSplitPrimary, buttonStylesSplitSmall],
    menuItems = getMenuItems(props),
    buttonProps: IButtonProps = {
      styles,
      split: true,
      menuIconName: IconName.ChevronDown,
      menuItems,
    },
    pauseButton = (
      <Button
        {...buttonProps}
        styles={[buttonStylesSplitSecondary, buttonStylesSplitSmall]}
        onClick={() => pauseEngine!(engine)}
        iconName={IconName.Pause}
        text="Pause"
      />
    ),
    shutdownButton = (
      <Button
        {...buttonProps}
        styles={[buttonStylesSplit, buttonStylesSplitDanger, buttonStylesSplitSmall]}
        onClick={() => pauseEngine!(engine)}
        iconName={'PowerButton' as IconNamesInput}
        text="Shut down"
      />
    ),
    resumeButton = (
      <Button
        {...buttonProps}
        onClick={() => resumeEngine!(engine)}
        primaryDisabled={deprecated}
        iconName={IconName.MSNVideos}
        text="Resume"
      />
    ),
    viewButton = (
      <Button
        {...buttonProps}
        menuItems={menuItems.filter((item) => item.key !== 'view')}
        styles={[buttonStylesSplit, buttonStylesSplitGhost, buttonStylesSplitSmall]}
        onClick={() => viewEngine!(engine)}
        iconName={'View' as IconNamesInput}
        text="View"
      />
    ),
    transitionalButton = (
      <Button
        menuItems={menuItems}
        split
        loaderWithMenuButton
        loading
        loaderProps={{
          label: title,
          styles: starting ? loaderStylesSpinnerButtonPrimary : loaderStylesSpinnerButtonSecondary,
        }}
        styles={[starting ? buttonStylesSplitPrimary : buttonStylesSplitSecondary, buttonStylesSplitSmall]}
        onClick={() => {}}
      />
    );
  return isTransitionalState(enumState!)
    ? transitionalButton
    : canResume(engineType!, enumState!)
    ? resumeButton
    : enumState === String(V1DAIEngineState.Running) && engineType === V1EngineType.DriverlessAi
    ? pauseButton
    : enumState === String(V1DAIEngineState.Running) && engineType === V1EngineType.H2O
    ? shutdownButton
    : viewButton;
}
